import { ChangeDetectorRef, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AthenaSearchService } from 'src/app/core/http/athena-search.service';
import { OpenAiMessage } from 'src/app/shared/models/chatbot';
import { PromptFlowService } from 'src/app/core/http/prompt-flow.service';
import { ChatInteraction, ChatRequestModel } from 'src/app/shared/models/chat-request';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chatbot-icon',
  templateUrl: './chatbot-icon.component.html',
  styleUrls: ['./chatbot-icon.component.scss'],
  standalone: true,
  imports: [CdkDrag, OverlayModule,  MatIconModule, FormsModule, CommonModule, MatDialogModule, MatProgressSpinnerModule]
})
export class ChatbotIconComponent {

  // @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  
  isOpen: boolean = false;
  newMessage: string = '';
  query: string = '';
  chatbotAnswers: string[]=[];
  // i = 0; not being used?
  messages: OpenAiMessage[] = [];
  userMessages: string[]=[];
  currentFullUserProfile: any;
  answer: any;

  newMessages: boolean = false;
  isLoading: boolean = false;
  hasScrolledToSpinner: boolean = true;
  tempUserChat: string = "";
  recentlyClosed: boolean = false;

  chatHistory: ChatInteraction[]=[];

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private promptFlowService: PromptFlowService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentFullUserProfile = JSON.parse(sessionStorage.getItem("profile"));
  }

  ngAfterViewChecked() {
    if(this.newMessages){
      this.scrollToLastResponse('chatbot');
      this.newMessages = false;
    }
    if(!this.hasScrolledToSpinner){
      this.scrollToSpinner();
      this.hasScrolledToSpinner = true;
    }
    if(this.recentlyClosed && this.isOpen){
      if(this.isLoading){
        this.scrollToSpinner();
      }else if(this.chatHistory.length > 0){
        this.scrollToLastResponse('user');
      }
      this.recentlyClosed = false;
    }
  }

  replaceAllBoldMarkers(text: string): string {
    let result = '';
    let startIndex = 0;
    let boldOpen = true;

    while (true) {
        let boldIndex = text.indexOf('**', startIndex);
        if (boldIndex === -1) {
            result += text.slice(startIndex);
            break;
        }

        result += text.slice(startIndex, boldIndex);
        result += boldOpen ? '<b>' : '</b>';
        boldOpen = !boldOpen;
        startIndex = boldIndex + 2;
    }

    return result;
}
  
  sendMessage(query: string) {
    this.tempUserChat = this.query;
    this.isLoading = true;
    this.hasScrolledToSpinner = false;
    this.getChatbotResponse(query);
    this.clearInputField();
  }

  async getChatbotResponse(query: string){
    const conversation: ChatInteraction = {
      inputs: "",
      outputs: ""
    };


    let chatRequest = new ChatRequestModel();

    chatRequest.userQuestion = query;
    chatRequest.chatHistory = this.chatHistory;
    chatRequest.environment = environment.type.toLowerCase();

    this.promptFlowService.sendMessage(chatRequest).subscribe({
      next: (res) => {
        if(res){
          this.answer = JSON.parse(res.chatHistory[0].outputs).answer;
          this.chatbotAnswers.push(JSON.parse(res.chatHistory[0].outputs).answer);
          conversation.inputs = query;
          conversation.outputs = this.replaceAllBoldMarkers((JSON.parse(res.chatHistory[0].outputs).answer));
        }
      },
      error: (error) => {
        console.error('Error:', error);
      },
      complete: () => {
        this.updateChatHistory(query,conversation);
        this.isLoading = false;
      }  
    });
  }

  clearInputField(){
    const inputField = document.getElementById('input-field') as HTMLInputElement;
    inputField.value = "";
    this.query="";
  }

  updateChatHistory(messages:string, conversation:ChatInteraction){
    this.userMessages.push(messages);
    this.chatHistory.push(conversation);
    this.newMessages = true;
  }

  scrollToBottom() {
    var scrollContainer = document.getElementById('scrollContainer');
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }

  scrollToSpinner(){
    const chatbotSpinner = document.getElementById("tempChatbotSpinner") as HTMLDivElement;
    if(chatbotSpinner){
      chatbotSpinner.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  // For the following two methods, messageType can be either:
  // 'user'    : For user messages
  // 'chatbot' : For chatbot messages
  scrollToLastResponse(messageType: string){
    this.scrollToResponse(messageType, this.chatHistory.length-1);
  }

  // All messages apart from the starter messages are numbered starting from 0.
  // Input a messageType and an id to go to a specific message.
  scrollToResponse(messageType: string, id: number){
    const chatResponse = document.getElementById(messageType+id) as HTMLDivElement;
    if(chatResponse){
      chatResponse.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  closeChatbot(){
    this.recentlyClosed = true;
    this.isOpen = false;
  }
}
