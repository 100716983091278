import { Component, TemplateRef } from "@angular/core";
import { ToastService } from "src/app/core/services/toast.service";

@Component({
  selector: "app-success-toast",
  templateUrl: "./success-toast.component.html",
  styleUrls: ["./success-toast.component.scss"],
  host: { "[class.ngb-toasts]": "true" },
})
export class SuccessToastComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
