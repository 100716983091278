import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { Region } from "src/app/shared/models/region";

@Injectable({
  providedIn: "root",
})
export class RegionService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}Region`;

    public getRegion(code: string): Observable<Region> {
      return this.http.get<Region>(`${this.apiRoot}/getRegion/${code}`);
    }



}