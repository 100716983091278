<div *ngIf="showDashboard">
  <div class="d-flex flex-row justify-content-around">
    <div class="col-6">
      <mat-tab-group
        [selectedIndex]="projectLookbackService.dashboardTab"
        (selectedTabChange)="projectLookbackService.DashBoardTabChange($event)"
      >
        <mat-tab *ngFor="let tab of tabs" [label]="tab.label"> </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="d-flex flex-column justify-content-start">
    <!---This looks like a very weird/hardcoded way to do this.. Should do an API call to get length-->
    <h3 class="m-4">
      You are currently participating in
      {{ projectLookbackService.currentVisibleProjectLookbacks?.length }} project lookbacks
    </h3>
    <div class="guideContainer">
      <h5 (click)="openPlbGuide()"> Need some help? </h5>
    </div>
  </div>
  <div class="addPlbHeader">
    <button
    type="button"
    class="btn btn-primary btn-lg"
    (click)="projectLookbackService.onClickCreateNewProjectLookback()"
    *ngIf="isAdmin"
  >
    + project lookback
  </button>
  </div>
  <div class="d-flex flex-row justify-content-around">
    <div class="row justify-content-around">
        <!-- JOIN LOOKBACK -->
        <div
        class="d-flex flex-column align-items-stretch"
        class="col-2 mt-4 project-lookback-card join-lookback-background p-4 d-flex flex-column justify-content-start"
      >
        <div class="mt-4">
          <h1>join a new project lookback</h1>
        </div>
        <div class="mt-4">
          <input matInput [(ngModel)]="projectCode" placeholder="please enter valid code" />
        </div>
        <div class="mt-4">
          <button class="btn" matButton (click)="getUserInput(projectCode)">
            join
          </button>
        </div>
      </div>
      <div
        style="cursor: pointer"
        *ngFor="
          let plb of projectLookbackService.currentVisibleProjectLookbacks
        "
      >
        <div
          (click)="openProjectLookbackDetail(plb)"
          *ngFor="
            let itemgroup of projectLookbackService.projectLookbackCommonService
              .visibleFieldItems;
            let i = index
          "
        >
          <div class="d-flex flex-row align-items-stretch mt-4">
            <div>
              <ng-container class="container">
                <mat-card
                  class="project-lookback-card"
                >
                <div class="color-rec">&nbsp;</div>
                  <mat-card-header class="header">
                    <div
                      class="d-flex flex-row justify-content-between"
                      style="width: 100%"
                    >
                      <div class="d-flex flex-column plbTitle">
                        <h2>{{ plb.projectName }}</h2>
                      </div>
                      <div style="gap: 10px" class="d-flex flex-row">
                        <mat-icon matTooltip="share"
                          class="buttonHover"
                          (click)="$event.stopPropagation(); goToShare(plb)"
                          >share</mat-icon
                        >
                        <mat-icon
                          *ngIf="isAdmin"
                          style="color: red; cursor: pointer"
                          class="buttonHover"
                          (click)="$event.stopPropagation(); delete(plb)"
                          matTooltip="delete"
                          >delete</mat-icon
                        >
                      </div>
                    </div>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="d-flex flex-row">
                      <div class="row" style="max-width: 100%;">
                        <ng-container>
                          <div
                            class="col-6 mt-4"
                            *ngFor="let item of itemgroup.value; let j = index"
                          >
                            <app-project-lookback-dynamic-component
                              #p{{i}}{{j}}
                              [controlType]="item.value.feMetadata.controlType"
                              [isVisible]="item.value.feMetadata.isVisible"
                              [labelValue]="item.value.feMetadata.formLabel"
                              [className]="
                                projectLookbackService.projectLookbackCommonService.isFieldRequired(
                                  projectLookbackService.projectLookback.status,
                                  item.key
                                )
                              "
                              [fieldValue]="
                                projectLookbackService.getProjectLookbackFieldValue(
                                  item.key,
                                  plb
                                )
                              "
                              [isEditable]="
                                projectLookbackService.projectLookbackCommonService.isFieldEditable(
                                  projectLookbackService.projectLookback
                                    .isProjectLookbackEditable,
                                  projectLookbackService.projectLookback.status,
                                  item.key
                                )
                              "
                              [extension]="projectLookbackService.extensionType"
                              [userType]="item.value.feMetadata.dataType"
                              [groupName]="itemgroup.key"
                              [previousProjectLookback]="
                                projectLookbackService.projectLookback
                              "
                              [isFromCreateNewProjectLookback]="false"
                              [fieldList]="
                                item.key == 'transitionName'
                                  ? projectLookbackService
                                      .projectLookbackEvalService
                                      .projectLookbackNextSteps
                                  : projectLookbackService.projectLookbackCommonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
                                      itemgroup.key,
                                      item.key,
                                      projectLookbackService.projectLookback
                                        .workflowType
                                    )
                              "
                            >
                            </app-project-lookback-dynamic-component>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </mat-card-content>
                  <mat-card-footer class="footer">
                    <p class="name-label m-2">Project Overview</p>
                    <div class="d-flex flex-row justify-content-between mb-3">
                      <ng-container *ngFor="let status of plb.lessonStatuses">
                        <div
                          class="col-4 d-flex flex-column align-items-center"
                        >
                          <p class="name-label">{{ status?.key }} [{{ status?.count }}]</p>
                        </div>
                      </ng-container>
                    </div>
                  </mat-card-footer>
                </mat-card>
              </ng-container>
            </div>
            <div class="col-1">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
