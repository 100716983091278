<div>
  <div class="text-primary bold m-2">verify reported lesson</div>
  <div class="m-2">
    <small class="text-muted"
      >once you verify the report, it will move to resolved page</small
    >
  </div>

  <span class="row col-12">
    <span class="col-11"></span>
    <small class="text-muted bold col-1"
      >{{ managerComments?.length }}/250</small
    >
  </span>
  <div class="m-2">
    <mat-form-field appearance="outline" style="width: 100%">
      <textarea
        id="textField"
        maxlength="250"
        style="line-height: normal"
        matInput
        [(ngModel)]="managerComments"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="row justify-content-center col-12 m-0">
    <button
      type="button"
      mat-button
      style="border: 1px solid #0066b2"
      class="btn btn-outline-primary col-5"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <span class="col-2"></span>
    <button
      type="button"
      [disabled]="!(managerComments?.length > 0)"
      (click)="confirm()"
      mat-button
      class="btn btn-primary col-5"
      id="submitButton"
    >
      submit
    </button>
  </div>
</div>
