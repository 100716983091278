import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "src/app/core/http/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styleUrls: ["./profile-edit.component.scss"],
})
export class ProfileEditComponent implements OnInit {
  UserBu: string;
  public enableTutorial: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProfileEditComponent>,
    private userService: UserService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.UserBu = sessionStorage.getItem("businessunit");
    this.enableTutorial = !this.data.user.skipTutorial;
  }

  close(): void {
    this.dialogRef.close();
  }

  update() {
    this.data.user.skipTutorial = !this.enableTutorial;
    this.userService.updateProjectLookbackUser(this.data.user).subscribe({
      next: (resp) => {
        sessionStorage.setItem("profile-ell", JSON.stringify(resp));
        this.data.user = resp;
        this.dialogRef.close();
      },
      error: (err) => {
        this.snackBar.open(err);
        this.dialogRef.close();
      },
    });
  }
}
