import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SearchTutorialComponent } from "src/app/modules/home/dialogs/search-tutorial/search-tutorial.component";

@Injectable({
  providedIn: "root",
})
export class SearchTutorialService {
  public isFirstLoad = true;
  public scopeFilter: boolean;
  public sortFilter: boolean;
  public facetFilter: boolean;
  constructor(public dialog: MatDialog) {}

  public openInitialDialogue() {
    this.setSearchFirstLoad();
    const dialogRef = this.dialog.open(SearchTutorialComponent, {
      width: "50%",
      data: {
        title: "welcome to your new search view!",
        body: `find all the functionalities from Lesson Learned Tool and much more in this 
                          new search view. Discover filter search and customize your favorite searches.`,
        nextDisplay: "let's start >",
        skipDisplay: "do not show me again",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) this.filtersDescriptionDialogue();
    });
  }

  public filtersDescriptionDialogue() {
    this.scopeFilter = true;
    const dialogRef = this.dialog.open(SearchTutorialComponent, {
      width: "50%",
      data: {
        title: "toggle around your main filters",
        body: `find all the functionalities from Lesson Learned Tool and much more in this 
                            new search view. Discover filter search and customize your favorite searches.`,
        nextDisplay: "ok, got it >",
      },
      position: { top: "300px", left: "4%" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.scopeFilter = false;
      if (!result) this.sortingDescriptionDialogue();
    });
  }

  public sortingDescriptionDialogue() {
    this.sortFilter = true;
    const dialogRef = this.dialog.open(SearchTutorialComponent, {
      width: "50%",
      data: {
        title: "choose items per page and sort results",
        body: `find all the functionalities from Lesson Learned Tool and much more in this 
                            new search view. Discover filter search and customize your favorite searches.`,
        nextDisplay: "ok, got it >",
      },
      position: { top: "280px", left: "750px" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.sortFilter = false;
      if (!result) this.facetDescriptionDialogue();
    });
  }

  public facetDescriptionDialogue() {
    this.facetFilter = true;
    const dialogRef = this.dialog.open(SearchTutorialComponent, {
      width: "50%",
      data: {
        title: "select your preferred filters using side view filter section",
        body: `find all the functionalities from Lesson Learned Tool and much more in this 
                            new search view. Discover filter search and customize your favorite searches.`,
        nextDisplay: "ok, got it >",
      },
      position: { top: "25%", left: "19%" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.facetFilter = false;
    });
  }

  public setSearchFirstLoad() {
    sessionStorage.setItem("search", JSON.stringify(this.isFirstLoad));
  }

  public isSearchFirstLoad(): string {
    return sessionStorage.getItem("search");
  }
}
