<div *ngIf="form">
  <div class="title-label" *ngIf="isEditable">
    <label
      [ngClass]="className"
      style="text-align: left; font-size: 15px; font-weight: bold"
    >
      {{ labelValue }}:
    </label>
    <div
      mwlTextInputHighlightContainer
      class="page-header-text"
      [formGroup]="form"
      style="width: 90% !important;"
      *ngIf="controlType == 'TextBox'"
    >
      <mat-form-field>
        <textarea
          class="textarea"
          mwlTextInputElement
          matInput
          [(ngModel)]="bindingModel[fieldId]"
          [(value)]="fieldValue"
          [formControlName]="fieldId"
          (change)="valueSelected($event)"
          #textarea
        >
        </textarea>
        <!-- <mwl-text-input-highlight [textInputElement]="textarea">
        </mwl-text-input-highlight> -->
      </mat-form-field>
    </div>

    <mat-form-field
      *ngIf="controlType == 'MatSelectSingle'"
      [formGroup]="form"
      appearance="outline"
      style="width: 81% !important"
      class="businessUnit"
    >
      <mat-select
        [(value)]="fieldValue"
        class="mat-select-style"
        placeholder="select one"
        [(ngModel)]="bindingModel[fieldId]"
        panelClass="mat-select-panel-class"
        [id]="fieldId"
        [formControlName]="fieldId"
        (selectionChange)="valueSelected($event)"
      >
        <mat-option [value]="" [matTooltip]="">
          <span></span>
        </mat-option>
        <mat-option
          *ngFor="
            let ref of projectLookbackCommonService.getFieldSelectValues(
              fieldMetadata,
              fieldDictatingForCrossReferenceConfig,
              groupName,
              fieldList
            )
          "
          [matTooltip]="ref.Description"
          [value]="ref.Code"
        >
          <span>{{ ref.Description }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      [ngClass]="formFieldClass"
      *ngIf="controlType == 'MatSelectMultiple'"
      [formGroup]="form"
      appearance="outline"
    >
      <mat-select
        [(value)]="fieldValue"
        class="mat-select-style"
        placeholder="select multiple"
        [(ngModel)]="bindingModel[fieldId]"
        panelClass="mat-select-panel-class"
        multiple
        [id]="fieldId"
        [formControlName]="fieldId"
        (selectionChange)="valueSelected($event)"
      >
        <mat-option
          *ngFor="
            let ref of projectLookbackCommonService.getFieldSelectValues(
              fieldMetadata,
              fieldDictatingForCrossReferenceConfig,
              groupName,
              fieldList
            )
          "
          [matTooltip]="ref.Description"
          [disabled]="isRemoveCurrentSelection && ref.Code === fieldValue"
          [value]="ref.Code"
        >
          <span>{{ ref.Description }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="page-header-text" *ngIf="controlType == 'MatDatePicker'" class="deadline">
      <mat-form-field>
        <input
          matInput
          class="dateInput"
          placeholder="Pick a date"
          style="width: 100% !important"
          [(value)]="fieldValue"
          [matDatepicker]="picker"
          [formControlName]="fieldId"
          [(ngModel)]="bindingModel[fieldId]"
          [min]="projectLookbackService.startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          startView="year"
          [startAt]="projectLookbackService.startDate"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div
      class="page-header-text"
      [formGroup]="form"
      *ngIf="controlType == 'Input'"
    >
      <input
        class="bulkInputTitle inputcheckbox"
        [(ngModel)]="bindingModel[fieldId]"
        [(value)]="fieldValue"
        [formControlName]="fieldId"
        (change)="valueSelected($event)"
      />
    </div>

    <div
      class="page-header-text"
      [formGroup]="form"
      *ngIf="controlType == 'InputNumber'"
    >
      <input
        numbersOnly
        class="bulkInputTitle inputcheckbox"
        [(ngModel)]="bindingModel[fieldId]"
        [formControlName]="fieldId"
        (change)="valueSelected($event)"
      />
    </div>
  </div>
  <div *ngIf="!isEditable">
    <label
      class="title-label"
      [ngClass]="className"
      style="text-align: left; font-size: 15px; font-weight: bold"
    >
      {{ labelValue }}:
    </label>
    <div>
      <label *ngIf="controlType !== 'MatDatePicker' && !isUser">
        {{ convertFieldValue(fieldValue) }}
      </label>

      <label *ngIf="controlType == 'MatDatePicker'">
        {{ fieldValue | date : "MM/dd/yyyy" }}
      </label>
    </div>
  </div>
</div>

<div *ngIf="!form">
  <div>
    <h5>
      {{ labelValue }}
    </h5>
    <div>
      <label class="plb-single-line-ellipsis" *ngIf="controlType !== 'MatDatePicker' && !isUser">
        {{ convertFieldValue(fieldValue) }}
      </label>

      <label class="plb-single-line-ellipsis" *ngIf="controlType == 'MatDatePicker'">
        {{ fieldValue | date : "MM/dd/yyyy" }}
      </label>
    </div>
  </div>
</div>

<div class="readOnlyField">
  <label
    class="plb-single-line-ellipsis"
    *ngIf="
      (controlType == 'MatSelectSingle' ||
        controlType == 'Input' ||
        controlType == 'MatAutoComplete') &&
      isUser
    "
  >
    {{ fieldValue?.fullName ? fieldValue?.fullName : fieldValue?.uniqueKey }}
  </label>
  <label
    class="plb-single-line-ellipsis" 
    *ngIf="controlType == 'MatSelectMultiple' && isUser">
    {{ projectLookbackCommonService.getAllValuesFromArrayUsers(fieldValue) }}
  </label>
</div>
