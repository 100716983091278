<div class="container" style="height: 100%; width: 100%">
  <img
    src="assets/images/404.svg"
    style="background-size: cover; background-attachment: fixed"
    alt=""
  />
  <div class="text-block">
    <h1 class="error">404</h1>
    <hr style="width: 35%; color: white" />
    <p class="text">Uh-oh! It looks like you're lost. This might be on us - please refresh or reach out to the support team for further assistance.</p>
  </div>
</div>
