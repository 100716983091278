import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { LessonService } from "../../http/lesson.service";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LessonSearchService {
  searchTrigger = new Subject();

  constructor(private router: Router, private lessonService: LessonService) {}

  performSearch(scope, searchText = "") {
    const data = { scope, searchText: searchText };
    this.searchTrigger.next(data);
    this.router.navigate(["/lessons"], { state: { data } });
  }
}
