import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { firstValueFrom } from "rxjs";
import { PackageListCreateComponent } from "src/app/modules/home/dialogs/package-list-create/package-list-create.component";
import { Lesson, PackageList, PackageListItem } from "src/app/shared/models";
import { PackagingLessonService } from "../../http/packaging-lesson.service";
import { ToastService } from "../toast.service";

@Injectable({
  providedIn: "root",
})
export class PackageService {
  packageList: PackageList = new PackageList();

  constructor(
    private toastService: ToastService,
    public dialog: MatDialog,
    public packageService: PackagingLessonService
  ) {}

  getPackageList() {
    let packageList: PackageList = JSON.parse(
      localStorage.getItem("package-list")
    );
    return packageList;
  }

  // Package Lessons
  getPackageLists() {
    this.packageList = this.getPackageList();
    if (this.packageList === null) {
      this.packageList = new PackageList();
      this.updatePackageListLocalStorage();
    }
  }

  deletePackageList(list: PackageListItem) {
    const index = this.packageList.lists.indexOf(list);
    if (index > -1) {
      firstValueFrom(
        this.packageService.deletePackagedLesson(list.packageName)
      ).then((res) => {
        this.packageList.lists.splice(index, 1);
      });
      this.updatePackageListLocalStorage();
    }
  }

  newPackageList(name: string) {
    if (name && name.length > 0) {
      if (this.packageList.lists) {
        if (this.packageList.lists.find((x) => x.packageName === name)) {
          // Return Toast, name already exist
          this.toastService.showError(
            true,
            "Uh-oh! Looks like a package with name: " + name + " already exist. Please use a different name and try again."
          );
        } else {
          let newPackage = new PackageListItem();
          newPackage.packageName = name;
          newPackage.packageLessons = [];
          this.packageList.lists.push(newPackage);
          this.createNewPackage(name);
        }
      } else {
        this.packageList = new PackageList();
        this.packageList.lists = [];
        let newPackage = new PackageListItem();
        newPackage.packageName = name;
        newPackage.packageLessons = [];
        this.packageList.lists.push(newPackage);
        this.createNewPackage(name);
      }
    }
  }

  async addLessonFromPackageList(list: PackageListItem, item: Lesson) {
    const listIndex = this.packageList.lists.indexOf(list);
    if (listIndex > -1) {
      if (
        this.packageList.lists[listIndex].packageLessons.find(
          (x) => x.id === item.id
        )
      ) {
        // Return Toast, lesson alread on list
        this.toastService.showError(true,
          "Uh-oh! Lesson: " +
            item.displayId +
            " already exist in package: " +
            this.packageList.lists[listIndex].packageName
        );
      } else {
        this.packageList.lists[listIndex].packageLessons.push(item);
        this.toastService.showSuccess(
          true,
          "Lesson: " +
            item.displayId +
            " added to package: " +
            this.packageList.lists[listIndex].packageName
        );
        await this.createNewPackage(list.packageName, [item.id]);
      }
    }
  }

  async addAllLessonFromPackageList(list: PackageListItem, item: Lesson[]) {
    const listIndex = this.packageList.lists.indexOf(list);
    if (listIndex > -1) {
      if (item) {
        for (const lesson of item) {
          if (
            this.packageList.lists[listIndex].packageLessons.find(
              (x) => x.id === lesson.id
            )
          ) {
            //Item already exist in list, ignore entry.
          } else {
            this.packageList.lists[listIndex].packageLessons.push(lesson);
            let itemIds = item.flatMap((l) => l.id);
            await this.createNewPackage(list.packageName, itemIds);
          }
        }
        this.toastService.showSuccess(
          true,
          "Lessons have been added to package: " +
            this.packageList.lists[listIndex].packageName
        );
      }
    }
  }

  async removeLessonFromPackageList(list: PackageListItem, item: Lesson) {
    const listIndex = this.packageList.lists.indexOf(list);
    if (listIndex > -1) {
      const itemIndex =
        this.packageList.lists[listIndex].packageLessons.indexOf(item);
      if (itemIndex > -1) {
        firstValueFrom(
          await this.packageService.deletePackagedLesson(
            list.packageName,
            item.id
          )
        ).then((res) => {
          this.packageList.lists[listIndex].packageLessons.splice(itemIndex, 1);
          this.toastService.showSuccess(
            true,
            "Lesson: " +
              item.displayId +
              "removed from package: " +
              this.packageList.lists[listIndex].packageName
          );
        });
        await this.updatePackageListLocalStorage();
      }
    }
  }

  openCreatePackageListDialog() {
    const dialogRef = this.dialog.open(PackageListCreateComponent, {
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.newPackageList(result);
      this.dialog.closeAll();
    });
  }

  async updatePackageListLocalStorage() {
    firstValueFrom(this.packageService.getPackagedLessons()).then((res) => {
      let packageLists: PackageList = { lists: res };
      localStorage.setItem(
        "package-list",
        res ? JSON.stringify(packageLists) : JSON.stringify(new PackageList())
      );
    });
  }

  changePackageDataSource(item: Lesson[]) {
    return new MatTableDataSource(item);
  }

  async createNewPackage(
    lessonPackageName: string,
    lessonIds: string[] = ["NA"]
  ) {
    firstValueFrom(
      await this.packageService.createPackagedLessons(
        lessonPackageName,
        lessonIds
      )
    ).then((res) => this.updatePackageListLocalStorage());
  }
}
