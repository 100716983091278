<div>
  <div class="reportLessonHeader">report lesson</div>
  <div class="m-2">
    <small *ngIf="!showNextPage" class="text-muted reportInstructions"
      >select the report type</small
    >
    <small *ngIf="showNextPage" class="text-muted reportInstructions"
      >add your comment to report lesson</small
    >
    <small *ngIf="showManagerCommentPage" class="text-muted reportInstructions" 
      >once you verify the report, it will move to resolved page</small
    >
  </div>

  <table *ngIf="!showNextPage">
    <tr>
      <th></th>
    </tr>
    <tr *ngFor="let item of reportingTypes">
      <td>
        <mat-radio-group [(ngModel)]="selectedReportType">
          <mat-radio-button
            [value]="item.Code"
            color="primary"
          ></mat-radio-button>
        </mat-radio-group>
      </td>
      <td class="left-align">
        <div>
          <b>{{ item.Description }}</b>
        </div>
      </td>
    </tr>
  </table>
  <span *ngIf="showNextPage" class="row col-12">
    <span class="col-11"></span>
    <small class="text-muted bold col-1"
      >{{ reportedComments?.length }}/250</small
    >
  </span>
  <span *ngIf="showManagerCommentPage" class="row col-12">
    <span class="col-11"></span>
    <small class="text-muted bold col-1"
      >{{ managerComments?.length }}/250</small
    >
  </span>
  <div *ngIf="showNextPage" class="m-2">
    <mat-form-field class="textField">
      <textarea
        id="textField"
        maxlength="250"
        style="line-height: normal"
        matInput
        [(ngModel)]="reportedComments"
      ></textarea>
    </mat-form-field>
  </div>
  <div *ngIf="showManagerCommentPage" class="m-2">
    <mat-form-field class="textField">
      <textarea
        id="textField"
        maxlength="250"
        style="line-height: normal"
        matInput
        [(ngModel)]="managerComments"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="row justify-content-center col-12 m-0">
    <button
      type="button"
      mat-button
      style="border: 1px solid #0066b2"
      class="btn btn-outline-primary col-5"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <span class="col-2"></span>
    <button
      type="button"
      [disabled]="!selectedReportType"
      *ngIf="!showNextPage"
      (click)="showNextPage = !showNextPage"
      mat-button
      class="btn btn-primary col-5"
      id="nextButton"
    >
      next
    </button>
    <button
      type="button"
      *ngIf="showNextPage"
      [disabled]="!(reportedComments?.length > 0)"
      (click)="confirm()"
      mat-button
      class="btn btn-primary col-5"
      id="sendReportButton"
    >
      send report
    </button>
  </div>
</div>
