import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/http/user.service';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';
import { CoordinatorService } from 'src/app/core/services/lesson-services/coordinator.service';
import { AddNewManagerComponent } from '../../../dialogs/add-new-manager/add-new-manager.component';
import { RemoveTechaprMgrComponent } from '../../../dialogs/remove-techapr-mgr/remove-techapr-mgr.component';
import { UserRole } from 'src/app/shared/models/user-role';
import { EcApprover, Manager, TechnicalApprover, User, LegalApprover } from 'src/app/shared/models';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserTeamBusinessUnitService } from 'src/app/core/http/user-team-bu.service';
import { BusinessUnitService } from 'src/app/core/http/business-unit.service';
import { UserDisciplineBusinessUnitService } from 'src/app/core/http/user-discipline-bu.service';
import { DisciplineService } from 'src/app/core/http/discipline.service';
import { TeamService } from 'src/app/core/http/team.service';
import { switchMap } from 'rxjs';
import { UserDepartmentBusinessUnitService } from 'src/app/core/http/user-department-bu.service';
import { UserRelatedProcessBusinessUnitService } from 'src/app/core/http/user-related-process-bu.service';
import { UserAssetBusinessUnitService } from 'src/app/core/http/user-asset-bu.service';
import { UserCopBusinessUnitService } from 'src/app/core/http/user-cop-bu.service';
import { DepartmentService } from 'src/app/core/http/department.service';
import { RelatedProcessService } from 'src/app/core/http/related-process.service';
import { RegionService } from 'src/app/core/http/region.service';
import { UserRegionBusinessUnitService } from 'src/app/core/http/user-region-bu.service';
import { FormControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { appInfo } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-dashboard-landing',
  templateUrl: './dashboard-landing.component.html',
  styleUrls: ['./dashboard-landing.component.scss']
})
export class DashboardLandingComponent {

  //Page Properties
  pageTitle = "Business Unit Coordinator Dashboard";


  // Holds values corresponding to given field utilizing ReferenceConfig
  businessUnits: ParsingType[] = [];
  businessUnitDiscs: ParsingType[] = [];
  businessUnitTeams: ParsingType[]=[];
  businessUnitDepartments: ParsingType[]=[];
  businessUnitRelatedProcesses: ParsingType[]=[];
  businessUnitAssets: ParsingType[]=[];
  businessUnitCops: ParsingType[]=[];

  regions: ParsingType[]=[];

  // Mat-Select default values
  buSelected: string = '';
  roleSelected: string = '';

  guideLink = "https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/122990/(Pre-Requisite)-Assigning-Technical-Approvers-and-Managers";

  // list for user validation
  technicalApproverList: any[] = [];
  managerList: any[] = [];
  ecApproverList: any[]=[];
  legalApproverList: any[]=[];
  

  // Initial list of technical approvers separated by approval trigger
  discTechnicalApprovers: any[] = [];
  teamTechnicalApprovers: any[] = [];
  departmentTechnicalApprovers: any[] = [];
  relatedProcessTechnicalApprovers: any[] = [];
  assetTechnicalApprovers: any[]=[];
  copTechnicalApprovers: any[]=[];

  // Filtered list of TECHAPR/MGR
  TechnicalApprovers: any[] = [];
  Managers: any[] =[];
  EcApprovers: any[]=[];
  LegalApprovers: any[]=[];

  // Loading flags (ensures tables or page isn't loading or populated improperly)
  isLoading = false;
  show: boolean = false;
  
  showDiscTechnicalApprovers: boolean = false;
  showDepartmentTechnicalApprovers: boolean = false;
  showTeamTechnicalApprovers: boolean = false;
  showRelatedProcessTechnicalApprovers: boolean = false;
  showAssetTechnicalApprovers: boolean = false;
  showCopTechnicalApprovers: boolean = false;


  showDiscTable: boolean = false;
  showTeamTable: boolean = false;
  showRelatedProcessTable: boolean = false;
  showDepartmentTable: boolean = false;
  showAssetTable: boolean = false;
  showCopTable: boolean = false;
  showEcTable: boolean = false;
  showDashboard: boolean = false;

  showManagers: boolean;
  showTechnicalApprovers: boolean;
  showLegalApprovers: boolean;
  showEcApprovers: boolean;
  showEmptyMessage: boolean = false;

  availableBusinessUnits: any = [];


  // Used to ensure that all values found within referenceConfig are valid and match given business unit/workflow
  validTeams = false;
  validDepartments = false;
  validRelatedProcesses = false;
  validDisciplines = false;
  validAssets = false;
  validCops = false;

  contentContactUrl = appInfo.contentContactUrl;

  roles:string[] = new Array();

  selectedTabIndex = new FormControl(0);
  scope: any = 'disciplines'; 
  tabs = [
    { key: "disciplines", label: "disciplines" },
    { key: "departments", label: "departments" },
    { key: "teams", label: "teams" },
    { key: "related processes", label: "related processes" },
    { key: "assets", label: "assets"},
    { key: "cops", label: "cops"}
  ];



  constructor(
    private router: Router,
    private userService: UserService,
    private commonService: CommonService,
    private coordinatorService: CoordinatorService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private userTeamBuService: UserTeamBusinessUnitService,
    private userDiscBuService: UserDisciplineBusinessUnitService,
    private buService: BusinessUnitService,
    private disciplineService: DisciplineService,
    private teamService: TeamService,
    private userDepartmentBuService: UserDepartmentBusinessUnitService,
    private userRelatedProcessBuService: UserRelatedProcessBusinessUnitService,
    private userAssetBuService: UserAssetBusinessUnitService,
    private relatedProcessService: RelatedProcessService,
    private departmentService: DepartmentService,
    private businessUnitService: BusinessUnitService,
    private userCopBuService: UserCopBusinessUnitService,
    private userRegionBuService: UserRegionBusinessUnitService,
    private regionService: RegionService
  ){}

  async ngOnInit() {
    this.commonService.loadConfig();
    this.coordinatorService.onInit();
    try{
      this.gettingBusinessUnitsAndTriggerValues();
      this.showDashboard = true;
    }
    catch{
      this.toastService.showRetrievingMessage(true, 'Please give us a moment as we finish setting up some things from our end.');
    }
  }


  gettingBusinessUnitsAndTriggerValues() {


    this.availableBusinessUnits = this.commonService.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.importOnly == undefined && x.mapShortname !== true && x.Code !== 'oroniteFEWorkflow'));
    this.availableBusinessUnits.sort((a, b) => a.mapShortname.localeCompare(b.mapShortname));


    // Looping through business Units and getting all the BU with FEWorkflow
    let bu = this.commonService.references['BusinessUnit'].sort((a, b) => {
      const buA = a.Description.toUpperCase();
      const buB = b.Description.toUpperCase();
      return buA.localeCompare(buB)
    });

    let currentUserProfile = JSON.parse(sessionStorage.getItem("profile"));
    if(currentUserProfile.idTokenClaims.roles.includes("DEVELOPER-ADMIN")){
      this.show = true;
      this.businessUnits = bu.filter(bu =>  bu["lessonWorkflowTypeTag"].some(b => b.includes('FEWorkflow')))
    }
    else if(currentUserProfile.idTokenClaims.roles.includes("ELL-MGR")){
      this.businessUnitService.getBusinessUnitByUserUniqueKey(currentUserProfile.idTokenClaims.email).subscribe(res => {
        this.show = true;
        this.businessUnits = bu.filter(bu => bu["Code"] == res.abbreviation);
      });
    }
    else{
      this.show = false
      this.toastService.showError(true, 'Uh-oh! Looks like you do not have proper permissions to utilize this dashboard. Please reach out to the support team for further assistance.');
    }
  }

  validateApprovers(technicalApprovers: User[]){
    if(technicalApprovers.length > 0){
      return true;
    }
    else{
      return false;
    }
  }

  openApproverGuide(){
    window.open(this.guideLink,'_blank');
  }

  setTabPosition(){
    switch(this.scope){
      case "disciplines":
        this.selectedTabIndex.setValue(0);
        this.getDisciplineTechnicalApprovers(this.buSelected);
        this.setVisibilityFlags(this.scope);
        break;
      case "departments":
        this.selectedTabIndex.setValue(1);
        break;
      case "teams":
        this.selectedTabIndex.setValue(2);
        break;
      case "related processes":
        this.selectedTabIndex.setValue(3);
        break;
      case "assets":
        this.selectedTabIndex.setValue(4);
        break;
      case "cops":
        this.selectedTabIndex.setValue(5);
        break;
    }
  }

  onTabChanged() {
    this.scope = this.tabs[this.selectedTabIndex.value].key;

    switch(this.scope){
      case "disciplines":
        this.getDisciplineTechnicalApprovers(this.buSelected);
        this.setVisibilityFlags(this.scope);
        break;
      case "departments":
        this.getDepartmentTechnicalApprovers(this.buSelected)
        this.setVisibilityFlags(this.scope);
        break;
      case "related processes":
        this.getRelatedProcessTechnicalApprovers(this.buSelected);
        this.setVisibilityFlags(this.scope);
        break;
      case "teams":
        this.getTeamTechnicalApprovers(this.buSelected);
        this.setVisibilityFlags(this.scope);
        break;
      case "assets":
        this.getAssetTechnicalApprovers(this.buSelected);
        this.setVisibilityFlags(this.scope);
      case "cops":
        this.getCopTechnicalApprovers(this.buSelected);
        this.setVisibilityFlags(this.scope);
    }
  }

  setManagersVisibility(){
    this.showManagers = true;
    this.showEcApprovers = false;
    this.showLegalApprovers = false;
    this.showTechnicalApprovers = false;
  }


  setEcApproversVisibility(){
    this.showManagers = false;
    this.showEcApprovers = true;
    this.showLegalApprovers = false;
    this.showTechnicalApprovers = false;
  }

  setLegalApproversVisibility(){
    this.showManagers = false;
    this.showEcApprovers = false;
    this.showLegalApprovers = true;
    this.showTechnicalApprovers = false;
  }

  setTechnicalApproversVisibility(){
    this.showTechnicalApprovers = true;
    this.showManagers = false;
    this.showEcApprovers = false;
    this.showLegalApprovers = false;
    this.setTabPosition();
  }

  async roleSelectedChange(role){
    this.roleSelected = role;
    switch(role){
      case "Manager": {
        this.setManagersVisibility();
        break;
      }
      case "Technical Approver": {
        this.setTechnicalApproversVisibility()
        break;
      }
      case "EC Approver": {
        this.setEcApproversVisibility()
        break;
      }
      case "Legal Approver": {
        this.setLegalApproversVisibility()
        break;
      }
    }
  }

  setVisibilityFlags(visibleScope){
    switch(visibleScope){
      case "disciplines":
        this.showDiscTechnicalApprovers = true;
        this.showDepartmentTechnicalApprovers = false;
        this.showTeamTechnicalApprovers = false;
        this.showRelatedProcessTechnicalApprovers = false;
        this.showAssetTechnicalApprovers = false;
        this.showCopTechnicalApprovers = false;
        break;
      case "departments":
        this.showDiscTechnicalApprovers = false;
        this.showDepartmentTechnicalApprovers = true;
        this.showTeamTechnicalApprovers = false;
        this.showRelatedProcessTechnicalApprovers = false;
        this.showAssetTechnicalApprovers = false;
        this.showCopTechnicalApprovers = false;
        break;
      case "teams":
        this.showDiscTechnicalApprovers = false;
        this.showDepartmentTechnicalApprovers = false;
        this.showTeamTechnicalApprovers = true;
        this.showRelatedProcessTechnicalApprovers = false;
        this.showAssetTechnicalApprovers = false;
        this.showCopTechnicalApprovers = false;
        break;
      case "related processes":
        this.showDiscTechnicalApprovers = false;
        this.showDepartmentTechnicalApprovers = false;
        this.showTeamTechnicalApprovers = false;
        this.showRelatedProcessTechnicalApprovers = true;
        this.showAssetTechnicalApprovers = false;
        this.showCopTechnicalApprovers = false;
        break;
      case "assets":
        this.showDiscTechnicalApprovers = false;
        this.showDepartmentTechnicalApprovers = false;
        this.showTeamTechnicalApprovers = false;
        this.showRelatedProcessTechnicalApprovers = false;
        this.showAssetTechnicalApprovers = true;
        this.showCopTechnicalApprovers = false;
        break;
      case "cops":
        this.showDiscTechnicalApprovers = false;
        this.showDepartmentTechnicalApprovers = false;
        this.showTeamTechnicalApprovers = false;
        this.showRelatedProcessTechnicalApprovers = false;
        this.showAssetTechnicalApprovers = false;
        this.showCopTechnicalApprovers = true;
    }
  }

  async getCopTechnicalApprovers(businessUnit){
    if(this.businessUnitCops.length > 0){
      this.validCops = true;
      this.showCopTechnicalApprovers = true;
      await this.userCopBuService.getAllUsersByBusinessUnit(businessUnit).subscribe(res => {
        if(res?.length > 0){
          this.showEmptyMessage = false;
          this.technicalApproverList = res.filter((value, index, arr) => {
            return !arr.slice(index + 1).some(user => user.uniqueKey === value.uniqueKey);
          });
          this.populateTechnicalApprovers(this.technicalApproverList, "cop");
        }
        else{
          this.showCopTable = true;
          this.showEmptyMessage = true;
        }
      })
    }
  }

  async getAssetTechnicalApprovers(businessUnit){
    if(this.businessUnitAssets.length > 0){
      this.validAssets = true;
      this.showAssetTechnicalApprovers = true;
      await this.userAssetBuService.getAllUsersByBusinessUnit(businessUnit).subscribe(res => {
        if(res?.length > 0){
          this.showEmptyMessage = false;
          this.technicalApproverList = res.filter((value, index, arr) => {
            return !arr.slice(index + 1).some(user => user.uniqueKey === value.uniqueKey);
          });
          this.populateTechnicalApprovers(this.technicalApproverList, "asset");
        }
        else{
          this.showAssetTable = true;
          this.showEmptyMessage = true;
        }
      })
    }
  }

  async getDisciplineTechnicalApprovers(businessUnit){
      if(this.businessUnitDiscs.length > 0){
        this.validDisciplines = true;
        this.showDiscTechnicalApprovers = true;
        await this.userDiscBuService.getAllUsersByBusinessUnit(businessUnit).subscribe(res => {
          if(res?.length > 0){
            this.showEmptyMessage = false;
            this.technicalApproverList = res.filter((value, index, arr) => {
              return !arr.slice(index + 1).some(user => user.uniqueKey === value.uniqueKey);
            });
            this.populateTechnicalApprovers(this.technicalApproverList, "discipline");

          }
          else{
            this.showDiscTable = true;
            this.showEmptyMessage = true;
          }
        })
      }
  }


  async getDepartmentTechnicalApprovers(businessUnit){
    if(this.businessUnitDepartments.length > 0){
      this.validDepartments = true;
      await this.userDepartmentBuService.getAllUsersByBusinessUnit(businessUnit).subscribe(res => {
        if(res?.length > 0){
          this.showEmptyMessage = false;
          this.technicalApproverList = res.filter((value, index, arr) => {
            return !arr.slice(index + 1).some(user => user.uniqueKey === value.uniqueKey);
          });
          this.populateTechnicalApprovers(this.technicalApproverList, "department");
        }
        else{
          this.showDepartmentTable = true;
          this.showEmptyMessage = true;
        }
      })
    }
  }

  async getTeamTechnicalApprovers(businessUnit){
    if(this.businessUnitTeams.length > 0){
      this.validTeams = true;
      await this.userTeamBuService.getAllUsersByBusinessUnit(businessUnit).subscribe(res => {
        if(res?.length > 0){
          this.showEmptyMessage = false;
          this.technicalApproverList = res.filter((value, index, arr) => {
            return !arr.slice(index + 1).some(user => user.uniqueKey === value.uniqueKey);
          });
          this.populateTechnicalApprovers(this.technicalApproverList, "team");
        }
        else{
          this.showTeamTable = true;
          this.showEmptyMessage = true;
        }
      })
    }
  }

  async getRelatedProcessTechnicalApprovers(businessUnit){
    if(this.businessUnitRelatedProcesses.length > 0){
      this.validRelatedProcesses = true;
      await this.userRelatedProcessBuService.getAllUsersByBusinessUnit(businessUnit).subscribe(res => {
        if(res?.length > 0){
          this.showEmptyMessage = false;
          this.technicalApproverList = res.filter((value, index, arr) => {
            return !arr.slice(index + 1).some(user => user.uniqueKey === value.uniqueKey);
          });
          this.populateTechnicalApprovers(this.technicalApproverList, "related process");
        }
        else{
          this.showRelatedProcessTable = true;
          this.showEmptyMessage = true;
        }
      })
    }
  }

  filterRoles(bu){
    if(bu.toLowerCase().includes("cop")){
      this.roles = [ "EC Approver", "Legal Approver", "Manager", "Technical Approver"];
    }
    else{
      this.roles = ["Technical Approver", "Manager",];
    }
  }

  async buSelectedChange(bu)
  {
    this.Managers = [];
    this.TechnicalApprovers = []
    this.assetTechnicalApprovers = [];
    this.discTechnicalApprovers = [];
    this.departmentTechnicalApprovers = [];
    this.relatedProcessTechnicalApprovers = [];
    this.teamTechnicalApprovers = [];
    this.copTechnicalApprovers = [];
    this.buSelected = bu;
    this.regions = this.commonService.references['Region'].sort((a, b) => {
      const buA = a.Description.toUpperCase();
      const buB = b.Description.toUpperCase();
      return buA.localeCompare(buB)
    });;
    this.businessUnitDiscs = this.commonService.references['Discipline'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    this.businessUnitTeams = this.commonService.references['Team'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    this.businessUnitDepartments = this.commonService.references['Department'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    this.businessUnitRelatedProcesses = this.commonService.references['RelatedProcess'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    this.businessUnitAssets = this.commonService.references['Asset'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    let cops =  this.commonService.references['Cop'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    let subGroups = this.commonService.references['CopSubGroup'].filter(bu =>  bu["lessonWorkflowTypeTag"].some(workflow => workflow.includes(this.buSelected.toLowerCase())));
    this.businessUnitCops = [...cops, ...subGroups];
    this.managerList = await this.coordinatorService.getManagersByBu(this.buSelected);
    this.filterRoles(bu);
    this.populateGroupsByRole(this.managerList, UserRole.MGR);
    this.ecApproverList = await this.coordinatorService.getEcApproversByBu(this.buSelected);
    this.populateGroupsByRole(this.ecApproverList, UserRole.ECAPR);
    this.legalApproverList = await this.coordinatorService.getLegalApproversByBu(this.buSelected);
    this.populateGroupsByRole(this.legalApproverList, UserRole.LGAPR);
  }

  async addNewManager() {
    const dialogRef = this.dialog.open(AddNewManagerComponent, {
      width: "500px",
      data: { role: UserRole.MGR },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.newUser && result.update) {
        let managerName = result.newUser.fullName;
        this.userService.updateUserPermissions(result.newUser.email, this.buSelected , UserRole.MGR).subscribe(async (result) => {
          this.managerList = await this.coordinatorService.getManagersByBu(this.buSelected);
          this.populateRoles(this.managerList, UserRole.MGR)
          this.toastService.showSuccess(true, `Successfully added ${managerName} as a manager!`);
        });
      }
    });
  }

  async addNewLegalApprover(triggerValues: any){

    const dialogRef = this.dialog.open(AddNewManagerComponent, {
      width: "500px",
      data: { role: UserRole.LGAPR, values: triggerValues },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.update) {
        this.showLegalApprovers = false;
        let legalApproverName = result.newUser.fullName;
        this.userService.updateUserPermissions(result.newUser.uniqueKey, this.buSelected, UserRole.LGAPR).subscribe(async res => {
          if(res){
            this.legalApproverList = await this.coordinatorService.getLegalApproversByBu(this.buSelected);
            this.populateRoles(this.legalApproverList, UserRole.LGAPR)
            this.toastService.showSuccess(true, `Successfully added ${legalApproverName} as a legal approver!`);
          }
        })
      }
    });
  }

  async addNewEcApprover(triggerValues: any){
    let usersFromGraphCall = await this.commonService.getAuthorizedApprovers("ELL-TECHAPR-USERS");
    const authorizedApprovers = usersFromGraphCall.map(user => user.email.toLowerCase());
    const dialogRef = this.dialog.open(AddNewManagerComponent, {
      width: "500px",
      data: { role: UserRole.ECAPR, values: triggerValues },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.update) {
        let ecApproverName = result.newUser.fullName;
        if(this.regions.map(reg => reg.Code).includes(result.value)){
          this.userRegionBuService.createUserRegionBusinessUnit(result.value, result.newUser.uniqueKey, this.buSelected).subscribe(async res => {
            if(res){
              this.ecApproverList = await this.coordinatorService.getEcApproversByBu(this.buSelected);
              this.populateRoles(this.ecApproverList, UserRole.ECAPR)
              this.toastService.showSuccess(true, `Successfully added ${ecApproverName} as a Ec approver!`);
            }
          })
        }
      }
    });

    // from regions selected..

    // update bool flag on user


    // create entry in lookup table

  }

  async updateApproverValues(userList, trigger){
    userList = userList.map(techapr => {
      if(techapr.codes.includes(trigger.triggerValue.code)){
          // Remove the code from the codes array
          techapr.codes = techapr.codes.filter(code => code !== trigger.triggerValue.code);
          // Remove the corresponding triggerValue (if needed)
          techapr.triggerValues = techapr.triggerValues.filter(tv => tv !== trigger.triggerValue.description);
      }
      if (techapr.codes.length === 0 && techapr.triggerValues.length === 0) {
        // Remove the entire techapr
        return null;
      }

      return techapr;
    });
  }

  async removeEcApprover(){
    const dialogRef = this.dialog.open(RemoveTechaprMgrComponent, {
      width: "500px",
      data: { role: UserRole.ECAPR, ecApprovers: this.EcApprovers, triggerType: "region" },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res.update){
        this.userRegionBuService.deleteUserRegionBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
          this.updateApproverValues(this.EcApprovers, res);
        });
      }
    })
  }

  async removeLegalApprover(){
    const dialogRef = this.dialog.open(RemoveTechaprMgrComponent, {
      width: "500px",
      data: { role: UserRole.LGAPR, legalApprovers: this.LegalApprovers },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res.update){
        this.userService.removeUserPermissions(res.user.email, res.user.businessUnit, UserRole.LGAPR).subscribe(result => {
          if(result){
            this.LegalApprovers = this.LegalApprovers.filter(lgApr => lgApr.email !== res.user.email);
            this.toastService.showSuccess(true, `Successfully updated legal approver: ${res.user.fullName}!`);
          }
        });
      }
    })
  }

  async removeManager() {
    const dialogRef = this.dialog.open(RemoveTechaprMgrComponent, {
      width: "500px",
      data: { role: UserRole.MGR, managers: this.Managers },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res.update){
        this.userService.removeUserPermissions(res.user.email, res.user.businessUnit, UserRole.MGR).subscribe(result => {
          if(result){
            this.Managers = this.Managers.filter(mgr => mgr.email !== res.user.email);
            this.toastService.showSuccess(true, `Successfully updated manager: ${res.user.fullName}!`);
          }
        });
      }
    })

  }

  async removeTechnicalApprover(trigger: string) {
    let technicalApprovers;
    let triggerType;
    switch(trigger){
      case "discipline":
        technicalApprovers = this.discTechnicalApprovers;
        triggerType = 'discipline';
        break;
      case "department":
        technicalApprovers = this.departmentTechnicalApprovers;
        triggerType = 'department';
        break;
      case "relatedProcess":
        technicalApprovers = this.relatedProcessTechnicalApprovers;
        triggerType = 'relatedProcess';
        break;
      case "team":
        technicalApprovers = this.teamTechnicalApprovers;
        triggerType = 'team';
        break;
      case "asset":
        technicalApprovers = this.assetTechnicalApprovers;
        triggerType = 'asset';
        break;
      case "cop":
        technicalApprovers = this.copTechnicalApprovers;
        triggerType = 'cop';
        break;
    }
    const dialogRef = this.dialog.open(RemoveTechaprMgrComponent, {
      width: "500px",
      data: { role: UserRole.TECHAPR, technicalApprovers: technicalApprovers, triggerType: triggerType},
    });

    dialogRef.afterClosed().subscribe((res => {
      if(res.update){
        switch(trigger){
          case "asset":
            this.userAssetBuService.deleteUserAssetBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
              this.updateApproverValues(this.assetTechnicalApprovers, res);
            });
            break;
          case "discipline":
            this.userDiscBuService.deleteUserDisciplineBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
              this.updateApproverValues(this.discTechnicalApprovers, res);
            });
            break;
          case "department":
            this.userDepartmentBuService.deleteUserDepartmentBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
              this.updateApproverValues(this.departmentTechnicalApprovers, res);
            });
            break;
          case "relatedProcess":
            this.userRelatedProcessBuService.deleteUserRelatedProcessBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
              this.updateApproverValues(this.relatedProcessTechnicalApprovers, res);
            });
            break;
          case "team":
            this.userTeamBuService.deleteUserTeamBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
              this.updateApproverValues(this.teamTechnicalApprovers, res);
            });
            break;
          case "cop":
            this.userCopBuService.deleteUserCopBusinessUnit(res.user.email, res.triggerValue.code, res.user.businessUnit).subscribe(result => {
              this.updateApproverValues(this.teamTechnicalApprovers, res);
            });
            break;
        }
        this.toastService.showSuccess(true, `Successfully updated technical approver: ${res.user.fullName}!`)
      }
    }))
  }
  

  async addNewTechAppr(triggerValues: any) {

    let usersFromGraphCall = await this.commonService.getAuthorizedApprovers("ELL-TECHAPR-USERS");
    const authorizedApprovers = usersFromGraphCall.map(user => user.email.toLowerCase());
    const dialogRef = this.dialog.open(AddNewManagerComponent, {
      width: "500px",
      data: { role: UserRole.TECHAPR, values: triggerValues },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.update) {
        let techAprName = result.newUser.fullName;
        if(this.businessUnitDiscs.map(disc => disc.Code).includes(result.value)){
          this.userDiscBuService.createUserDisciplineBusinessUnit(result.value, result.newUser.uniqueKey ,this.buSelected).subscribe(async res => {
            this.technicalApproverList = await this.coordinatorService.getTechAprsByBu(this.buSelected);
            this.populateTechnicalApprovers(this.technicalApproverList, "discipline");
          })
        }
        else if(this.businessUnitDepartments.map(dept => dept.Code).includes(result.value)){
          this.userDepartmentBuService.createUserDepartmentBusinessUnit(result.value, result.newUser.uniqueKey ,this.buSelected).subscribe(async res => {
            this.technicalApproverList = await this.coordinatorService.getTechAprsByBu(this.buSelected);
            this.populateTechnicalApprovers(this.technicalApproverList, "department");
          })
        }
        else if(this.businessUnitTeams.map(team => team.Code).includes(result.value)){
          this.userTeamBuService.createUserTeamBusinessUnit(result.value, result.newUser.uniqueKey ,this.buSelected).subscribe(async res => {
            this.technicalApproverList = await this.coordinatorService.getTechAprsByBu(this.buSelected);
            this.populateTechnicalApprovers(this.technicalApproverList, "team");
          })
        }
        else if(this.businessUnitRelatedProcesses.map(rp => rp.Code).includes(result.value)){
          this.userRelatedProcessBuService.createUserRelatedProcessBusinessUnit(result.value, result.newUser.uniqueKey ,this.buSelected).subscribe(async res => {
            this.technicalApproverList = await this.coordinatorService.getTechAprsByBu(this.buSelected);
            this.populateTechnicalApprovers(this.technicalApproverList, "related process");
          })
        }
        else if(this.businessUnitAssets.map(asset => asset.Code).includes(result.value)){
          this.userAssetBuService.createUserAssetBusinessUnit(result.value, result.newUser.uniqueKey ,this.buSelected).subscribe(async res => {
            this.technicalApproverList = await this.coordinatorService.getTechAprsByBu(this.buSelected);
            this.populateTechnicalApprovers(this.technicalApproverList, "asset");
          })
        }
        else if(this.businessUnitCops.map(cop => cop.Code).includes(result.value)){
          this.userCopBuService.createUserCopBusinessUnit(result.value, result.newUser.uniqueKey ,this.buSelected).subscribe(async res => {
            this.technicalApproverList = await this.coordinatorService.getTechAprsByBu(this.buSelected);
            this.populateTechnicalApprovers(this.technicalApproverList, "cop");
          })
        }

        if(authorizedApprovers.includes(result.newUser.uniqueKey.toLowerCase())){
          this.toastService.showSuccess(true, `Successfully added ${techAprName} as a technical approver!`);
        }else{
          this.toastService.showWarning(true, `Uh-oh! Looks like ${techAprName} has not been added to the authorized list of users within Azure. Although the technical approver has been added, they will not be able to approve lessons. Please reach out to the development team so they can add them.`)
        }
      }
    });
    this.isLoading = true;
  }

  populateRoles(userList: any[], role: string){
    if(userList != null && role == UserRole.MGR){
      this.Managers = [];
      userList.forEach(manager => {
        var mgr = new Manager();
        mgr.fullName = manager.fullName;
        mgr.email = manager.uniqueKey;
        mgr.businessUnit = this.buSelected;
        this.Managers.push(mgr);
      })
    }
    else if(userList != null && role == UserRole.ECAPR){
      this.EcApprovers = [];
      let regions = [];
      let regionCodes = [];
      userList.forEach(async ecApr => {
        var ec = new EcApprover();
        await firstValueFrom(this.userRegionBuService.getAllRegionApprovers(ecApr.id)).then(res => {
          if(res.length > 0){
            regions = res.map((userRegion) => userRegion.description);
            regionCodes = res.map((userRegionCodes) => userRegionCodes.code);
            ec.fullName = ecApr.fullName;
            ec.email = ecApr.uniqueKey;
            ec.businessUnit = this.buSelected;
            ec.triggerValues = regions;
            ec.codes = regionCodes;
            this.EcApprovers.push(ec);
          }
        })
      })
      this.showEcTable = true;
    }
    else if(userList != null && role == UserRole.LGAPR){
      this.LegalApprovers = [];
      userList.forEach(lgApr => {
        var lg = new LegalApprover();
        lg.fullName = lgApr.fullName;
        lg.email = lgApr.uniqueKey;
        lg.businessUnit = this.buSelected;
        this.LegalApprovers.push(lg);
      })
    }
  }

  async populateTechnicalApprovers(userList: any[], trigger){
    if(userList != null){
      this.discTechnicalApprovers = [];
      this.departmentTechnicalApprovers = [];
      this.relatedProcessTechnicalApprovers = [];
      this.teamTechnicalApprovers = [];
      this.copTechnicalApprovers = [];
      this.assetTechnicalApprovers = [];
      for(let technicalApprover of userList){
        const techApr = new TechnicalApprover();
        switch(trigger){
          case "cop":
            this.showCopTable = false;
            let cops = [];
            let copCodes = [];
            techApr.fullName = technicalApprover.fullName;
            techApr.email = technicalApprover.uniqueKey;
            techApr.businessUnit = this.buSelected;
            await firstValueFrom(this.userCopBuService.getAllCopApprovers(technicalApprover.id)).then(res => {
              if(res.length > 0){
                cops = res.map((userCop) => userCop.description);
                copCodes = res.map((userCopCodes) => userCopCodes.code);
                techApr.triggerValues = cops;
                techApr.codes = copCodes;
                this.copTechnicalApprovers.push(techApr);
              }
            });
            this.showCopTable = true;
            break;
          case "asset":
            this.showAssetTable = false;
            let assets = [];
            let assetCodes = [];
            techApr.fullName = technicalApprover.fullName;
            techApr.email = technicalApprover.uniqueKey;
            techApr.businessUnit = this.buSelected;
            await firstValueFrom(this.userAssetBuService.getAllAssetApprovers(technicalApprover.id)).then(res => {
              if(res.length > 0){
                assets = res.map((userAsset) => userAsset.description);
                assetCodes = res.map((userAssetCodes) => userAssetCodes.code);
                techApr.triggerValues = assets;
                techApr.codes = assetCodes;
                this.assetTechnicalApprovers.push(techApr);
              }
            });
            this.showAssetTable = true;
            break;
          case "discipline":
            this.showDiscTable = false;
            let discs = [];
            let discCodes = [];
            techApr.fullName = technicalApprover.fullName;
            techApr.email = technicalApprover.uniqueKey;
            techApr.businessUnit = this.buSelected;
            await firstValueFrom(this.userDiscBuService.getAllDiscApprovers(technicalApprover.id)).then(res => {
              if(res.length > 0){
                discs = res.map((userDisc) => userDisc.description);
                discCodes = res.map((userDiscCodes) => userDiscCodes.code);
                techApr.triggerValues = discs;
                techApr.codes = discCodes;
                this.discTechnicalApprovers.push(techApr);
              }
            });
            this.showDiscTable = true;
            break;
          case "team":
            this.showTeamTable = false;
            let teams = [];
            let teamCodes = [];
            techApr.fullName = technicalApprover.fullName;
            techApr.email = technicalApprover.uniqueKey;
            techApr.businessUnit = this.buSelected;
            await firstValueFrom(this.userTeamBuService.getAllTeamApprovers(technicalApprover.id)).then(res => {
              if(res.length > 0){
                teams = res.map((userTeam) => userTeam.description);
                teamCodes = res.map((userTeamCodes) => userTeamCodes.code);
                techApr.triggerValues = teams;
                techApr.codes = teamCodes;
                this.teamTechnicalApprovers.push(techApr);
              }
            });
            this.showTeamTable = true;
            break;
          case "department":
            this.showDepartmentTable = false;
            let departments = [];
            let departmentCodes = [];
            techApr.fullName = technicalApprover.fullName;
            techApr.email = technicalApprover.uniqueKey;
            techApr.businessUnit = this.buSelected;
            await firstValueFrom(this.userDepartmentBuService.getAllDepartmentApprovers(technicalApprover.id)).then(res => {
              if(res.length > 0){
                departments = res.map((userDepartment) => userDepartment.description);
                departmentCodes = res.map((userDepartmentCodes) => userDepartmentCodes.code);
                techApr.triggerValues = departments;
                techApr.codes = departmentCodes;
                this.departmentTechnicalApprovers.push(techApr);
              }
            });
            this.showDepartmentTable = true;
            break;
          case "related process":
            this.showRelatedProcessTable = false;
            let relatedProcesses = [];
            let rpCodes = [];
            techApr.fullName = technicalApprover.fullName;
            techApr.email = technicalApprover.uniqueKey;
            techApr.businessUnit = this.buSelected;
            await firstValueFrom(this.userRelatedProcessBuService.getAllRelatedProcessApprovers(technicalApprover.id)).then(res => {
              if(res.length > 0){
                relatedProcesses = res.map((userRp) => userRp.description);
                rpCodes = res.map((userRpCodes) => userRpCodes.code);
                techApr.triggerValues = relatedProcesses;
                techApr.codes = rpCodes;
                this.relatedProcessTechnicalApprovers.push(techApr);
              }
            });
            this.showRelatedProcessTable = true;
            break;
        }
        this.isLoading = true;
      }
    }
  }


  async populateGroupsByRole(userList: any[], role: string){
    if(userList != null && role == UserRole.MGR){
      userList.forEach(manager => {
        var mgr = new Manager();
        mgr.fullName = manager.fullName;
        mgr.email = manager.uniqueKey;
        mgr.businessUnit = this.buSelected;
        this.Managers.push(mgr);
      })
    }
    else if(userList != null && role == UserRole.ECAPR){
      let regions = [];
      let regionCodes = [];
      userList.forEach(async ecApprover => {
        var ec = new EcApprover();
        await firstValueFrom(this.userRegionBuService.getAllRegionApprovers(ecApprover.id)).then(res => {
          if(res.length > 0){
            regions = res.map((userRegion) => userRegion.description);
            regionCodes = res.map((userRegionCodes) => userRegionCodes.code);
            ec.fullName = ecApprover.fullName;
            ec.email = ecApprover.uniqueKey;
            ec.businessUnit = this.buSelected;
            ec.triggerValues = regions;
            ec.codes = regionCodes;
            this.EcApprovers.push(ec);
          }
        })
      })
      this.showEcTable = true;
    }
    else if(userList != null && role == UserRole.LGAPR){
      userList.forEach(legalApprover => {
        var legalApr = new LegalApprover();
        legalApr.fullName = legalApprover.fullName;
        legalApr.email = legalApprover.uniqueKey;
        legalApr.businessUnit = this.buSelected;
        this.LegalApprovers.push(legalApr);
      })
    }
    this.isLoading = true;
  }
}

export interface ParsingType 
{
  Code: string;
  Description: string;
}