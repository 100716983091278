import { Injectable } from "@angular/core";
import {
  ActionTypes,
  DataTypeConstants,
} from "src/app/config/global-enums.config";
import { Lesson, Reference } from "src/app/shared/models";
import {
  WorkflowType,
  FieldsModel,
  PreTransitionActionsModel,
  TransitionConditionsActionModel,
  WorkflowConfig,
} from "src/app/shared/models/configsV2/workflowConfigModel";
import { CommonService } from "./common.service";
import { SetConfigService } from "../set-config.service";
import { ToastService } from "../toast.service";
import { ProjectLookback } from "src/app/shared/models/project-lookback";
import { stat } from "fs";

@Injectable({
  providedIn: "root",
})
export class EvalconfigService {
  workflowConfig: WorkflowConfig[] = [];
  lessonNextSteps: Reference[] = [];
  lessonPriorSteps: Reference[]=[];
  projectLookbackNextSteps: Reference[] = [];

  constructor(
    public commonService: CommonService,
    public setConfigService: SetConfigService,
    public toastService: ToastService
  ) {}

  onInit() {
    this.workflowConfig = JSON.parse(localStorage.getItem("workflowConfig"));
  }

  public async setAndValidateTransition(lesson: Lesson): Promise<boolean> {
    const element = this.workflowConfig[lesson.lessonWorkflowType].find(
      (x) =>
        x.FromStatus === lesson.status && x.ToStatus === lesson.pendingStatus
    );

    if (element) {
      let isActionResultTransitionActions = this.performPreTransitionActions(
        element,
        lesson
      );
  
      if (element) {
        let isActionResultTransitionActions = this.performPreTransitionActions(
          element,
          lesson
        );
  
        if (isActionResultTransitionActions && element.TransitionConditions) {
          let isActionResultTransitionCondition =
            await this.performTransitionConditions(
              element.TransitionConditions,
              lesson
            );
          return isActionResultTransitionCondition;
        }
        return isActionResultTransitionActions; //PreTranistionActions return
      }
  
      this.toastService.showError(true, "Uh-oh! There was an issue in transitioning the lesson. Please reach out to the support team for further assistance.")
      return false;
    }
  }

  getLessonWorkflowStatuses(lessonWorkflowType: string) {
    const fromStatuses = this.workflowConfig[lessonWorkflowType].map((config) => config.FromStatus);
    const toStatuses = this.workflowConfig[lessonWorkflowType].map((config) => config.ToStatus);

    let uniqueStatuses = [...new Set([...fromStatuses, ...toStatuses])];

    uniqueStatuses = uniqueStatuses.filter(status => status !== 'bulkImportExcel');

    return uniqueStatuses;
  }

  async performTransitionConditions(
    tcActionModels: TransitionConditionsActionModel[],
    lesson: Lesson
  ): Promise<boolean> {
    let actionResult = true;
    tcActionModels.forEach((t) => {
      if (t.FieldValue) {
        if (t.MethodName == "allFieldContainsCertainValue") {
          actionResult = this.allFieldContainsCertainValue(
            lesson,
            t.FieldNames,
            t.FieldValue
          );
        } else if (t.MethodName == "anyFieldContainsCertainValue") {
          actionResult = this.anyFieldContainsCertainValue(
            lesson,
            t.FieldNames,
            t.FieldValue
          );
        }
      }
    });

    return actionResult;
  }

  async performPreTransitionActions(
    copWorkflowModel: WorkflowType,
    lesson: Lesson
  ): Promise<boolean> {
    let actionResult = true;
    copWorkflowModel.PreTransitionActions.forEach(async (x) => {
      if (x.ActionType === ActionTypes.SetValue) {
        actionResult = this.performSetValueActionType(x, actionResult, lesson);
      } else if (
        actionResult &&
        x.ActionType === ActionTypes.CopyValueFromField
      ) {
        actionResult = this.performCopyValueFromFieldActionType(x, lesson);
      } else if (
        actionResult &&
        x.ActionType === ActionTypes.SetValueFromGraphDropwdown
      ) {
        await this.performSetValueFromGraphDropwdown(x, lesson).then(
          (res) => (actionResult = res)
        );
      }
    });
    return actionResult;
  }

  private performSetValueActionType(
    x: PreTransitionActionsModel,
    actionResult: boolean,
    lesson: Lesson
  ) {
    x.Fields.forEach((f) => {
      actionResult = this.fieldConditionCheck(f);
      if (actionResult) {
        actionResult = this.setFieldValue(f, lesson);
      } else {
        return actionResult;
      }
    });
    return actionResult;
  }

  private performCopyValueFromFieldActionType(
    x: PreTransitionActionsModel,
    lesson: Lesson
  ): boolean {
    try {
      if (x.FromFieldName && x.ToFieldName) {
        if (typeof x.FromFieldName === DataTypeConstants.string) {
          if (x.ToFieldName === "assignees") {
            lesson[x.ToFieldName] = [].concat(lesson[x.FromFieldName]);
          } else {
            lesson[x.ToFieldName] = lesson[x.FromFieldName];
          }
        } else if ((x.FromFieldName as string[]).length == 1) {
          if (x.ToFieldName === "assignees") {
            lesson[x.ToFieldName] = [].concat(lesson[x.FromFieldName[0]]);
          } else {
            lesson[x.ToFieldName] = lesson[x.FromFieldName];
          }
        } else if ((x.FromFieldName as string[]).length > 1) {
          lesson[x.ToFieldName] = [];
          (x.FromFieldName as string[]).forEach((c) => {
            if (lesson[c]) {
              if (x.ToFieldName === "assignees") {
                lesson[x.ToFieldName].push(lesson[c]);
                return;
              }
            }
          });
        }
      }
      return true;
    } catch (ex) {
      this.toastService.showError(true,
        `Uh-oh! Looks like there was an error copying value from ${x.FromFieldName} to ${x.ToFieldName}. Please reach out to the support team for further assistance.`
      );
      return false;
    }
  }

  private async performSetValueFromGraphDropwdown(
    x: PreTransitionActionsModel,
    lesson: Lesson
  ): Promise<boolean> {
    //this is not working as expected if there is an error (if you forget to pass lessonWorfklowType) -- revist
    const usersFromGraph =
      await this.commonService.getGraphUsersByLessonWorkflowType(
        x.ReferenceConfigGraph,
        lesson.lessonWorkflowType,
        null,
        null,
        null,
        null,
        lesson.businessUnit
      );
    if (lesson.lessonWorkflowType) {
      lesson.managers = usersFromGraph;
      if (!lesson.managers) {
        this.toastService.showError(true,
          `Uh-oh! Looks like there was an error in setting the values for this: ${x.FromFieldName} to ${x.ToFieldName}. Please reach out to the support team for further assistance.`
        );
        return false;
      }
      return true;
    }
  }

  fieldConditionCheck(f: FieldsModel): boolean {
    let actionResult = true;
    if (f.Condition == null) {
      return true;
    }
    f.Condition.forEach((c) => {
      actionResult = this.setConfigService.callDynamicFunction(c);
      if (!actionResult) {
        return actionResult;
      }
    });
  }

  setFieldValue(f: FieldsModel, lesson: Lesson): boolean {
    const actionResult = true;
    // core properties
    if (f.Value == "Date.Now()") {
      lesson[f.FieldName] = new Date(Date.now());
    } else {
      lesson[f.FieldName] = f.Value;
    }
    return actionResult;
  }

  anyFieldContainsCertainValue(
    lesson: Lesson,
    fields: string[],
    fieldValue: any
  ): boolean {
    let actionResult = false;
    fields.forEach((f) => {
      if (typeof fieldValue === DataTypeConstants.string) {
        if (lesson[f] == fieldValue) {
          actionResult = true;
        }
      } else if ((fieldValue as string[]).length >= 1) {
        (fieldValue as string[]).forEach((v) => {
          if (lesson[f] == v) {
            actionResult = true;
          }
        });
      }
    });
    return actionResult;
  }

  allFieldContainsCertainValue(
    lesson: Lesson,
    fields: string[],
    fieldValue: any
  ): boolean {
    let actionResult = true;
    fields.forEach((f) => {
      if (typeof fieldValue === DataTypeConstants.string) {
        if (lesson[f] != fieldValue) {
          actionResult = false;
        }
      } else if ((fieldValue as string[]).length >= 1) {
        (fieldValue as string[]).forEach((v) => {
          if (lesson[f] != v) {
            actionResult = false;
          }
        });
      }
    });
    return actionResult;
  }

  //method to show hide lesson next step based on transition condition
  public async loadLessonNextSteps(lesson: Lesson) {
    let transitionStep = [];
    const workflowCopy = this.commonService.workflowConfig[
      lesson.lessonWorkflowType
    ].filter((wf) => wf.FromStatus == lesson.status);
    workflowCopy.forEach(async (status) => {
      if (
        status.TransitionConditions != undefined ||
        status.TransitionConditions != null
      ) {
        let transitionResult = await this.performTransitionConditions(
          status.TransitionConditions,
          lesson
        );
        if (transitionResult) {
          this.copyLessonNextStepAction(transitionStep, status);
        }
      } else {
        this.copyLessonNextStepAction(transitionStep, status);
      }
    });
  }

  private copyLessonNextStepAction(
    transitionStep: Reference[],
    workflow: WorkflowType
  ) {

    if (workflow.TransitionName){
      transitionStep.push({
        Code: workflow.TransitionName,
        Description: workflow.TransitionName,
        Sequence: workflow.TransitionNameDisplayOrder,
        Condition: workflow?.TransitionConditions,
        Direction: workflow?.TransitionType
      });

      transitionStep.forEach((step) => {
        if(step.Direction){
          let filteredBackwardSteps = transitionStep.filter(step => step.Direction.toLowerCase() === 'backward');
          let filteredNextSteps = transitionStep.filter(step => step.Direction.toLowerCase() === 'forward');
    
          this.lessonPriorSteps = [
            ...new Set(filteredBackwardSteps.sort((a, b) => a.Sequence - b.Sequence)),
          ];
    
          this.lessonNextSteps = [
            ...new Set(filteredNextSteps.sort((a, b) => a.Sequence - b.Sequence)),
          ];
        }
      }
      )
    }
  }
}
