import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  public tab: any = "tab1";
  public favoriteLessonCount: number = 0;
  public assignedLessonsCount: number = 0;
  public participatedLessonsCount: number = 0;

  public onClick(check) {
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else if (check == 5) {
      this.tab = "tab5";
    } else if (check == 6) {
      this.tab = "tab6";
    } else if (check == 7) {
      this.tab = "tab7";
    } else if (check == 8) {
      this.tab = "tab8";
    }
  }
}
