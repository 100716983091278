import { Injectable } from "@angular/core";
import {
  Reference,
  User,
} from "src/app/shared/models";
import { WorkflowConfig } from "src/app/shared/models/configsV2/workflowConfigModel";
import { ReferenceConfigModel } from "src/app/shared/models/configsV2/referenceConfigModel";
import {
  FieldConfigModel,
  Workflow,
  AttributeCore,
} from "src/app/shared/models/configsV2/fieldConfigModel";
import {
  AccessOnCertainRoles,
  FromStatusFieldConfigModel,
  WorkflowModel,
} from "src/app/shared/models/configsV2/fromStatusFieldConfigModel";
import { UserService } from "../../http/user.service";
import { Router } from "@angular/router";
import { CrossReferenceConfigModel } from "src/app/shared/models/configsV2/crossReferenceConfigModel";
import {
  DataTypeConstants,
  FieldConfigStatusValues,
  Statuses,
} from "src/app/config/global-enums.config";
import { environment } from "src/environments/environment";
import { MsGraphService } from "../../http/ms-graph.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SetConfigService } from "../set-config.service";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { GraphUserCache } from "src/app/shared/models/graph-users-groups";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from "rxjs/operators";
import { CrossVisibilityFieldConfigModel } from "src/app/shared/models/configsV2/crossVisibilityFieldConfigModel";
import { bulkEditConfigProperties } from "src/app/shared/models/configsV2/bulkEditConfigModel";
import { ProjectLookback, ProjectLookbackFieldUpdate } from "src/app/shared/models/project-lookback";
import { ToastService } from "../toast.service";

@Injectable({ providedIn: "root" })
export class ProjectLookbackCommonService {
  private grpEnv = environment.groupEnv;
  crossReferenceConfig = new CrossReferenceConfigModel();
  workflowConfig: WorkflowConfig[] = [];
  referenceConfig = new ReferenceConfigModel();
  fieldConfig: FieldConfigModel;
  fromStatusFieldConfig: FromStatusFieldConfigModel;
  crossVisibilityFieldConfig = new CrossVisibilityFieldConfigModel();
  public bulkEditConfig: bulkEditConfigProperties;

  fieldConfigGroups: Array<{ key: string; value: any }>;
  fieldConfigurations: any;
  editableFieldsByStatus: Array<{ key: string; value: string[] }>;
  visibleFieldsByStatus: Array<{ key: string; value: string[] }>;
  requiredFieldsByStatus: Array<{ key: string; value: string[] }>;
  readOnlyFieldsByStatus: Array<{ key: string; value: string[] }>;
  visibleFieldItems: any;

  isProjectLookbackEditable: boolean;


  allReferenceConfigGraphGroupsCache: GraphUserCache = {};

  //crossReference config fields (these are dependant dropdowns)
  subDisciplineReferencesfromCrossReferenceConfig: Reference[] = [];

  //For loading auto complete list of users
  userAsyncList: Observable<User[]>;1
  private searchText$ = new Subject<string>();

  userProfile: User;
  references: any;
  showPOCLoading = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private graphapi: MsGraphService,
    private setConfigService: SetConfigService,
    private toastService: ToastService
  ) { }

  public loadConfig() {
    try{
      this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
      this.workflowConfig = JSON.parse(localStorage.getItem("workflowConfig"));
      this.crossReferenceConfig = JSON.parse(
        localStorage.getItem("crossReferenceConfig")
      );
      this.referenceConfig = JSON.parse(localStorage.getItem("referenceConfig"));
      this.fieldConfig = JSON.parse(localStorage.getItem("fieldConfig"));
      this.fromStatusFieldConfig = JSON.parse(
        localStorage.getItem("fromStatusFieldConfig")
      );
      this.crossVisibilityFieldConfig = JSON.parse(
        localStorage.getItem("crossVisibilityFieldConfig")
      );
      this.bulkEditConfig = JSON.parse(localStorage.getItem("bulkEditConfig"));
      //To load Graph Users for POC field
      this.loadGraphUsersAsync();
      this.setReferencesFromReferenceConfigCache();
    }
    catch{
      this.toastService.showRetrievingMessage(true, 'Please give us a moment as we finish setting up some things from our end.');
    }
  }

  public getAdminStatus(currentUser: User) {
    let isAdmin = currentUser.roleClaims.some(ele => ele.includes('MGR'));
    return isAdmin;
  }

  public loadGroups() {
    const core = this.fieldConfig.fieldConfig.projectLookbackCore;
    this.fieldConfigGroups = this.readFieldConfiguration(core);
    return this.fieldConfigGroups;
  }

  public loadFieldConfigurations() {
    const coreFieldsConfig = this.fieldConfig.fieldConfig.projectLookbackCore;
    this.fieldConfigurations = Object.assign(coreFieldsConfig);
  }

  public loadFieldsStatus(projectLookback: ProjectLookback) {
    const statusList =
      this.fromStatusFieldConfig.fromStatusFieldConfig.extension[
      projectLookback.workflowType
      ];
    this.editableFieldsByStatus = this.getFieldList(
      statusList,
      FieldConfigStatusValues.editable,
      projectLookback
    );
    this.requiredFieldsByStatus = this.getFieldList(
      statusList,
      FieldConfigStatusValues.required,
      projectLookback
    );
    this.readOnlyFieldsByStatus = this.getFieldList(
      statusList,
      FieldConfigStatusValues.readOnly,
      projectLookback
    );
    this.visibleFieldsByStatus = this.getAllVisibleFiledsByStatus(
      this.readOnlyFieldsByStatus,
      this.editableFieldsByStatus
    );
  }

  public getFieldList(fields: WorkflowModel, fName: string, projectLookback: ProjectLookback) {
    const groupObject = new Array<{ key: string; value: string[] }>();
    for (let status in Statuses) {
      const statusKey = Statuses[status];
      let arr = [];
      for (const fieldItem in fields) {
        const fieldKey = fieldItem;
        const fieldModelBehaviour = fields[fieldItem];
        for (let behaviour in fieldModelBehaviour) {
          const attributeValue = fieldModelBehaviour[behaviour];
          let ifStatusExists = attributeValue["statuses"].find(
            (x) => x == statusKey
          );
          if (ifStatusExists) {
            if (behaviour === fName) {
              if (
                fName === FieldConfigStatusValues.readOnly ||
                fName === FieldConfigStatusValues.editable
              ) {
                arr = this.updateFieldListForStatus(
                  fName,
                  attributeValue,
                  projectLookback,
                  arr,
                  fieldKey,
                  statusKey
                );
              } else if (
                fName === FieldConfigStatusValues.required ||
                fName === FieldConfigStatusValues.exportable
              ) {
                arr.push(fieldKey);
              }
            }
          }
        }
      }
      groupObject.push({ key: statusKey, value: arr });
    }
    return groupObject;
  }

  getAllVisibleFiledsByStatus(editableFieldsByStatus, readOnlyFieldByStatus) {
    const visibleFieldByStatus = new Array<{ key: string; value: string[] }>();
    for (let status in Statuses) {
      let statusKey = Statuses[status];
      let arrReadOnlyFields = readOnlyFieldByStatus.find(
        (x) => x.key === statusKey
      ).value;
      let arrEditableFields = editableFieldsByStatus.find(
        (x) => x.key === statusKey
      ).value;
      let arrVisibleFields = arrReadOnlyFields.concat(arrEditableFields);
      visibleFieldByStatus.push({ key: statusKey, value: arrVisibleFields });
    }
    return visibleFieldByStatus;
  }

  public isFieldEditable(
    isProjectLookbackEditable: boolean,
    status: string,
    fieldName: string
  ): boolean {
    if (this.editableFieldsByStatus && fieldName && status) {
      if (isProjectLookbackEditable) {
        const fieldList = this.editableFieldsByStatus.find(
          (x) => x.key === status
        ).value;
        return fieldList?.includes(fieldName);
      }
    }
    return false;
  }

  public isFieldVisible(status: string, fieldName: string): boolean {
    if (this.visibleFieldsByStatus && status && fieldName) {
      const fieldList = this.visibleFieldsByStatus.find(
        (x) => x.key === status
      ).value;
      return fieldList?.includes(fieldName);
    }
    return false;
  }

  public isFieldRequired(status: string, fieldName: string): string {
    if (this.requiredFieldsByStatus && status && fieldName) {
      const fieldList = this.requiredFieldsByStatus.find(
        (x) => x.key === status
      ).value;
      return fieldList?.includes(fieldName)
        ? FieldConfigStatusValues.required
        : "";
    }
    return "";
  }

  public isfRequired(status: string, fieldName: string): boolean {
    if (this.requiredFieldsByStatus && status && fieldName) {
      const fieldList = this.requiredFieldsByStatus.find(
        (x) => x.key === status
      ).value;
      return fieldList?.includes(fieldName);
    }
    return false;
  }

  updateFieldListForStatus(
    fName: string,
    attributeValue: any,
    projectLookback: ProjectLookback,
    arr: any,
    fieldKey: string,
    statusKey: string
  ) {
    let ifNotNull: any;
    let onCertainRoles: any;
    let ifParticipant: any;
    if (fName === FieldConfigStatusValues.readOnly) {
      ifNotNull = attributeValue["readOnlyIfNotNull"];
      onCertainRoles = attributeValue["readOnlyOnCertainRoles"];
      ifParticipant = attributeValue["readOnlyIfParticipant"];
    } else if (fName === FieldConfigStatusValues.editable) {
      ifNotNull = null;
      onCertainRoles = attributeValue["editableOnCertainRoles"];
      ifParticipant = attributeValue["editableIfParticipant"];
    }
    if (ifNotNull) {
      if (this.setConfigService.isNotNull(projectLookback[ifNotNull.Param])) {
        arr.push(fieldKey);
      }
    } else if (ifParticipant) {
      if (
        ifParticipant == true &&
        this.setConfigService.isParticipantOfProjectLookback(projectLookback)
      ) {
        arr.push(fieldKey);
      }
    } else if (onCertainRoles) {

      let fcm = onCertainRoles as AccessOnCertainRoles;
      if (fcm.status.indexOf(statusKey) != -1) {
        for (let index = 0; index < fcm.roles.length; index++) {
          const element = fcm.roles[index];
          if (this.setConfigService.certainRoles(element)) {
            arr.push(fieldKey);
            break;
          }
        }
      } else {
        arr.push(fieldKey);
      }

    } else {
      arr.push(fieldKey);
    }
    return arr;
  }

  public loadVisibleItemGroupValues(status: string) {
    this.visibleFieldItems = new Array<{
      key: string;
      value: AttributeCore[];
    }>();
    this.fieldConfigGroups.forEach((i) => {
      const arr = [];
      i.value.forEach((j) => {
        if (this.isFieldVisible(status, j.key)) {
          arr.push({ key: j.key, value: j.value });
        }
      });
      if (arr.length > 0) {
        this.visibleFieldItems.push({ key: i.key, value: arr });
      }
    });
  }

  public getUsersFromDirectory(event) {
    const query = event.toString();
    if (query.length > 1) {
      this.showPOCLoading = true;
      this.searchText$.next(query);
    }
  }

  NavigateBackToHomePage() {
    this.router.navigate(["/home"]);
  }

  public setReferencesFromReferenceConfigCache() {
    let coreReferences = this.referenceConfig.core;
    this.references = Object.assign(coreReferences);
  }

  public getProjectLookbackFieldValueInstruction(fieldId: string) {
    //checking in core
    for (const group in this.fieldConfig.fieldConfig.projectLookbackCore) {
      if (this.fieldConfig.fieldConfig.projectLookbackCore[group][fieldId]) {
        return this.fieldConfig.fieldConfig.projectLookbackCore[group][fieldId].feMetadata
          ?.instruction;
      }
    }
  }

  getProjectLookbackFieldValue(projectLookback: ProjectLookback, field: string) {
    if (projectLookback) {
      if (projectLookback[field]) return projectLookback[field];
      else {
        if (projectLookback[projectLookback.workflowType])
          return projectLookback[projectLookback.workflowType][field];
      }
    }
    return null;
  }

  public getProjectLookbackForm(projectLookbackStatus: string, projectLookback?: ProjectLookback, visibleFieldItems?: any): FormGroup {
    const plbFormGroup = {}
    const fieldItems = visibleFieldItems
    ? visibleFieldItems
    : this.visibleFieldItems;
    let value: any;
    if (fieldItems) {
      fieldItems.forEach((group) => {
        group.value.forEach((item) => {
          if (item.value.feMetadata.dataType == DataTypeConstants.string) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : "",
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.stringArray
          ) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : [],
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.userObj
          ) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : User,
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (item.value.feMetadata.dataType == DataTypeConstants.date) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : Date,
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.number
          ) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : Number,
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.boolean
          ) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : Boolean,
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.userArray
          ) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : [],
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType ==
            DataTypeConstants.lessonCommentArray //prob delete later
          ) {
            value = this.getProjectLookbackFieldValue(projectLookback, item.key);
            plbFormGroup[item.key] = new FormControl(
              value ? value : [],
              this.isfRequired(projectLookbackStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          }
        });
      });
    }
    return new FormGroup(plbFormGroup);
  }

  public getPendingStatus(
    selectedTransitionName: any,
    extensionType: string,
    projectLookbackStatus: string
  ) {
    //get the pendingStatus on the basis of transitionName
    let workflowElement = this.workflowConfig[extensionType]?.find(
      (wf) =>
        wf.FromStatus == projectLookbackStatus &&
        wf.TransitionName == selectedTransitionName
    );

    return workflowElement?.ToStatus;
  }

  getGroupDescription(key: string) {
    let groups = this.references["GroupReferences"];
    let description =
      groups.find((x) => x.Code == key) != undefined
        ? groups.find((x) => x.Code == key).Description
        : key;
    return description;
  }

  // Potentially need to change this function for 'Asset'
  public getFieldSelectValues(
    tempProjectLookback: ProjectLookbackFieldUpdate,
    fieldToDictate: string,
    groupName: string,
    fieldList: Reference[]
  ): Reference[] {
    let crossReferenceConfig =
      this.fieldConfig.fieldConfig.projectLookbackCore[groupName][tempProjectLookback.fieldId]
        ?.feMetadata?.crossReferenceConfigEnum;
    let referenceConfig =
      this.fieldConfig.fieldConfig.projectLookbackCore[groupName][tempProjectLookback.fieldId]
        ?.feMetadata?.referenceConfigEnum;

    if (crossReferenceConfig !== undefined) {
      let crossReferenceValuesPair = this.crossReferenceConfig[
        crossReferenceConfig
      ]?.filter(
        (crossReference) =>
          crossReference[fieldToDictate] === tempProjectLookback.projectLookback[fieldToDictate]
      );

      let dictatedReferenceValues = this.referenceConfig.core[
        `${referenceConfig}`
      ]?.filter(
        (f) =>
          crossReferenceValuesPair.filter(
            (e) => e[tempProjectLookback.fieldId] === f.Code
          ).length > 0
      );
      return dictatedReferenceValues;
    } else {
      return fieldList;
    }
  }

  replaceTemplateGraphGroup(
    referenceConfigGraph: string,
    cop?: string,
    copSubgroup?: string,
    region?: string,
    discipline?: string,
    businessUnit?: string
  ) {
    /*
            SE-ELL-{ENV}-MGR-{COP} -> cop
            SE-ELL-{ENV}-TECHAPR-{COP}-{COPSUBGROUP} -> cop and subgroup
            SE-ELL-{ENV}-ECAPR-{REGION} -> ahead of time
            SE-ELL-{ENV}-IPAPR -> ahead of time
            SE-ELL-{ENV}-LGAPR -> ahead of time

            BUs:
            SE-BLL-{ENV}-TECHAPR-GOM-FE-{DISCIPLINE} -> discipline
            SE-BLL-{ENV}-MGR-GOM-FE -> setvalue in evalConfig

            */
    if (referenceConfigGraph) {
      let key = referenceConfigGraph
        .replace("{ENV}", this.grpEnv)
        .replace("{COP}", cop ?? "{}")
        .replace("{REGION}", region?.toUpperCase() ?? "{}")
        .replace("{BU}", businessUnit?.toUpperCase() ?? "{}")
        .replace("{DISCIPLINE}", discipline?.toUpperCase() ?? "{}");

      if (copSubgroup) {
        key = key.replace("{COPSUBGROUP}", copSubgroup);
      }

      key = key.replace("-{COPSUBGROUP}", "");
      return key;
    }
  }

  getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
    group: string,
    field: string,
    plbWorkflowType: string
  ): Reference[] {
    const enumName =
      this.fieldConfigurations[group][field].feMetadata.referenceConfigEnum;
    if (enumName) {
      let list = this.references[enumName];
      if(list) {
        list = list.filter((x) => {
          if(x.projectLookbackWorkflowTypeTag != null){
            return x.projectLookbackWorkflowTypeTag.includes(plbWorkflowType);
          }
          });
        };
        return list;
      }
      return null;
    }

  async getGraphUsersByProjectLookbackWorkflowType(
    referenceConfigGraph: string,
    workflowType: string,
    cop?: string,
    copSubgroup?: string,
    region?: string,
    discipline?: string,
    businessUnit?: string
  ): Promise<User[]> {
    let groupName = this.replaceTemplateGraphGroup(
      referenceConfigGraph[workflowType],
      cop,
      copSubgroup,
      region,
      discipline,
      businessUnit
    );
    if (!(groupName in this.allReferenceConfigGraphGroupsCache)) {
      //if the group name is not in dict: call and populate group with graph
      this.allReferenceConfigGraphGroupsCache[groupName] =
        await this.getUsersFromGraph(groupName);
    }
    return this.allReferenceConfigGraphGroupsCache[groupName];
  }

  public getAllValuesFromArrayUsers(val: User[]): string {
    return val?.length ? val.map((_) => _.fullName).join("; ") : null;
  }

  async getUsersFromGraph(groupName): Promise<User[]> {
    let usersGraph: User[] = [];
    await firstValueFrom(
      this.graphapi.getAADGroupId(groupName).pipe(
        map(async (data) => {
          if (data && data.value.length > 0) {
            let groupId = data.value[0].id;
            await firstValueFrom(
              this.graphapi.getAADGroupMembersFromGraph(groupId).pipe(
                map((data) => {
                  let arrayItem = data.value;
                  arrayItem.forEach((users) => {
                    let user = new User();
                    user.fullName = users.displayName;
                    user.email = users.userPrincipalName;
                    user.uniqueKey = users.userPrincipalName.toLowerCase();
                    usersGraph.push(user);
                  });
                })
              )
            );
          }
        })
      )
    );
    return usersGraph;
  }














  // Private
  private loadGraphUsersAsync() {
    this.userAsyncList = this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((user) => {
        this.showPOCLoading = false;
        return this.userService.getUserFromChevronDirectory(user);
      })
    );
  }

  private readFieldConfiguration(groupType: Workflow) {
    let groupObject = new Array<{ key: string; value: AttributeCore[] }>();
    for (const item in groupType) {
      const arr = [];
      const obj = groupType[item];
      const group = item;
      for (const attribute in obj) {
        const key = attribute;
        const attributeValue = obj[attribute];
        arr.push({ key: key, value: attributeValue });
      }
      groupObject.push({ key: group, value: arr });
    }
    return groupObject;
  }
}

