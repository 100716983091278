<div class="row pb-3">
  <div class="col col-11 left-align packageClass">
    <h2>additional SME</h2>
  </div>
  <div class="col col-1">
    <span class="closebtn" (click)="cancel()">
      &times;
    </span>
  </div>
</div>

<div class="row">
  <div class="col-6 left-align paddingmarginTopBottom0 workflowoptions">
    <div>
      <label class="required">cop</label>
    </div>
    <div class="page-header-text">
      <mat-form-field appearance="outline">
        <mat-select
          class="mat-select-style"
          panelClass="mat-select-panel-class"
          id="cop"
          [(value)]="cop"
          [(ngModel)]="cop"
          placeholder="select one"
          (valueChange)="checkCopSubgroup()"
        >
          <mat-option *ngFor="let ref of cops" [value]="ref.Code">
            <span>{{ ref.Description }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-6 workflowoptions" *ngIf="copSubgroups.length > 0">
    <div>
      <label class="required">cop subgroup</label>
    </div>
    <div class="page-header-text">
      <mat-form-field appearance="outline">
        <mat-select
          class="mat-select-style"
          panelClass="mat-select-panel-class"
          id="copSubgroup"
          [(value)]="copSubgroup"
          [(ngModel)]="copSubgroup"
          placeholder="select one"
          (valueChange)="onCopChanged()"
        >
          <mat-option
            *ngFor="let ref of copSubgroups"
            [value]="ref.copSubGroup"
          >
            <span>{{
              ref.copSubGroup | ConvertCodeToDescription : data.allCopSubgroups
            }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="row loader" *ngIf="!show">
  <div class="col col-12 spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div *ngIf="show && smeCount > 0">
  <div class="row">
    <div class="col col-12 left-align paddingmarginTopBottom0 paddingtop10">
      <div class="col-10 left-align paddingmarginTopBottom0">
        <div class="overflowDiv">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>name</th>
              <td mat-cell [id]="element.fullName" *matCellDef="let element">
                {{ element.fullName }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: columnsToDisplay"
              style="cursor: pointer"
              (click)="getRecord(row)"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
