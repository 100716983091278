<ngb-toast
  *ngFor="let toast of toastService.toastsError"
  class="toast-error"
  [autohide]="true"
  [delay]="toast.delay || 3000"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <mat-icon aria-hidden="false" aria-label="Example add-circle-outline icon">
      add_circle_outline</mat-icon
    >
    Error: {{ toast.textOrTpl }}
    <span class="hoover-button" (click)="toastService.remove(toast)">X</span>
  </ng-template>
</ngb-toast>
