import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { finalize } from "rxjs/operators";
import { LessonService } from "src/app/core/http/lesson.service";
import { ImportFileResult } from "src/app/shared/models";

@Component({
  selector: "import-lesson-modal",
  templateUrl: "import-lesson-modal.component.html",
  styleUrls: ["import-lesson-modal.component.scss"],
})
export class ImportLessonModalComponent implements OnInit {
  public fileName: string;
  public uploadInProgress: boolean = false;
  public showImage: boolean = false;
  public file: File;
  public fileNickname: string;
  public showDisclaimer: boolean = true;
  public fileDescription: string = "";
  public count: any;
  public importCompleted: boolean = false;
  public selectedTemplateType: string = "";
  importFileResult: ImportFileResult = new ImportFileResult();
  response = null;
  public nickname: string =
    'A nickname that will be used (by others) through the bulk workflow to identify the learnings.For example: "Chevron Shipping Sharepoint".';
  public sourceDescription: string =
    "A detailed description which identifies the origins of the learnings associated with this bulk import for traceability purposes. For example: ‘Imported from legacy Chevron Shipping Company (CSC) Lessons Learned Database in 2021. This latter database of learnings consisted of vetted learnings compiled by CSC employees seconded to Chevron MCPs as well as learnings captured from CSC tanker vessels.";
  public coreReferences: any;
  isPLB: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImportLessonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lessonService: LessonService
  ) {}

  public ngOnInit() {
    let referenceConfigCache = JSON.parse(
      localStorage.getItem("referenceConfig")
    );
    this.coreReferences = referenceConfigCache.core;
    if(this.data?.workflow){
      this.selectedTemplateType = "BulkImport_" + this.data.workflow;
      this.isPLB = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close(this.response);
  }

  public onFileSelected(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  public downloadTemplate() {
    this.lessonService
      .downloadTemplate(this.selectedTemplateType)
      .subscribe((res: Response) => {
        const file: any = res.body;
        let link = document.createElement("a");
        link.download = this.selectedTemplateType.toString() + ".xlsm";
        link.href = URL.createObjectURL(file);
        link.click();
        URL.revokeObjectURL(link.href);
      });
  }

  public upload() {
    this.uploadInProgress = true;
    if (this.file) {
      const formData = new FormData();
      formData.append("file", this.file);

      if(this.data?.workflow){
        const upload$ = this.lessonService.uploadPlbLesson(
        formData,
        this.fileNickname,
        this.fileDescription,
        this.data.projectCode,
        this.data.businessUnit
      );
      upload$
        .pipe(finalize(() => (this.uploadInProgress = false)))
        .subscribe((response) => {
          this.importCompleted = true;
          this.showDisclaimer = false;
          setTimeout(() => {
            this.dialogRef.close(response);
          }, 2000);
        });
      }
      else{
        const upload$ = this.lessonService.uploadLesson(
          formData,
          this.fileNickname,
          this.fileDescription
        );
        upload$
          .pipe(finalize(() => (this.uploadInProgress = false)))
          .subscribe((response) => {
            this.importCompleted = true;
            this.showDisclaimer = false;
            this.response = response;
            setTimeout(() => {
              this.dialogRef.close(response);
            }, 2000);
          });
      }
    }
  }

  public removeFile() {
    this.file = null;
    this.fileName = null;
  }
}
