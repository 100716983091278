import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  PageLayout,
  SearchType,
  SliderType,
  NextPageService,
} from "@cvx/nextpage";
import { appInfo, environment } from "src/environments/environment";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { StorageService } from "./core/services/storage.service";
import { UserService } from "./core/http/user.service";
import { UniqueVisitEventModel } from "./shared/models/app-insights";
import { ApplicationInsightsService } from "./core/http/application-insights.service";
import { Subject, filter, takeUntil } from "rxjs";
import { Router } from "@angular/router";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "ELL";
  name = "";
  loggedIn: boolean;
  isAuthenticated: boolean = false;
  contentContactName = appInfo.contentContactName;
  contentContactUrl = appInfo.contentContactUrl;
  technicalContactName = appInfo.technicalContactName;
  technicalContactUrl = appInfo.technicalContactUrl;
  copyright = appInfo.copyright;
  lastUpdate = environment.type == "DEV" ? "" : appInfo.lastUpdate;

  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;

  public productUserGuide = appInfo.productUserGuide;
  public productUserGuideUrl = appInfo.productUserGuideUrl;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private NP: NextPageService,
    private authService: MsalService,
    private storageService: StorageService,
    private userService: UserService,
    private appInsights: ApplicationInsightsService,
    public router: Router,
    private msalBroadcast: MsalBroadcastService) {
    this.NP.options.sitename = "Enterprise Lessons Learned";
    this.NP.options.navigationItems = [
      {
        title: "dashboard",
        url: "/home",
      },
      {
        title: "create new lesson",
        url: "/lessons",
      },
      {
        title: "search",
        url: "/lessons",
      },
      {
        title: "my actions",
        url: "/lessons",
      },
      {
        title: "reporting & metrics",
        url: "/report",
      },
      {
        title: "feedback",
        url: "/report",
      },
    ];
  }

  ngOnInit() {
    this.msalBroadcast.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$))
      .subscribe(_ => this.setLoginDisplay())
  }

  setLoginDisplay(){
    this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
    if (this.isAuthenticated && this.router.url.endsWith('/login-failed')) {
      this.router.navigate(['/']).then((_) => {});
    }
    else{
      this.setUserProfile();
    }
  }



  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public setUserProfile(): void {
    const currentUserProfile = this.authService.instance.getAllAccounts()[0];
    if (currentUserProfile) {
      this.userService.setAccount();
      this.userService
        .loggedUser({
          fullName: currentUserProfile.name,
          email: currentUserProfile.username,
          uniqueKey: currentUserProfile.username.toLowerCase(),
        })
        .subscribe(async (response) => {
          sessionStorage.setItem("profile", JSON.stringify(currentUserProfile));
          sessionStorage.setItem("name", currentUserProfile.name);
          sessionStorage.setItem("email", currentUserProfile.username);
          sessionStorage.setItem(
            "uniqueKey",
            currentUserProfile.username.toLowerCase()
          );
          sessionStorage.setItem("profile-ell", JSON.stringify(response));
          sessionStorage.setItem(
            "businessunit",
            response.businessUnit ? response.businessUnit : null
          );
          sessionStorage.setItem(
            "roles",
            currentUserProfile.idTokenClaims?.roles?.toString() ?? ""
          );
          sessionStorage.setItem("idtoken", currentUserProfile.idToken);
          await this.storageService.setConfigDataInLocalStorageStorage();
          this.loggedIn = true;
          let uniqueVisitModel = new UniqueVisitEventModel();
          uniqueVisitModel.userFullName = response.fullName;
          uniqueVisitModel.userBusinessUnit = response.businessUnit;
          uniqueVisitModel.userUniqueKey = response.uniqueKey;
          this.appInsights
            .createUniqueVisitCustomEvent(uniqueVisitModel)
            .subscribe();
        });
    }
  }
}
