import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnChanges {
  @Input() pageTitle!: string;
  formattedPageTitle!: string;

  ngOnChanges() {
    this.formattedPageTitle = this.pageTitle.replace(/\n/g, '<br>');
  }
}
