import { Injectable, TemplateRef } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastType } from "./../../config/global-enums.config";

@Injectable({ providedIn: "root" })
export class ToastService {
  toasts: any[] = [];
  toastsError: any[] = [];
  toastType: number = 0;

  constructor(private snackBar: MatSnackBar) {}

  showSuccess(display: boolean, message: string) {
    if (display) {
      this.snackBar.open(message, "", {
        panelClass: ['success'],
        duration: 5000,
      });
    } else {
      this.snackBar.dismiss();
    }
  }

  showWarning(display: boolean, message: string){
    if (display) {
      this.snackBar.open(message, "", {
        panelClass: ['warning'],
        duration: 5000,
      });
    } else {
      this.snackBar.dismiss();
    }
  }

  showError(display: boolean, message: string){
    if (display) {
      this.snackBar.open(message, "", {
        panelClass: ['error'],
        duration: 5000,
      });
    } else {
      this.snackBar.dismiss();
    }
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
    this.toastsError = this.toastsError.filter((t) => t !== toast);
  }

  showRetrievingMessage(display: boolean, message: string) {
    if (display) {
      this.snackBar.open(message, "", {
        duration: 3000,
      });
    } else {
      this.snackBar.dismiss();
    }
  }
}
