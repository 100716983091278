export class User {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  uniqueKey: string;
  email: string;
  emailNotifaction: boolean;
  lastLogin: Date;
  packagedLessons: string;
  businessUnit: string;
  skipTutorial: boolean;
  roleClaims: string[] = [];
  projectCodes: string[] = [];
}

export class TechnicalApprover {
  fullName: string;
  firstName: string;
  lastName: string;
  uniqueKey: string;
  email: string;
  value: string;
  discipline: string;
  disciplines: string[]=[];
  team: string;
  id: number;
  asset: string;
  triggerValues: string[]=[];
  codes: string[]=[];
  discCodes: string[]=[];
  businessUnit: string;
}

export class Manager {
  fullName: string;
  firstName: string;
  email: string;
  id: number;
  lastName: string;
  uniqueKey: string;
  businessUnit: string;
}

export class EcApprover {
  fullName: string;
  firstName: string;
  email: string;
  id: number;
  lastName: string;
  uniqueKey: string;
  businessUnit: string;
  triggerValues: string[]=[];
  codes: string[]=[];
}

export class LegalApprover {
  fullName: string;
  firstName: string;
  email: string;
  id: number;
  lastName: string;
  uniqueKey: string;
  businessUnit: string;
}

export class UserGroupList {
  manager = new UserGroupMembers();
  approverTechnicals = new UserGroupMembers();
  approverSMEs = new UserGroupMembers();
  approverEC = new UserGroupMembers();
  approverIP = new UserGroupMembers();
  approverLegal = new UserGroupMembers();
}

export class UserGroupMembers {
  fieldName: string; //manager
  groupName: string; //eg, SE-ELL-DEV-MGR-LNG
  users: User[] = [];
}

export class MSGraphProfile {
  displayName: string;
  givenName: string;
  surname: string;
  id: string;
  mail: string;
  userPrincipalName: string;
  isAdmin: boolean;
}
