<div mat-dialog-content>
  <div class="reply">Reply: "{{data.prevContent}}"</div>

  <textarea (change)="newComment($event)"></textarea>
</div>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()" class="btn btn-outline-primary">
    Cancel
  </button>
  <button
    mat-button
    (click)="onSend()"
    [mat-dialog-close]="data.newContent"
    class="btn btn-primary"
  >
    Send
  </button>
</mat-dialog-actions>
