import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';

@Component({
  selector: 'app-add-lesson-to-project-lookback',
  templateUrl: './add-lesson-to-project-lookback.component.html',
  styleUrl: './add-lesson-to-project-lookback.component.scss'
})
export class AddLessonToProjectLookbackComponent implements OnInit {

  availableProjectLookbacks: ParsingType[]=[];
  currentTriggerValue: any;
  projectCode: string = '';
  lessonExtensionType: string = '';

  constructor(public dialogRef: MatDialogRef<AddLessonToProjectLookbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private commonService: CommonService){

  }

  ngOnInit() {
    this.availableProjectLookbacks = this.data.values;
    this.lessonExtensionType = this.data.values[0].lessonExtensionType;
  }

  onValueChange(event: any){
    this.projectCode = event.value;
  }

  cancel() {
    this.dialogRef.close({update: false, value: null});
  }

  submit() {
    if(this.projectCode != null){
      this.dialogRef.close({ update: true, value: this.projectCode, extensionType: this.lessonExtensionType });
    }
    else{
      this.dialogRef.close({ update: false, value: null});
    }
  }
}

export class ParsingType {
  projectCode: string;
  lessonExtensionType: string;
  projectName: string;
}