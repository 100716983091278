<div class="row">
  <div class="col col-12 packageClass">
    <div>
      <span>create new list</span>
    </div>
    <div>
      <h6>enter list name</h6>
    </div>
    <div class="py-3">
      <input input="text" [(ngModel)]="name" />
    </div>
  </div>
</div>
<div class="row py-2">
  <div class="col col-12 d-flex justify-content-between dialogButtons">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">
      cancel
    </button>
    <button
      type="button"
      [mat-dialog-close]="true"
      [disabled]="!(name.length > 0)"
      class="btn btn-primary"
      (click)="confirm()"
    >
      create
    </button>
  </div>
</div>
