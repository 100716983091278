import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-ec-approver-change",
  templateUrl: "./ec-approver-change.component.html",
  styleUrls: ["./ec-approver-change.component.scss"],
})
export class EcApproverChangeComponent implements OnInit {
  retVal: boolean = false;
  title: string = "";
  textBody: string = "";

  constructor(
    public dialogRef: MatDialogRef<EcApproverChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.retVal = false;

    this.title = this.data.title;
    this.textBody = this.data.body;
  }

  confirm() {
    this.retVal = true;
    this.dialogRef.close(this.retVal);
  }

  cancel() {
    this.dialogRef.close(this.retVal);
  }
}
