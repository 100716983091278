<button
  mat-button
  class="close"
  (click)="skip()"
  aria-label="Close"
  matDialogClose
>
  <span aria-hidden="true">&times;</span>
</button>
<div class="container-fluid module">
  <div class="row">
    <div class="col-12 d-flex align-items-left px-0">
      <h2 class="titleText">{{ title }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col col-12 left-align px-0 paddingtop10 bodyText">
      {{ textBody }}
    </div>
  </div>
  <div class="row">
    <div class="col col-12 left-align paddingmarginTopBottom0 paddingtop10">
      <mat-dialog-actions class="d-flex justify-content-between">
        <h2 id="skipDialog" style="cursor: pointer" (click)="confirm()">
          {{ this.skipDisplay }}
        </h2>
        <h2 id="confirmDialog" [mat-dialog-close]="true" (click)="start()">
          {{ this.nextDisplay }}
        </h2>
      </mat-dialog-actions>
    </div>
  </div>
</div>
