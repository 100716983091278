export enum ProjectLookbackStatus {
    all = 'all', //For dashboard tabs
    active = 'active',
    draft = 'draft',
    inAdminReview = 'in admin review',
    completed = 'completed',

}

export enum LessonTabStatus {
    all =  'all', //For lesson tabs
    draft = 'draft',
    review = 'review',
    rejected = 'rejected',
    approved = 'approved',
    publish = 'publish',
}

export enum LessonStatus {
    draft = 'draft',
    review = 'review',
    rejected = 'rejected',
    approved = 'approved',
    publish = 'publish',
}