import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ExportResultsEventModel,
  LessonsLearnedViewsEventModel,
  ReportMetrics,
  SaveOrSubmitEventModel,
  UniqueVisitEventModel,
} from "src/app/shared/models/app-insights";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApplicationInsightsService {
  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}ApplicationInsights`;

  constructor(private http: HttpClient) {}

  getReportMetrics(): Observable<ReportMetrics> {
    const endPoint = `${this.apiRoot}/GetReportMetrics`;
    return this.http.get<ReportMetrics>(endPoint);
  }

  getReportMetricsByBU(bu: string): Observable<ReportMetrics> {
    const endPoint = `${this.apiRoot}/GetReportMetricsByBU?bu=${bu}`;
    return this.http.get<ReportMetrics>(endPoint);
  }

  getUniqueVisitsByBU(): Observable<Record<string, number>> {
    let endpoint = `${this.apiRoot}/GetUniqueVisitsByBU`;
    return this.http.get<Record<string, number>>(endpoint);
  }

  getUniqueUsersByBU(): Observable<Record<string, number>> {
    let endpoint = `${this.apiRoot}/GetUniqueUsersByBU`;
    return this.http.get<Record<string, number>>(endpoint);
  }

  createUniqueVisitCustomEvent(
    uniqueVisitModel: UniqueVisitEventModel
  ): Observable<any> {
    const endPoint = `${this.apiRoot}/createUniqueVisitCustomEvent`;
    return this.http.post<any>(endPoint, uniqueVisitModel);
  }

  createUniqueSearchCustomEvent(
    uniqueSearcModel: UniqueVisitEventModel
  ): Observable<any> {
    const endPoint = `${this.apiRoot}/CreateUniqueSearchCustomEvent`;
    return this.http.post<any>(endPoint, uniqueSearcModel);
  }

  createLessonLearnedViewCustomEvent(
    lessonsLearnedViews: LessonsLearnedViewsEventModel
  ): Observable<any> {
    const endPoint = `${this.apiRoot}/CreateLessonLearnedViewCustomEvent`;
    return this.http.post<any>(endPoint, lessonsLearnedViews);
  }

  createSaveOrSubmitCustomEvent(
    saveOrSubmit: SaveOrSubmitEventModel
  ): Observable<any> {
    const endPoint = `${this.apiRoot}/saveOrSubmitCustomEvent`;
    return this.http.post<any>(endPoint, saveOrSubmit);
  }

  createExportResultEvent(
    exportSearchResultEvent: ExportResultsEventModel
  ): Observable<any> {
    const endPoint = `${this.apiRoot}/exportResultEvent`;
    return this.http.post<any>(endPoint, exportSearchResultEvent);
  }
}
