import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { appInfo } from "src/environments/environment";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent {
  public supportTicketUrl = appInfo.supportTicketUrl;
  public authenticationErrorDocumentation = appInfo.authenticationErrorUrl;

  public showAuthenticationFix: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; status?: number }
  ) {}

  ngOnInit(){
    if(this.data.message.includes('authentication')){
      this.showAuthenticationFix = true;
    }
  }
}
