import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Lesson, LessonComment, LessonAttachment } from "src/app/shared/models";
import { LessonSearchResponse, SearchFacet, SearchOptions } from "src/app/shared/models/search-options";
import { LessonOperationResponse } from "src/app/shared/models/lesson-operation-response";
import { CurrentUserService } from "./current-user.service";
import { ProjectLookbackOperationResponse } from "src/app/shared/models/project-lookback-operation-response";

@Injectable({
  providedIn: "root",
})
export class LessonService {
  constructor(private http: HttpClient) { }

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}Lesson`;

  getLesson(lessonid: string): Observable<LessonSearchResponse> {
    const endPoint = `${this.apiRoot}/${lessonid}`;
    return this.http.get<LessonSearchResponse>(endPoint);
  }

  getLessonFromSql(lessonid: string): Observable<LessonSearchResponse> {
    const endPoint = `${this.apiRoot}/sql/${lessonid}`;
    return this.http.get<LessonSearchResponse>(endPoint);
  }

  getProjectLookbackLessons(lessonid: string): Observable<LessonSearchResponse> {
    const endPoint = `${this.apiRoot}/getProjectLookbackLessons/${lessonid}`;
    return this.http.get<LessonSearchResponse>(endPoint);
  }

  attachLessonToProjectLookback(body): Observable<ProjectLookbackOperationResponse>{
    const endPoint = `${this.apiRoot}/attachLessonToProjectLookback`;

    return this.http.post<ProjectLookbackOperationResponse>(endPoint, body);
  }

  createUpdateLesson(body): Observable<LessonOperationResponse> {
    const endPoint = `${this.apiRoot}/CreateUpdateLesson`;
    return this.http.post<LessonOperationResponse>(endPoint, body);
  }

  searchLessons(searchParameters): Observable<LessonSearchResponse> {
    let limit = -1;
    let endPoint = `${this.apiRoot}/SearchLessons`;
    return this.http.post<LessonSearchResponse>(endPoint, searchParameters);
  }

  searchLessonsFromSql(searchParameters): Observable<LessonSearchResponse> {
    let limit = -1;
    let endPoint = `${this.apiRoot}/searchLessonsFromSQL`;
    return this.http.post<LessonSearchResponse>(endPoint, searchParameters);
  }

  createComment(comment: LessonComment): Observable<LessonComment> {
    let endPoint = `${this.apiRoot}/CreateComment`;
    return this.http.post<LessonComment>(endPoint, comment);
  }

  downloadAttachment(fileName: string): Observable<any> {
    const endPoint =
      `${this.apiRoot}/DownloadAttachmentFile?fileName=` + fileName;
    return this.http.post(endPoint, null, { responseType: "blob" });
  }

  /** sql importfile endpoint for bulk import */
  uploadLesson(
    body: any,
    fileNickname: string,
    fileDescription: string
  ): Observable<any> {
    let endPoint = `${this.apiRoot}/ImportFile?importNickname=${encodeURI(
      fileNickname
    )}&longDescription=${encodeURI(fileDescription)}`;
    return this.http.post<LessonAttachment[]>(endPoint, body);
  }

  uploadPlbLesson(
    body: any,
    fileNickname: string,
    fileDescription: string,
    projectCode: string,
    businessUnitAbr: string
  ): Observable<any> {
    let endPoint = `${this.apiRoot}/ImportPLBFile?importNickname=${encodeURI(
      fileNickname
    )}&longDescription=${encodeURI(fileDescription)}&projectCode=${encodeURI(projectCode)}&businessUnitAbr=${encodeURI(businessUnitAbr)}`;
    return this.http.post<LessonAttachment[]>(endPoint, body);
  }

  deleteLessons(lessonsIds: string[]): Observable<LessonOperationResponse> {
    const endPoint = `${this.apiRoot}/DeleteLessons`;
    return this.http.post<LessonOperationResponse>(endPoint, lessonsIds);
  }

  public downloadTemplate(templateName: string): Observable<any> {
    let endPoint = `${this.apiRoot}/DownloadImportTemplate?templateType=${templateName}`;
    return this.http.post(
      endPoint,
      {},
      { responseType: "blob", observe: "response" }
    );
  }

  bulkUpdateLessons(
    lessons: Lesson[],
    importId: string
  ): Observable<LessonOperationResponse> {
    const endPoint = `${this.apiRoot}/BulkUpdate?importId=${importId}`;
    return this.http.post<LessonOperationResponse>(endPoint, lessons);
  }

  searchedLessonsCount(): Observable<number> {
    let limit = -1;
    let endPoint = `${this.apiRoot}/searchLessonsCount`;
    return this.http.get<number>(endPoint);
  }

  getLessonSuggestions(query: string): Observable<string[]> {
    let endpoint = `${this.apiRoot}/suggest?query=${query}`;
    return this.http.get<any>(endpoint);
  }

  getTopFiveLessons(): Observable<Lesson[]> {
    let endpoint = `${this.apiRoot}/GetTopFiveLessons`;
    return this.http.get<any>(endpoint);
  }

  getLessonsByWorkflow(): Observable<Record<string, number>> {
    let endpoint = `${this.apiRoot}/GetLessonsByWorkflow`;
    return this.http.get<Record<string, number>>(endpoint);
  }

  getLessonsByStatus(workflowType: string): Observable<Record<string, number>> {
    let endpoint = `${this.apiRoot}/GetLessonsByStatus?workflowType=${workflowType}`;
    return this.http.get<Record<string, number>>(endpoint);
  }

  getPublishedLessonCount(): Observable<any> {
    let endpoint = `${this.apiRoot}/GetPublishedLessonsCount`;
    return this.http.get<any>(endpoint);
  }
  public downloadPdf(lessonId: string): Observable<any> {
    let endPoint = `${this.apiRoot}/downloadPdf?lessonId=${lessonId}`;
    return this.http.post(
      endPoint,
      {},
      { responseType: "blob", observe: "response" }
    );
  }
}
