<app-page-header [pageTitle]="pageTitle"> </app-page-header>
<main class="container-fluid">
  <div class="plbContainer">
    <div class="plbHeader">
      <h3> Please select one of the options below</h3>
      <span> All participants for the following Project Lookback have been notified regarding the transition. Please follow the documentation provided by the email for next steps.</span>
    </div>
    <div class="row d-flex justify-content-around navigationOptionsDiv">
      <mat-card appearance="outlined" class="optionsCard" (click)="shareCode()">
        <img class="icon" src="assets/icons/Icon - Share.png" alt="" />
        <mat-card-content class="shareButton"
          ><h4
            type="button"
            id="createLesson"
          >
            share project lookback
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card
        appearance="outlined"
        class="optionsCard"
        (click)="navigateToDashboard()"
      >
        <img
          class="icon"
          src="assets/icons/Icon - Search.png"
          alt=""
        />
        <mat-card-content class="viewButton"
          ><h4 type="button" id="viewLesson">
            view your project lookbacks
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card 
        appearance="outlined" 
        class="optionsCard" 
        (click)="navigateBackToHomePage()">
        <img class="icon" src="assets/icons/Icon - Home.png" alt="" />
        <mat-card-content class="homeButton"
          ><h4 type="button" id="navigateToHome">
            return to home page
          </h4></mat-card-content
        >
      </mat-card>
    </div>
  </div>
</main>
