import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { Discipline } from "src/app/shared/models/discipline";
import { ChatRequestModel } from "src/app/shared/models/chat-request";

@Injectable({
  providedIn: "root",
})
export class PromptFlowService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}PromptFlow`;

    public sendMessage(chatRequest: ChatRequestModel): Observable<ChatRequestModel> {
      return this.http.post<ChatRequestModel>(`${this.apiRoot}/sendMessage`, chatRequest, {
        responseType: 'json',
      });
    }
}