import { Component, Inject,ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';
import { User } from 'src/app/shared/models';
import { AutocompleteComponent } from '../../components/autocomplete/autocomplete.component';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent {
  adminName: User[] = [];
  pendingAdmins: User[] = [];
  originalAdminName: User[] = []
  
  @ViewChild("toAutocomplete") toAutocomplete: AutocompleteComponent;

  constructor(
    public dialogRef: MatDialogRef<AddAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public toastService: ToastService
  ) {}

  ngOnInit() {
    this.adminName = [...this.data.currentAdmin];
    this.originalAdminName = [...this.data.currentAdmin];
  }
  
  addUsers(user) {
    if(user) {
      let newUser = new User()
      newUser.uniqueKey = user[0].mail;
      newUser.firstName = user[0].givenName;
      newUser.lastName = user[0].surname;
      newUser.email = user[0].mail;
      newUser.fullName = `${newUser.lastName}, ${newUser.firstName}`;
      if (!this.adminName.find(x => x.uniqueKey == newUser.uniqueKey) && !this.pendingAdmins.find(t => t.uniqueKey == newUser.uniqueKey))
      {
        this.pendingAdmins.push(newUser);
      }
      this.toAutocomplete.clearSelection();
    } 
  }

  deleteUser(user) {
    if(user) {
      let indx = this.adminName.findIndex(x => x.uniqueKey == user.uniqueKey);
      if(indx != -1){
        this.adminName.splice(indx, 1);
        return
      }

      indx = this.pendingAdmins.findIndex(x => x.uniqueKey == user.uniqueKey);
      if(indx != -1){
        this.pendingAdmins.splice(indx, 1);
      }
    } 
  }

  cancel() {
    this.dialogRef.close(this.originalAdminName);
  }

  submit() {
    let combinedUsers = [...this.adminName, ...this.pendingAdmins]
    this.dialogRef.close({users: combinedUsers, update: true});
  }

}
