export class FlawedLesson {
  public lessonId: string;
  public flawReportingTypeByUser: string;
  public commentsReportedByUser: string;
  public reportedByUser: string;
  public lessonWorkflowType: string;
  public managerActedByUniquekey: string;
  public managerComments: string;
  public managerActedStatus: boolean;
}
