import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Lesson, User, Reference, PackageList } from "src/app/shared/models";
import { SharedModule } from "src/app/shared/shared.module";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from "../../../dialogs/dialog-message/dialog-message.component";
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import { empty } from "rxjs";



@Component({
  selector: 'app-lesson-workflow-approval-selection',
  templateUrl: './lesson-workflow-approval-selection.component.html',
  styleUrls: ['./lesson-workflow-approval-selection.component.scss'],
  providers: [SharedModule],
})


export class LessonWorkflowApprovalSelectionComponent {

  availableWorkflows: any = [];
  availableWorkflowsOnMap: any = [];
  selectedWorkflow: any = '';
  pageTitle = "Lesson Workflow Selection";

  selectedValue: string;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog
  ){}

  async ngOnInit() {
    this.commonService.loadConfig();
    this.availableWorkflows = this.commonService.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.importOnly == undefined && x.mapShortname !== true && x.Code !== 'oroniteFEWorkflow'));
    this.availableWorkflows.sort((a, b) => a.mapShortname.localeCompare(b.mapShortname));
    this.availableWorkflowsOnMap = this.commonService.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.importOnly == undefined && x.showOnMap == true));
  }

  async buSelectedChange(workflow){
    let workflowName = this.availableWorkflows.filter((x) => (x.Code === workflow));
    const body = `Do you want to continue with the ${workflowName[0].mapShortname} workflow?` ;
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      panelClass:"dialog",
      data: { title: "", body },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.router.navigate(["/createNewLesson"], {
          queryParams: { lessonWorkflowType: workflow },
        });
      }});


  }

  async workflowSelectedChange(workflow){
    const body = `Do you want to continue with the ${workflow.mapShortname} workflow?` ;
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      panelClass:"dialog",
      data: { title: "", body },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.router.navigate(["/createNewLesson"], {
          queryParams: { lessonWorkflowType: workflow.Code },
        });
      }});
  }
}
