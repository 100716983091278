import { TechnicalApprover } from "./user";

export enum UserRole 
{
    TECHAPR = 'TechnicalApprover',
    MGR = 'Manager',
    Manager = 'MGR',
    TechnicalApprover = 'TECHAPR',
    LegalApprover = 'LGAPR',
    EcApprover = 'ECAPR',
    ECAPR = 'EcApprover',
    LGAPR = 'LegalApprover'
}