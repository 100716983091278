import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MSGraphProfile } from 'src/app/shared/models';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MsGraphService } from 'src/app/core/http/ms-graph.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  myControl = new FormControl();
  options: MSGraphProfile[];
  selectedValue: MSGraphProfile;
  selectedOptions: MSGraphProfile[] = [];
  isSearching: boolean = false;
  noResultsFound: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() label: string;
  @Input() profileId: string;
  @Input() showLabel: boolean = false;
  @Input() clearable: boolean = false;
  @Input() allowMultipleSelection: boolean = false;
  @Input() selectable: boolean = true;
  @Input() removable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() showProfileList: boolean = false;
  @Input() clearOnOptionSelect: boolean = false;
  @Input() appearance: string = 'fill';
  @Input() notRequiredAdmin: boolean = false;
  @Input() id: number;

  @Output() selectedItemEvent = new EventEmitter<MSGraphProfile>();
  @Output() selectedOptionsEvent = new EventEmitter<MSGraphProfile[]>();

  @ViewChild('multipleInput') multipleInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  constructor(private graphService: MsGraphService, private changeDetector: ChangeDetectorRef, private toastService: ToastService) {}

  async ngOnInit() {
    if (this.profileId) {
      const initialProfile = await this.graphService
        .getAADUserById(this.profileId)
        .toPromise().catch((error) => {this.toastService.showError(true, "Uh-oh! Looks like we were unable to find this User in Azure Active Directory. Please check the " + this.label + " field or reach out to the support team for further assistance.")});
      if(initialProfile) {
        this.selectedValue = initialProfile.value[0];
        this.myControl.setValue(initialProfile.value[0]);
      }
    }

    if(this.disabled && !this.notRequiredAdmin){
      this.myControl.disable();
    }

    this.myControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.isSearching = true;
          this.options = [];
          this.changeDetector.detectChanges();
        }),
        switchMap((value) =>
          this.graphService
            .searchAADUsers(this.getSearchString(value as any))
            .pipe(
              finalize(() => {
                this.isSearching = false;
                this.changeDetector.detectChanges();
              })
            )
        )
      )
      .subscribe({
        next: (result) => {
          this.options = [...result.value];
          this.noResultsFound = this.options.length == 0;
        },
        error: (err: Error) => console.error('SEARCH USER ERROR: ', err),
      });

      this.myControl.valueChanges.subscribe((value) => this.setValue(value));
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.allowMultipleSelection) {
      this.selectedOptions.push(event.option.value);
      this.selectedOptionsEvent.emit(this.selectedOptions);
      this.multipleInput.nativeElement.value = '';
      this.myControl.setValue(null);
      this.selectedOptions = []
    } else {
      this.selectedItemEvent.emit(event.option.value);
      if(this.clearOnOptionSelect) this.clearSelection();
    }
  }

  // Need to handle both the search string and a selected full type
  private setValue(value: string | MSGraphProfile): void {
    if (typeof value === 'string') this.selectedValue = null;
    else this.selectedValue = value;
  }

  getSearchString(value: string | MSGraphProfile): string {
    if (typeof value === 'string') return value;
    else if (!!value) return value.displayName;
    else return '';
  }

  getOptionText(option: MSGraphProfile) {
    if (option) return option.displayName;

    return '';
  }

  clearSelection(): void {
    this.selectedValue = null;
    this.myControl.setValue(null);
    this.selectedItemEvent.emit(null);
  }

  removeSelectedOption(option): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.selectedOptionsEvent.emit(this.selectedOptions);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Clear the input value
    event.chipInput!.clear();

    this.myControl.setValue(null);
  }

  panelClosed() {
    if(this.selectedValue) this.myControl.setValue(this.selectedValue);
    else this.clearSelection();
  }
}
