<div class="profile-edit" padding>
  <b style="color: #0066b2">Profile </b>
  <hr>
  <div class="row" padding>
    <div class="col-6">
      <label class="fieldLabel">Full Name:</label>
    </div>
    <div class="col-6">
      {{ data.user.fullName }}
    </div>
  </div>
  <div class="row" padding>
    <div class="col-6">
      <label class="fieldLabel">Email:</label>
    </div>
    <div class="col-6">
      {{ data.user.email }}
    </div>
  </div>
    <div class="row" padding>
    <div class="col-6">
      <label class="fieldLabel">Business Unit:</label>
    </div>
    <div class="col-6">
      {{ UserBu }}
    </div>
  </div>
  <div class="row" padding>
    <div class="col-6">
      <label class="fieldLabel">Last Login:</label>
    </div>
    <div class="col-6">
      {{ data.user.lastLogin | date }}
    </div>
  </div>
  <!-- <div class="row" padding>
    <div class="col-6">
      <label class="fieldLabel">Email Notification Enabled:</label>
    </div>
    <div class="col-6">
      <mat-checkbox
        color="primary"
        [(ngModel)]="data.user.emailNotification"
      ></mat-checkbox>
    </div>
  </div> -->
  <div class="row" padding>
    <div class="col-6">
      <label class="fieldLabel">Enable Tutorial:</label>
    </div>
    <div class="col-6">
      <mat-checkbox color="primary" [(ngModel)]="enableTutorial"></mat-checkbox>
    </div>
  </div>

  
  <div class="row options">
    <button color="outline-primary" (click)="close()" class="btn btn-outline-primary" id="closeButton">
      close
    </button>
    <button color="primary" (click)="update()" class="btn btn-primary" id="updateButton">update</button>
</div> 
</div>
