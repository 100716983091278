import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { Department } from "src/app/shared/models/department";
import { UserDepartmentBusinessUnit } from "src/app/shared/models/user-trigger-business-unit";

@Injectable({
  providedIn: "root",
})
export class UserDepartmentBusinessUnitService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}UserDepartmentBusinessUnit`;

    public getAllUsersByDeptId(deptId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByDepartmentId/${deptId}`);
    }

    public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
    }

    public getAllUsersByBuId(buId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
    }

    public getAllDepartmentApprovers(userId: number): Observable<Department[]> {
      return this.http.get<Department[]>(`${this.apiRoot}/getAllDepartmentApprovers/${userId}`);
    }

    public getAllDepartmentApproversByUniqueKey(uniqueKey: string): Observable<Department[]> {
      return this.http.get<Department[]>(`${this.apiRoot}/getAllDepartmentApproversByUniqueKey/${uniqueKey}`);
    }

    // Deprecated
    // public createUserDepartmentBusinessUnit(userId: number, departmentId: number, businessUnitId: number): Observable<UserDepartmentBusinessUnit>{
    //   const endPoint = `${this.apiRoot}/createUserDepartmentBusinessUnit`
    //   const body = {userId, departmentId, businessUnitId};
    //   return this.http.post<UserDepartmentBusinessUnit>(endPoint, body);
    // }

    public createUserDepartmentBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserDepartmentBusinessUnit>{
      const endPoint = `${this.apiRoot}/createUserDepartmentBusinessUnitV2`
      const body = {triggerCode, uniqueKey, businessUnitCode};
      return this.http.post<UserDepartmentBusinessUnit>(endPoint, body);
    }

    public deleteUserDepartmentBusinessUnit(uniqueKey: string, department: string, businessUnit: string){
      const endPoint = `${this.apiRoot}/deleteUserDepartmentBusinessUnit/uniqueKey=${uniqueKey}/department=${department}/businessUnit=${businessUnit}`
      return this.http.delete(endPoint);
    }






}