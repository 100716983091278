import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { AttachmentService } from "../../../../core/services/lesson-services/attachment.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LessonService } from "../../../../core/http/lesson.service";
import { LessonAttachment, User } from "../../../../shared/models";
import { CollectionAction } from "../../../../config/global-enums.config";
import { MatMenuTrigger } from "@angular/material/menu";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { ToastService } from "src/app/core/services/toast.service";
import { R } from "@angular/cdk/keycodes";

@Component({
  selector: "app-create-new-lesson-attachment",
  templateUrl: "./create-new-lesson-attachment.component.html",
  styleUrls: ["./create-new-lesson-attachment.component.scss"],
})
export class CreateNewLessonAttachmentComponent implements OnInit {
  @Input() isLessonEditable: boolean;
  @Input() isParticipant: boolean;
  @Input() isManager: any;
  @Input() isLessonEnterprise: boolean;

  // Variable to store shortLink from api response
  shortLink = "";
  loading = false; // Flag variable
  file: File = null; // Variable to store file
  fileSize = "";
  fileSizeMsg = "";
  currentUser: User;
  element: any;

  // Inject service
  constructor(
    public svcAttachment: AttachmentService,
    private snackBar: MatSnackBar,
    private svcLesson: LessonService,
    public singleLessonService: CreateEditSingleLessonService,
    private toastService: ToastService
  ) {}

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("profile-ell"));
  }

  public removeExtensionForAttachmentName(filename: string) {
    const lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) {
      return filename;
    } else {
      return filename.substr(0, lastDotPosition);
    }
  }

  isFileSizeValid(file: File): boolean {
    let ret = true;

    if (file.size > 1024 * 1024) {
      const fileSize = Math.round((file.size * 100) / (1024 * 1024)) / 100;
      if (fileSize > 2) {
        ret = false;
      }
    }

    return ret;
  }

  chooseFile(files: FileList) {
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      this.processSingleFile(files[i]);
    }
  }

  getFileIcon(fileName: string): string {
    const fileExt = this.getFileExtension(fileName);
    let retVal = "";

    if (fileExt === "pdf") {
      retVal = "assets/icons/Icon - Document - PDF.svg";
    } else if (fileExt === "xls" || fileExt === "xlsx") {
      retVal = "assets/icons/Icon - Document - Excel.svg";
    } else if (fileExt === "doc" || fileExt === "docx") {
      retVal = "assets/icons/Icon - Document - Word.svg";
    } else if (fileExt === "png" || fileExt === "jpeg" || fileExt === "svg") {
      retVal = "assets/icons/Icon - Document - Image.svg";
    } else if (fileExt === "pptx" || fileExt === "ppt") {
      retVal = "assets/icons/Icon - Document - PPT.svg";
    }

    return retVal;
  }

  deleteFile(i: number, attachment: LessonAttachment) {
    this.svcAttachment.deletedFiles.push(attachment);
    this.svcAttachment.selectedFiles.splice(i, 1);
  }

  removeFile(i: number) {
    this.svcAttachment.selectedFiles.splice(i, 1);
  }


  showMessageandHide(display: boolean, message: string) {
    this.toastService.showRetrievingMessage(display, message);
    setInterval(() => {
      this.snackBar.dismiss();
    }, 10000);
  }

  getFileExtension(fileName: string): string {
    const re = /(?:\.([^.]+))?$/;

    const ext = re.exec(fileName)[1];

    return ext.toLowerCase();
  }

  download(fileName: string) {
    setTimeout(() => {
      this.svcLesson.downloadAttachment(fileName).subscribe((res) => {
        this.downloadFile(res, fileName);
      });
    }, 100);
  }

  downloadFile(data: Blob, fileName: string) {
    const link = document.createElement("a");
    link.download = fileName;
    setTimeout(() => {
      link.href = URL.createObjectURL(data);
      link.click();
      URL.revokeObjectURL(link.href);
    }, 100);
  }

  downloadLocalFile(file: File) {
    setTimeout(() => {
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileURL;
      a.target = "_blank";
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
    }, 100);
  }

  onContextMenu(event: MouseEvent, item: number, element: any) {
    event.preventDefault();
    if(this.isParticipant || element.isPublic){
      this.contextMenuPosition.x = event.clientX + "px";
      this.contextMenuPosition.y = event.clientY + 20 + "px";
      this.contextMenu.menuData = { item };
      this.contextMenu.openMenu();
    }
  }

  onContextMenuAction1() {
    alert(`Click on Action 1`);
  }

  onContextMenuAction2() {
    alert(`Click on Action 2`);
  }
  
  disableDownloadAttachment(index: number) {
    this.element = this.svcAttachment.selectedFiles[index];
    if(this.element!=undefined && this.element!=null){return !(this.isLessonEnterprise || this.isParticipant || this.isLessonEditable);
    } else {
      return true;
    }
  }
  
  //need to refactor based on field config refactoring
  disableDeleteAttachment(index: number) {
    this.element = this.svcAttachment.selectedFiles[index];
    if(this.element!=undefined && this.element!=null && this.isLessonEditable)
      return false;
    else
      return true;
  }

  onContextMenuDownload(index: number) {
    this.element = this.svcAttachment.selectedFiles[index];
    setTimeout(() => {
      if (this.element.attachmentId) {
        this.download(this.element.attachmentId + "-" + this.element.fileName);
      } else {
        this.downloadLocalFile(this.element.fileStream);
      }
    }, 500);
  }

  onContextMenuDelete(index: number) {
    if (confirm("Are you sure you want to remove the attachment?")) {
      this.element = this.svcAttachment.selectedFiles[index];
      if (
        this.element.attachmentId == undefined ||
        this.element.attachmentId == null
      ) {
        this.removeFile(index);
      } else {
        this.deleteFile(index, this.element);
      }

      this.svcAttachment.bindGrid();
    }
  }

  private processSingleFile(file) {
    if (file) {
      const attachment = new LessonAttachment();
      attachment.fileName = file.name;
      attachment.action = CollectionAction.Add;
      attachment.isPublic = true;
      attachment.lastUpdatedDate = new Date();
      attachment.strFileSize = this.svcAttachment.getFileSize(file.size);
      attachment.lastUpdatedUser = this.currentUser;
      attachment.fileStream = file;
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const data = new Uint8Array(reader.result as ArrayBuffer);
          attachment.ContentData = Array.from(data);
          this.svcAttachment.selectedFiles.push(attachment);
          this.svcAttachment.bindGrid();
        },
        false
      );
      reader.readAsArrayBuffer(file);
    }
  }
}
