<div class="container-fluid p-4">
  <h2>add lesson to project lookback</h2>
  <mat-form-field appearance="outline" *ngIf="availableProjectLookbacks.length > 0" class="triggerValue">
    <mat-select (selectionChange)="onValueChange($event)" [(ngModel)]="currentTriggerValue" [(value)]="currentTriggerValue" name="val" placeholder="Please select one">
      <mat-option
        *ngFor="let val of availableProjectLookbacks"
        [value]="val.projectCode"
        >{{ val.projectName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <div class="d-flex flex-row justify-content-end mt-4" style="gap: 1rem">
    <button
      type="button"
      mat-button
      class="btn"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <button class="btn btn-primary" id="submitButton" (click)="submit()">
      submit
    </button>
  </div>
</div>
