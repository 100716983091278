import { Inject, Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-package-list-create",
  templateUrl: "./package-list-create.component.html",
  styleUrls: ["./package-list-create.component.scss"],
})
export class PackageListCreateComponent implements OnInit {
  public name: string = "";
  constructor(
    public dialogRef: MatDialogRef<PackageListCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // This is intentional
  }

  cancel(): void {
    this.name = "";
    this.dialogRef.close();
  }
  confirm() {
    this.dialogRef.close(this.name);
  }
}
