<div class="dialog-message">
  <div class="row" style="height:3rem;">
    <div class="col col-11 left-align packageClass">
    </div>
    <div class="col col-1">
      <span class="closebtn" (click)="cancel()">
        &times;
      </span>
    </div>
  </div>
  <div class="row py-1">
    <div class="col col-12 left-align paddingmarginTopBottom0 paddingtop10 bodyText">
      {{ textBody }}
    </div>
  </div>
  <div class="workflowoptions" *ngIf="showDropdown">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="dropdownValue" class="mat-select-style" placeholder="select one"
        panelClass="mat-select-panel-class">
        <mat-option *ngFor="let ref of dropdownList" [matTooltip]="ref.Description" [value]="ref.Code">
          <span>{{ ref.Description }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="showTextField" class="row">
    <mat-form-field style="width: 100%">
      <textarea id="textField" matInput [(ngModel)]="fieldValue"></textarea>
    </mat-form-field>
  </div>
  <div class="row py-2">
    <div class="col col-12 d-flex justify-content-between dialogButtons">
      <button type="button" id="cancelDialog" class="btn btn-outline-primary" (click)="cancel()">
        cancel
      </button>
      <button type="button" *ngIf="hideConfirm != true" id="confirmDialog" [mat-dialog-close]="true"
        class="btn btn-primary" [disabled]="showTextField && fieldValue == ''" (click)="confirm()">
        confirm
      </button>
    </div>
  </div>
</div>