import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UserFavoriteLesson } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class FavoriteLessonService {
  constructor(private http: HttpClient) {}

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}FavoriteLessons`;
  getFavoriteLessonsCount(): Observable<number> {
    return this.http.get<number>(`${this.apiRoot}/GetFavoriteLessonsCount`);
  }

  getFavoriteLessons(): Observable<UserFavoriteLesson[]> {
    return this.http.get<UserFavoriteLesson[]>(
      `${this.apiRoot}/GetFavoriteLessons`
    );
  }

  createFavoriteLesson(lessonId: string): Observable<UserFavoriteLesson[]> {
    return this.http.post<UserFavoriteLesson[]>(
      `${this.apiRoot}/CreateFavoriteLesson/${lessonId}`,
      null
    );
  }

  deleteFavoriteLesson(id: number): Observable<number> {
    return this.http.delete<number>(
      `${this.apiRoot}/DeleteFavoriteLesson/${id}`
    );
  }
}
