import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./modules/home/pages/home/home.component";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
  MsalModule,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from "./msal.setup";
import { NotfoundComponent } from "./modules/home/pages/notfound/notfound.component";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { LandingPageModule } from "./modules/home/landing-page.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderComponent } from "./modules/home/components/header/header.component";
import { MenubarComponent } from "./modules/home/components/menubar/menubar.component";
import { ProfileEditComponent } from "./modules/home/components/profile-edit/profile-edit.component";
import { DialogMessageComponent } from "./modules/home/dialogs/dialog-message/dialog-message.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { PackageListCreateComponent } from "./modules/home/dialogs/package-list-create/package-list-create.component";
import { ImportLessonModalComponent } from "./modules/home/pages/home/import-lesson-modal/import-lesson-modal.component";
import { FooterComponent } from "./modules/home/components/footer/footer.component";
import { SuccessToastComponent } from "./shared/components/success-toast/success-toast.component";
import { ErrorToastComponent } from "./shared/components/error-toast/error-toast.component";
import { LessonRoutingGuard } from "./core/services/lesson-routing-guard.service";
import { CreateEditSharedModule } from "./shared/create-edit-shared.module";
import { Create } from "powerbi-client";
import { ChatbotIconComponent } from "./modules/home/components/chatbot-icon/chatbot-icon.component";

const appComponents = [
  AppComponent,
  HomeComponent,
  HeaderComponent,
  MenubarComponent,
  ProfileEditComponent,
  DialogMessageComponent,
  PackageListCreateComponent,
  ImportLessonModalComponent,
  FooterComponent,
  SuccessToastComponent,
  ErrorToastComponent,
];

@NgModule({ declarations: [appComponents, NotfoundComponent],
    exports: [appComponents],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        LandingPageModule,
        CoreModule,
        SharedModule,
        MsalModule,
        BrowserAnimationsModule,
        CreateEditSharedModule,
        ChatbotIconComponent], providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { floatLabel: "never" },
        },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        {
            provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        LessonRoutingGuard,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
