import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldConfigModel } from "src/app/shared/models/configsV2/fieldConfigModel";
import { ReferenceConfigModel } from "src/app/shared/models/configsV2/referenceConfigModel";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { LessonVotingService } from "src/app/core/services/lesson-services/lesson-voting-service";
import { ToastService } from "src/app/core/services/toast.service";
import { FlawedLesson } from "src/app/shared/models/flawedLesson";
import { ApplicationInsightsService } from "src/app/core/http/application-insights.service";
import { SaveOrSubmitEventModel } from "src/app/shared/models/app-insights";
import { HighlightTag } from "angular-text-input-highlight";
import { ExtensionType } from "src/app/config/global-enums.config";
import { DialogMessageComponent } from "src/app/modules/home/dialogs/dialog-message/dialog-message.component";
import { ReportLessonComponent } from "src/app/modules/home/dialogs/report-lesson/report-lesson.component";
import { CreateEditProjectLookbackService } from "src/app/core/services/project-lookback-services/create-edit-project-lookback.service";
import { ProjectLookbackCommonService } from "src/app/core/services/project-lookback-services/project-lookback-common.service";

@Component({
  selector: 'app-create-edit-publish-plb-lesson',
  templateUrl: './create-edit-publish-plb-lesson.component.html',
  styleUrls: ['./create-edit-publish-plb-lesson.component.scss']
})
export class CreateEditPublishPlbLessonComponent implements OnInit {

  @ViewChild("stickyMenu") menuElement: ElementRef;
  private grpEnv = environment.groupEnv;
  sticky = false;
  elementPosition: any;
  referencesData: any;
  referenceConfig = new ReferenceConfigModel();
  fieldConfig = new FieldConfigModel();

  pageTitle = "Project Lookback Workflow";

  isAdmin: boolean = false;

  public showValidateButton = false;
  public showNaughtyWords = false;

  constructor(
    public commonService: CommonService,
    public singleLessonService: CreateEditSingleLessonService,
    public lessonVotingService: LessonVotingService,
    private elementRef: ElementRef,
    private appInsights: ApplicationInsightsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private toastService: ToastService,
    public projectLookbackService: CreateEditProjectLookbackService,
    public projectLookbackCommonService: ProjectLookbackCommonService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
    this.isAdmin = this.projectLookbackCommonService.getAdminStatus(this.singleLessonService.userProfile);
  }

  ngAfterViewInit() {
    if (this.menuElement) {
      this.elementPosition = this.menuElement.nativeElement.offsetTop;
    }
  }

  async getData() {
    await this.projectLookbackService.checkForPlb(true);
    this.fieldConfig = JSON.parse(localStorage.getItem("fieldConfig"));
    this.referenceConfig = JSON.parse(localStorage.getItem("referenceConfig"));
    const paramLessonId = this.activatedRoute.snapshot.queryParamMap.get("id");
    this.singleLessonService.extensionType = ExtensionType.plbLessonWorkflow;
    this.singleLessonService.onInit();
    this.singleLessonService.generateNewOrEditLessonValue(paramLessonId);
    if (!this.singleLessonService.lesson.projectCodes.includes(this.projectLookbackService.projectLookback.projectCode)) {
      this.singleLessonService.lesson.projectCodes.push(this.projectLookbackService.projectLookback.projectCode);
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= 270;
  }

  getTags(item): HighlightTag[] {
    let tags = [];
    if (item.value.feMetadata.highlightWords) {
      const workflowIndex = (
        item.value.feMetadata.highlightWorkflow ?? []
      ).indexOf(this.singleLessonService.lesson.lessonWorkflowType);
      const statusIndex = (item.value.feMetadata.highlightStatus ?? []).indexOf(
        this.singleLessonService.lesson.status
      );
      if (workflowIndex != -1 && statusIndex != -1) {
        this.showValidateButton = true;
        const fieldValue = this.singleLessonService.lesson[item.key];
        if (this.showNaughtyWords && fieldValue) {
          const regexNaughtyWord = new RegExp(
            localStorage.getItem("regexNaughtyWord"),
            "gi"
          );
          let naughtyWord;
          while (
            (naughtyWord = regexNaughtyWord.exec(" " + fieldValue + " "))
          ) {
            tags.push({
              indices: {
                start: naughtyWord.index,
                end: naughtyWord.index + naughtyWord[0].trim().length,
              },
              cssClass: "bg-highlight",
              data: naughtyWord[0].trim(),
            });
          }
          return tags;
        }
      }
    }

    return tags;
  }

  public reportLesson() {
    const dialogRef = this.dialog.open(ReportLessonComponent, {
      width: "550px",
      data: {
        reportingTypes:
          this.commonService.referenceConfig.core["ReportingTypes"],
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const flawedLesson = new FlawedLesson();
        flawedLesson.managerActedStatus = false;
        flawedLesson.flawReportingTypeByUser = result.reportType;
        flawedLesson.commentsReportedByUser = result.reportedComments;
        flawedLesson.lessonId = this.singleLessonService.lesson.id;
        flawedLesson.lessonWorkflowType =
          this.singleLessonService.lesson.lessonWorkflowType;
        flawedLesson.reportedByUser =
          this.singleLessonService.userProfile.uniqueKey;

        const referenceConfigGraph =
          this.commonService.fieldConfig.fieldConfig.lessonCore["personasGroup"]
            .manager?.feMetadata?.referenceConfigGraph;
        const userArr =
          await this.commonService.getGraphUsersByLessonWorkflowType(
            referenceConfigGraph,
            this.singleLessonService.lesson.lessonWorkflowType,
            this.singleLessonService.lesson.cop,
            null,
            null,
            this.singleLessonService.lesson.businessUnit
          );
        if (userArr.length > 0) {
          flawedLesson.managerActedByUniquekey = userArr[0].uniqueKey;
          await this.singleLessonService.reportLesson(flawedLesson);
          this.router.navigateByUrl("lessons");
        } else {
          this.toastService.showError(true, 'Uh-oh! Looks like there is no valid Manager setup. Please reach out to the support team for further assistance.');
        }
      }
    });
  }
  
  saveOrSubmitLesson(isSubmit: boolean, isSave?: boolean) {
    this.removeErrorClass();
    if (isSubmit) {
      this.addErrorClass();
    } else {
      //we need to set the pending status to null so it will not trigger the email when we save the lesson.It should only trigger when we submit
      this.singleLessonService.lesson.pendingStatus = null;
    }

    let projectCode = this.projectLookbackService.projectLookback.projectCode;
    if (!this.singleLessonService.lesson.projectCodes.includes(projectCode))
    {
      this.singleLessonService.lesson.projectCodes.push(projectCode);
    }
    this.singleLessonService.saveOrSubmitLesson(isSubmit);

    // Navigate back to PLB instead

    let saveOrSubmitCustomEvent = new SaveOrSubmitEventModel();
    saveOrSubmitCustomEvent.componentName =
      CreateEditPublishPlbLessonComponent.name;
    saveOrSubmitCustomEvent.methodName = this.saveOrSubmitLesson.name;
    this.appInsights
      .createSaveOrSubmitCustomEvent(saveOrSubmitCustomEvent)
      .subscribe();
  }

    // Confirm Dialog R2 Release
    public confirmSubmitDialog(selectedAction: string) {
      //To populate transitionName and pending status for lesson
      this.singleLessonService.lesson.transitionName = selectedAction;
      this.onTransitionNameSelected(selectedAction);
  
      if (this.singleLessonService.lessonFormGroup.valid) {
        const body = `are you sure you want to submit?`;
        const dialogRef = this.dialog.open(DialogMessageComponent, {
          panelClass:"dialog",
          data: { title: "create new lesson", body },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.saveOrSubmitLesson(true);
          }
        });
      } else {
        this.saveOrSubmitLesson(true);
      }
    }
  
    //Set Pending Status for lesson
    onTransitionNameSelected(selectedTransitionName: any) {
      //change the transitionName and change the pendingStatus accordingly
  
      if (selectedTransitionName) {
        this.singleLessonService.lesson.pendingStatus =
          this.commonService.getPendingStatus(
            selectedTransitionName,
            this.singleLessonService.lesson.lessonWorkflowType,
            this.singleLessonService.lesson.status
          );

        if (!this.singleLessonService.lesson.pendingStatus)
          this.toastService.showError(true, 
            `Uh-oh! There was an issue in transitioning the project lookback. Please reach out to the support team for further assistance."`
          );
      }
    }

  addErrorClass() {
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements = dom?.querySelectorAll(".ng-invalid");
    elements?.forEach((control) => {
      control.classList.add("error");
    });
  }

  removeErrorClass() {
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements = dom?.querySelectorAll(".error");
    elements?.forEach((control) => {
      control.classList.remove("error");
    });
  }

}
