import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-ipims-legacy-data",
  templateUrl: "./legacy-data.component.html",
  styleUrls: ["./legacy-data.component.scss"],
})
export class LegacyDataComponent implements OnInit {
  legacyData: any[];
  legacyDataMapper: { key: string; value: string }[] = new Array<{
    key: string;
    value: string;
  }>();

  constructor(
    public dialogRef: MatDialogRef<LegacyDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.legacyData = JSON.parse(this.data.legacyData);
    this.legacyDataMapper = this.convertToKeyValue();
  }

  cancel() {
    this.legacyData = [];
    this.legacyDataMapper = [];
    this.dialogRef.close();
  }

  convertToKeyValue() {
    let array = new Array<{ key: string; value: string }>();
    for (let item in this.legacyData) {
      array.push({ key: item, value: this.legacyData[item] });
    }
    return array;
  }
}
