import { Pipe, PipeTransform } from "@angular/core";
import { Reference } from "src/app/shared/models";
@Pipe({ name: "ConvertCodeToDescription" })
export class ConvertCodeToDescription implements PipeTransform {
  transform(
    lessonItem: any,
    referenceList: Reference[],
    includeBreaker = false
  ): string {
    let retValue: string = "";
    if (!(lessonItem === undefined || lessonItem === null) && referenceList) {
      if (Array.isArray(lessonItem)) {
        lessonItem.forEach((s) => {
          if (retValue && retValue.length == 0)
            retValue = this.getDescription(s, referenceList);
          else
            retValue = retValue + " / " + this.getDescription(s, referenceList);
        });
      } else retValue = this.getDescription(lessonItem, referenceList);
    }
    return referenceList ? retValue : lessonItem;
  }

  public getDescription(
    lessonItem: string,
    referenceList: Reference[]
  ): string {
    return referenceList?.find(
      (x) => x.Code.toLowerCase() === lessonItem.toString().toLowerCase()
    )?.Description;
  }
}
