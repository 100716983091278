export class UniqueVisitEventModel {
  userFullName: string;
  userUniqueKey: string;
  userBusinessUnit?: string;
}

export class UniqueSearchEventModel extends UniqueVisitEventModel {
  searchText: string;
  scope: string;
  facets: Array<Facet>;
}

export class LessonsLearnedViewsEventModel extends UniqueVisitEventModel {
  lessonId: string;
  lessonBusinessUnit: string;
}

export class ExportResultsEventModel extends UniqueVisitEventModel {
  lessonIds: string;
}

export class SaveOrSubmitEventModel {
  componentName: string;
  methodName: string;
}

export class ReportMetrics {
  uniqueVisits: number;
  uniqueSearches: number;
  uniqueUsers: number;
}

export class Facet {
  key: string;
  value: string;
}
