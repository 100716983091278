import { Injectable, OnDestroy } from "@angular/core";
import {
  Lesson,
  User,
  LessonAttachment,
  LessonComment,
  LessonFieldUpdate,
} from "src/app/shared/models";
import { SharedService } from "../../../modules/home/shared.service";
import { FormGroup, Validators } from "@angular/forms";
import { LessonService } from "../../http/lesson.service";
import { AttachmentService } from "./attachment.service";
import { ToastService } from "../toast.service";
import { EcApproverChangeComponent } from "../../../modules/home/dialogs/ec-approver-change/ec-approver-change.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogMessageComponent } from "../../../modules/home/dialogs/dialog-message/dialog-message.component";
import { Router } from "@angular/router";
import { CollectionAction, Statuses } from "../../../config/global-enums.config";
import { AdditionalSMEComponent } from "../../../modules/home/dialogs/additional-sme/additional-sme.component";
import { LegacyDataComponent } from "src/app/modules/home/dialogs/legacy-data/legacy-data.component";
import { LessonOperationResponse } from "src/app/shared/models/lesson-operation-response";
import { HttpErrorResponse } from "@angular/common/http";
import { FavoriteLessonCommonService } from "./favorite-lesson-common.service";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { ExtensionType } from "src/app/config/global-enums.config";
import { WorkflowType } from "src/app/shared/models/configsV2/workflowConfigModel";
import { EvalconfigService } from "./eval-config.service";
import { AttributeCore } from "src/app/shared/models/configsV2/fieldConfigModel";
import { PreviousStateRoutingService } from "../previous-state-routing.service";
import { SqlServerService } from "src/app/core/http/sql-server.service";
import { LessonVotingService } from "./lesson-voting-service";
import { environment } from "src/environments/environment";
import { LessonsLearnedViewsEventModel } from "src/app/shared/models/app-insights";
import { FlawedLesson } from "src/app/shared/models/flawedLesson";
import { constants } from "src/app/shared/models/constant";
import { ApplicationInsightsService } from "../../http/application-insights.service";
import { FlaggingLessonService } from "../../http/flagging-lesson.service";
import { firstValueFrom, Subscription } from "rxjs";
import { ProjectLookback } from "src/app/shared/models/project-lookback";
import { ProjectLookbackService } from "../../http/project-lookback.service";
import { CreateEditProjectLookbackService } from "../project-lookback-services/create-edit-project-lookback.service";
import { UserRole } from "src/app/shared/models/user-role";
import { TeamService } from "../../http/team.service";
import { UserTeamBusinessUnitService } from "../../http/user-team-bu.service";
import { UserService } from "../../http/user.service";
import { UserDisciplineBusinessUnitService } from "../../http/user-discipline-bu.service";
import { UserAssetBusinessUnitService } from "../../http/user-asset-bu.service";
import { DisciplineService } from "../../http/discipline.service";
import { DepartmentService } from "../../http/department.service";
import { UserDepartmentBusinessUnitService } from "../../http/user-department-bu.service";
import { UserRelatedProcessBusinessUnitService } from "../../http/user-related-process-bu.service";
import { RelatedProcessService } from "../../http/related-process.service";
import { AssetService } from "../../http/asset.service";
import { CopService } from "../../http/cop.service";
import { UserCopBusinessUnitService } from "../../http/user-cop-bu.service";
import { RegionService } from "../../http/region.service";
import { UserRegionBusinessUnitService } from "../../http/user-region-bu.service";
import { CoordinatorService } from "./coordinator.service";

@Injectable({
  providedIn: "root",
})
export class CreateEditSingleLessonService implements OnDestroy{
  private grpEnv = environment.groupEnv;
  show = false;

  pageTitle = "";
  statusTitle = "";
  lessonFormGroup: FormGroup;
  extensionType: string;
  lesson: Lesson = new Lesson();
  lessonOperationResponse = new LessonOperationResponse();
  
  localPointOfContact = new User();
  poc: User[] = [];
  approverSMEs: User[] = [];

  selectedRevCycle: Number = 0;
  selectedRegion: Number = 0;
  selectedAnotherCOP: Number = 0;

  selectedApp1: Number = 0;
  selectedApp2: Number = 0;
  selectedApp3: Number = 0;

  successMsg: string;
  errorMsg: string;

  userProfile: User;
  userInitials: string;

  approverActionRequests: Array<string> = [];
  lessonNextSteps: WorkflowType[] = [];
  lessonNextStep: string = "";

  // Is required, Editable, and dasable arrays in order to bind to html
  requiredFields: Array<string> = [];
  editableFields: Array<string> = [];
  visibleFields: Array<string> = [];
  groupCache: Array<string> = [];

  approverActionRequest: string = "";

  CachedUniqueKey: string;

  completionPercent: any;
  cachedUniqueKey: string;
  userGroups: User[] = [];
  copManager: string;

  // fields that should be invisible prior to certain field selections
  showCopSubGroup: boolean = false;
  showArea: boolean = false;
  showFacility: boolean = false;


  setStartDate = Date.now();
  startDate = new Date(this.setStartDate);

  bufferTest: User[]=[];

  // User arrays to ensure technical approvers are not appended during same trigger value changes
  prevCopApprovers: User[]=[];
  prevDiscApprovers: User[]=[];
  prevDeptApprovers: User[]=[];
  prevTeamApprovers: User[]=[];
  prevRelatedProcessApprovers: User[]=[];
  prevAssetApprovers: User[]=[];


  field = new AttributeCore();

  availableWorkflows: any;

  lessonWorkflowStatuses: any;

  showProgressBar: boolean = false;

  //Fields for Lesson Vote Count
  voteCountLikes: number;
  voteCountDislikes: number;
  disableLike: boolean = false;
  disableDislike: boolean = false;
  userVote: string;

  public showLesson: boolean = true;
  public subscriptions: Subscription = new Subscription();
  constructor(
    private router: Router,
    private shared: SharedService,
    private lessonService: LessonService,
    private attachmentService: AttachmentService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private previousRoute: PreviousStateRoutingService,
    private snackBar: MatSnackBar,
    public favoriteLessonCommonService: FavoriteLessonCommonService,
    private appInsights: ApplicationInsightsService,
    public commonService: CommonService,
    private sqlService: SqlServerService,
    public evalService: EvalconfigService,
    public lessonVotingService: LessonVotingService,
    private flaggingLesson: FlaggingLessonService,
    private projectLookbackService: CreateEditProjectLookbackService,
    private projectLookbackApiService: ProjectLookbackService,
    private teamService: TeamService,
    private userTeamBuService: UserTeamBusinessUnitService,
    private userService: UserService,
    private userDisciplineBuService: UserDisciplineBusinessUnitService,
    private userAssetBuService: UserAssetBusinessUnitService,
    private assetService: AssetService,
    private disciplineService: DisciplineService,
    private departmentService: DepartmentService,
    private relatedProcessService: RelatedProcessService,
    private copService: CopService,
    private userCopBuService: UserCopBusinessUnitService,
    private userDepartmentBuService: UserDepartmentBusinessUnitService,
    private userRelatedProcessBuService: UserRelatedProcessBusinessUnitService,
    private regionService: RegionService,
    private userRegionBuService: UserRegionBusinessUnitService,
    private coordinatorService: CoordinatorService

  ) { }

  onInit() {
    this.lesson = new Lesson();
    this.show = false;
    this.shared.onClick(2);
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.evalService.onInit();
    this.commonService.loadConfig();
  }

  async generateNewOrEditLessonValue(id: string) {
    if (id) {
      this.lesson.id = id.toUpperCase();
      await this.retrieveLesson();
    } else {
      this.generateNewLessonValue();
      await this.retrieveLesson();
    }
  }

  private generateNewLessonValue() {
    // put the status in enums
    this.lesson.status = Statuses.draft;
    this.lesson.lessonWorkflowType = this.extensionType;

    let workflowName =  this.commonService.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.Code == this.lesson.lessonWorkflowType));
    this.pageTitle = (workflowName.map((item) => item.mapShortname) + ' Workflow');

    if (this.extensionType != ExtensionType.copWorkflow) {
      if(this.extensionType != ExtensionType.plbLessonWorkflow){
        this.lesson.businessUnit =
        this.commonService.references.BusinessUnit.find((i) =>
          i.lessonWorkflowTypeTag.includes(this.extensionType)
        )?.Code ?? undefined;
      }
      else{
        this.lesson.businessUnit =
        this.commonService.references.BusinessUnit.find((i) =>
          i.lessonWorkflowTypeTag.includes(this.extensionType) && i.Code.includes(this.projectLookbackService.projectLookback.businessUnit)
        )?.Code ?? undefined;

        this.lesson.asset = this.projectLookbackService.projectLookback.asset;
        this.lesson.approverTechnicals = this.projectLookbackService.projectLookback.projectAdmins;
        this.retrieveManagers(ExtensionType.plbLessonWorkflow);

      }

    this.lesson.region =
      this.commonService.crossReferenceConfig.BusinessUnitRegion?.find(
        (i) => i.businessUnit == this.lesson.businessUnit
      )?.region ?? undefined;

    }

    this.lesson.assignees = [this.userProfile]; // this changes if user submits a lesson
    this.lesson.lastUpdatedUser = { ...this.userProfile };
    this.lesson.submitter = { ...this.userProfile };
    this.lesson.pointOfContact = { ...this.userProfile };

    //setting default values from field config
    let coreGroup = this.commonService.fieldConfig.fieldConfig.lessonCore;
    for (const item in coreGroup) {
      const fields = coreGroup[item]; //fields
      for (const fieldName in fields) {
        const key = fieldName; //fieldname
        const fieldAttribute = fields[fieldName]; //fieldProperties
        if (fieldAttribute.feMetadata?.defaultValue != undefined) {
          this.lesson[key] = fieldAttribute.feMetadata.defaultValue;
        }
      }
    }
  }

  async retrieveManagers(lessonWorkflowType){
    await this.commonService.loadGraphUsersByLessonWorkflowType();  
    let roleClaimName = this.commonService.getRoleClaims(UserRole.Manager);
    let managers;

    switch(lessonWorkflowType){
      case "copWorkflow":
        managers = await this.commonService.loadUsersApiByFilters("", this.lesson.lessonWorkflowType, UserRole.MGR);
        managers.forEach((user => {
          if(!user.roleClaims.includes(roleClaimName)){
            this.toastService.showError(true, 'Uh-oh! Looks like there was an issue assigning the Manager: ' + user.fullName + ' due to insufficient permissions. Please reach out to the support team for further assistance.');
          }
          else{
            this.lesson.managers.push(user);
          }
        }))
        break;
      case "plbWorkflow":
        this.lesson.managers = this.projectLookbackService.projectLookback.projectAdmins;
        break;
      default:
        managers = await this.commonService.loadUsersApiByFilters("", this.lesson.businessUnit, UserRole.MGR);
        managers.forEach((user => {
          if(!user.roleClaims.includes(roleClaimName)){
            this.toastService.showError(true, 'Uh-oh! Looks like there was an issue assigning the Manager: ' + user.fullName + ' due to insufficient permissions. Please reach out to the support team for further assistance.');
          }
          else{
            this.lesson.managers.push(user);
          }
        }))
        break;
    }
    if(this.lesson.managers.length == 0){
      this.toastService.showError(true, 'Uh-oh! Looks like there was an issue with finding a valid manager for this workflow. Please reach out to your coordinator or the development team for more information.')
    }
  }

  resetForm() {
    this.resetAttachment();
    this.lessonFormGroup = this.commonService.getLessonForm(this.lesson.status);
  }

  updateLesson(value: LessonFieldUpdate) {
    this.lesson[value.fieldId] = value.lesson[value.fieldId];
  }

  getLessonFieldValue(field: string, extension?: string) {
    if (this.lesson[field]) {
      return this.lesson[field];
    } else {
      if (this.lesson[this.lesson.lessonWorkflowType]) {
        return this.lesson[this.lesson.lessonWorkflowType][field];
      }
    }

  }

  async getLessonValues(lessonId){
    let lesson = await firstValueFrom(this.lessonService.getLessonFromSql(lessonId));
    return lesson;
  }

  getRequestmoreUser(): string {
    let manager = this.lesson?.managers[0]?.uniqueKey;
    let submitter = this.lesson?.submitter?.uniqueKey;
    return `mailto:${manager};${submitter}`;
  }

  shareLesson(): string {
    const userName = sessionStorage.getItem("name");
    const lessonLink = document.createElement("a");
    lessonLink.href = window.location.href;
    const body = `Hi Lesson Learned User,
    \n${userName} has shared the below lesson with you
    \nDetails:\nLessonId: ${this.lesson.displayId} \nLessonTitle: ${this.lesson.title}\nLink to Lesson: ${lessonLink}`;
    return `mailto:?subject=${userName} has shared this lesson with you&body=${encodeURIComponent(
      body
    )}`;
  }

  async retrieveLesson() {
    if (this.lesson.id !== "") {      
      //existing lesson
      const getLessonSubscription = this.lessonService
        .getLessonFromSql(this.lesson.id)
        .subscribe(async (result) => {
          this.lesson = result.lessons[0];
          await this.commonService.loadGraphUsersByLessonWorkflowType(
            this.lesson.cop,
            this.lesson.copSubGroup,
            this.lesson.region,
            this.lesson.discipline,
            this.lesson.businessUnit
          );
          this.extensionType = this.lesson.lessonWorkflowType;
          let workflowName =  this.commonService.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.Code == this.lesson.lessonWorkflowType));
          this.pageTitle = (workflowName.map((item) => item.mapShortname) + ' Workflow');

          this.lessonWorkflowStatuses = this.evalService.getLessonWorkflowStatuses(this.lesson.lessonWorkflowType);
          if(this.lessonWorkflowStatuses){
              let currentStatusIndex = this.lessonWorkflowStatuses.findIndex(status => status === this.lesson.status);
              const stages = `${currentStatusIndex + 1}/${this.lessonWorkflowStatuses.length}`;
              this.statusTitle = `Lesson Progression - Stage: (${stages})`;
              this.showProgressBar = true;
          }
          await this.loadConfigs();

          //check if no subgroup
          if (this.lesson.cop != null) {
            let hasSubGroup = this.commonService.crossReferenceConfig.CopCopSubGroup.some(val => val.cop == this.lesson.cop);
            if (hasSubGroup) {
              this.setIsVisibility("copSubGroup", true);
            }
            else {
              this.setIsVisibility("copSubGroup", false);
            }
          }

          //checking isenterprise

          if (
            !this.lesson.isEnterprise &&
            this.lesson.businessUnit != this.userProfile.businessUnit
          ) {
            if(this.userProfile.roleClaims.some(ele => ele.includes('MGR'))){
              this.showLesson = true;
            }
            else{
              this.showLesson = false;
            }
          }

          //setting default values for crossVisibilityFields---existing lesson
          if (this.lesson.sendAsCesComment == null) {
            let crossVisibilityFields: any =
              constants.CrossVisibilityFieldsConst;
            crossVisibilityFields.forEach((i) => {
              this.commonService.resetDefaultVaulesForCrossVisibility(
                i,
                this.lesson
              );
            });
          }
          this.checkCrossVisibilityConfig();
          this.resetForm();
          this.logApplicationStatistics(
            this.lesson.id,
            this.lesson.businessUnit
          );
          if (this.lesson.lessonAttachments) {
            this.attachmentService.selectedFiles = [].concat(
              this.lesson.lessonAttachments
            );
          }
          this.attachmentService.bindGrid();
          this.show = true;
        });
      
      this.subscriptions.add(getLessonSubscription);
    } else if (this.lesson.id === "") {
      this.retrieveManagers(this.lesson.lessonWorkflowType);

      this.lessonWorkflowStatuses = this.evalService.getLessonWorkflowStatuses(this.lesson.lessonWorkflowType);
      if(this.lessonWorkflowStatuses){
          let currentStatusIndex = this.lessonWorkflowStatuses.findIndex(status => status === this.lesson.status);
          const stages = `${currentStatusIndex + 1}/${this.lessonWorkflowStatuses.length}`;
          this.statusTitle = `Lesson Progression - Stage: (${stages})`;
          this.showProgressBar = true;
      }


      await this.loadConfigs();
      this.checkCrossVisibilityConfig();
      this.resetForm();
      this.resetAttachment();


      // field visibility logic unique to SASBU
      if(this.lesson.lessonWorkflowType === 'sasbuFEWorkflow'){
        this.setIsVisibility("area", false);
        this.setIsVisibility("facility", false);
      }
      this.show = true;
    } else {
      this.show = true;
      this.attachmentService.selectedFiles = this.lesson.lessonAttachments;
      this.attachmentService.bindGrid();
    }
  }

  private async loadConfigs() {
    this.commonService.loadGroups();
    this.commonService.loadFieldConfigurations();
    await this.commonService.loadFieldsStatus(this.lesson);
    this.commonService.loadVisibleItemGroupValues(this.lesson.status);
    this.evalService.loadLessonNextSteps(this.lesson);
  }

  async attachLessonToProjectLookback(){
    firstValueFrom(this.lessonService.createUpdateLesson(this.lesson)).then(res => {
      if(res){
        this.lessonService.attachLessonToProjectLookback(this.lesson).subscribe(res => {
          if(res){
            this.toastService.showSuccess(true, `The given lesson is now associated to the Project Lookback: ${res.projectLookbacks[0].projectName}!`);
          }
        })
      }
    })
          

  }

  resetAttachment() {
    this.attachmentService.selectedFiles = [];
    this.attachmentService.deletedFiles = [];
    this.attachmentService.bindGrid();
  }

  public getFirstValueFromArrayUsers(val: User[]): string {
    return val ? val[0]?.fullName : null;
  }

  public getAllValuesFromArrayUsers(val: User[]): string {
    return val?.length ? val.map((_) => _.fullName).join("; ") : null;
  }

  handleError(err, savedAssignee, savedStatus, savedPreviousStatus) {
    this.lesson.status = savedStatus;
    this.lesson.previousStatus = savedPreviousStatus;
    this.lesson.assignees = savedAssignee;
    this.toastService.showError(true, `Uh-oh! Looks like you were unable to save this lesson due to: ${err.error}. Please reach out to the support team for further assistance.`);
    this.show = true;
  }

  async onValueChange(
    selectedValue: any,
    field: string,
    referenceConfigGraph: string
  ) {
    switch (field) {
      case "businessUnit":
        await this.onBusinessUnitChanged(selectedValue);
        break;
      case "sendAsCesComment":
        this.checkCrossVisibilityConfig();
        break;
      default:
    }
    if(this.lesson.lessonWorkflowType != 'copWorkflow'){
      switch (field) {
        case "discipline":
          this.onDisciplineChanged(selectedValue, referenceConfigGraph);
          break;
        case "team":
          this.onTeamChanged(selectedValue, referenceConfigGraph);
          break;
        case "department":
          this.onDepartmentChanged(selectedValue, referenceConfigGraph);
          break;
        case "relatedProcess":
          this.onRelatedProcessChanged(selectedValue, referenceConfigGraph);
          break;
        case "asset":
          this.onAssetChange(selectedValue, referenceConfigGraph);
          break;
        case "block":
          this.checkNullArea(selectedValue);
          break;
        case "area":
          this.checkNullFacility(selectedValue);
      }
    }
    else{
      switch (field) {
        case "cop":
          this.onCopChanged(selectedValue, referenceConfigGraph);
          break;
        case "copSubGroup":
          this.onCopSubGroupChanged(selectedValue, referenceConfigGraph);
          break;
        case "approverEC":
          this.onEcApproverChanged(selectedValue);
          break;
        case "ipLegalComplianceReviewQuestion1":
        case "ipLegalComplianceReviewQuestion2":
        case "ipLegalComplianceReviewQuestion3":
        case "exportComplianceReviewQuestion1":
        case "exportComplianceReviewQuestion2":
        case "exportComplianceReviewQuestion3":
          this.onComplianceChangeForPopulatingLegalApproverCopWorkflow();
          this.checkCrossVisibilityConfig();
          break;
      }
    }
  }

  async populateApprovers(oldApprovers: User[], newApprovers: User[], referenceConfigGraph: string) {

    let existingApprovers;
    let authorizedApprovers;
    let usersFromGraphCall = await this.commonService.getGraphUsersByLessonWorkflowType(referenceConfigGraph, this.lesson.lessonWorkflowType);

    if(this.lesson.approverTechnicals === null){
      this.lesson.approverTechnicals = [];
    }
    if(oldApprovers && oldApprovers.length > 0){
      existingApprovers = oldApprovers.map(u => u.uniqueKey);
    }

    authorizedApprovers = usersFromGraphCall.map(u => u.uniqueKey);

    if(existingApprovers){
      newApprovers.forEach(( user => {
        if(!existingApprovers.includes(user.uniqueKey.toLowerCase()) && authorizedApprovers.includes(user.uniqueKey.toLowerCase())){
          this.lesson.approverTechnicals.push(user);
        }
      }))
    }
    else{
      newApprovers.forEach(( user => {
        if(authorizedApprovers.includes(user.uniqueKey.toLowerCase())){
          this.lesson.approverTechnicals.push(user);
        }
      }))
    }
  }

  //=================On Compliance Questions Change===============
  async onComplianceChangeForPopulatingLegalApproverCopWorkflow() {
    //allReferenceConfigGraphGroupsCache gets called on app startup and is available in commonService
    //only populate if they start making changes on the compliance changes.

    let legalApprover = await this.coordinatorService.getLegalApproversByBu(this.lesson.lessonWorkflowType);
    if(legalApprover){
      this.lesson.approverLegal = legalApprover[0];
    }
  }

  async populateApproverECCopWorkflow() {
    this.regionService.getRegion(this.lesson.region).subscribe(res => {
      if(res){
        this.userRegionBuService.getAllUsersByRegionId(res.id).subscribe(res => {
          this.lesson.approverEC = res[0];
        })
      }
    })
  }


  //===================Core OnChange Region==========================
  async onBusinessUnitChanged(selectedBU: any) {
    if (selectedBU) {
      this.lesson.region =
        this.commonService.crossReferenceConfig.BusinessUnitRegion?.find(
          (i) => i.businessUnit == selectedBU.value
        )?.region ?? undefined;

      await this.populateApproverECCopWorkflow();
      this.checkCrossVisibilityConfig();

      if (!this.lesson.region)
        this.toastService.showError(true, 
          `Looks like there is no region setup for this business unit ${this.lesson.businessUnit}. Please reach out to the support team for further assistance.`
        );
    }
  }

  //==================================================BU Workflows ON CHANGE REGION============================

  async onAssetChange(
    selectedAsset: any,
    referenceConfigGraph: string
  ) {
    if(this.prevAssetApprovers.length > 0){
      this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevTeamApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
    }
    if(this.lesson.lessonWorkflowType != "plbLessonWorkflow" || "copWorkflow"){
      if(selectedAsset.value.length > 0){
        this.assetService.getAsset(selectedAsset.value).subscribe(res => {
          if(res){
            this.userAssetBuService.getAllUsersByAssetId(res.id).subscribe( r => {
              let userIds = r.map(user => Number(user.id));
              if(userIds.length > 0){
                this.userService.validateUsers(userIds, this.lesson.businessUnit, UserRole.TECHAPR).subscribe( res => {
                  if(res.length > 0){
                    this.prevAssetApprovers = res;
                    this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                    this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
                  }
                  else{
                    this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
                  }
                })
              }
            })
          }
          else{
            this.assetService.getAllAssets().subscribe(res => {
              if(res){
                var assetCodes = res.map(asset => asset.code);
                selectedAsset.value.forEach(code => {
                  if(!assetCodes.includes(code)){
                    this.toastService.showError(true, 'Uh-oh! Looks like that asset does not exist in our database. Please reach out to the development team for further guidance.');
                  }
                })
              }
            })
          }
        })
      }
    }
  }

  async onDisciplineChanged(
    selectedDiscipline: any,
    referenceConfigGraph: string
  ) {
    if(this.prevDiscApprovers.length > 0){
      this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevDiscApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
    }
    if(this.lesson.lessonWorkflowType != "plbLessonWorkflow" ||  "copWorkflow"){
      this.disciplineService.getDiscipline(selectedDiscipline.value.toLowerCase()).subscribe(res => {
        if(res){
          this.userDisciplineBuService.getAllUsersByDiscId(res.id).subscribe( r => {
            let userIds = r.map(user => Number(user.id));
            if(userIds.length > 0){
              this.userService.validateUsers(userIds, this.lesson.businessUnit, UserRole.TECHAPR).subscribe( res => {
                if(res.length > 0){
                  this.prevDiscApprovers = res;
                  this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                  this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
                }
                else{
                  this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
                }
              })
            }
          })
        }
        else{
          this.toastService.showError(true, 'Uh-oh! Looks like that discipline does not exist in our database. Please reach out to the development team for further guidance.');
        }
      })
    }
  }

  async onTeamChanged(selectedTeam: any, referenceConfigGraph: string){
    if(this.prevTeamApprovers.length > 0){
      this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevTeamApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
    }
    this.teamService.getTeam(selectedTeam.value.toLowerCase()).subscribe(res => {
      if(res){
        this.userTeamBuService.getAllUsers(res.id).subscribe( r => {
          let userIds = r.map(user => Number(user.id));
          if(userIds.length > 0){
            this.userService.validateUsers(userIds, this.lesson.businessUnit, UserRole.TECHAPR).subscribe( res => {
              if(res.length > 0){
                this.prevTeamApprovers = res;
                this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
              }
              else{
                this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
              }
            });
          }
        })
      }
      else{
        this.toastService.showError(true, 'Uh-oh! Looks like that discipline does not exist in our database. Please reach out to the development team for further guidance.');
      }
    });
  }

  async onDepartmentChanged(selectedDept: any, referenceConfigGraph: string){
    if(this.prevDeptApprovers.length > 0){
      this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevDeptApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
    }
    this.departmentService.getDepartment(selectedDept.value.toLowerCase()).subscribe(res => {
      if(res){
        this.userDepartmentBuService.getAllUsersByDeptId(res.id).subscribe( r => {
          let userIds = r.map(user => Number(user.id));
          if(userIds.length > 0){
            this.userService.validateUsers(userIds, this.lesson.businessUnit, UserRole.TECHAPR).subscribe( res => {
              if(res.length > 0){
                this.prevDeptApprovers = res;
                this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
              }
              else{
                this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
              }
            });
          }
        })
      }
      else{
        this.toastService.showError(true, 'Uh-oh! Looks like that department does not exist in our database. Please reach out to the development team for further guidance.');
      }
    });
  }
  async onRelatedProcessChanged(selectedRelatedProcess: any, referenceConfigGraph: string){
    if(this.prevRelatedProcessApprovers.length > 0){
      this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevRelatedProcessApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
    }
    this.relatedProcessService.getRelatedProcess(selectedRelatedProcess.value.toLowerCase()).subscribe(res => {
      if(res){
        this.userRelatedProcessBuService.getAllUsersByRelatedProcessId(res.id).subscribe( r => {
          let userIds = r.map(user => Number(user.id));
          if(userIds.length > 0){
            this.userService.validateUsers(userIds, this.lesson.businessUnit, UserRole.TECHAPR).subscribe( res => {
              if(res.length > 0){
                this.prevRelatedProcessApprovers = res;
                this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
              }
              else{
                this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
              }
            });
          }
        })
      }
      else{
        this.toastService.showError(true, 'Uh-oh! Looks like that related process does not exist in our database. Please reach out to the development team for further guidance.');
      }
    });
  }

  //==================================================COPwORKFLOW ON CHANGE REGION============================
  async onCopChanged(selectedCOP: any, referenceConfigGraph: string) {
    if (selectedCOP) {
      this.lesson.copTags = [].concat(selectedCOP.value);
      let hasSubGroup = this.commonService.crossReferenceConfig.CopCopSubGroup.some(val => val.cop === selectedCOP.value);

      if(!hasSubGroup){
        if(this.prevCopApprovers.length > 0){
          this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevCopApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
        }
        if(this.lesson.lessonWorkflowType != "plbLessonWorkflow"){
          this.copService.getCop(selectedCOP.value).subscribe(res => {
            if(res){
              this.userCopBuService.getAllUsersByCopId(res.id).subscribe( r => {
                let userIds = r.map(user => Number(user.id));
                if(userIds.length > 0){
                  this.userService.validateUsers(userIds, this.lesson.lessonWorkflowType, UserRole.TECHAPR).subscribe( res => {
                    if(res.length > 0){
                      this.prevDiscApprovers = res;
                      this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                      this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
                    }
                    else{
                      this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
                    }
                  })
                }
              })
            }
            else{
              this.toastService.showError(true, 'Uh-oh! Looks like that CoP does not exist in our database. Please reach out to the development team for further guidance.');
            }
          })
        }
      }
      this.checkNullCopSubGroup(selectedCOP.value);
      if (!this.lesson.managers) {
        this.toastService.showError(true,
          `Looks like there is not a Manager setup for: ${selectedCOP.value}. Please reach out to the support team for further assistance regarding this group: SE-ELL-${this.grpEnv}-MGR- ${this.lesson.cop}`
        );
      }
    }
  }

  async onCopSubGroupChanged(selectedCopSubGroup: any, referenceConfigGraph: string) {
    if(this.prevCopApprovers.length > 0){
      this.lesson.approverTechnicals = this.lesson.approverTechnicals.filter(approver => !this.prevCopApprovers.some(prevApprover => prevApprover.uniqueKey === approver.uniqueKey));
    }
    if(this.lesson.lessonWorkflowType != "plbLessonWorkflow"){
      this.copService.getCop(selectedCopSubGroup.value.toLowerCase()).subscribe(res => {
        if(res){
          this.userCopBuService.getAllUsersByCopId(res.id).subscribe( r => {
            let userIds = r.map(user => Number(user.id));
            if(userIds.length > 0){
              this.userService.validateUsers(userIds, this.lesson.lessonWorkflowType, UserRole.TECHAPR).subscribe( res => {
                if(res.length > 0){
                  this.prevDiscApprovers = res;
                  this.populateApprovers(this.lesson.approverTechnicals, res, referenceConfigGraph);
                  this.toastService.showSuccess(true, 'Successfully updated technical approvers!')
                }
                else{
                  this.toastService.showError(true, 'Uh-oh! Looks like the assigned technical approver has not been added to proper AAD group. Please reach out to the development team so they can add them.');
                }
              })
            }
          })
        }
        else{
          this.toastService.showError(true, 'Uh-oh! Looks like that CoP does not exist in our database. Please reach out to the development team for further guidance.');
        }
      })
    }
  }



  async onEcApproverChanged(selectedEcApprover: User) {
    if (selectedEcApprover && this.lesson.status == "inEcReview") {
      this.ecApproverChangeDialog(selectedEcApprover);
    }
  }

  ecApproverChangeDialog(selectedEcApprover: User) {
    const body = `changing ec approver will reassign this lesson to a new ec approver: ${selectedEcApprover?.fullName}. Please confirm.`;
    const dialogRef = this.dialog.open(EcApproverChangeComponent, {
      width: "600px",
      data: { title: "ec approver change confirm", body: body },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.lesson.approverEC = selectedEcApprover;
          this.lesson.assignees = [].concat(selectedEcApprover.uniqueKey); //SHOULD this be string or User type?
        }
      })
    );
  }

  // Consult Additional SME (COP Workflow)

  ConsultAdditionalSME(referenceConfigGraph: any): void {
    this.openAdditionalSMEDialog(referenceConfigGraph);
  }

  openAdditionalSMEDialog(referenceConfigGraph: any): void {
    const dialogRef = this.dialog.open(AdditionalSMEComponent, {
      width: "60%",
      height: "50%",
      data: {
        cop: this.lesson.cop,
        allCops: this.commonService.references['Cop'],
        allCopSubgroups: this.commonService.referenceConfig.core["CopSubGroup"],
        copSubgroups: this.commonService.crossReferenceConfig.CopCopSubGroup,
        lesson: this.lesson,
        additionalSmeReferenceConfigGraph: referenceConfigGraph,
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.lesson.approverSMEs = [].concat(result);
          this.commonService.loadFieldsStatus(this.lesson);
          this.commonService.loadVisibleItemGroupValues(this.lesson.status);
        }
      })
    );
  }
  //================================================== END COPwORKFLOW ON CHANGE REGION============================

  // for Neeraja, delete this and use trough dynamic component.
  compareUsers(o1: User, o2: User) {
    return o1?.uniqueKey == o2?.uniqueKey;
  }

  AddDeleteAttachmentActions() {
    const attachments: LessonAttachment[] = [];

    if (this.attachmentService.selectedFiles.length > 0) {
      for (let i = 0; i < this.attachmentService.selectedFiles.length; i++) {
        if (
          this.attachmentService.selectedFiles[i].attachmentId == undefined ||
          this.attachmentService.selectedFiles[i].attachmentId == null
        ) {
          this.attachmentService.selectedFiles[i].action = CollectionAction.Add;
        } else {
          this.attachmentService.selectedFiles[i].action =
            CollectionAction.None;
        }

        attachments.push(this.attachmentService.selectedFiles[i]);
      }
    }

    if (this.attachmentService.deletedFiles.length > 0) {
      for (let i = 0; i < this.attachmentService.deletedFiles.length; i++) {
        this.attachmentService.deletedFiles[i].action = CollectionAction.Delete;
        attachments.push(this.attachmentService.deletedFiles[i]);
      }
    }
    this.lesson.lessonAttachments = attachments;
  }



  showMessageandHide(display: boolean, message: string) {
    this.toastService.showRetrievingMessage(display, message);
    setInterval(() => {
      this.snackBar.dismiss();
    }, 5000);
  }

  commentChangedHandler(comments: LessonComment[]) {
    this.lesson.lessonComments = comments;
  }

  NavigateBackToHomePage() {
    if (this.previousRoute.getPreviousUrl()) {
      this.router.navigate([this.previousRoute.getPreviousUrl()]);
    }
    else {
      this.router.navigate(["/home"]);
    }
  }

  lowerCaseStatus(status: string) {
    return status.toLowerCase();
  }

  async saveOrSubmitLesson(isSubmit: boolean) {
    this.show = false;
    const savedAssignee = this.lesson.assignees;
    const savedStatus = this.lesson.status;
    const savedPreviousStatus = this.lesson.previousStatus;
    if (isSubmit) {
      if (!this.lessonFormGroup.valid) {
        this.show = true;
        this.toastService.showError(true, "Please fill out all required fields before proceeding");
        return;
      }
      if (!this.lesson.dateOfSubmission) {
        this.lesson.dateOfSubmission = new Date();
      }
      let result = await this.evalService.setAndValidateTransition(this.lesson);
      if (!result) {
        this.handleError(
          {
            error: `Invalid Flow: ${this.lesson.status} => ${this.lesson.pendingStatus}! something went wrong in set-config service`,
          },
          savedAssignee,
          savedStatus,
          savedPreviousStatus
        );
        return;
      }
      this.lesson.previousStatus = this.lesson.status;
      this.lesson.status = this.lesson.pendingStatus;
    }
    this.AddDeleteAttachmentActions(); // Save current attachment values
    let message = "Record updated successfully";
    if (!this.lesson.id) {
      // Id does not have a value - NEW
      message = "Record created successfully";
    }

    firstValueFrom(this.lessonService.createUpdateLesson(this.lesson)).then(
      (result) => {
        if (result.success) {
          if(this.lesson.lessonWorkflowType == 'plbLessonWorkflow'){
            this.projectLookbackService.projectLookback.isProjectLookbackEditable = true;
            if(!this.projectLookbackService.projectLookback.lessonIds.includes(result.lessons[0].id)){
              this.projectLookbackService.projectLookback.lessonIds.push(result.lessons[0].id);
            }
            firstValueFrom(this.projectLookbackApiService.createUpdateProjectLookback(this.projectLookbackService.projectLookback)).then(
              (result) => {
                this.NavigateBackToHomePage();
              }
            );
          }
          else{
            this.router.navigate([`editLesson/submittedLesson/${result.lessons[0].id}`]);
          }
          
        } else {
          this.showMessageandHide(true, message);
        }
      },
      (error: HttpErrorResponse): void => {
        if (error) {
          const lesson: Lesson = error.error.lessons[0];
          this.handleError(
            { error: lesson.validationException ?? error.error.message },
            savedAssignee,
            savedStatus,
            savedPreviousStatus
          );
        }
      }
    );
  }

  checkNullFacility(areaValue){
    if(this.lesson.facility != null){
      this.lesson.facility = null;
    }

    if(areaValue.value != null){
      let hasFacility = this.commonService.crossReferenceConfig.BlockArea.some(val => val.area === areaValue.value);
      if(hasFacility){
        this.setIsVisibility("facility", true); //Doesn't exist when lesson.status==draft
        this.lessonFormGroup.controls["facility"].setValidators([Validators.required]);
        this.lessonFormGroup.controls["facility"].updateValueAndValidity();
      }
      else{
        this.setIsVisibility("facility", false);
        this.lessonFormGroup.controls["facility"].removeValidators([Validators.required]);
        this.lessonFormGroup.controls["facility"].updateValueAndValidity();
      }
    }
  }

  checkNullArea(blockValue){
    if(this.lesson.area != null){
      this.lesson.area = null;
    }
    if(blockValue.value != null){
      let hasArea = this.commonService.crossReferenceConfig.BlockArea.some(val => val.block === blockValue.value);
      if(hasArea){
        this.setIsVisibility("area", true); //Doesn't exist when lesson.status==draft
        this.lessonFormGroup.controls["area"].setValidators([Validators.required]);
        this.lessonFormGroup.controls["area"].updateValueAndValidity();
      }
      else{
        this.setIsVisibility("area", false);
        this.lessonFormGroup.controls["area"].removeValidators([Validators.required]);
        this.lessonFormGroup.controls["area"].updateValueAndValidity();
      }
    }
  }


  checkNullCopSubGroup(CopValue: any) {
    // Need to reset these values if changed during llreview
    if (this.lesson.approverTechnicals != null) {
      this.lesson.approverTechnicals = null;
    }
    if (this.lesson.copSubGroup != null) {
      this.lesson.copSubGroup = null;
    }
    if (CopValue != null) {
      let hasSubGroup = this.commonService.crossReferenceConfig.CopCopSubGroup.some(val => val.cop === CopValue);
      if (hasSubGroup) {
        this.setIsVisibility("copSubGroup", true); //Doesn't exist when lesson.status==draft
        this.lessonFormGroup.controls["copSubGroup"].setValidators([Validators.required]);
        this.lessonFormGroup.controls["copSubGroup"].updateValueAndValidity();
      }
      else {
        this.setIsVisibility("copSubGroup", false);
        this.lessonFormGroup.controls["copSubGroup"].removeValidators([Validators.required]);
        this.lessonFormGroup.controls["copSubGroup"].updateValueAndValidity();
        //this.populateApproverTechnicalsCOPWorkflow();
      }
    }
  }


  deleteLessonDialogue() {
    const body = `Are you sure you want to delete this lesson? This action is irreversible.`;
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      width: "700px",
      data: { body },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deleteLesson();
        }
      })
    );
  }

  LegacyDataDialogue() {
    const dialogRef = this.dialog.open(LegacyDataComponent, {
      width: "45%",
      position: { right: "10px" },
      autoFocus: false,
      maxHeight: "90vh", // you can adjust the value as per your view,
      data: {
        legacyData: this.lesson.legacyData,
      },
    });
  }

  deleteLesson() {
    this.show = false;
    const lessonIdsTodelete: Array<string> = [];
    lessonIdsTodelete.push(this.lesson.id);
    this.subscriptions.add(
      this.lessonService
        .deleteLessons(lessonIdsTodelete)
        .subscribe((result) => {
          this.toastService.showSuccess(
            true,
            `Successfully deleted ${result}!`
          );
          if (result.success) {
            this.toastService.showSuccess(true, `Successfully deleted lesson!`);
            this.favoriteLessonCommonService
              .getFavoriteLessonCountByUser()
              .then(
                (resultCount) => (this.shared.favoriteLessonCount = resultCount)
              );
          }
          this.NavigateBackToHomePage();
        })
    );
  }

  loadCopsubGroup() {
    return this.commonService.crossReferenceConfig.CopCopSubGroup.filter(
      (copSubGroup) => copSubGroup.cop == this.lesson.cop
    );
  }

  public logApplicationStatistics(lessonId?, lessonBusinessUnit?) {
    let userProfile: User = JSON.parse(sessionStorage.getItem("profile-ell"));
    let lessonsLearnedViews = new LessonsLearnedViewsEventModel();
    lessonsLearnedViews.lessonId = lessonId;
    lessonsLearnedViews.lessonBusinessUnit = lessonBusinessUnit;
    lessonsLearnedViews.userFullName = userProfile.fullName;
    lessonsLearnedViews.userUniqueKey = userProfile.uniqueKey;
    lessonsLearnedViews.userBusinessUnit = userProfile.businessUnit;
    this.subscriptions.add(
      this.appInsights
        .createLessonLearnedViewCustomEvent(lessonsLearnedViews)
        .subscribe()
    );
  }

  cancelLesson() {
    if (this.router.url.includes("createNewLesson")) {
      this.router.navigate(["/home"]);
    } else {
      this.router.navigate([this.previousRoute.getPreviousUrl()]);
    }
  }

  /* utilized in order to reset subscription before Observable is fully destroyed/unsubscribed */
  resetSubscriptionClosedState() {
    if (this.subscriptions.closed) {
      this.subscriptions.closed = false;
    }
  }

  /** create new lesson dialog */
  onclickcreateNewLessons() {
    this.resetSubscriptionClosedState();
    this.commonService.loadConfig();
    this.availableWorkflows = this.commonService.referenceConfig.core["LessonWorkflowType"];

    let body: string = "select approval path:";
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      width: "45%",
      maxHeight: "90vh",
      data: {
        title: "create new lesson",
        body: body,
        showDropdown: true,
        dropdownList: this.availableWorkflows.filter((x) => (x.importOnly == undefined && x.prerequisiteWorkflow == undefined)),
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          if(this.availableWorkflows.find((x) => x.prerequisiteWorkflow === res)){
            const dialogRef = this.dialog.open(DialogMessageComponent, {
              width: "45%",
              maxHeight: "90vh",
              data: {
                title: "select following workflow:",
                showDropdown: true,
                dropdownList: this.availableWorkflows.filter((x => (x.prerequisiteWorkflow == res))),
              },
            });
            this.subscriptions.add(
              dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                  this.router.navigate(["/createNewLesson"], {
                    queryParams: { lessonWorkflowType: res.toString() },
                  });
                }
              })
            );
          }
          else{
            this.router.navigate(["/createNewLesson"], {
              queryParams: { lessonWorkflowType: res.toString() },
            });
          }
        }
      })
    );
  }

  //show hide logic for fields on the basis of crossVisibilityConfig
  public checkCrossVisibilityConfig() {
    let visibleFieldsFromCrossVisibilityConfig = [];
    let hiddenFieldsFromCrossVisibilityConfig = [];
    for (const item in this.commonService.crossVisibilityFieldConfig) {
      //example of fieldItem is: the true/false vals inside each of the fields in crossVisibilityConfig
      let fieldItem = this.commonService.crossVisibilityFieldConfig[item];

      //why are we assiging the fieldvalue: the value of exportComplianceReviewQuestion1 in respective to the lesson, they are coming from the default values already set
      let fieldValue;
      fieldValue = this.lesson[item];
      if (fieldValue != null && fieldValue != undefined) {
        if (
          Array.isArray(
            fieldItem[fieldValue]?.visibleFields ||
            fieldItem[fieldValue]?.visibleFields != undefined
          )
        ) {
          visibleFieldsFromCrossVisibilityConfig =
            visibleFieldsFromCrossVisibilityConfig?.concat(
              fieldItem[fieldValue].visibleFields
            );
        }

        if (Array.isArray(fieldItem[fieldValue]?.hiddenFields)) {
          hiddenFieldsFromCrossVisibilityConfig =
            hiddenFieldsFromCrossVisibilityConfig.concat(
              fieldItem[fieldValue].hiddenFields
            );
        }
      }

      //resetting default values for hidden fields, this should be its own method??
      hiddenFieldsFromCrossVisibilityConfig.forEach((i) => {
        this.commonService.resetDefaultVaulesForCrossVisibility(i, this.lesson);
      });
    }

    hiddenFieldsFromCrossVisibilityConfig =
      hiddenFieldsFromCrossVisibilityConfig.filter(
        (f) => !visibleFieldsFromCrossVisibilityConfig.includes(f)
      );

    //these two methods update common.service.visiblefields
    hiddenFieldsFromCrossVisibilityConfig.forEach((x) => {
      this.setIsVisibility(x, false);
    });
    visibleFieldsFromCrossVisibilityConfig.forEach((x) => {
      this.setIsVisibility(x, true);
    });

    if (
      this.lesson.status != Statuses.draft &&
      this.lesson.status != Statuses.Publish
    ) {
      this.evalService.loadLessonNextSteps(this.lesson);
    }
  }

  //setting isVisibile properties for the fields
  public setIsVisibility(fieldName: string, isVisible: boolean) {
    this.commonService.visibleFieldItems.forEach((group) => {
      group.value.forEach((y) => {
        if (y.key == fieldName) {
          y.value.feMetadata.isVisible = isVisible;
        } else if (y.value.feMetadata.isVisible == undefined) {
          y.value.feMetadata.isVisible = true;
        }
      });
    });
  }

  public async reportLesson(flawedLesson: FlawedLesson) {
    this.sqlService.createUpdateFlawedLessons(flawedLesson).subscribe()
  }

  public async validateLesson() {
    const result = await this.flaggingLesson
      .validateLesson(this.lesson.id)
      .toPromise();
    const lesson = result.lessons[0];
    this.lesson.title = lesson.title;
    this.lesson.description = lesson.description;
    this.lesson.potentialCause = lesson.potentialCause;
    this.lesson.recommendation = lesson.recommendation;
  }

  public downloadPdf() {
    this.subscriptions.add(
      this.lessonService
        .downloadPdf(this.lesson.id)
        .subscribe((res: Response) => {
          const file: any = res.body;
          let link = document.createElement("a");
          link.download = this.lesson.id + ".pdf";
          link.href = URL.createObjectURL(file);
          link.click();
          URL.revokeObjectURL(link.href);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
