<ng-container class="spinner" *ngIf="!show">
  <br />
  <mat-spinner></mat-spinner>
</ng-container>
<ng-container *ngIf="show">
  <ng-template [ngIf]="isBaseComment === true">
    <div class="col col-12 paddingmarginTopBottom0 paddingmarginleftright0">
      <button
        *ngIf="commentsCountCheck()"
        mat-button
        (click)="accordion.openAll()"
      >
        Expand All
      </button>
      <button
        *ngIf="commentsCountCheck()"
        mat-button
        (click)="accordion.closeAll()"
      >
        Collapse All
      </button>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel *ngFor="let item of comments; let i = index">
          <mat-expansion-panel-header>
            <div
              class="row"
              style="
                font-size: small;
                padding: 0;
                margin: 0;
                display: flex;
                height: 100%;
                width: 95%;
              "
            >
              <div
                style="font-size: smaller; padding: 0; margin: 0"
                class="color-label col col-2 left-align paddingmarginTopBottom0 paddingmarginleftright0"
              >
                <b>{{ item.lastUpdatedUser.fullName }}</b> <br />
                {{ item.lastUpdatedDate | date }}
              </div>
              <div
                style="padding: 0; margin: 0"
                class="col col-9 left-align paddingmarginTopBottom0 paddingmarginleftright0"
              >
                {{ item.content }}
              </div>
              <div
                style="font-size: smaller; padding: 0; margin: 0"
                class="col col-1 right-align paddingmarginTopBottom0 paddingmarginleftright0"
                *ngIf="isLessonEditable"
              >
                <mat-icon
                  *ngIf="item.allowReply"
                  (click)="reply(item)"
                  style="float: right; cursor: pointer; color: #0066b2"
                  aria-hidden="false"
                  aria-label="reply comment"
                  >reply</mat-icon
                >
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            style="padding: 0; margin: 0"
            class="row"
            *ngIf="item.lessonReplies.length > 0"
          >
            <div
              style="padding: 0; margin: 0"
              class="col col-1 left-align paddingmarginTopBottom0 paddingmarginleftright0"
            ></div>
            <div
              style="padding: 0; margin: 0"
              class="col col-11 left-align paddingmarginTopBottom0 paddingmarginleftright0"
            >
              <app-comment
                [comments]="item.lessonReplies"
                [lessonId]="lessonId"
                [isChildComment]="true"
                [isBaseComment]="false"
              ></app-comment>
            </div>
          </div>
        </mat-expansion-panel>
        <div
          class="col-12 right-align right-controls text-right-align paddingtop10"
          *ngIf="isLessonEditable"
        >
          <div class="col col-1 left-align user-card-header">
            <img
              *ngIf="false"
              src="..."
              class="rounded mx-auto d-block"
              alt="..."
            />
            <span *ngIf="true">{{ userInitials }}</span>
          </div>
          <div
            class="col col-11 right-align paddingmarginleftright0 paddingmarginTopBottom0 page-header-text"
          >
            <textarea
              [(ngModel)]="newComment"
              placeholder="Enter new comment..."
            ></textarea>
            <button
              class="btn btn-primary btn-comment"
              (click)="postNewComment()"
              [disabled]="commentLengthCheck()"
            >
              Record Comment
            </button>
          </div>
        </div>
      </mat-accordion>
    </div>
  </ng-template>

  <ng-template [ngIf]="isBaseComment === false">
    <div
      class="col col-12 paddingmarginTopBottom0 paddingmarginleftright0"
      *ngFor="let item of comments; let i = index"
    >
      <hr />
      <div
        class="row"
        style="
          font-size: small;
          padding: 0;
          margin: 0;
          display: flex;
          height: 100%;
          width: 100%;
        "
      >
        <div
          style="font-size: smaller; padding: 0; margin: 0"
          class="color-label col col-2 left-align paddingmarginTopBottom0 paddingmarginleftright0"
        >
          <b>{{ item.lastUpdatedUser.fullName }}</b> <br />
          {{ item.lastUpdatedDate | date }}
        </div>
        <div
          style="padding: 0; margin: 0"
          class="col col-9 left-align paddingmarginTopBottom0 paddingmarginleftright0"
        >
          {{ item.content }}
        </div>
        <div
          *ngif="item.allowReply"
          style="font-size: smaller; padding: 0; margin: 0"
          class="col col-1 right-align paddingmarginTopBottom0 paddingmarginleftright0"
        >
          <mat-icon
            (click)="reply(item)"
            style="float: right; cursor: pointer; color: #0066b2"
            aria-hidden="false"
            aria-label="reply comment"
            >reply</mat-icon
          >
        </div>
      </div>
      <div
        style="padding: 0; margin: 0"
        class="row"
        *ngIf="item.lessonReplies && item.lessonReplies.length > 0"
      >
        <div
          style="padding: 0; margin: 0"
          class="col col-1 left-align paddingmarginTopBottom0 paddingmarginleftright0"
        ></div>
        <div
          style="padding: 0; margin: 0"
          class="col col-11 left-align paddingmarginTopBottom0 paddingmarginleftright0"
        >
          <app-comment
            [comments]="item.lessonReplies"
            [lessonId]="lessonId"
            [isChildComment]="true"
            [isBaseComment]="false"
          ></app-comment>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
