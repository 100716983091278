<div class="row paddingtop10 paddingbottom10">
  <div class="col col-1 d-flex flex-row-reverse">
    <p class="color-label back">
      <span (click)="bulkEditService.Back()">back</span>
    </p>
  </div>
</div>
<div class="col col-11 header">
  <h2 class="title">{{ bulkEditService.title }}</h2>
</div>

<hr>

<form [formGroup]="bulkEditService.lessonsFormGroup">
  <main class="container" *ngIf="!bulkEditService.showSubmit">
      <div class="row instructionContainer" *ngIf="bulkEditService.visibleBulkFieldItems.length > 0">
        <h3 class="instructionFont">
          Assigned technical approver(s) will be notified when lessons have been submitted  
        </h3>
        <div class="center">
          <ng-container
            *ngFor="
              let itemGroup of bulkEditService.visibleBulkFieldItems;
              let i = index
            "
          >
            <ng-container *ngFor="let item of itemGroup.value; let j = index">
              <div
                *ngIf="item?.value?.feMetadata?.isVisible"
              >
                <div class="technicalApproverLabel">
                  <label
                    [ngClass]="
                      bulkEditService.commonService.isFieldRequired(
                        bulkEditService.lessons[0].status,
                        item.key
                      )
                    "
                  >
                    {{ item.value.feMetadata.formLabel }}:
                  </label>
                </div>
                <app-dynamic-component
                  #p{{i}}{{j}}
                  fieldId="{{ item.key }}"
                  [ifTooltip]="true"
                  [controlType]="item.value.feMetadata.controlType"
                  [isFromCreateNewLesson]="true"
                  [fieldValue]="
                    bulkEditService.getLessonFieldValue(0, item.key)
                  "
                  [columsWidth]="6"
                  [isVisible]="item?.value?.feMetadata?.isVisible"
                  [showlabel]="false"
                  [fieldList]="
                    item.key == 'transitionName'
                      ? bulkEditService.evalService.lessonNextSteps
                      : bulkEditService.commonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
                          itemGroup.key,
                          item.key,
                          bulkEditService.lessons[0].lessonWorkflowType
                        )
                  "
                  [isEditable]="bulkEditService.isFieldEditable(item.key)"
                  [extension]="bulkEditService.lessons[0].lessonWorkflowType"
                  [form]="bulkEditService.lessonsFormGroup"
                  [previousLesson]="bulkEditService.lessons[0]"
                  [referenceConfigGraph]="
                    item.value.feMetadata.referenceConfigGraph
                  "
                  [userType]="item.value.feMetadata.dataType"
                  [groupName]="itemGroup.key"
                  (onLessonChange)="bulkEditService.updateLessonFields($event)"
                  (onValueChange)="
                    singleLessonService.onValueChange(
                      $event,
                      item.key,
                      item.value.feMetadata.referenceConfigGraph
                    )
                  "
                >
                </app-dynamic-component>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <h3 class="instructionFont">
          (Optional) - Please input any comments or concerns regarding this bulk import
        </h3>
        <div class="center">
          <p class="disclaimerText">
            Disclaimer: Comments will be for approval process only. This will not be
            included with the published lesson.
          </p>
          <div class="inputContainer">
            <input
            class="bulkInputComment"
            [(ngModel)]="bulkEditService.comment"
            formControlName="comment"
            placeholder="please type here..."
          />
            <button
            id="confirmPostDialog"
            type="button"
            class="btn btn-primary"
            (click)="bulkEditService.commentPostAll()"
            >
              post
            </button>
          </div>
        </div>
        <h3 class="instructionFont">
          You are currently viewing and/or editing: 
          <b class="highlightText">{{
            paginator?.pageSize > bulkEditService.lessons?.length
              ? bulkEditService.lessons?.length
              : paginator?.pageSize
          }}</b>
          out of <b class="highlightText"> {{ bulkEditService.lessons?.length }} </b> lessons - with the associated status of:
          <b class="highlightText">{{
            bulkEditService.lessonsStatus
              | ConvertCodeToDescription : commonService.references.Status
          }}</b>
          <mat-icon
            *ngIf="bulkEditService.lessons?.length >= 500"
            matTooltip="Please check you submited all your records associated to import id, we only support 500 at the time"
            style="opacity: 0.5; vertical-align: bottom"
            >info</mat-icon
          >
        </h3>
        <div class="center">
          <h3 class="errorFont">
            <b>error count : {{ bulkEditService.errorCount }}</b>
          </h3>
          <div class="paginatorTest">
            <mat-paginator
              class="itemsPerPage"
              #paginator
              showFirstLastButtons
              [pageSizeOptions]="[10, 50, 100]"
              pageSize="10"
            ></mat-paginator>
          </div>
          <div class="row loader tableContainer" *ngIf="!bulkEditService.show">
            <mat-spinner></mat-spinner>
          </div>
          <div
          class="row tableContainer"
          formArrayName="lessonsFormArray"
          *ngIf="bulkEditService.show"
        >
          <table
            mat-table
            [dataSource]="bulkEditService.dataSource"
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="select" class="rowHighlight">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? bulkEditService.masterToggle() : null"
                  [checked]="
                    bulkEditService.selection.hasValue() &&
                    bulkEditService.isAllSelected()
                  "
                  [indeterminate]="
                    bulkEditService.selection.hasValue() &&
                    !bulkEditService.isAllSelected()
                  "
                  [color]="bulkEditService.checkboxColor"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let lesson">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="
                    $event ? bulkEditService.selection.toggle(lesson) : null
                  "
                  [checked]="bulkEditService.selection.isSelected(lesson)"
                  [color]="bulkEditService.checkboxColor"
                >
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>
                {{ bulkEditService.formLabel("id") }}
              </th>
              <td mat-cell *matCellDef="let lesson">{{ lesson.displayId }}</td>
            </ng-container>
            <ng-container
              *ngFor="let itemGroup of bulkEditService.visibleLessonFields"
            >
              <ng-container
                *ngFor="let item of itemGroup.value; let i = index"
                matColumnDef="{{ item.key }}"
              >
                <th mat-header-cell *matHeaderCellDef>
                  {{ item.value.feMetadata.formLabel }}
                  <mat-icon
                    aria-hidden="false"
                    style="cursor: pointer"
                    matTooltip="{{ bulkEditService.columnSettingToolTip }}"
                    matTooltipPosition="after"
                    (click)="openColumnSettingDialog(i)"
                    >settings</mat-icon
                  >
                </th>
                <td
                  mat-cell
                  *matCellDef="let lesson; let j = index"
                  [formGroupName]="getLessonIndex(j)"
                >
                  <app-dynamic-component
                    #p{{i}}{{getLessonIndex(j)}}
                    fieldId="{{ item.key }}"
                    [ifTooltip]="true"
                    [controlType]="
                      bulkEditService.allowedControlTypes.indexOf(
                        item.value.feMetadata.controlType
                      ) != -1
                        ? item.value.feMetadata.controlType
                        : 'Input'
                    "
                    [isFromCreateNewLesson]="false"
                    [isVisible]="true"
                    [showlabel]="false"
                    [fieldDictatingForCrossReferenceConfig]="
                      item.value.feMetadata.fieldDictatingForCrossReferenceConfig
                    "
                    [crossReferenceConfigEnum]="
                      item.value.feMetadata.crossReferenceConfigEnum
                    "
                    [fieldValue]="
                      bulkEditService.getLessonFieldValue(
                        getLessonIndex(j),
                        item.key
                      )
                    "
                    [columsWidth]="6"
                    [fieldList]="
                      bulkEditService.commonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
                        itemGroup.key,
                        item.key,
                        bulkEditService.lessons[0].lessonWorkflowType
                      )
                    "
                    [isEditable]="bulkEditService.isFieldEditable(item.key)"
                    [extension]="bulkEditService.lessons[0].lessonWorkflowType"
                    [form]="
                      bulkEditService.lessonsFormArray.controls[getLessonIndex(j)]
                    "
                    [previousLesson]="bulkEditService.lessons[getLessonIndex(j)]"
                    [referenceConfigGraph]="
                      item.value.feMetadata.referenceConfigGraph
                    "
                    [userType]="item.value.feMetadata.dataType"
                    [groupName]="itemGroup.key"
                    (onLessonChange)="bulkEditService.updateLesson($event)"
                    (onValueChange)="
                      singleLessonService.onValueChange(
                        $event,
                        item.key,
                        item.value.feMetadata.referenceConfigGraph
                      )
                    "
                  >
                  </app-dynamic-component>
                </td>
              </ng-container>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="bulkEditService.columnsToDisplay; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="
                let row;
                let i = index;
                columns: bulkEditService.columnsToDisplay
              "
              [ngClass]="bulkEditService.validateError(row)"
            ></tr>
          </table>
        </div>
        <mat-dialog-actions class="btns">
          <button
            id="confirmDiscardDialog"
            class="btn btn-outline-primary"
            type="button"
            *ngIf="bulkEditService.lessonsStatus == 'draft'"
            (click)="bulkEditService.deleteLessonsInBulk()"
          >
            discard
          </button>
  
          <button
            id="confirmSubmitDialog"
            mat-button
            [matMenuTriggerFor]="menu"
            type="button"
            class="btn btn-primary float-right"
          >
            submit
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngFor="
                let actions of bulkEditService.evalService.lessonNextSteps
              "
              mat-menu-item
              (click)="confirmSubmitDialog(actions.Code)"
            >
              {{ actions.Description }}
            </button>
          </mat-menu>
        </mat-dialog-actions>
        </div>
        </div>
  </main>

  <main class="container" *ngIf="bulkEditService.showSubmit">
    <div class="row loader">
      <mat-spinner></mat-spinner>
    </div>
  </main>
</form>
