import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LessonVoting } from "src/app/shared/models";
import { SearchOptions } from "src/app/shared/models/search-options";
import { FlawedLesson } from "src/app/shared/models/flawedLesson";

@Injectable({
  providedIn: "root",
})
export class SqlServerService {
  constructor(private http: HttpClient) {}

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}SqlServer`;

  getImportLogs(importLogIds): Observable<any> {
    let endPoint = `${this.apiRoot}/${importLogIds}`;
    return this.http.get<any[]>(endPoint);
  }
  createUserVote(body: LessonVoting): Observable<any> {
    let endPoint = `${this.apiRoot}/createUserVote?lessonId=${body.lessonId}&votingAction=${body.votingAction}&votingComment=${body.votingComment}`;
    return this.http.post<any>(endPoint, body);
  }

  deleteUserVote(id: number): Observable<number> {
    return this.http.delete<number>(`${this.apiRoot}/deleteUserVote/${id}`);
  }
  getUserVoteCount(lessonId: string): Observable<any> {
    return this.http.get<any>(`${this.apiRoot}/getUserVoteCount/${lessonId}`);
  }
  getUserVote(lessonId: string): Observable<any> {
    return this.http.get<any>(`${this.apiRoot}/getUserVote/${lessonId}`, {
      responseType: "text" as "json",
    });
  }

  createUpdateFlawedLessons(
    flawedLesson: FlawedLesson
  ): Observable<FlawedLesson> {
    const endPoint = `${this.apiRoot}/createUpdateFlawedLessons`;
    return this.http.post<FlawedLesson>(endPoint, flawedLesson);
  }

  getFlawedLessons(
    managerActedStatus: boolean,
    searchOptions: SearchOptions
  ): Observable<any> {
    let endPoint = `${this.apiRoot}/getFlawedLessons/${managerActedStatus}`;
    return this.http.post<any>(endPoint, searchOptions);
  }

  getFlawedLessonsCount(): Observable<any> {
    let endPoint = `${this.apiRoot}/getFlawedLessonsCount`;
    return this.http.get<any>(endPoint);
  }
}
