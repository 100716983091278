<div
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDrag
  class="openChatIcon"
  (click)="isOpen = !isOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
></div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>

  <div class="chatbotContainer" >
    <div mat-dialog-title id="header">
      <div class="chatbot-title">Enterprise Lessons Learned Assist</div>
      <button id="close-button" (click)="closeChatbot()">X</button>
    </div>
    <div (mousedown)="$event.stopPropagation()">
      <div class="chatHistoryContainer" id="scrollContainer">
        <div></div>
        <div class="chatbotMessage">
          <p class="disclaimerText">Disclaimer: Please remember that answers provided by this assistance are not to be taken literal. These are simply recommendations based on past lessons learned and proper due diligence should be completed for each unique scenario.</p>
        </div>
        <div class="chatbotMessage">
          <p class="chatbotText">Hello {{ this.currentFullUserProfile.name }}! I'm your go-to assistant for all things Lessons Learned within Chevron. To get started, ask me a question! </p>
        </div>
        <div class="chatHistory">
          <div *ngFor="let comm of chatHistory; index as chatId">
            <div class="userMessage right-align" id="user{{chatId}}">
              <p class="userText" > {{ comm.inputs }} </p>
            </div>
            <div class="chatbotMessage" style="padding-top: 5rem;">
              <div class="chatbotText" id="chatbot{{chatId}}" *ngIf="comm.outputs" [innerHtml]="comm.outputs"></div>
            </div>
          </div>
          <div *ngIf="isLoading">
            <div class="userMessage right-align" id="tempUserChat">
              <p class="userText"> {{tempUserChat}} </p>
            </div>
            <div class="chatbotMessage" style="padding-top: 5rem;" id="tempChatbotSpinner">
              <div class="chatbotText">
                <mat-spinner></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form id="input-form">
      <div class="queryContainer">
        <input
          id="input-field"
          type="text"
          placeholder="ask me anything..."
          [(ngModel)]="query"
          name="query"
        />
        <button id="submit-button" type="submit" (click)="sendMessage(query)">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </form>
  </div>
</ng-template>
