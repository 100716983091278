<app-page-header [pageTitle]="pageTitle"></app-page-header>
<main class="container-fluid">
  <div class="w-100" *ngIf="!projectLookbackService.show">
    <div
      class="d-flex justify-content-center align-items-center"
      style="min-height: 600px"
    >
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div *ngIf="projectLookbackService.show">
    <app-project-lookback-card></app-project-lookback-card>
  </div>
</main>
