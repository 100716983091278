import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-report-lesson",
  templateUrl: "./report-lesson.component.html",
  styleUrls: ["./report-lesson.component.scss"],
})
export class ReportLessonComponent implements OnInit {
  public reportingTypes: string[] = [];

  public selectedReportType: string;
  public reportedComments: string;
  public managerComments: string;

  public showNextPage: boolean = false;
  public showManagerCommentPage: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ReportLessonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.reportingTypes = this.data?.reportingTypes;
  }

  confirm() {
    this.dialogRef.close({
      reportType: this.selectedReportType,
      reportedComments: this.reportedComments,
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
