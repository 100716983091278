import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly roleNames = {
    user: "User",
    admin: "Admin",
  };
  loggedIn: boolean;

  constructor(private http: HttpClient, private msalService: MsalService, private msalBroadcastService: MsalBroadcastService) {
  }

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}User`;
  readonly currentUserProfileSubject = new ReplaySubject<AccountInfo>(1);
  readonly currentUserProfile$ = this.currentUserProfileSubject.asObservable();
  readonly rolesSubject = new ReplaySubject<string[]>(1);
  readonly roles$ = this.rolesSubject.asObservable();

  public setAccount() {
    let allCvxAccounts = this.msalService.instance
      .getAllAccounts()
      .filter(
        (account) => account.tenantId == environment.appRegistration.tenantId
      );
    this.loggedIn = allCvxAccounts.length > 0;
    if (this.loggedIn) {
      const account = allCvxAccounts[0];
      this.setCurrentUser(account);
    }
  }

  loggedUser(body): Observable<any> {
    let endPoint = `${this.apiRoot}/loggedUser`;
    return this.http.post(endPoint, body);
  }

  public getUsers(isDisabled: boolean): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiRoot}/all/${isDisabled}`);
  }

  public getUserFromChevronDirectory(query: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiRoot}/getChevronDirUser/${query}`);
  }

  public getUserByUniqueKey(uniqueKey: string): Observable<User> {
    return this.http.get<User>(`${this.apiRoot}/uniqueKey/${uniqueKey}`);
  }

  public createUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.apiRoot}`, user);
  }

  public updateUser(user: User): Observable<User> {
    return this.http.put<User>(`${this.apiRoot}`, user);
  }

  public updateProjectLookbackUser(user: User): Observable<User> {
    return this.http.put<User>(`${this.apiRoot}/projectLookback`, user);
  }

  public validateUsers(userIds: number[], businessUnit: string, role: string): Observable<User[]> {
    // can get string of ids -> 
    let params = new HttpParams()
    .set('userIds', userIds.join(','))
    .set('businessUnit', businessUnit)
    .set('role', role);

    return this.http.get<User[]>(`${this.apiRoot}/validateUsers`, {params});
  }

  public getUserByEmail(emailId: string): Observable<User> {
    return this.http.get<User>(`${this.apiRoot}/email/${emailId}`);
  }

  public getUserByFilters(businessUnit: string, role: string): Observable<User[]> {
    let params = new HttpParams()
    .set('businessUnit', businessUnit)
    .set('role', role);
    return this.http.get<User[]>(`${this.apiRoot}/getUserByFilters`, {params});
  }

  public getTriggersByRole(businessUnitAbr: string, role: string, triggerPairs: any): Observable<User[]> {
    let params = new HttpParams()
    .set('businessUnit', businessUnitAbr)
    .set('role', role);
    return this.http.get<User[]>(`${this.apiRoot}/getTriggersByRole`, {params});
  }

  public updateUserPermissions(uniqueKey: string, businessUnit: string, role: string): Observable<User> {
    const body = {
      uniqueKey: uniqueKey,
      businessUnit: businessUnit,
      role: role
    }

    return this.http.put<User>(`${this.apiRoot}/addUserPermissions`, body);
  }

  public removeUserPermissions(uniqueKey: string, businessUnit: string, role: string): Observable<User> {
    const body = {
      uniqueKey: uniqueKey,
      businessUnit: businessUnit,
      role: role
    }

    return this.http.put<User>(`${this.apiRoot}/removeUserPermissions`, body);
  }

  getCurrentUser(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  setCurrentUser(account: AccountInfo): void {
    this.msalService.instance.setActiveAccount(account);
    this.currentUserProfileSubject.next(account);

    const nextRole = account.idTokenClaims ? ["roles"] : null;

    if (nextRole !== null) {
      this.rolesSubject.next(nextRole);
    }
  }

}
