import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoordinatorDashboardComponent } from "./coordinator-dashboard.component";
import { SharedModule } from "../../../../shared/shared.module";
import { CoreModule } from "../../../../core/core.module";
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { TextInputHighlightModule } from "angular-text-input-highlight";
import { UserTableComponent } from "./user-table/user-table.component";
import { DashboardLandingComponent } from './dashboard-landing/dashboard-landing.component';

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
    //TextInputHighlightModule,
    RouterModule.forChild([
      { path: "", component: DashboardLandingComponent },
    ]),
    CreateEditSharedModule,
    
  ],
  declarations: [
    UserTableComponent,
    DashboardLandingComponent
  ],
})
export class CoordinatorDashboardModule {}
