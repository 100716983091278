import { Component, OnInit, Inject } from "@angular/core";
import { Reference, User } from "src/app/shared/models";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { CopService } from "src/app/core/http/cop.service";
import { UserCopBusinessUnitService } from "src/app/core/http/user-cop-bu.service";
import { UserService } from "src/app/core/http/user.service";

@Component({
  selector: "app-additional-sme",
  templateUrl: "./additional-sme.component.html",
  styleUrls: ["./additional-sme.component.scss"],
})
export class AdditionalSMEComponent implements OnInit {
  cops: Reference[] = [];
  cop: string = "";
  usersAddtionalSmesFromOtherCops: User[] = [];
  show = false;
  columnsToDisplay: string[] = ["name"];
  dataSource = null;
  smeCount: number;

  public copSubgroup: string;
  public copSubgroups: any = [];

  bindGrid() {
    this.dataSource = new MatTableDataSource(
      this.usersAddtionalSmesFromOtherCops
    );
    this.show = true;
  }

  constructor(
    public dialogRef: MatDialogRef<AdditionalSMEComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private copService: CopService,
    private userCopBuService: UserCopBusinessUnitService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.show = true;

    this.cops = this.data.allCops
      .filter((x) => x.Code != this.data.cop)
      .map((o) => {
        return { Code: o.Code, Description: o.Description };
      });
  }

  public async checkCopSubgroup() {
    this.copSubgroups = this.data.copSubgroups?.filter(
      (value) => value.cop == this.cop
    );
    if (this.copSubgroups.length == 0) {
      this.copSubgroup = null;
      await this.onCopChanged();
    }
  }

  async onCopChanged() {
    /*
         Always returns one SME group object so can read [0].
        sme group objects are TECHAPR from another cop
        let referenceConfigGraph = "SE-ELL-{ENV}-TECHAPR-{COP}-{COPSUBGROUP}" // this is from template
        */
    if (this.cop) {
      this.show = false;
      this.copService.getCop(this.cop).subscribe(res => {
        this.userCopBuService.getAllUsersByCopId(res.id).subscribe(res => {
          if(res){
            this.smeCount = res.length;
            this.usersAddtionalSmesFromOtherCops = res;
            this.bindGrid();
          }
        })
      })



      // let users = await this.commonService.getGraphUsersByLessonWorkflowType(
      //   this.data.additionalSmeReferenceConfigGraph,
      //   this.cop,
      //   this.copSubgroup
      // );
      // if (users) this.usersAddtionalSmesFromOtherCops = users;
      // this.bindGrid();
    }
  }

  getRecord(selectedUser) {
    this.dialogRef.close(selectedUser);
  }

  cancel() {
    this.dialogRef.close();
  }
}
