import { Injectable } from "@angular/core";
import { Lesson, LessonVoting } from "src/app/shared/models";
import { SqlServerService } from "src/app/core/http/sql-server.service";
@Injectable({
  providedIn: "root",
})
export class LessonVotingService {
  lesson: Lesson = new Lesson();
  //Fields for Lesson Vote Count
  voteCountLikes: number;
  voteCountDislikes: number;
  disableLike: boolean = false;
  disableDislike: boolean = false;
  userVote: string;

  constructor(private svcLessonVote: SqlServerService) {}

  //=======================================Lesson Vote Methods===========================
  public createLessonVote(votingAction: string, lessonId: string, votingComment?: string) {
    return new Promise<void>((resolve) => {
      let lessonVoting: LessonVoting = new LessonVoting();
      lessonVoting.lessonId = lessonId;
      lessonVoting.votingAction = votingAction;
      lessonVoting.votingComment = votingComment;
      this.svcLessonVote.createUserVote(lessonVoting).subscribe((result) => {
        this.voteCountLikes = result.votingLikes;
        this.voteCountDislikes = result.votingDislikes;
        this.getUserLessonVote(lessonId);
        this.getLessonVoteCount(lessonId);
        resolve();
      });
    });
  }

  public getUserLessonVote(lessonId: string) {
    this.svcLessonVote.getUserVote(lessonId).subscribe((result) => {
      if (result == "Likes") {
        this.disableLike = true;
        this.disableDislike = false;
      } else if (result == "Dislikes") {
        this.disableDislike = true;
        this.disableLike = false;
      } else {
        this.disableDislike = false;
        this.disableLike = false;
      }
    });
  }

  public getLessonVoteCount(lessonId: string) {
    this.svcLessonVote.getUserVoteCount(lessonId).subscribe((result) => {
      this.voteCountLikes = result.likes;
      this.voteCountDislikes = result.dislikes;
    });
  }
}
