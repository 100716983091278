import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "src/app/core/http/user.service";
import { User } from "src/app/shared/models";
import { DialogMessageComponent } from "../dialog-message/dialog-message.component";

@Component({
  selector: "app-search-tutorial",
  templateUrl: "./search-tutorial.component.html",
  styleUrls: ["./search-tutorial.component.scss"],
})
export class SearchTutorialComponent implements OnInit {
  skipTutorial: boolean = false;
  title: string = "";
  textBody: string = "";
  nextDisplay: string = "";
  skipDisplay: string = "";
  public currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<SearchTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.title = this.data.title;
    this.textBody = this.data.body;
    this.nextDisplay = this.data.nextDisplay;
    this.skipDisplay = this.data.skipDisplay;
    this.skipTutorial = this.data.skipTutorial;
  }

  start() {
    this.dialogRef.close(this.skipTutorial);
  }

  confirm() {
    const body = `if you want to turn it on go to profile`;
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      width: "500px",
      data: { title: body },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentUser.skipTutorial = true;
        this.updateUserDetail();
      }
    });
  }

  updateUserDetail() {
    this.userService.updateUser(this.currentUser).subscribe({
      next: (resp) => {
        sessionStorage.setItem("profile-ell", JSON.stringify(resp));
        this.currentUser = resp;
        this.skip();
      },
      error: (err) => {
        this.snackBar.open(err);
        this.skip();
      },
    });
  }

  skip() {
    this.skipTutorial = true;
    this.dialogRef.close(this.skipTutorial);
  }
}
