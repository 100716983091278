import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ReportPageComponent } from "./report.component";
import { PbiserviceService } from "src/app/core/http/pbiservice.service";
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { Create } from "powerbi-client";


@NgModule({
  declarations: [ReportPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: ReportPageComponent }]),
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatButtonModule,
    CreateEditSharedModule
  ],
  providers: [PbiserviceService],
})
export class ReportsModule {}
