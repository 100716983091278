import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedService } from "../../shared.service";
import { FavoriteLessonService } from "src/app/core/http/favoriteLesson.service";
import { User } from "src/app/shared/models";
import { MatDialog } from "@angular/material/dialog";
import { ProfileEditComponent } from "../profile-edit/profile-edit.component";
import { environment } from "src/environments/environment";
import { AppRoles, SearchScopeEnum } from "src/app/config/global-enums.config";
import { UserService } from "src/app/core/http/user.service";
import { MsGraphService } from "src/app/core/http/ms-graph.service";
import { Router } from "@angular/router";
import { NavigationLocation } from '@cvx/nextpage';
import { CurrentUserService } from "src/app/core/http/current-user.service";
import { LessonService } from "src/app/core/http/lesson.service";
import { SearchOptions } from "src/app/shared/models/search-options";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  photoData: any;
  userName: string;
  businessUnit: string;
  favoriteLessonCount: number;
  emailNotification: boolean;
  environment: string;
  scopeOptions = [
    { key: "PublishedLessons", label: "published" },
    { key: "ParticipatedLessons", label: "my lessons" },
    { key: "AssignedLessons", label: "actions" },
    // { key: "FavoriteLessons", label: "favorites" },
  ];
  role: string;

  @Output() event = new EventEmitter<any>();
  searchText: string;
  scope: any = "PublishedLessons";
  scopeLabel: any = "published";
  counter: number = 0;
  NavigationLocation = NavigationLocation;

  pendingTasks: number = 0;

  constructor(
    public shared: SharedService,
    public dialog: MatDialog,
    private svcUserFavoriteLessons: FavoriteLessonService,
    private userService: UserService,
    private graphService: MsGraphService,
    private router: Router,
    private currentUserService: CurrentUserService,
  ) { }

  ngOnInit(): void {
    const user = this.userService.getCurrentUser();
    this.userName = user.name;
    this.environment = environment.type;
    this.svcUserFavoriteLessons
      .getFavoriteLessonsCount()
      .subscribe((result) => {
        this.shared.favoriteLessonCount = result;
      });

    this.graphService?.getUserPhoto().subscribe((data) => {
      if (data) {
        this.createImageFromBlob(data);
      } else {
        this.photoData = "assets/icons/Icon - Person.png";

      }
    }, () => {
      this.photoData = "assets/icons/Icon - Person.png";
    });

    //Add archive tab if user role is ELL manager R2 Release
    this.role = sessionStorage.getItem("roles");
    if (this.role === AppRoles.ELLMGR.toString()) {
      this.scopeOptions.push({ key: "ArchivedLessons", label: "archived" });
    }

    this.businessUnit = sessionStorage.getItem("businessunit") ?? "";

    this.currentUserService.searchLessonsForCurrentUser();
    this.currentUserService.currentTaskStatus.subscribe(status => this.pendingTasks = status);
  }

  updateCurrentUserService() {
    this.currentUserService.searchLessonsForCurrentUser();
  }

  createImageFromBlob(image: any) {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.photoData = reader.result;
      },
      false
    );
    reader.readAsDataURL(image);
  }

  editProfile() {
    const profile: User = JSON.parse(sessionStorage.getItem("profile-ell"));

    const dialogRef = this.dialog.open(ProfileEditComponent, {
      width: "600px",
      data: { user: profile },
    });
  }

  scopeChange(event) {
    this.scopeLabel = this.scopeOptions.find((x) => x.key == event.value).label;
  }

  goHome() {
    this.router.navigate(["/"]);
  }

  goToMyAction() {
    this.shared.onClick(4);
    this.router.navigate(["/lessons"]);
  }

}
