<div class="container-fluid module">
  <div class="w-100" *ngIf="!projectLookbackService.show">
    <div
      class="d-flex justify-content-center align-items-center"
      style="min-height: 600px"
    >
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <form
    [formGroup]="projectLookbackService.projectLookbackFormGroup"
    *ngIf="
      projectLookbackService.projectLookbackFormGroup &&
      projectLookbackService.showProjectLookback
    "
  >
    <div *ngIf="projectLookbackService.show">
      <div class="row">
        <div class="col col-12">
          <div class="col-12 page-header-text paddingleft30">
            <div class="left-align">
              <!-- HEADER -->
              <div id="header">
                <div id="back">
                  <span
                    class="backText mr-1"
                    style="cursor: pointer"
                    (click)="projectLookbackService.NavigateBackToHomePage()"
                  >
                    project lookback</span
                  >
                  &#62;
                  <span
                    *ngIf="!projectLookbackService.projectLookback.id"
                    class="highlighText ml-1"
                  >
                    create new project lookback</span
                  >
                  <span
                    *ngIf="
                      projectLookbackService.projectLookback.id &&
                      projectLookbackService.projectLookback.status !==
                        'publish'
                    "
                    class="highlighText ml-1"
                  >
                    edit project lookback</span
                  >
                  <span
                    *ngIf="
                      projectLookbackService.projectLookback.id &&
                      projectLookbackService.projectLookback.status ===
                        'publish'
                    "
                    class="highlighText ml-1"
                  >
                    published project lookback</span
                  >
                  <!-- TODO: EDIT AND COMPLETED PROJECT LOOKBACK -->
                </div>
                <div id="title" class="d-flex justify-content-left mt-4">
                  <h1>Project Lookback</h1>
                </div>
                <hr class="hr" />
              </div>
              <!-- HEADER -->
            </div>
          </div>
        </div>
        <div class="col col-12">
          <div class="fields-border">
            <div class="row paddinglefttopbottom_20 plbContainer">
              <div class="col-9 left-align">
                <b
                  *ngIf="
                    projectLookbackService.projectLookback
                      .isProjectLookbackEditable
                  "
                >
                  please complete all required fields</b
                >
              </div>
            </div>
            <div class="paddingleftrightbottom_20">
              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel
                  *ngFor="
                    let itemgroup of projectLookbackService
                      .projectLookbackCommonService.visibleFieldItems;
                    let i = index;
                    let isFirst = first
                  "
                  [expanded]="isFirst"
                >
                  <mat-expansion-panel-header>
                    <div
                      class="row"
                      style="padding: 0; margin: 0; display: flex; width: 95%"
                    >
                      <div
                        style="padding: 0; margin: 0"
                        class="color-label col col-2 left-align paddingmarginTopBottom0 paddingmarginleftright0"
                      >
                        <b
                          >{{
                            projectLookbackService.projectLookbackCommonService.getGroupDescription(
                              itemgroup.key
                            )
                          }}
                        </b>
                        <br />
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div style="padding: 0; margin: 0" class="row"></div>
                  <div class="row">
                    <div
                      class="col-4 paddingtop10 plbFieldContents"
                      *ngFor="let item of itemgroup.value; let j = index"
                    >
                      <!-- <p> {{ item }}</p> -->
                      <app-project-lookback-dynamic-component
                        #p{{i}}{{j}}
                        fieldId="{{ item.key }}"
                        [controlType]="item.value.feMetadata.controlType"
                        [isVisible]="item.value.feMetadata.isVisible"
                        [labelValue]="item.value.feMetadata.formLabel"
                        [className]="
                          projectLookbackService.projectLookbackCommonService.isFieldRequired(
                            projectLookbackService.projectLookback.status,
                            item.key
                          )
                        "
                        [fieldValue]="
                          projectLookbackService.getProjectLookbackFieldValue(
                            item.key
                          )
                        "
                        [extension]="projectLookbackService.extensionType"
                        [form]="projectLookbackService.projectLookbackFormGroup"
                        [userType]="item.value.feMetadata.dataType"
                        [groupName]="itemgroup.key"
                        (onProjectLookbackChange)="
                          projectLookbackService.updateProjectLookback($event)
                        "
                        [previousProjectLookback]="
                          projectLookbackService.projectLookback
                        "
                        [isFromCreateNewProjectLookback]="true"
                        [fieldList]="
                          item.key == 'transitionName'
                            ? projectLookbackService.projectLookbackEvalService
                                .projectLookbackNextSteps
                            : projectLookbackService.projectLookbackCommonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
                                itemgroup.key,
                                item.key,
                                projectLookbackService.projectLookback
                                  .workflowType
                              )
                        "
                        (onValueChange)="
                          projectLookbackService.onValueChange(
                            $event,
                            item.key,
                            item.value.feMetadata.referenceConfigGraph
                          )
                        "
                        [isEditable]="
                          projectLookbackService.projectLookbackCommonService.isFieldEditable(
                            projectLookbackService.projectLookback
                              .isProjectLookbackEditable,
                            projectLookbackService.projectLookback.status,
                            item.key
                          )
                        "
                      >
                      </app-project-lookback-dynamic-component>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div
            class="col-12 d-flex flex-row justify-content-end"
            style="gap: 1rem"
          >
            <span
              class="color-label status"
              id="saveButton"
              style="cursor: pointer"
              (click)="saveOrSubmitProjectLookback(false)"
            >
              Save as
              {{
                projectLookbackService.projectLookback.status
                  | ConvertCodeToDescription
                    : projectLookbackService.projectLookbackCommonService
                        .references.Status
              }}
            </span>
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="projectLookbackService.cancelLookback()"
              id="cancelButton"
            >
              cancel
            </button>
            <button
              class="btn btn-primary"
              mat-button
              [matMenuTriggerFor]="menu"
              id="submitButton"
            >
              submit
            </button>

            <mat-menu #menu="matMenu">
              <button
                id="menuButton"
                *ngFor="
                  let actions of projectLookbackService
                    .projectLookbackEvalService.projectLookbackNextSteps
                "
                mat-menu-item
                (click)="confirmSubmitDialog(actions.Code)"
              >
                {{ actions.Description }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
