import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { UserTeam } from "src/app/shared/models/user-team";
import { Discipline } from "src/app/shared/models/discipline";
import { UserDisciplineBusinessUnit } from "src/app/shared/models/user-trigger-business-unit";

@Injectable({
  providedIn: "root",
})
export class UserDisciplineBusinessUnitService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}UserDisciplineBusinessUnit`;

    public getAllUsersByDiscId(discId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByDisciplineId/${discId}`);
    }

    public getAllUsersByBuId(buId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
    }

    public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
    }

    public getAllDiscApprovers(userId: number): Observable<Discipline[]> {
      return this.http.get<Discipline[]>(`${this.apiRoot}/getAllDiscApprovers/${userId}`);
    }

    public getAllDiscApproversByUniqueKey(uniqueKey: string): Observable<Discipline[]> {
      return this.http.get<Discipline[]>(`${this.apiRoot}/getAllDiscApproversByUniqueKey/${uniqueKey}`);
    }

    // Deprecated
    // public createUserDisciplineBusinessUnit(userId: number, disciplineId: number, businessUnitId: number): Observable<UserDisciplineBusinessUnit>{
    //   const endPoint = `${this.apiRoot}/createUserDisciplineBusinessUnit`
    //   const body = {userId, disciplineId, businessUnitId};
    //   return this.http.post<UserDisciplineBusinessUnit>(endPoint, body);
    // }

    public createUserDisciplineBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserDisciplineBusinessUnit>{
      const endPoint = `${this.apiRoot}/createUserDisciplineBusinessUnitV2`
      const body = {triggerCode, uniqueKey, businessUnitCode};
      return this.http.post<UserDisciplineBusinessUnit>(endPoint, body);
    }

    public deleteUserDisciplineBusinessUnit(uniqueKey: string, discipline: string, businessUnit: string){
      const endPoint = `${this.apiRoot}/deleteUserDisciplineBusinessUnit/uniqueKey=${uniqueKey}/discipline=${discipline}/businessUnit=${businessUnit}`
      return this.http.delete(endPoint);
    }






}