<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.className"
  [autohide]="true"
  [delay]="toast.delay || 3000"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <mat-icon
      *ngIf="toastService.toastType == 1"
      aria-hidden="false"
      aria-label="Example check-circle-outline icon"
    >
      check_circle_outline</mat-icon
    >
    <mat-icon
      *ngIf="toastService.toastType == 2"
      aria-hidden="false"
      aria-label="Example warning-amber icon"
    >
      warning_amber</mat-icon
    >
    {{ toastService.toastType == 1 ? "Success" : "Alert" }}:
    {{ toast.textOrTpl }}
    <span class="hoover-button" (click)="toastService.remove(toast)">X</span>
  </ng-template>
</ngb-toast>
