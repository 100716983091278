import { NgModule } from "@angular/core";
import { UserService } from "./http/user.service";
import { LessonService } from "./http/lesson.service";
import { LessonVotingService } from "./services/lesson-services/lesson-voting-service";
import { ErrorHandlerModule } from "./interceptors/error-handler.module";
import { FlaggingLessonService } from "./http/flagging-lesson.service";

@NgModule({
  declarations: [],
  imports: [ErrorHandlerModule],
  exports: [ErrorHandlerModule],
  providers: [
    UserService,
    LessonService,
    LessonVotingService,
    FlaggingLessonService,
  ],
})
export class CoreModule {}
