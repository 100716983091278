<!-- Main Content -->
<app-page-header [pageTitle]="pageTitle"></app-page-header>
<main class="container-fluid module">
  <div class="row">
    <div class="col-10 px-0 py-0 reportedLessonTabHeader">
      <mat-tab-group
        [selectedIndex]="selectedTabIndex.value"
        (selectedIndexChange)="selectedTabIndex.setValue($event)"
        (selectedTabChange)="onTabChanged()"
      >
        <mat-tab
          *ngFor="let tab of tabs; let index = index"
          [label]="tab.label"
        >
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="count col-2">
      <span class="pendingCount align-bottom">
        {{ show ? totalPending : "" }}
      </span>
      <span class="pendingText align-bottom">
        lesson(s) {{ selectedTabIndex.value === 0 ? "reported" : "resolved" }}
      </span>
    </div>
  </div>
  <div class="row paging">
    <div class="paginator d-flex justify-content-end">
      <ngb-pagination
        [(page)]="page"
        [maxSize]="1"
        [collectionSize]="items"
        [rotate]="true"
        [pageSize]="itemsPerPage"
        (pageChange)="onPageChange($event)"
      >
      </ngb-pagination>
    </div>
  </div>
  <div class="row loader" *ngIf="!show">
    <div class="col col-9 lesson-table align-items-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div *ngIf="show" class="row paddingmarginTopBottom0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Lesson Id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td
          mat-cell
          class="lesson-id"
          *matCellDef="let lesson"
          (click)="navigateToAddEditLesson(lesson.lessonId)"
          [innerHTML]="lesson.lessonId"
        >
          <a href=""> {{ lesson.lessonId }} </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="report type">
        <th mat-header-cell *matHeaderCellDef>report type</th>
        <td mat-cell *matCellDef="let lesson">
          {{ lesson.flawReportingTypeByUser }}
        </td>
      </ng-container>
      <ng-container matColumnDef="report comment">
        <th mat-header-cell *matHeaderCellDef>report comment</th>
        <td
          mat-cell
          *matCellDef="let lesson"
          matTooltip="{{ lesson.commentsReportedByUser }}"
          matTooltipPosition="after"
        >
          {{ lesson.commentsReportedByUser }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reported by">
        <th mat-header-cell *matHeaderCellDef>reported by</th>
        <td mat-cell *matCellDef="let lesson">{{ lesson.reportedByUser }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>action</th>
        <td mat-cell *matCellDef="let lesson">
          <button class="resolveButton" (click)="verifyReportedLesson(lesson)">
            mark as resolved
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="manager Comment">
        <th mat-header-cell *matHeaderCellDef>manager comment</th>
        <td
          mat-cell
          *matCellDef="let lesson"
          [matTooltip]="true ? lesson.managerComments : null"
          matTooltipPosition="after"
        >
          {{ lesson.managerComments }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <ng-container *ngIf="allFlawedLessons.length === 0">
      <div class="row col-12 d-flex justify-content-center py-0">
        <p>No reported lessons</p>
      </div>
    </ng-container>
  </div>
</main>

<!-- End Main Content -->
