<np-header [ngClass]="{ 'tco-logo': businessUnit == 'Eurasia Business Unit' }">
  <!-- Global (top menu) Navigation -->
  <!-- <np-menu [location]="NavigationLocation.Global"></np-menu> -->
  <!-- USER INFO START -->
  <div class="welcome-wrapper">
    <div class="noti" [matMenuTriggerFor]="noti">
      <div [ngClass]="{ after: pendingTasks }"></div>
      <img
        alt="Notifications"
        src="assets/icons/Icon - Alert.svg"
        (click)="updateCurrentUserService()"
      />
    </div>
    <mat-menu #noti="matMenu">
      <span mat-menu-item>
        <button class="profile-text" mat-menu-item (click)="goToMyAction()">
          <span
            >You currently have
            <span style="color: red; font-weight: bolder">{{
              pendingTasks
            }}</span>
            pending lesson(s).</span
          >
        </button>
      </span>
    </mat-menu>

    <div class="welcome" [matMenuTriggerFor]="menu">
      <img *ngIf="photoData" [src]="photoData" alt="Profile picture" />
      <div *ngIf="userName" id="userName">
        <div class="welcome-text">welcome,</div>
        <div>{{ userName }}</div>
      </div>
      <img
        alt="Profile settings"
        class="profile"
        src="assets/icons/Chevron_Down.svg"
        onClick=""
      />

      <mat-menu #menu="matMenu">
        <span mat-menu-item>
          <button class="profile-text" mat-menu-item (click)="editProfile()">
            <span>edit profile</span>
          </button>
        </span>
      </mat-menu>
    </div>
  </div>
  <!-- USER INFO END -->
</np-header>
