import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from "@angular/forms";
import { HighlightTag } from "angular-text-input-highlight";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { CreateEditProjectLookbackService } from "src/app/core/services/project-lookback-services/create-edit-project-lookback.service";
import {
  Lesson,
  LessonFieldUpdate,
  Reference,
  User,
} from "src/app/shared/models";

@Component({
  selector: "app-dynamic-component",
  templateUrl: "./dynamic-component.component.html",
  styleUrls: ["./dynamic-component.component.scss"],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicComponentComponent implements OnInit {
  public lesson: Lesson;
  public bindingModel: any;
  isUser: boolean = false;
  users: User[] = [];
  @Input() fieldId: string;
  @Input() ifTooltip: boolean;
  @Input() fieldValue: any;
  @Input() fieldList: Reference[];
  @Input() labelValue: string;
  @Input() showlabel: boolean;
  @Input() className: string;
  @Input() toolTipInstruction: string;
  @Input() isEditable: boolean;
  @Input() isVisible: boolean;
  @Input() columsWidth: number;
  @Input() isFromCreateNewLesson: boolean;
  @Input() isRemoveCurrentSelection: boolean = false;
  @Input() currentSelection: string = "";
  @Input() previousLesson: Lesson;
  @Input() labelWrapperClass: string = "";
  @Input() formFieldClass: string = "";
  @Input() controlType: string;
  @Input() fieldDictatingForCrossReferenceConfig: string;
  @Input() crossReferenceConfigEnum: string;
  @Input() isExtensionProperty: boolean;
  @Input() extension: string;
  @Input() userType: string;
  @Input() form: FormGroup;
  @Input() referenceConfigGraph: string;
  @Input() filteredGraphGroup: User[]; //bulk edit needs this guy, delete and use referenceConfigGraph
  @Input() groupName: string;
  @Input() tags: HighlightTag[] = [];
  fieldMetadata = new LessonFieldUpdate();
  approverGroup: string;
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();
  @Output() onLessonChange: EventEmitter<LessonFieldUpdate> =
    new EventEmitter();
  constructor(
    public singleLessonService: CreateEditSingleLessonService,
    public projectLookbackService: CreateEditProjectLookbackService,
    public commonService: CommonService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lesson = new Lesson();
    if (this.isFromCreateNewLesson) {
      this.lesson = this.previousLesson;
    } else {
      this.lesson = JSON.parse(JSON.stringify(this.previousLesson));
    }
    this.bindingModel = this.lesson;
    this.setUserType();

    if (this.fieldId == "lessonComments") {
      let comments: any = [];
      if (this.fieldValue) {
        this.fieldValue.forEach((element) => {
          comments.push(element.content);
        });
      }
      this.fieldValue = comments.join(", ");
    }
    this.fieldMetadata.lesson = this.lesson;
    this.fieldMetadata.fieldId = this.fieldId;

    if (this.referenceConfigGraph) {
      this.approverGroup = this.commonService.replaceTemplateGraphGroup(
        this.referenceConfigGraph[this.lesson.lessonWorkflowType],
        this.lesson.cop,
        this.lesson.copSubGroup,
        this.lesson.region,
        this.lesson.discipline,
        this.lesson.businessUnit
      );
      if (
        !this.commonService.allReferenceConfigGraphGroupsCache[
          this.approverGroup
        ]
      ) {
        this.commonService.getGraphUsersByLessonWorkflowType(
          this.referenceConfigGraph,
          this.lesson.lessonWorkflowType,
          this.lesson.cop,
          this.lesson.copSubGroup,
          this.lesson.region,
          this.lesson.discipline,
          this.lesson.businessUnit
        );
      }
    }
  }

  valueSelected(event: any) {
    /* 
    Note: binding to lesson with tempLesson as the two way binding with ngModel doesnt work for MatAutoComplete and MatSlideToggle. 
    Esentially the binding happens at the ngModel in html and here depeding on the control type
    */
    let tempLesson = new LessonFieldUpdate();
    tempLesson.lesson = this.lesson;
    tempLesson.fieldId = this.fieldId;
    if (this.controlType == "MatAutoComplete") {
      tempLesson.lesson[this.fieldId] = event.option.value;

      //this.onValueChange.emit(event.option.value);
    } else if (this.controlType == "MatSlideToggle") {
      tempLesson.lesson[this.fieldId] = event.checked;

      this.onValueChange.emit(event);
    }
    //This gets trigger for any onValueChange to determine if there is an output activity to take place for onChange()
    else {
      if (event.value != undefined) this.onValueChange.emit(event);
    }
    this.onLessonChange.emit(tempLesson);
  }

  openLegacyDialog() {
    this.singleLessonService.LegacyDataDialogue();
  }
  // this needs to be generic based on the workflowType
  setUserType() {
    if (this.userType == "User" || this.userType == "User[]") {
      this.isUser = true;
    }
  }

  compareUsers(o1: User, o2: User) {
    return o1?.uniqueKey == o2?.uniqueKey;
  }
}
