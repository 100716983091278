import { Lesson } from "src/app/shared/models";

export class PackageList {
  lists: PackageListItem[] = [];
}

export class PackageListItem {
  packageName: string;
  packageLessons: Lesson[];
}
