<div class="dialogContainer">
  <div class="container-fluid p-4">
    <div class="d-flex flex-row">
      <mat-icon>share</mat-icon>
      <h4 class="ml-2">Share lookback code</h4>
    </div>
    <p class="mt-2">Share your code with specific participants</p>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <app-autocomplete
        label="Please enter a name..."
        #toAutocomplete
        [allowMultipleSelection]="true"
        [clearOnOptionSelect]="true"
        (selectedOptionsEvent)="getUserInfo($event)"
      ></app-autocomplete>
    </div>
    <div class="mt-4">
      <h4>Active Participants: {{ 1 + participants?.length ?? 0}}</h4>
      <div
        *ngFor="let admin of adminName"
        class="d-flex flex-row justify-content-between"
      >
        <p>
          {{ admin.fullName }}
        </p>
        <div class="d-flex flex-row">
          <p>Admin</p>
        </div>
      </div>
      <div
        *ngFor="let user of participants"
        class="d-flex flex-row justify-content-between"
      >
        <p>
          {{ user.fullName }}
        </p>
        <div class="d-flex flex-row">
          <p>Participants</p>
        </div>
      </div>
      <h4 *ngIf="pendingParticipants?.length"> Pending Participants: {{ pendingParticipants?.length ?? 0}}</h4>
      <div
        *ngFor="let user of pendingParticipants"
        class="d-flex flex-row justify-content-between"
      >
        <p>
          {{ user.fullName }}
        </p>
        <div class="d-flex flex-row">
          <p>Pending</p>
          <mat-icon style="color: red" class="ml-2" (click)="deleteUser(user)"
            >delete</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-end mt-2 mb-2" style="gap: 1rem">
      <button
        type="button"
        mat-button
        class="btn"
        (click)="cancel()"
        id="cancelButton"
      >
        cancel
      </button>
      <div>
        <button
          (click)="sendEmail()"
          style="text-align: center"
          class="btn btn-primary"
        >
          submit
        </button>
      </div>
    </div>
    <div>
      <p>Copy code to your clipboard</p>
      <div class="d-flex flex-row justify-content-between">
        <div>
          <input style="width: 20rem" [value]="projectCode" />
        </div>
        <div>
          <button
            (click)="copyCode()"
            style="text-align: center; color: white"
            class="btn btn-primary copyButton"
          >
            copy
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
