import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from "./error-dialog.component";

@Injectable()
export class ErrorDialogService {
  private opened = false;
  constructor(private dialog: MatDialog) {}

  openDialog(error: any, message: string, status?: number): void {
    if (!this.opened && !this.skipDialog(message, status)) {
      this.opened = true;
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: {
          message:
            typeof message == "string" ? message : JSON.stringify(message),
          status,
        },
        maxHeight: "100%",
        width: "700px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }
  private skipDialog(message: string, status?: number): boolean {
    if (!message || typeof message != "string") {
      return false;
    }

    if (
      status &&
      status === 404 &&
      (message.includes("graph.microsoft.com") ||
        message.includes("api/User/getChevronDirUser/bin"))
    ) {
      return true;
    }

    if (
      message.includes("Cannot read property 'querySelector' of null") ||
      message.includes("api/User/getChevronDirUser/bin")
    ) {
      return true;
    }

    return false;
  }
}
