<div class="example-container mat-elevation-z4" *ngIf="dataSource">
  <mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="fullName">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item?.fullName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item?.email}}  </mat-cell>
    </ng-container>

    <ng-container matColumnDef="discipline">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isDiscipline ? 'efforts__hideColumn' : ''"
        >Discipline(s)</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isDiscipline ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="team">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isTeam ? 'efforts__hideColumn' : ''"
        >Team</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isTeam ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="department">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isDepartment ? 'efforts__hideColumn' : ''"
        >Department</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isDepartment ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="related process">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isRelatedProcess ? 'efforts__hideColumn' : ''"
        >Related Process</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isRelatedProcess ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="asset">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isAsset ? 'efforts__hideColumn' : ''"
        >Asset</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isAsset ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cop">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isCop ? 'efforts__hideColumn' : ''"
        >Cop</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isCop ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="region">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="!isRegion ? 'efforts__hideColumn' : ''"
        >Region</mat-header-cell
      >
      
      <mat-cell
        *matCellDef="let item"
        [ngClass]="!isRegion ? 'efforts__hideColumn' : ''"
      >
      {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
