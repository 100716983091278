<div class="error-dialog">
  <h6 mat-dialog-title>Uh-oh! Looks like an unexpected error has occurred.</h6>
  <div mat-dialog-content *ngIf="!showAuthenticationFix">
    <p *ngIf="data.status">
      <b>status code: {{ data.status }}</b>
    </p>
    <p>
      Please try refreshing the page. If the error consists please create a
      ticket:
      <a class="urlLink" href="{{ supportTicketUrl }}" target="_blank" rel="noopener">
        click here for instructions on how to create a ticket</a>
    </p>
    <p class="error-message">
      {{ data?.message }}
    </p>
  </div>
  <div mat-dialog-content *ngIf="showAuthenticationFix">
    <p *ngIf="data.status">
      <b>status code: {{ data.status }}</b>
    </p>
    <p>
      Please try refreshing the page. You can navigate to <a class="urlLink" href="{{ authenticationErrorDocumentation }}" target="_blank" rel="noopener">here</a>
      for step-by-step instructions to resolve this.
    </p>
    <p class="error-message">
      {{ data?.message }}
    </p>
  </div>
  <div class="row py-2">
    <div class="col col-12 d-flex justify-content-between dialogButtons">
      <button type="button" mat-dialog-close class="btn btn-outline-primary">
        close
      </button>
    </div>
  </div>
</div>