import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { Department } from "src/app/shared/models/department";

@Injectable({
  providedIn: "root",
})
export class DepartmentService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}Department`;

    public getDepartment(code: string): Observable<Department> {
      return this.http.get<Department>(`${this.apiRoot}/getDepartment/${code}`);
    }



}