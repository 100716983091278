import { Injectable } from "@angular/core";
import {
  ActionTypes,
  DataTypeConstants,
} from "src/app/config/global-enums.config";
import {  Reference } from "src/app/shared/models";
import {
  WorkflowType,
  FieldsModel,
  PreTransitionActionsModel,
  TransitionConditionsActionModel,
  WorkflowConfig,
} from "src/app/shared/models/configsV2/workflowConfigModel";
import { SetConfigService } from "../set-config.service";
import { ToastService } from "../toast.service";
import { ProjectLookback } from "src/app/shared/models/project-lookback";
import { ProjectLookbackCommonService } from "./project-lookback-common.service";

@Injectable({
  providedIn: "root",
})
export class ProjectLookbackEvalConfigService {
  workflowConfig: WorkflowConfig[] = [];
  projectLookbackNextSteps: Reference[] = [];
  projectLookbackPriorSteps: Reference[] = [];

  constructor(
    public ProjectLookbackCommonService: ProjectLookbackCommonService,
    public setConfigService: SetConfigService,
    public toastService: ToastService
  ) {}

  onInit() {
    this.workflowConfig = JSON.parse(localStorage.getItem("workflowConfig"));
  }

  public async setAndValidateTransition(projectLookback: ProjectLookback): Promise<boolean> {
    const element = this.workflowConfig[projectLookback.workflowType].find(
      (x) =>
        x.FromStatus === projectLookback.status && x.ToStatus === projectLookback.pendingStatus
    );

    if (element) {
      let isActionResultTransitionActions = this.performPreTransitionActions(
        element,
        projectLookback
      );

      if (isActionResultTransitionActions && element.TransitionConditions) {
        let isActionResultTransitionCondition =
          await this.performTransitionConditions(
            element.TransitionConditions,
            projectLookback
          );
        return isActionResultTransitionCondition;
      }
      return isActionResultTransitionActions;
    }
    this.toastService.showError(true, "Uh-oh! There was an issue in transitioning the project lookback. Please reach out to the support team for further assistance.");
    return false;
  }

  async performTransitionConditions(
    tcActionModels: TransitionConditionsActionModel[],
    projectLookback: ProjectLookback
  ): Promise<boolean> {
    let actionResult = true;
    tcActionModels.forEach((t) => {
      if (t.FieldValue) {
        if (t.MethodName == "allFieldContainsCertainValue") {
          actionResult = this.allFieldContainsCertainValue(
            projectLookback,
            t.FieldNames,
            t.FieldValue
          );
        } else if (t.MethodName == "anyFieldContainsCertainValue") {
          actionResult = this.anyFieldContainsCertainValue(
            projectLookback,
            t.FieldNames,
            t.FieldValue
          );
        }
      }
    });

    return actionResult;
  }

  async performPreTransitionActions(
    workflowModel: WorkflowType,
    projectLookback: ProjectLookback
  ): Promise<boolean> {
    let actionResult = true;
    if(workflowModel.PreTransitionActions){
      workflowModel.PreTransitionActions.forEach(async (x) => {
        if (x.ActionType === ActionTypes.SetValue) {
          actionResult = this.performSetValueActionType(x, actionResult, projectLookback);
        } else if (
          actionResult &&
          x.ActionType === ActionTypes.CopyValueFromField
        ) {
          actionResult = this.performCopyValueFromFieldActionType(x, projectLookback);
        } else if (
          actionResult &&
          x.ActionType === ActionTypes.SetValueFromGraphDropwdown
        ) 
        {
          await this.performSetValueFromGraphDropwdown(x, projectLookback).then(
            (res) => (actionResult = res)
          );
        }
      });
    }
    return actionResult;
  }

  getProjectLookbackWorkflowStatuses(projectLookbackWorkflowType: string){
    const fromStatuses = this.workflowConfig[projectLookbackWorkflowType].map((config) => config.FromStatus);
    const toStatuses = this.workflowConfig[projectLookbackWorkflowType].map((config) => config.ToStatus);

    let uniqueStatuses = [...new Set([...fromStatuses, ...toStatuses])];
    uniqueStatuses = uniqueStatuses.filter(status => status !== 'bulkImportExcel');

    return uniqueStatuses;

  }

  private performSetValueActionType(
    x: PreTransitionActionsModel,
    actionResult: boolean,
    projectLookback: ProjectLookback
  ) {
    x.Fields.forEach((f) => {
      actionResult = this.fieldConditionCheck(f);
      if (actionResult) {
        actionResult = this.setFieldValue(f, projectLookback);
      } else {
        return actionResult;
      }
    });
    return actionResult;
  }

  private performCopyValueFromFieldActionType(
    x: PreTransitionActionsModel,
    projectLookback: ProjectLookback
  ): boolean {
    try {
      if (x.FromFieldName && x.ToFieldName) {
        if (typeof x.FromFieldName === DataTypeConstants.string) {
          if (x.ToFieldName === "assignees") {
            projectLookback[x.ToFieldName] = [].concat(projectLookback[x.FromFieldName]);
          } else {
            projectLookback[x.ToFieldName] = projectLookback[x.FromFieldName];
          }
        } else if ((x.FromFieldName as string[]).length == 1) {
          if (x.ToFieldName === "assignees") {
            projectLookback[x.ToFieldName] = [].concat(projectLookback[x.FromFieldName[0]]);
          } else {
            projectLookback[x.ToFieldName] = projectLookback[x.FromFieldName];
          }
        } else if ((x.FromFieldName as string[]).length > 1) {
          projectLookback[x.ToFieldName] = [];
          (x.FromFieldName as string[]).forEach((c) => {
            if (projectLookback[c]) {
              if (x.ToFieldName === "assignees") {
                projectLookback[x.ToFieldName].push(projectLookback[c]);
                return;
              }
            }
          });
        }
      }
      return true;
    } catch (ex) {
      this.toastService.showError(true,
        `Uh-oh! Looks like there was an error copying value from ${x.FromFieldName} to ${x.ToFieldName}. Please reach out to the support team for further assistance.`
      );
      return false;
    }
  }

  private async performSetValueFromGraphDropwdown(
    x: PreTransitionActionsModel,
    projectLookback: ProjectLookback
  ): Promise<boolean> {
    //this is not working as expected if there is an error (if you forget to pass projectLookbackWorfklowType) -- revist
    const usersFromGraph =
      await this.ProjectLookbackCommonService.getGraphUsersByProjectLookbackWorkflowType(
        x.ReferenceConfigGraph,
        projectLookback.workflowType,
        null,
        null,
        null,
        null,
        projectLookback.businessUnit
      );
    if (projectLookback.workflowType) {
      projectLookback.projectAdmins = [].concat(usersFromGraph[0]);
      if (!projectLookback.projectAdmins) {
        this.toastService.showError(true, 
          `Uh-oh! Looks like there was an error in setting the values for this: ${x.FromFieldName} to ${x.ToFieldName}. Please reach out to the support team for further assistance.`
        );
        return false;
      }
      return true;
    }
  }

  fieldConditionCheck(f: FieldsModel): boolean {
    let actionResult = true;
    if (f.Condition == null) {
      return true;
    }
    f.Condition.forEach((c) => {
      actionResult = this.setConfigService.callDynamicFunction(c);
      if (!actionResult) {
        return actionResult;
      }
    });
  }

  setFieldValue(f: FieldsModel, projectLookback: ProjectLookback): boolean {
    const actionResult = true;
    // core properties
    if (f.Value == "Date.Now()") {
      projectLookback[f.FieldName] = new Date(Date.now());
    } else {
      projectLookback[f.FieldName] = f.Value;
    }
    return actionResult;
  }

  anyFieldContainsCertainValue(
    projectLookback: ProjectLookback,
    fields: string[],
    fieldValue: any
  ): boolean {
    let actionResult = false;
    fields.forEach((f) => {
      if (typeof fieldValue === DataTypeConstants.string) {
        if (projectLookback[f] == fieldValue) {
          actionResult = true;
        }
      } else if ((fieldValue as string[]).length >= 1) {
        (fieldValue as string[]).forEach((v) => {
          if (projectLookback[f] == v) {
            actionResult = true;
          }
        });
      }
    });
    return actionResult;
  }

  allFieldContainsCertainValue(
    projectLookback: ProjectLookback,
    fields: string[],
    fieldValue: any
  ): boolean {
    let actionResult = true;
    fields.forEach((f) => {
      if (typeof fieldValue === DataTypeConstants.string) {
        if (projectLookback[f] != fieldValue) {
          actionResult = false;
        }
      } else if ((fieldValue as string[]).length >= 1) {
        (fieldValue as string[]).forEach((v) => {
          if (projectLookback[f] != v) {
            actionResult = false;
          }
        });
      }
    });
    return actionResult;
  }

  //method to show hide projectLookback next step based on transition condition
  public async loadProjectLookbackNextSteps(projectLookback: ProjectLookback) {
    let transitionStep = [];
    const workflowCopy = this.ProjectLookbackCommonService.workflowConfig[
      projectLookback.workflowType
    ].filter((wf) => wf.FromStatus == projectLookback.status);
    workflowCopy.forEach(async (status) => {
      if (
        status.TransitionConditions != undefined ||
        status.TransitionConditions != null
      ) {
        let transitionResult = await this.performTransitionConditions(
          status.TransitionConditions,
          projectLookback
        );
        if (transitionResult) {
          this.copyProjectLookbackNextStepAction(transitionStep, status);
        }
      } else {
        this.copyProjectLookbackNextStepAction(transitionStep, status);
      }
    });
  }

  private copyProjectLookbackNextStepAction(
    transitionStep: Reference[],
    workflow: WorkflowType
  ) {
    if (workflow.TransitionName){
      transitionStep.push({
        Code: workflow.TransitionName,
        Description: workflow.TransitionName,
        Sequence: workflow.TransitionNameDisplayOrder,
        Condition: workflow?.TransitionConditions,
        Direction: workflow?.TransitionType
      });

      let filteredBackwardSteps = transitionStep.filter(step => step.Direction.toLowerCase() === 'backward');
      let filteredNextSteps = transitionStep.filter(step => step.Direction.toLowerCase() === 'forward');


      this.projectLookbackPriorSteps = [
        ...new Set(filteredBackwardSteps.sort((a, b) => a.Sequence - b.Sequence)),
      ];

      this.projectLookbackNextSteps = [
        ...new Set(filteredNextSteps.sort((a, b) => a.Sequence - b.Sequence)),
      ];
    }
  }


}