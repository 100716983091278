import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { UserAssetBusinessUnit } from "src/app/shared/models/user-trigger-business-unit";
import { Asset } from "src/app/shared/models/asset";

@Injectable({
  providedIn: "root",
})

export class UserAssetBusinessUnitService {

  constructor(private http: HttpClient) {}

  apiRoot = `${environment.apiUrl}UserAssetBusinessUnit`;

  public getAllUsersByAssetId(assetId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByAssetId/${assetId}`);
  }

  public getAllUsersByBuId(buId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
  }

  public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
  }

  public getAllAssetApprovers(userId: number): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.apiRoot}/getAllAssetApprovers/${userId}`);
  }

  public getAllAssetApproversByUniqueKey(uniqueKey: string): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.apiRoot}/getAllAssetApproversByUniqueKey/${uniqueKey}`);
  }

  
  public createUserAssetBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserAssetBusinessUnit>{
    const endPoint = `${this.apiRoot}/createUserAssetBusinessUnitV2`
    const body = {triggerCode, uniqueKey, businessUnitCode};
    return this.http.post<UserAssetBusinessUnit>(endPoint, body);
  }

  public deleteUserAssetBusinessUnit(uniqueKey: string, asset: string, businessUnit: string){
    const endPoint = `${this.apiRoot}/deleteUserAssetBusinessUnit/uniqueKey=${uniqueKey}/asset=${asset}/businessUnit=${businessUnit}`
    return this.http.delete(endPoint);
  }


}