import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/shared/models';
import { UserRole } from 'src/app/shared/models/user-role';
import { ParsingType } from '../../pages/coordinator-dashboard/dashboard-landing/dashboard-landing.component';
import { UserService } from 'src/app/core/http/user.service';
import { UserDisciplineBusinessUnitService } from 'src/app/core/http/user-discipline-bu.service';
import { UserDepartmentBusinessUnitService } from 'src/app/core/http/user-department-bu.service';
import { UserTeamBusinessUnitService } from 'src/app/core/http/user-team-bu.service';
import { UserAssetBusinessUnitService } from 'src/app/core/http/user-asset-bu.service';
import { UserRelatedProcessBusinessUnitService } from 'src/app/core/http/user-related-process-bu.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserRegionBusinessUnitService } from 'src/app/core/http/user-region-bu.service';

@Component({
  selector: 'app-remove-techapr-mgr',
  templateUrl: './remove-techapr-mgr.component.html',
  styleUrls: ['./remove-techapr-mgr.component.scss']
})
export class RemoveTechaprMgrComponent {

  selectedManager: ParsingType;
  selectedLegalApprover: ParsingType;
  selectedEcApprover: ParsingType;
  selectedTechnicalApprover: ParsingType;
  selectedTriggerValue: ParsingType;
  triggerValues: any;
  triggerType: any;

  constructor(
    public dialogRef: MatDialogRef<RemoveTechaprMgrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private userService: UserService,
    private userDiscBuService: UserDisciplineBusinessUnitService,
    private userAssetBuService: UserAssetBusinessUnitService,
    private userDepartmentBuService: UserDepartmentBusinessUnitService,
    private userTeamBuService: UserTeamBusinessUnitService,
    private userRelatedProcessBuService: UserRelatedProcessBusinessUnitService,
    private userRegionBuService: UserRegionBusinessUnitService,
    private toastService: ToastService
  ) {}


  ngOnInit() {
    this.triggerType = this.data.triggerType;
  }

  // Get readable description/long name
  convertToDesc(val){
    switch(val){
      case "Manager":
        return "manager";
      case "TechnicalApprover":
        return "technical approver";
      case "relatedProcess":
        return "related process";
      case "discipline":
        return "discipline";
      case "team":
        return "team";
      case "department":
        return "department";
      case "asset":
        return "asset";
      case "LegalApprover":
        return "legal approver";
      case "EcApprover":
        return "ec approver";
    }
  }

  onTechnicalApproverChange(technicalApprover, triggerType: string){
    switch(triggerType){
      case "discipline":
        this.userDiscBuService.getAllDiscApproversByUniqueKey(technicalApprover.email).subscribe(res => {
          this.triggerValues = res;
        })
        break;
      case "team":
        this.userTeamBuService.getAllTeamApproversByUniqueKey(technicalApprover.email).subscribe(res => {
          this.triggerValues = res;
        })
        break;
      case "department":
        this.userDepartmentBuService.getAllDepartmentApproversByUniqueKey(technicalApprover.email).subscribe(res => {
          this.triggerValues = res;
        })
        break;
      case "relatedProcess":
        this.userRelatedProcessBuService.getAllRelatedProcessApproversByUniqueKey(technicalApprover.email).subscribe(res => {
          this.triggerValues = res;
        })
        break;
      case "asset":
        this.userAssetBuService.getAllAssetApproversByUniqueKey(technicalApprover.email).subscribe(res => {
          this.triggerValues = res;
        })
        break;
      case "region":
        this.userRegionBuService.getAllRegionApproversByUniqueKey(technicalApprover.email).subscribe(res => {
          this.triggerValues = res;
        })
        break;


    }
  }

  cancel() {
    this.dialogRef.close({update: false, user: null, value: null});
  }

  submit() {
    if(this.selectedManager){
      this.dialogRef.close({update: true, user: this.selectedManager});
    }
    else if(this.selectedEcApprover){
      this.dialogRef.close({update: true, user: this.selectedEcApprover, triggerValue: this.selectedTriggerValue});
    }
    else if(this.selectedLegalApprover){
      this.dialogRef.close({update: true, user: this.selectedLegalApprover});
    }
    else if(this.selectedTechnicalApprover){
      if(this.selectedTriggerValue){
        this.dialogRef.close({update: true, user: this.selectedTechnicalApprover, triggerValue: this.selectedTriggerValue });
      }
      else{
        let triggerName = this.convertToDesc(this.data.triggerType);
        this.toastService.showError(true, `Uh-oh! Looks like you forgot to select an appropriate ${triggerName}`);
      }
    }
  }

}
