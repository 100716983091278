import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Create } from 'powerbi-client';
import { CreateEditSingleLessonService } from 'src/app/core/services/lesson-services/create-edit-single-lesson.service';
import { SharedService } from '../../../shared.service';
import { LessonSearchService } from 'src/app/core/services/lesson-services/lesson-search.service';

@Component({
  selector: 'app-submitted-lesson',
  templateUrl: './submitted-lesson.component.html',
  styleUrls: ['./submitted-lesson.component.scss']
})


export class SubmittedLessonComponent {

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private shared: SharedService,
    public singleLessonService: CreateEditSingleLessonService,
    public lessonSearchService: LessonSearchService
  ){}

  id: any;
  pageTitle = "Your lesson has been submitted";

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
  }

  navigateBackToHomePage() {
    this.router.navigate(["/home"]);
  }

  navigateToActions(){
    this.shared.onClick(4);
    this.router.navigate(["/lessons"]);
  }
}
