import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ConfigCacheService {
  constructor(private http: HttpClient) {}

  // set root api and its controller in API
  apiRoot = `${environment.apiUrl}ConfigCache`;

  public getAllFieldConfigJsons(): Observable<any> {
    return this.http.get<any>(`${this.apiRoot}/getAllFieldConfigJsons`);
  }
}
