import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { UserTeam } from "src/app/shared/models/user-team";
import { Team } from "src/app/shared/models/team";
import { UserTeamBusinessUnit } from "src/app/shared/models/user-trigger-business-unit";

@Injectable({
  providedIn: "root",
})
export class UserTeamBusinessUnitService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}UserTeamBusinessUnit`;

    public getAllUsers(id: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByTeamId/${id}`);
    }

    public getAllUsersByTeamId(teamId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByTeamId/${teamId}`);
    }

    public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
    }

    public getAllUsersByBuId(buId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
    }

    public getAllTeamApprovers(userId: number): Observable<Team[]> {
      return this.http.get<Team[]>(`${this.apiRoot}/getAllTeamApprovers/${userId}`);
    }

    public getAllTeamApproversByUniqueKey(uniqueKey: string): Observable<Team[]> {
      return this.http.get<Team[]>(`${this.apiRoot}/getAllTeamApproversByUniqueKey/${uniqueKey}`);
    }

    // Deprecated
    // public createUserTeamBusinessUnit(userId: number, teamId: number, businessUnitId: number): Observable<UserTeamBusinessUnit>{
    //   const endPoint = `${this.apiRoot}/createUserTeamBusinessUnit`
    //   const body = {userId, teamId, businessUnitId};
    //   return this.http.post<UserTeamBusinessUnit>(endPoint, body);
    // }

    public createUserTeamBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserTeamBusinessUnit>{
      const endPoint = `${this.apiRoot}/createUserTeamBusinessUnitV2`
      const body = {triggerCode, uniqueKey, businessUnitCode};
      return this.http.post<UserTeamBusinessUnit>(endPoint, body);
    }

    public deleteUserTeamBusinessUnit(uniqueKey: string, team: string, businessUnit: string){
      const endPoint = `${this.apiRoot}/deleteUserTeamBusinessUnit/uniqueKey=${uniqueKey}/team=${team}/businessUnit=${businessUnit}`
      return this.http.delete(endPoint);
    }



}