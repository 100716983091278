import { Injectable } from "@angular/core";
import { EMPTY, Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { GraphError } from "@microsoft/microsoft-graph-client";

@Injectable({
  providedIn: "root",
})
export class MsGraphService {
  private apiRoot = `${environment.msGraphUrl}`;
  private grpEnv = environment.groupEnv;

  constructor(private http: HttpClient) {}

  getUserPhoto(): Observable<Blob | null> {
    const endPoint = this.apiRoot + "me/photo/$value";
    const headers = new HttpHeaders().set("Content-Type", "blob");
    return this.http.get(endPoint, { headers, responseType: "blob" });
  }

  getAADGroupId(groupName: string): Observable<any> {
    const graphCondition = `groups?$filter=startswith(displayName,'${groupName}')`;
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get<any>(endPoint);
  }

  getAADUserById(userId: string): Observable<any> {
    if (userId) {
      const graphCondition = `users?$filter=id eq '${userId}'`;
      const headers = new HttpHeaders().set('ConsistencyLevel', 'eventual');
      const endPoint = this.apiRoot + graphCondition;
      return this.http.get<any>(endPoint, { headers });
    }
    return EMPTY;
  }
  
  searchAADUsers(nameOrEmail: string): Observable<any> {
    const isNullOrWhiteSpace = !nameOrEmail || !nameOrEmail.trim()

    if (isNullOrWhiteSpace)
      return EMPTY;

    const graphCondition = `users?$search="displayName:${nameOrEmail}" OR "mail:${nameOrEmail}"&$select=id,displayName,givenName,surname,mail,userPrincipalName&$orderbydisplayName&$count=true&$top=10`;
    const headers = new HttpHeaders().set('ConsistencyLevel', 'eventual');
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get<any>(endPoint, { headers });

  }

  getAADGroupMembersFromGraph(groupId: string): Observable<any> {
    const graphCondition = `groups/${groupId}/members?$top=999`;
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get<any>(endPoint);
  }
  private handleGraphError(error: GraphError) {
    // Return null if user has no info/photo else throw error
    if (error.statusCode === 404) {
      return of(null);
    }
    throw error;
  }
}
