import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { LessonReply, LessonComment, User } from "src/app/shared/models";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatAccordion } from "@angular/material/expansion";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"],
})
export class CommentComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() comments: LessonComment[];
  @Input() lessonId: string;
  @Input() isBaseComment: boolean;
  @Input() isLessonEditable: boolean;
  @Input() isChildComment: boolean;

  @Output() newLessonComments: EventEmitter<LessonComment[]> =
    new EventEmitter();

  show = true;
  userInitials: string;
  newComment = "";
  isNewLesson: boolean;
  userProfile: User;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.userInitials = this.getInitials();
    if (this.lessonId) {
      this.isNewLesson = false;
    } else {
      this.isNewLesson = true;
      if (!this.comments) {
        this.comments = [];
      }
    }
    this.updateLasUpdatedUserAndAllowReply();
  }

  updateLasUpdatedUserAndAllowReply() {
    if (this.comments) {
      this.comments.forEach((comment) => {
        if (this.isChildComment) {
          comment.allowReply = false;
        } else {
          comment.allowReply = true;
        }
      });
    }
  }

  // New Comment method
  postNewComment() {
    let newItem = new LessonComment();
    newItem.content = this.newComment;
    newItem.allowReply = true;
    newItem.lastUpdatedUser = this.userProfile;
    newItem.lastUpdatedDate = new Date();
    newItem.isPublic = true;
    newItem.lessonReplies = [];
    this.show = false;
    if (this.isNewLesson) {
      newItem.allowReply = false;
    }

    this.comments.push(newItem);
    this.newLessonComments.emit(this.comments);
    this.newComment = "";
    this.show = true;
  }
  clear() {
    this.newComment = "";
  }
  commentLengthCheck() {
    return !(this.newComment.length > 0);
  }
  commentsCountCheck() {
    if (this.comments !== undefined) return this.comments.length > 0;
    return false;
  }
  // Reply Comment method/modal
  reply(parentItem: LessonComment) {
    let newItem = new LessonReply();
    newItem.content = "";
    const dialogRef = this.dialog.open(DialogContent, {
      width: "600px",
      data: {
        prevAuthor: parentItem.lastUpdatedUser,
        prevContent: parentItem.content,
        newContent: newItem.content,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.length > 0) {
          newItem.content = result;
          newItem.lastUpdatedUser = this.userProfile;
          newItem.lastUpdatedDate = new Date();
          newItem.allowReply = false;
          parentItem.lessonReplies.push(newItem);
        } else {
          // Show some error message
        }
      }
    });
  }

  getInitials(): string {
    let names = sessionStorage.getItem("name").split(",");
    return `${names[1].trim().substring(0, 1)}${names[0]
      .trim()
      .substring(0, 1)}`;
  }
}

export interface DialogData {
  prevAuthor: string;
  prevContent: string;
  newContent: string;
}
@Component({
  selector: "dialog-content",
  templateUrl: "dialog-content.html",
  styleUrls: ["./comment.component.scss"],
})
export class DialogContent {
  constructor(
    public dialogRef: MatDialogRef<DialogContent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSend(): void {
    this.dialogRef.close(this.data.newContent);
  }

  newComment(ev: any) {
    this.data.newContent = ev.target.value;
  }
}
