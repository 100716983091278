import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { UserTeam } from "src/app/shared/models/user-team";
import { Region } from "src/app/shared/models/region";
import { UserRegionBusinessUnit } from "src/app/shared/models/user-trigger-business-unit";

@Injectable({
  providedIn: "root",
})
export class UserRegionBusinessUnitService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}UserRegionBusinessUnit`;

    public getAllUsersByRegionId(regionId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByRegionId/${regionId}`);
    }

    public getAllUsersByBuId(buId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
    }

    public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
    }

    public getAllRegionApprovers(userId: number): Observable<Region[]> {
      return this.http.get<Region[]>(`${this.apiRoot}/getAllRegionApprovers/${userId}`);
    }

    public getAllRegionApproversByUniqueKey(uniqueKey: string): Observable<Region[]> {
      return this.http.get<Region[]>(`${this.apiRoot}/getAllRegionApproversByUniqueKey/${uniqueKey}`);
    }

    // Deprecated
    // public createUserDisciplineBusinessUnit(userId: number, disciplineId: number, businessUnitId: number): Observable<UserDisciplineBusinessUnit>{
    //   const endPoint = `${this.apiRoot}/createUserDisciplineBusinessUnit`
    //   const body = {userId, disciplineId, businessUnitId};
    //   return this.http.post<UserDisciplineBusinessUnit>(endPoint, body);
    // }

    public createUserRegionBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserRegionBusinessUnit>{
      const endPoint = `${this.apiRoot}/createUserRegionBusinessUnitV2`
      const body = {triggerCode, uniqueKey, businessUnitCode};
      return this.http.post<UserRegionBusinessUnit>(endPoint, body);
    }

    public deleteUserRegionBusinessUnit(uniqueKey: string, region: string, businessUnit: string){
      const endPoint = `${this.apiRoot}/deleteUserRegionBusinessUnit/uniqueKey=${uniqueKey}/region=${region}/businessUnit=${businessUnit}`
      return this.http.delete(endPoint);
    }






}