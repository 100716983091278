import { Lesson } from "./lesson";

export class LessonOperationResponse {
  lessons: Array<Lesson> = new Array<Lesson>();
  message: string;
  success: boolean;
  errorCount: number;
  successCount: number;
  importId: string;
}
