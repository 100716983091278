<div class="bulkImportDiv">
  <div class="d-flex align-items-center justify-content-center h4 bold mb-5" style="border-top: 2rem solid #fff" >
    Bulk Import Lessons Learned
  </div>
  <div class="col-12 border p-0 d-flex mt-4 bulkImportContainer">
    <div class="col-7 border-right p-2 pl-4">
      <div class="text-primary bold m-0">
        1. First time importing a bulk lessons learned?
      </div>
      <div>
        <small class="text-muted" style="padding-left: 1rem;"
          > Please select one of the templates from the dropdown to the right and fill out the required fields in order to bulk upload.
        </small
        >
      </div>
      <small class="text-muted" style="padding-left: 1rem;"
        >Ensure to <i>"Enable Content"</i> before entering any data into the
        bulk upload excel file.</small
      >
      <mat-icon
        class="align-self-center"
        (mouseover)="showImage = !showImage"
        (mouseout)="showImage = !showImage"
        style="opacity: 0.5"
        >info</mat-icon
      >
      <img
        *ngIf="showImage"
        class="carousel-caption imagePreview"
        src="assets/images/macrosEnable.png"
        alt=""
      />
    </div>
    <div
      class="col-5 row justify-content-end p-2 d-flex align-items-center p-2" style="padding-left: 2rem !important; padding-bottom: 4rem !important;"
    >
      <div class="col-8" style="margin-bottom: 1rem;">
        <div class="align-self-center">
          <h5>Templates: </h5>
          <mat-form-field appearance="outline" class="templateField">
            <mat-select
              [(ngModel)]="selectedTemplateType"
              class="mat-select-style"
              placeholder="select one"
              panelClass="mat-select-panel-class"
            >
              <mat-option
                *ngFor="let ref of coreReferences.DownloadTemplate"
                [value]="ref.Code"
              >
                <span>{{ ref.Description }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-4 align-items-center" style="padding-top: 2rem;">
        <button
          type="button"
          class="btn btn-primary m-2 btn-md downloadBtn"
          (click)="downloadTemplate()"
          type="button"
        >
          download
        </button>
      </div>
    </div>
    <input
      #fileInputNew
      class="form-control"
      type="file"
      hidden
      accept=".xlsm"
      (change)="onFileSelected($event)"
    />
  </div>
  <div class="col-12 p-4 mt-2 upload-lesson-border d-flex flex-column">
      <div>
        <div class="text-primary bold m-0">
          2. Fill out unique name and source description
        </div>
        <div class="col-12 row">
            <label style="margin-bottom: 0" style="padding-left: 1rem;">
              <h5>Import Name: (required)</h5>
            </label>
            <span class="width-100" style="padding-bottom: 0.5rem;">
              <input
                id="fileNickname"
                matInput
                class="input-text"
                type="text"
                [(ngModel)]="fileNickname"
                placeholder="please input a unique name for this bulk import"
              />
            </span>
            <label style="margin-bottom: 0" style="padding-left: 1rem;"
            ><h5>Import Description: </h5>
          </label>
          <span class="width-100">
            <textarea
              id="bulkDescription"
              class="input-text"
              matInput
              [(ngModel)]="fileDescription"
              placeholder="please input some descriptive details regarding this bulk import"
            ></textarea>
          </span>
        </div>
      </div>
  </div>
  <div class="col-12 p-4 mt-2 d-flex flex-column uploadContainer">
    <div class="d-flex flex-column pb-1">
      <div class="text-primary bold m-0">
        3. Upload your lessons learned bulk file
      </div>
      <div class="upload-lesson-text m-0">
        We only accept .xlsm files utilizing our template
      </div>
    </div>
    <div class="uploadBtns">
      <button
        class="btn btn-primary chooseFileBtn"
        (click)="fileInputNew.click()"
        style="color: #fff"
      >
        browse
      </button>
      <mat-chip-listbox class="offset-1 col-5 d-flex align-items-center fileName">
        <mat-chip-option *ngIf="fileName">
          {{ fileName }}
          <mat-icon matChipRemove (click)="removeFile()">close</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
      <button
        *ngIf="file"
        style="color: #fff"
        [disabled]="!file || !fileNickname"
        (click)="upload()"
        class="btn btn-primary width-100 uploadBtn"
      >
        upload
      </button>
  </div>
  <div *ngIf="file && showDisclaimer">
    <p class="disclaimerText">
      Disclaimer: Lessons uploaded via bulk importing will be placed in the 'draft' status. Please navigate to your actions to transition further.
    </p>
  </div>

  <mat-progress-bar
  class="progress-bar"
  mode="indeterminate"
  *ngIf="uploadInProgress"
>
  </mat-progress-bar>
  <ng-container *ngIf="!uploadInProgress">
    <div *ngIf="importCompleted">
      <p class="successText">
        Bulk upload completed successfully. An e-mail notification will be
        sent when the submission is available in your ‘Actions’.
      </p>
    </div>
  </ng-container>
</div>
</div>