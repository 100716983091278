import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { HomeComponent } from "./modules/home/pages/home/home.component";
import { NotfoundComponent } from "./modules/home/pages/notfound/notfound.component";
import { ReportedLessonsListComponent } from "./modules/home/pages/reportedlessons-list/reportedlessons-list.component";
import { NextPageService } from "@cvx/nextpage";
import { ReportsModule } from "../app/modules/home/pages/report/report.module";
import { CreateEditPublishLessonModule } from "./modules/home/pages/create-edit-publish-lesson/create-edit-publish-lesson.module";
import { LessonListModule } from "../app/modules/home/pages/lessons-list/lesson-list.module";
import { MsalGuard } from "@azure/msal-angular";
import { ProjectLookbackModule } from "./modules/home/pages/project-lookback/project-lookback.module";
import { CoordinatorDashboardModule } from "./modules/home/pages/coordinator-dashboard/coordinator-dashboard.module";
import { LessonRoutingGuard } from "./core/services/lesson-routing-guard.service";
import { LessonWorkflowApprovalPathModule } from "./modules/home/pages/lesson-workflow-approval-path/lesson-workflow-approval-path.module";


const reportsModule = () => {
  return ReportsModule;
};
const createEditPublishLessonModule = () => {
  return CreateEditPublishLessonModule;
};
const lessonListModule = () => {
  return LessonListModule;
};
const projectLookbackModule = () => {
  return ProjectLookbackModule;
}

const coordinatorDashboardModule = () => {
  return CoordinatorDashboardModule;
}

const lessonWorkflowApprovalPathModule = () => {
  return LessonWorkflowApprovalPathModule;
}

const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [MsalGuard] },
  { path: "home", component: HomeComponent, canActivate: [MsalGuard] },
  { path: "report", loadChildren: reportsModule },
  {
    path: "createNewLesson",
    loadChildren: createEditPublishLessonModule,
    canActivate: [MsalGuard],
  },
  {
    path: "selectLessonWorkflowApproval",
    loadChildren: lessonWorkflowApprovalPathModule,
    canActivate: [MsalGuard],
  },
  {
    path: "editLesson",
    loadChildren: createEditPublishLessonModule,
    canActivate: [MsalGuard, LessonRoutingGuard],
  },
  {
    path: "viewLesson",
    loadChildren: createEditPublishLessonModule,
    canActivate: [MsalGuard],
  },
  { path: "lessons", loadChildren: lessonListModule, canActivate: [MsalGuard] },
  {
    path: "reportedLessons",
    component: ReportedLessonsListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "projectLookbackView",
    loadChildren: projectLookbackModule,
    canActivate: [MsalGuard]
  },
  {
    path: "coordinatorDashboard",
    loadChildren: coordinatorDashboardModule,
    canActivate: [MsalGuard]
  },
  { path: "**", component: NotfoundComponent, canActivate: [MsalGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private NP: NextPageService) {
    this.NP.options.navigationItems = routes;
  }
}
