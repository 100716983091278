import { Reference } from "..";

export class ReferenceConfigModel {
  core: Reference[];
  extension: ExtensionModel;
}

export class ExtensionModel {
  copWorkflow: Reference[];
  gombuFEWorkflow: Reference[];
  gomWELLSWorkflow: Reference[];
  nobleWorkflow: Reference[];
  abuFEWorkflow: Reference[];
  tcoFEWorkflow: Reference[];
  bbuFEWorkflow: Reference[];
  sasbuFEWorkflow: Reference[];
  rbuFEWorkflow: Reference[];
}
