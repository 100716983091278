import { Component, Inject,ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectLookback, User } from 'src/app/shared/models';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from 'src/app/core/services/toast.service';
import { L } from '@angular/cdk/keycodes';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { ProjectLookbackService } from 'src/app/core/http/project-lookback.service';
import { AutocompleteComponent } from '../../components/autocomplete/autocomplete.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-share-project-lookback',
  templateUrl: './share-project-lookback.component.html',
  styleUrls: ['./share-project-lookback.component.scss']
})
export class ShareProjectLookbackComponent {
  projectCode: string = "";
  adminName: User[];
  participants: User[];
  pendingParticipants: User[] = [];
  participantsToNotify: User[]=[];
  projectLookback: ProjectLookback;
  @ViewChild("toAutocomplete") toAutocomplete: AutocompleteComponent;
  

  constructor(
    public dialogRef: MatDialogRef<ShareProjectLookbackComponent>,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public toastService: ToastService,
    private projectLookbackService: CreateEditProjectLookbackService,
    private projectLookbackApiService: ProjectLookbackService
  ) {}

  ngOnInit() {
    this.projectCode = this.data.code;
    this.getProjectLookbackInfo(this.projectCode);
    this.adminName = this.data.admin;
    this.participants = this.data.participant;
    this.pendingParticipants = this.data.pendingParticipants;
    let adminUniqueKey = this.adminName.map(x => x.uniqueKey);
    this.participants = this.participants.filter(x => {return !adminUniqueKey.includes(x.uniqueKey)});
  }

  copyCode() {
    this.clipboard.copy(this.projectCode);
    this.toastService.showSuccess(
          true,
          "project code has been copied to your clipboard!"
        );
  }

  cancel() {
    this.dialogRef.close();
  }

  getProjectLookbackInfo(projectCode){
    this.projectLookbackApiService.getProjectLookback(projectCode).subscribe(result => {
      this.projectLookback = result.projectLookbacks[0];
      this.pendingParticipants = this.projectLookback.pendingParticipants;
    })
  }

  getUserInfo(user) {
    // update PLB participants
    if(user) {
      let newUser = new User()
      newUser.uniqueKey = user[0].mail;
      newUser.firstName = user[0].givenName;
      newUser.lastName = user[0].surname;
      newUser.email = user[0].mail;
      newUser.fullName = `${newUser.lastName}, ${newUser.firstName}`;

      if (!this.adminName.find(x => x.uniqueKey == newUser.uniqueKey) && !this.participants.find(x => x.uniqueKey == newUser.uniqueKey) && !this.pendingParticipants.find(t => t.uniqueKey == newUser.uniqueKey))
      {
        this.pendingParticipants.push(newUser);
      }
      this.toAutocomplete.clearSelection();
    } 
  }

  deleteUser(user) {
    if(user) {
      let indx = this.adminName.findIndex(x => x.uniqueKey == user.uniqueKey);
      if(indx != -1){
        this.adminName.splice(indx, 1);
        return
      }

      indx = this.pendingParticipants.findIndex(x => x.uniqueKey == user.uniqueKey);
      if(indx != -1){
        this.pendingParticipants.splice(indx, 1);
      }
    } 
  }

   async sendEmail(){
    this.projectLookback.isProjectLookbackEditable = true;
    this.projectLookback.pendingParticipants = [...this.pendingParticipants]; 

    const notifiedParticipants = await firstValueFrom(this.projectLookbackApiService.getProjectLookback(this.projectLookback.projectCode));

    this.participantsToNotify = this.projectLookback.pendingParticipants.filter(u1 => !notifiedParticipants.projectLookbacks[0].pendingParticipants.some(u2 => u2.uniqueKey === u1.uniqueKey));
    this.participantsToNotify = this.participantsToNotify.concat(this.projectLookback.projectAdmins);

    // Ensure no dupes
    this.participantsToNotify = this.participantsToNotify.filter((participant, index, self) =>
      index === self.findIndex(p => p.email === participant.email)
    );
    

    this.projectLookback.participantsToNotify = this.participantsToNotify;

    this.projectLookbackApiService.createUpdateProjectLookback(this.projectLookback).subscribe(result => {
      if(result){
        this.projectLookbackApiService.shareProjectLookback(this.projectLookback.projectCode).subscribe(res => {
          this.toastService.showSuccess(true, 'You have successfully shared the project lookback!');
        });
      }
    })
    this.dialogRef.close();
  }


}
