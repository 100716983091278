import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { LessonService } from "./lesson.service";
import { Lesson, User } from "src/app/shared/models";
import { LessonSearchResponse, SearchFacet, SearchOptions } from "src/app/shared/models/search-options";
import { SearchScopeEnum } from "src/app/config/global-enums.config";
import { BehaviorSubject, Observable } from "rxjs";
import { LessonStatus } from "src/app/shared/models/project-lookback-status";
import { LessonOperationResponse } from "src/app/shared/models/lesson-operation-response";

@Injectable({
  providedIn: "root",
})
export class CurrentUserService {

    constructor(private lessonService: LessonService){}

    public pendingTasks = new BehaviorSubject<number>(0);
    currentTaskStatus = this.pendingTasks.asObservable();

    lessons: Lesson[] = []

    searchLessonsForCurrentUser() {
        let searchOptions = new SearchOptions();
        searchOptions.scope = SearchScopeEnum.AssignedLessons;

        this.lessonService.searchLessonsFromSql(searchOptions).subscribe(res => {
            if(res) { 
                this.updatePendingTasksFromSearch(res);
            }
        });
    }

    updatePendingTasksFromSearch(response: LessonSearchResponse){
        this.lessons = response.lessons.filter(lesson => lesson.status != LessonStatus.publish);
        this.pendingTasks.next(this.lessons.length);
    }  

    updatePendingTasks(response: LessonOperationResponse) {
        if(response && response.success) {
            this.lessons = response.lessons.filter(lesson => lesson.status != LessonStatus.publish);
            this.pendingTasks.next(this.lessons.length);
        }
    }
}
