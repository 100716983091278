import { Component } from "@angular/core";

@Component({
  selector: "app-create-edit-publish-lesson",
  templateUrl: "./create-edit-publish-lesson.component.html",
  styleUrls: ["./create-edit-publish-lesson.component.scss"],
})
export class CreateEditPublishLessonComponent
{

}
