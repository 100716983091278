import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { FieldConfigModel } from "src/app/shared/models/configsV2/fieldConfigModel";
import { Lesson, PackageList, User } from "src/app/shared/models";
import { PackageService } from "src/app/core/services/lesson-services/package.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SqlServerService } from "src/app/core/http/sql-server.service";
import { ToastService } from "src/app/core/services/toast.service";
import { FormControl } from "@angular/forms";
import { SearchOptions } from "src/app/shared/models/search-options"
import { FlawedLesson } from "src/app/shared/models/flawedLesson";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { VerfiyReportedlessonComponent } from "../../dialogs/verfiy-reportedlesson/verfiy-reportedlesson.component";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { SharedModule } from "src/app/shared/shared.module";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-reportedlessons-list",
  templateUrl: "./reportedlessons-list.component.html",
  styleUrls: ["./reportedlessons-list.component.scss"],
  providers: [SharedModule],
})
export class ReportedLessonsListComponent implements OnInit {
  test: number;

  dataSource = new MatTableDataSource();
  packageDataSource = null;
  allLessons: Lesson[] = new Array<Lesson>();
  packageList: PackageList = new PackageList();
  fieldConfigCache: FieldConfigModel;
  scope: any = "reported";
  show: boolean = false;
  page = 1;
  itemsPerPage = 10;
  items = 0;
  pageTitle = "Reported Lessons";
  selectedTabIndex = new FormControl(0);
  tabs = [
    { key: "reported", label: "reported" },
    { key: "resolved", label: "resolved" },
  ];
  managerActedStatus: boolean;
  searchOptions: SearchOptions = new SearchOptions();
  importLogs: any = [];
  allFlawedLessons: FlawedLesson[] = new Array<FlawedLesson>();

  displayedColumns: string[] = [
    "Lesson Id",
    "report type",
    "report comment",
    "reported by",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userProfile: User;
  totalPending: number = 0;
  showManagerCommentsPage: boolean = false;
  constructor(
    public packageService: PackageService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private toastService: ToastService,
    private sqlServerService: SqlServerService,
    public commonService: CommonService,
    public singleLessonService: CreateEditSingleLessonService
  ) {}

  setTabPosition() {
    switch (this.scope) {
      case "reported":
        this.selectedTabIndex.setValue(0);
        break;
      case "resolved":
        this.selectedTabIndex.setValue(1);
        break;
    }
  }
  //To change the scope value based on tab selection
  onTabChanged() {
    this.scope = this.tabs[this.selectedTabIndex.value].key;
    this.itemsPerPage = 10;
    if (this.scope == "reported") {
      this.managerActedStatus = false;
    } else {
      this.managerActedStatus = true;
    }
    this.performSearch(this.managerActedStatus);
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.page = 1;
    this.itemsPerPage = itemsPerPage;
    if (this.scope == "reported") {
      this.managerActedStatus = false;
    } else this.managerActedStatus = true;
    this.performSearch(this.managerActedStatus);
  }

  onPageChange(page: any) {
    this.searchOptions.skip = this.itemsPerPage * (this.page - 1);
    if (this.scope == "reported") {
      this.managerActedStatus = false;
    } else this.managerActedStatus = true;
    this.performSearch(this.managerActedStatus);
  }

  setSearchParameters() {
    this.searchOptions.size = this.itemsPerPage;
    this.searchOptions.skip = this.itemsPerPage * (this.page - 1);
  }

  performSearch(actedStatus: any) {
    this.setSearchParameters();
    this.show = false;
    this.toastService.showRetrievingMessage(true, "Please wait a moment as we get the lessons.");
    if (this.scope == "reported") {
      this.displayedColumns = this.displayedColumns.filter((element) => {
        return element !== "manager Comment";
      });
      if (this.displayedColumns.indexOf("action") === -1) {
        this.displayedColumns.push("action");
      }
    } else if ((this.scope = "resolved")) {
      this.displayedColumns = this.displayedColumns.filter((element) => {
        return element !== "action";
      });
      if (this.displayedColumns.indexOf("manager Comment") === -1) {
        this.displayedColumns.push("manager Comment");
      }
    }
    this.sqlServerService
      .getFlawedLessons(actedStatus, this.searchOptions)
      .subscribe((result) => {
        this.allFlawedLessons = result.items;
        this.dataSource = new MatTableDataSource(this.allFlawedLessons);
        this.items = result.totalCount;
        this.show = true;
        this.snackBar.dismiss();
        this.totalPending = result.totalCount;
      });
  }

  verifyReportedLesson(flawedLesson: FlawedLesson): void {
    const dialogRef = this.dialog.open(VerfiyReportedlessonComponent, {
      width: "550px",
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let actedStatus = true;
        flawedLesson.managerActedStatus = actedStatus;
        flawedLesson.managerActedByUniquekey = this.userProfile.email;
        flawedLesson.managerComments = result.managerComments;
        await this.singleLessonService.reportLesson(flawedLesson);
        this.toastService.showSuccess(true,
          `Successfully resolved ${flawedLesson.lessonId}!`
        );
        this.router.navigateByUrl("reportedLessons");
        this.performSearch(false);
      }
    });
  }

  navigateToAddEditLesson(lessonId = "") {
    if (lessonId == "") {
      const data: any = lessonId
        ? {
            LessonId: lessonId,
          }
        : undefined;

      this.router.navigate(["/createNewLesson"], { state: { data } });
    } else {
      window.open(`/editLesson?id=${lessonId}`, "_blank");
    }
  }

  ngOnInit(): void {
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.searchOptions.size = this.itemsPerPage;
    this.performSearch(false);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
