<app-page-header [pageTitle]="pageTitle"></app-page-header>
<main class="container-fluid">
    <div class="businessUnitSelectionContainer">
        <h3> If you would like a more in-depth look of the metrics of a given business unit - please select one from the dropdown below</h3>
        <div class="selectionDialog">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="selectedWorkflow" placeholder="Select Business Unit"
                    panelClass="mat-select-panel-class" (selectionChange)="workflowSelected($event)">
                    <mat-option *ngFor="let ref of workflows" [matTooltip]="ref.Description" [value]="ref">
                    <span>{{ ref.Description }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-button (click)="clearWorkflow()" *ngIf="selectedWorkflow" class="clearBtn">Clear</button>
        </div>
    </div>
    <mat-divider class="spacerDivider"></mat-divider>
    <h3 *ngIf="!selectedWorkflow"> Total number of lessons per workflow </h3>
    <h3 *ngIf="selectedWorkflow"> Total number of lessons by status </h3>
    <mat-grid-list cols="6" rowHeight="2:1">
        <mat-grid-tile *ngFor="let item of this.lessonCountsKeys">
            <div class="container-fluid gridTile">
                <div class="row"><mat-icon>bar_chart</mat-icon><h6 class="h6Bottom">{{this.lessonCounts[item]}}</h6></div>
                <div class="row"><mat-label class="cellDescription">{{ this.getDescription(item) }}</mat-label></div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-divider class="spacerDivider"></mat-divider>

    <h3> User metrics </h3>

    <mat-grid-list cols="6" rowHeight="2:1">
        <mat-grid-tile *ngFor="let item of this.appInsightsReportKeys">
            <div class="container-fluid gridTile">
                <div class="row"><mat-icon>bar_chart</mat-icon><h6 class="h6Bottom">{{this.appInsightsReport[item]}}</h6></div>
                <div class="row"><mat-label class="cellDescription">{{ this.getLongName(item) }}</mat-label></div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</main>