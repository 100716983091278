import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../../../shared/shared.module";
import { CoreModule } from "../../../../core/core.module";
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { TextInputHighlightModule } from "angular-text-input-highlight";
import { LessonWorkflowApprovalSelectionComponent } from "./lesson-workflow-approval-selection/lesson-workflow-approval-selection.component";


@NgModule({
  imports: [
    SharedModule,
    CoreModule,
    //TextInputHighlightModule,
    RouterModule.forChild([
      { path: "", component: LessonWorkflowApprovalSelectionComponent },
    ]),
    CreateEditSharedModule,
    
  ],
  declarations: [
    LessonWorkflowApprovalSelectionComponent
  ],
})
export class LessonWorkflowApprovalPathModule {}
