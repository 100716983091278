<footer>
  <ul>
    <li *ngIf="lastUpdate !== ''">Last update: {{ lastUpdate }}</li>
    <li>&copy; {{ copyright }} Chevron Corporation</li>
    <li>{{ confidentiality }}</li>
    <li>
      OpCo: <a href="{{ opcoUrl }}">{{ opcoName }}</a>
    </li>
  </ul>
  <ul>
    <li>
      Content Contact:
      <a href="{{ contentContactUrl }}">{{ contentContactName }}</a>
    </li>
    <li>
      Technical Contact:
      <a href="{{ technicalContactUrl }}">{{ technicalContactName }}</a>
    </li>
    <li>
      Product User Guide:
      <a href="{{ productUserGuideUrl }}">{{ productUserGuide }}</a>
    </li>
  </ul>
</footer>
