<div>
  <div class="title-label" *ngIf="isVisible && showlabel">
    <label
      [ngClass]="className"
      style="text-align: left; font-size: 15px; font-weight: bold"
    >
      {{ labelValue }}:
    </label>
    <mat-icon
      *ngIf="toolTipInstruction"
      [matTooltip]="toolTipInstruction"
      style="opacity: 0.5"
      >info</mat-icon
    >
  </div>
  <div class="page-header-text" *ngIf="isEditable && controlType != 'input'">
    <mat-form-field
      [ngClass]="formFieldClass"
      *ngIf="controlType == 'MatSelectMultiple' && !isUser"
      [formGroup]="form"
      appearance="outline"
    >
      <mat-select
        [(value)]="fieldValue"
        class="mat-select-style"
        placeholder="select multiple"
        [(ngModel)]="bindingModel[fieldId]"
        panelClass="mat-select-panel-class"
        multiple
        [id]="fieldId"
        [formControlName]="fieldId"
        (selectionChange)="valueSelected($event)"
      >
        <mat-option
          *ngFor="
            let ref of commonService.getFieldSelectValues(
              fieldMetadata,
              fieldDictatingForCrossReferenceConfig,
              groupName,
              fieldList
            )
          "
          [matTooltip]="ref.Description"
          [disabled]="isRemoveCurrentSelection && ref.Code === fieldValue"
          [value]="ref.Code"
        >
          <span>{{ ref.Description }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--this section is for multiple User selection-->
    <mat-form-field
      *ngIf="controlType == 'MatSelectSingle' && !isUser && isVisible"
      [formGroup]="form"
      appearance="outline"
    >
      <mat-select
        [(value)]="fieldValue"
        class="mat-select-style"
        placeholder="select one"
        [(ngModel)]="bindingModel[fieldId]"
        panelClass="mat-select-panel-class"
        [id]="fieldId"
        [formControlName]="fieldId"
        (selectionChange)="valueSelected($event)"
      >
        <mat-option [value]="" [matTooltip]="">
          <span></span>
        </mat-option>
        <mat-option
          *ngFor="
            let ref of commonService.getFieldSelectValues(
              fieldMetadata,
              fieldDictatingForCrossReferenceConfig,
              groupName,
              fieldList
            )
          "
          [matTooltip]="ref.Description"
          [disabled]="isRemoveCurrentSelection && ref.Code == currentSelection"
          [value]="ref.Code"
        >
          <span>{{ ref.Description }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      [ngClass]="formFieldClass"
      *ngIf="controlType == 'MatSelectMultiple' && isUser"
      [formGroup]="form"
      appearance="outline"
    >
      <mat-select
        [(value)]="fieldValue"
        [compareWith]="compareUsers"
        class="mat-select-style"
        placeholder="select multiple"
        [(ngModel)]="bindingModel[fieldId]"
        panelClass="mat-select-panel-class"
        multiple
        [id]="fieldId"
        [formControlName]="fieldId"
        (selectionChange)="valueSelected($event)"
      >
        <mat-option
          *ngFor="
            let user of commonService.allReferenceConfigGraphGroupsCache[
              approverGroup
            ]
          "
          [disabled]="isRemoveCurrentSelection && user.FieldName === fieldValue"
          [value]="user"
        >
          <span>{{ user?.fullName }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--this section is for multiple User selection-->

    <!--this section is for single User selection-->
    <mat-form-field
      *ngIf="controlType == 'MatSelectSingle' && isUser"
      [formGroup]="form"
      appearance="outline"
    >
      <mat-select
        [(value)]="fieldValue"
        [compareWith]="compareUsers"
        class="mat-select-style"
        placeholder="select one"
        [(ngModel)]="bindingModel[fieldId]"
        panelClass="mat-select-panel-class"
        [id]="fieldId"
        [formControlName]="fieldId"
        (selectionChange)="valueSelected($event)"
      >
        <mat-option [value]="" [matTooltip]="">
          <span></span>
        </mat-option>
        <mat-option
          *ngFor="
            let user of commonService.allReferenceConfigGraphGroupsCache[
              approverGroup
            ]
          "
          [disabled]="isRemoveCurrentSelection && user.FieldName === fieldValue"
          [value]="user"
        >
          <span>{{ user?.fullName }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--this section is for single User selection-->
  </div>
  <div
    class="page-header-text"
    [formGroup]="form"
    *ngIf="isEditable && controlType == 'Input' && isVisible"
  >
    <input
      class="bulkInputTitle inputcheckbox"
      [(ngModel)]="bindingModel[fieldId]"
      [formControlName]="fieldId"
      (change)="valueSelected($event)"
    />
  </div>
  <div
    class="page-header-text"
    [formGroup]="form"
    *ngIf="isEditable && controlType == 'InputNumber'"
  >
    <input
      numbersOnly
      class="bulkInputTitle inputcheckbox"
      [(ngModel)]="bindingModel[fieldId]"
      [formControlName]="fieldId"
      (change)="valueSelected($event)"
    />
  </div>
  <div
    mwlTextInputHighlightContainer
    class="page-header-text"
    [formGroup]="form"
    *ngIf="isEditable && controlType == 'TextBox' && isVisible"
  >
    <mat-form-field style="width: 90% !important">
      <textarea
        class="textarea"
        [style.overflow]="tags?.length ? 'hidden' : null"
        mwlTextInputElement
        matInput
        [(ngModel)]="bindingModel[fieldId]"
        [formControlName]="fieldId"
        (change)="valueSelected($event)"
        #textarea
      >
      </textarea>
      <!-- <mwl-text-input-highlight [tags]="tags" [textInputElement]="textarea">
      </mwl-text-input-highlight> -->
    </mat-form-field>
  </div>
  <div
    class="page-header-text"
    [formGroup]="form"
    *ngIf="isEditable && controlType == 'MatSlideToggle' && isVisible"
  >
    <mat-slide-toggle
      color="primary"
      [(ngModel)]="bindingModel[fieldId]"
      [formControlName]="fieldId"
      (change)="valueSelected($event)"
    >
    </mat-slide-toggle>
  </div>
  <div
    class="page-header-text"
    *ngIf="controlType == 'create-new-lesson-LessonAttachment'"
  >
    <app-create-new-lesson-attachment
      color="primary"
      [isManager]="singleLessonService.lesson?.managers?.uniqueKey"
      [isParticipant]="singleLessonService.lesson.isParticipantOfLesson"
      [isLessonEditable]="singleLessonService.lesson.isLessonEditable"
      [isLessonEnterprise]="singleLessonService.lesson.isEnterprise"
    >
    </app-create-new-lesson-attachment>
  </div>
  <div
    class="page-header-text"
    *ngIf="isEditable && controlType == 'comment.component'"
  >
    <app-comment
      color="primary"
      [comments]="singleLessonService.lesson.lessonComments"
      [lessonId]="singleLessonService.lesson.id"
      [isLessonEditable]="singleLessonService.lesson.isLessonEditable"
      [isBaseComment]="true"
      (newLessonComments)="singleLessonService.commentChangedHandler($event)"
    >
    </app-comment>
  </div>
  <div
    class="page-header-text"
    *ngIf="isEditable && controlType == 'MatDatePicker'"
  >
    <mat-form-field>
      <mat-label>Pick a date</mat-label>
      <input
        matInput
        class="dateInput"
        [matDatepicker]="picker"
        [formControlName]="fieldId"
        [(ngModel)]="bindingModel[fieldId]"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="year"
        [startAt]="singleLessonService.startDate"
      >
      </mat-datepicker>
    </mat-form-field>
  </div>
  <div class="page-header-text" *ngIf="controlType == 'MatAutoComplete'">
    <div *ngIf="isEditable" [formGroup]="form">
      <input
        class="inputcheckbox"
        type="text"
        placeholder="type cai, name or email..."
        aria-label="Number"
        (input)="commonService.getUsersFromDirectory($event.target.value)"
        [matAutocomplete]="auto"
        field="fullName"
        [formControlName]="fieldId"
        [(ngModel)]="fieldValue.fullName"
      />
      <mat-spinner
        *ngIf="commonService.showPOCLoading"
        matSuffix
        [diameter]="18"
        style="float: right; margin-left: 8px"
      ></mat-spinner>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="valueSelected($event)"
      >
        <mat-option
          *ngFor="let itm of commonService.userAsyncList | async"
          [id]="itm.uniqueKey"
          [value]="itm"
        >
          {{ itm.fullName }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <!--consult additional SME button-->
  <div
    class="page-header-text"
    *ngIf="isEditable && controlType == 'consult-SME'"
  >
    <span
      class="color-label status SMELabel"
      (click)="singleLessonService.ConsultAdditionalSME(referenceConfigGraph)"
    >
      + SME
    </span>
  </div>

  <!-- not editable fields --> 
   <!-- Such as status etc. -->
  <div class="readonlyField title-label-gray" *ngIf="!isEditable">
    <p
      *ngIf="
        (controlType == 'MatSelectSingle' ||
          controlType == 'Input' ||
          controlType == 'MatAutoComplete') &&
        isUser &&
        isVisible
      "
    >
      {{ fieldValue?.fullName ? fieldValue?.fullName : fieldValue?.uniqueKey }}
    </p>
    <p *ngIf="controlType == 'MatSelectMultiple' && isUser">
      {{ singleLessonService.getAllValuesFromArrayUsers(fieldValue) }}
    </p>
    <p *ngIf="controlType == 'MatSelectSingle' && !isUser && isVisible">
      {{ fieldValue | ConvertCodeToDescription : fieldList }}
    </p>
    <p *ngIf="controlType == 'MatSelectMultiple' && !isUser">
      {{ fieldValue | ConvertCodeToDescription : fieldList }}
    </p>
    <p
      *ngIf="
        (controlType == 'Input' ||
          controlType == 'TextBox' ||
          controlType == 'InputNumber' ||
          controlType == 'MatSlideToggle') &&
        !isUser &&
        isVisible
      "
    >
      {{ fieldValue | ConvertCodeToDescription : fieldList }}
    </p>
    <p *ngIf="controlType == 'MatDatePicker'">
      {{ fieldValue | date : "MM/dd/yyyy" }}
    </p>
  </div>
  <!-- legacydata dialog -->
  <div
    class="page-header-text"
    *ngIf="
      !isEditable &&
      controlType == 'legacy-data' &&
      singleLessonService.lesson.legacySor != null
    "
  >
    <span class="color-label status legacyDataText" (click)="openLegacyDialog()">
      Show Legacy Data
    </span>
  </div>
</div>
