<div class="row">
  <div class="col-10">
    <div class="row home-input-group d-flex searchBar">
      <input
        type="search"
        (keydown.enter)="navigateToLessonListViewSearch(scope, searchText)"
        [(ngModel)]="searchText"
        id="form1"
        class="form-control"
        placeholder="I'm looking for...."
      />
      <img class="searchIcon" src="assets/images/search.png" alt="" (click)="navigateToLessonListViewSearch(scope, searchText)"/>
    </div>
    <div class="row homeCard">
      <div class="row d-flex justify-content-around align-items-center homeTitleDiv">
        <h2 class="homeTitle">
          <span>Hello {{ this.currentFullUserProfile.name.split(',')[1].trim() }}!</span>
          <div class="reviewText">
            <p>You have {{ pendingTasks }} pending actions.<p>
            <p *ngIf="pendingTasks < 0" class="actionText" (click)="navigateToLessons()">Let's get started by looking at some lessons!</p>
            <p *ngIf="pendingTasks > 0"class="actionText" (click)="navigateToActions()">Go to your actions</p>
          </div>
        </h2>
      </div>
    </div>

    <div class="row shortcutCard">
      <h5 class="shortcutTitle">
      Shortcuts
      </h5>
      <div class="shortcutLinks">
        <div class="col-1">
          <h5 (click)="openTraining()"> 
            <img
            class="home_icons"
            src="assets/icons/Icon - Learning.png"
            alt=""
          />Training
        </h5>
        </div>
        <div class="col-2">
          <h5 (click)="openFeedback()"> 
            <img
            class="home_icons"
            src="assets/icons/feedback_icon.png"
            alt=""
          />
          Feedback</h5>
        </div>
        <div class="col-3">
          <h5 (click)="navigateToMetrics()">           
            <img
            class="home_icons"
            src="assets/icons/metrics_icon.png"
            alt=""
          />
          View metrics</h5>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-around searchCardDiv">
      <mat-card appearance="outlined" class="searchCard" (click)="navigateToLessonWorkflowSelection()">
        <img class="icon" src="assets/icons/Icon - Document.png" alt="" />
        <mat-card-content class="createbutton"
          ><h4
            type="button"
          >
            create new lesson
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card
        appearance="outlined"
        class="searchCard"
        (click)="navigateToLessonListView()"
      >
        <img
          class="icon"
          src="assets/icons/Icon - Search.png"
          alt=""
        />
        <mat-card-content class="createbutton"
          ><h4 type="button" id="createNewLesson">
            search published lessons
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card appearance="outlined" class="searchCard" (click)="importLessons()">
        <img class="icon" src="assets/icons/Icon - Bulk Import.png" alt="" />
        <mat-card-content class="createbutton"
          ><h4 type="button" id="importBulkLessons">
            import lessons in bulk
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card
        class="searchCard"
        appearance="outlined"
        type="button"
        (click)="navigateToProjectLookbackView()"
      >
        <img
          class="icon"
          src="assets/images/cvx_lessons-learneds_icon_RGB_FA.png"
          alt=""
        />
        <mat-card-content class="createbutton">
          <h4 id="projectLookbackView">
            create or join a project lookback
          </h4></mat-card-content
        >
      </mat-card>
    </div>
  </div>
  <div class="col-2 top5LessonsCard" >
    <div class="lessonsContainer">
      <div class="topLessonsTitle">
        <h1>
          Top Lessons Learned
        </h1>
      </div>
      <h6 class="viewAllLessonsText" (click)="navigateToLessonListView()"> view all lessons ></h6>
    </div>
    <div class="lessonDetails">
      <div style="padding-left: 40%" *ngIf="!show">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="show" class="toplessons">
        <app-lesson-details
        class="col-12 px-0 py-0"
        [allLessons]="topFiveLessons"
        [lessonsSource]="currentLessonsSource"
        [showCards]="showCards"
        [referenceCacheData]="commonService.referenceConfig"
        [fieldConfigCore]="fieldConfigCore"
        [selectedTabIndex]="selectedTabIndex.value"
      >
      </app-lesson-details>
      </div>
    </div>
</div>

