import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { UserTeam } from "src/app/shared/models/user-team";
import { RelatedProcess } from "src/app/shared/models/related-process";
import { UserRelatedProcessBusinessUnit } from "src/app/shared/models/user-trigger-business-unit";

@Injectable({
  providedIn: "root",
})
export class UserRelatedProcessBusinessUnitService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}UserRelatedProcessBusinessUnit`;

    public getAllUsersByRelatedProcessId(relatedProcessId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByRelatedProcessId/${relatedProcessId}`);
    }

    public getAllUsersByBuId(buId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
    }

    public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
    }
    
    public getAllRelatedProcessApprovers(userId: number): Observable<RelatedProcess[]> {
      return this.http.get<RelatedProcess[]>(`${this.apiRoot}/getAllRelatedProcessApprovers/${userId}`);
    }

    public getAllRelatedProcessApproversByUniqueKey(uniqueKey: string): Observable<RelatedProcess[]> {
      return this.http.get<RelatedProcess[]>(`${this.apiRoot}/getAllRelatedProcessApproversByUniqueKey/${uniqueKey}`);
    }

    // Deprecated
    // public createUserRelatedProcessBusinessUnit(userId: number, relatedProcessId: number, businessUnitId: number): Observable<UserRelatedProcessBusinessUnit>{
    //   const endPoint = `${this.apiRoot}/createUserRelatedProcessBusinessUnit`
    //   const body = {userId, relatedProcessId, businessUnitId};
    //   return this.http.post<UserRelatedProcessBusinessUnit>(endPoint, body);
    // }

    public createUserRelatedProcessBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserRelatedProcessBusinessUnit>{
      const endPoint = `${this.apiRoot}/createUserRelatedProcessBusinessUnitV2`
      const body = {triggerCode, uniqueKey, businessUnitCode};
      return this.http.post<UserRelatedProcessBusinessUnit>(endPoint, body);
    }

    public deleteUserRelatedProcessBusinessUnit(uniqueKey: string, relatedProcess: string, businessUnit: string){
      const endPoint = `${this.apiRoot}/deleteUserRelatedProcessBusinessUnit/uniqueKey=${uniqueKey}/relatedProcess=${relatedProcess}/businessUnit=${businessUnit}`
      return this.http.delete(endPoint);
    }






}