import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProjectLookbackService } from 'src/app/core/http/project-lookback.service';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {
  Inject,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { User } from "src/app/shared/models";
import { ProjectLookback } from 'src/app/shared/models/project-lookback';
import { UserService } from "src/app/core/http/user.service";
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast.service';
import { LessonStatus, ProjectLookbackStatus } from 'src/app/shared/models/project-lookback-status';
import { DialogMessageComponent } from '../../../dialogs/dialog-message/dialog-message.component';
import { firstValueFrom } from 'rxjs';
import { ShareProjectLookbackComponent } from '../../../dialogs/share-project-lookback/share-project-lookback.component';
import { ProjectLookbackCommonService } from 'src/app/core/services/project-lookback-services/project-lookback-common.service';
@Component({
  selector: 'app-project-lookback-card',
  templateUrl: './project-lookback-card.component.html',
  styleUrls: ['./project-lookback-card.component.scss']
})
export class ProjectLookbackCardComponent implements OnInit{
  userProfile: User;
  public projectCode: string;
  showDashboard: boolean = false;

  guideLink = "https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/104151/Project-Lookback-Walkthrough";
  
  tabs = Object.values(ProjectLookbackStatus).map((status, index) => ({
      key: index,
      label: status,
  }));


  lessonStatus: Object;


  isAdmin: boolean = false;
  
    constructor(
      public projectLookbackService: CreateEditProjectLookbackService,
      public projectLookbackApiService: ProjectLookbackService,
      public projectLookbackCommonService: ProjectLookbackCommonService,
      public userService: UserService,
      public router: Router,
      private toastService: ToastService,
      public dialog: MatDialog
    ){}

    ngOnInit(): void {
      this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
      this.isAdmin = this.projectLookbackCommonService.getAdminStatus(this.userProfile);
      this.projectOverview();
      this.showDashboard = true;
    }

    projectOverview() {
      this.projectLookbackService.currentVisibleProjectLookbacks.forEach(plb => {
        if(!plb["lessons"]){
          plb.lessons = []
        }

        let lesson = [...plb["lessons"]];
      
        let temp = Object.values(LessonStatus).map((s) => ({
            key: s,
            count: lesson.filter(x =>x.status.toString() == s.toString()).length,
        }));
        plb.lessonStatuses =  temp;
      })
      

    }

    openPlbGuide(){
      window.open(this.guideLink,'_blank');
    }
  

    goToShare(plb: ProjectLookback) {
      const dialogRef = this.dialog.open(ShareProjectLookbackComponent, {
        width: "40%",
        position: {},
        autoFocus: false,
        minHeight: "40vh", // you can adjust the value as per your view,
        data: {
          code: plb.projectCode,
          admin: plb.projectAdmins,
          participant: plb.participants,
          pendingParticipants: plb.pendingParticipants
        },
      });
    }

    delete(plb: ProjectLookback) {
      const projectLookbackIdsToDelete: Array<string> = [];
      projectLookbackIdsToDelete.push(plb.projectId);
      const body = 'Are you sure you want to delete this Project Lookback?';
      const dialogRef = this.dialog.open(DialogMessageComponent, {
        panelClass:"dialog",
        width: "600px",
        data: { title: "delete project lookback?", body },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          try{
            this.projectLookbackService.deleteProjectLookback(projectLookbackIdsToDelete);
            this.toastService.showSuccess(true, 'Successfully deleted the project lookback!');
          
          }
          catch(error){
            this.toastService.showError(true, "Uh-oh! Looks like there was an issue when trying to delete the project lookback. Please reach out to the support team for further assistance.");
          }
        }
      });
      }

    getUserInput(inputValue: string) {
      this.userProfile.projectCodes = this.userProfile.projectCodes || [];
      this.projectLookbackApiService.getProjectLookback(inputValue).subscribe(result => {
        if(result.projectLookbacks[0] != null){
          this.userProfile.projectCodes.push(inputValue);
          this.userService.updateProjectLookbackUser(this.userProfile).subscribe(result => {
            sessionStorage.setItem("profile-ell", JSON.stringify(this.userProfile));
          });

          this.toastService.showSuccess(
            true, "You have successfully joined the project lookback!"
          );
          
          
          //this.projectLookbackService.participants = result.projectLookbacks[0].participants.concat(this.userProfile);
          if(!result.projectLookbacks[0].participants.some(user => user.uniqueKey === this.userProfile.uniqueKey)){
            result.projectLookbacks[0].participants.push(this.userProfile);
          }
          result.projectLookbacks[0].isProjectLookbackEditable = true;
          result.projectLookbacks[0].userCount = result.projectLookbacks[0].participants.length;
          result.projectLookbacks[0].pendingParticipants = result.projectLookbacks[0].pendingParticipants.filter(user => user.uniqueKey !== this.userProfile.uniqueKey);
          this.projectLookbackApiService.createUpdateProjectLookback(result.projectLookbacks[0]).subscribe(result => {
          });

          this.refreshComponent();

          this.projectCode = '';

        }
        else{
          this.toastService.showError(
            true, "Uh-oh! Looks like we were unable to find a valid project lookback with that code. Please reach out to your project admin for verification."
          );
          this.projectCode = '';
        }
      });
    }

    async refreshComponent() {
      await this.projectLookbackService.getProjectLookbacksForCurrentUser();    
    }

    openProjectLookbackDetail(plb: ProjectLookback) {
      this.projectLookbackService.projectLookback = plb;
      this.projectLookbackService.NavigateToLessonReview();
    }

}
