import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ErrorDialogService } from "src/app/shared/components/error-dialog/error-dialog.service";
import { ToastService } from "../services/toast.service";
import { clear } from "console";

@Injectable()
export class LessonErrorHandler implements ErrorHandler {
    private urlsToIgnore: string[] = [ "hash_empty_error", "/api/User/loggedUser", "/oath2/v2.0/token", "no_tokens_found", "monitor_window_timeout" ];

    constructor(
        private errorDialogService: ErrorDialogService,
        private zone: NgZone,
        private toastService: ToastService
    ) { }

    //temporarily leave spaghetti cases for debugging

    handleError(err: any): void {
      console.log('error verbosity: ', err);
      console.log('error verbosity msg: ', err.message);
      if (this.urlsToIgnore.some(url => err.message.includes(url))) {
        localStorage.clear();
        sessionStorage.clear();
        console.log('cleared cache... reloading...')
        window.location.reload();
    }
    }
}
