import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LessonsListPageComponent } from "./lessons-list.component";
import { SharedModule } from "../../../../shared/shared.module";
import { CoreModule } from "../../../../core/core.module";
import { BulkEditLessonComponent } from "../../dialogs/bulk-edit-lesson/bulk-edit-lesson.component";
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { ColumnSettingComponent } from "../../dialogs/column-setting/column-setting.component";
import { DynamicComponentComponent } from "../../components/dynamic-component/dynamic-component.component";
import { LessonsTableCardComponent } from "./lessons-table-card/lessons-table-card.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { SearchTutorialComponent } from "../../dialogs/search-tutorial/search-tutorial.component";

@NgModule({
  declarations: [
    LessonsListPageComponent,
    BulkEditLessonComponent,
    ColumnSettingComponent,
    //DynamicComponentComponent,
    LessonsTableCardComponent,
    SearchTutorialComponent,
  ],
  imports: [
    RouterModule.forChild([{ path: "", component: LessonsListPageComponent }]),
    SharedModule,
    CoreModule,
    CreateEditSharedModule,
    OverlayModule,
  ],
})
export class LessonListModule {}
