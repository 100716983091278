import { Injectable } from "@angular/core";
import { FieldConfigStatusValues } from "src/app/config/global-enums.config";
import { Lesson, User } from "src/app/shared/models/";
import { ExportResultsEventModel } from "src/app/shared/models/app-insights";
import { appInfo, environment } from "src/environments/environment";
import { ApplicationInsightsService } from "../../http/application-insights.service";
import { CommonService } from "./common.service";
import { ToastService } from "../toast.service";

@Injectable({
  providedIn: "root",
})
export class ExportCsvService {
  constructor(
    private appInsights: ApplicationInsightsService,
    private toastSerivce: ToastService,
    private commonService: CommonService
  ) {}

  public exportToCsv(filename: string, items: Lesson[], fieldConfigCore: any) {
    if (!Array.isArray(items) || !items.length) {
      // array does not exist, is not an array, or is empty
      // ⇒ do not attempt to process array
      this.toastSerivce.showError(true,
        "There are currently no lessons in the list - Please add lessons to the list before proceeding."
      );
      return;
    }

    this.commonService.loadConfig();
    let cleanLessons = this.cleanLesson(items, fieldConfigCore);
    const replacer = (key, value) => (value === null || value === undefined ? "N/A" : value); // Handling nulls
    const header = this.getAllowedExportFields(items);
    const titleHeader: string[] = [];
    header.forEach((x) =>
      titleHeader.push(this.fieldLabel(x, fieldConfigCore))
    );

    const csv = cleanLessons.map((row) =>
      header
        .map((fieldName) => {
          if (fieldName === "linkToLesson")
            return `${environment.homeUrl}/editLesson?id=${row.id}`;
          else return JSON.stringify(row[fieldName], replacer);
        })
        .join(",")
    );
    csv.unshift(titleHeader.join(","));
    csv.unshift(
      `Lessons Learned are Company Confidential. Please do not share externally without prior written approval. Content Contact: ${appInfo.contentContactName}. \n`
    );
    csv.unshift(
      `DISCLAIMER: Please double-click and/or expand the header cells in order to format things in a more readable manner.`
    )
    // csv.unshift("");
    const csvArray = csv.join("\r\n");

    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

    this.logAppInsightsExportActions(cleanLessons);
  }

  logAppInsightsExportActions(lessons: Lesson[]) {
    let userProfile: User = JSON.parse(sessionStorage.getItem("profile-ell"));
    let exportResultModel = new ExportResultsEventModel();
    exportResultModel.lessonIds = lessons.map((l) => l.id).toString();
    exportResultModel.userFullName = userProfile.fullName;
    exportResultModel.userUniqueKey = userProfile.uniqueKey;
    this.appInsights.createExportResultEvent(exportResultModel).subscribe();
  }

  getAllowedExportFields(items: Lesson[]): string[] {
    let allowedExportFields: string[] = [];
    items.forEach((item) => {
      //To get exportable fields
      let statusList =
        this.commonService.fromStatusFieldConfig.fromStatusFieldConfig
          .extension[item.lessonWorkflowType];
      
      let visibleFields = this.commonService
        .getFieldList(
          statusList,
          FieldConfigStatusValues.exportable.toString(),
          item
        )
        .find((x) => x.key === item.status).value;

      allowedExportFields = [
        ...new Set([...allowedExportFields.concat(visibleFields)]),
      ];

    });

    //Attachments and comments are behaving weirdly after exporting, So removing both of them.
    allowedExportFields = allowedExportFields.filter((field) => {
      if (!(field == "lessonAttachments" || field == "lessonComments"))
        return field;
    });
    //Add link to a lesson
    allowedExportFields.push("linkToLesson");

    return allowedExportFields;
  }

  public cleanLesson(lessons: any[], fieldConfigCore: any): Lesson[] {
    let data = JSON.parse(JSON.stringify(lessons));
    data.forEach((item) => {
      let tempLesson: Lesson = JSON.parse(JSON.stringify(item));
      //For exporting user Fields flattened the tempLessons array
      Object.assign(tempLesson, tempLesson[tempLesson.lessonWorkflowType]);
      for (const property in tempLesson) {
        //For exporting single Select Fields
        if (
          fieldConfigCore.find((f) => f.key == property)?.value?.feMetadata
            .controlType == "MatSelectSingle"
        )
          item[property] = this.validateData(
            tempLesson[property],
            this.commonService.referenceConfig.core[
              fieldConfigCore.find((f) => f.key == property)?.value?.feMetadata
                .referenceConfigEnum
            ]
          );
        //For exporting multi Select Fields
        else if (
          fieldConfigCore.find((f) => f.key == property)?.value?.feMetadata
            .controlType == "MatSelectMultiple"
        )
          item[property] = this.validateArrayData(
            tempLesson[property],
            this.commonService.referenceConfig.core[
              fieldConfigCore.find((f) => f.key == property)?.value?.feMetadata
                .referenceConfigEnum
            ]
          ).join();
        //For exporting user Fields
        else if (
          fieldConfigCore.find((f) => f.key == property)?.value?.feMetadata
            .dataType == "User"
        )
          item[property] = this.validateEmail(tempLesson[property]);
      }
      ///This needs to go inside MatSelectSingle field but right now has a mismatch in fieldConfig
      item.status = this.validateData(
        tempLesson.status,
        this.commonService.referenceConfig.core["Status"]
      );

      // Attachment
      let attachments = [];
      tempLesson.lessonAttachments.forEach((x) => {
        attachments.push(x.uri);
      });
      item.attachments = attachments.join();

      // Comments
      let comments = [];
      tempLesson.lessonComments.forEach((x) => {
        comments.push(x);
      });
      item.comments = comments.join();
    });
    return data;
  }

  fieldLabel(field: string, fieldConfigCore: any): string {
    return fieldConfigCore.find((fieldid) => fieldid.key == field)?.value
      ?.feMetadata.formLabel;
  }

  private validateData(p: any, q: any) {
    if (q)
      return p
        ? q?.find((y: any) => y?.Code.toLowerCase() === p.toLowerCase())
            ?.Description
        : "";
  }

  private validateArrayData(p: any, q: any) {
    p.forEach((x, index) => {
      p[index] = q?.find(
        (y) => y.Code.toLowerCase() === x.toLowerCase()
      )?.Description;
    });
    return p;
  }

  private validateEmail(p: any) {
    return p ? p.email : "";
  }
}
