<div *ngIf="showCards">
  <div *ngFor="let lesson of allLessons; let i = index">
    <mat-card>
      <mat-card-content class="lessonDetails">
        <div class="d-flex flex-row lessonBorder">
          <div class="color-rec col-1">&nbsp;</div>
          <div
            class="lessonDetailContainer"
            style="cursor: pointer"
            (click)="goToEditLesson(lesson)"
          >
            <div
              class="d-flex flex-row top-box pl-4 align-items-center pt-2 pb-2" style="position: relative;"
            >
              <div class="lessonTitle">
                <h3 class="single-line-ellipsis" [innerHTML]="lesson?.title">
                </h3>
              </div>
              <div class="lessonlabelContainer">
                <mat-label class="noDiscLabel" *ngIf="!lesson.discipline && lesson.status !== 'publish'"> needs review (no discipline) </mat-label>
                <mat-label class="workflowLabel" *ngIf="lesson.lessonWorkflowType != null"> {{ convertLessonWorkflow(lesson?.lessonWorkflowType) }} </mat-label>
                <mat-label class="projectCodeLabel" *ngIf="lesson.projectCodes?.[0] !== null && lesson.projectCodes.length > 0"> associated project lookback code: {{ lesson.projectCodes[0] }}</mat-label>
                <mat-label class="statusLabel" [ngStyle]="{ color: getStatusTextColor(lesson.status), 'background-color': getStatusBackgroundColor(lesson.status)}"> {{ displayStatus(lesson.status) }}</mat-label>
              </div>
            </div>
            <div
              class="d-flex flex-row justify-content-start w-100 bottom-box pl-4 pt-2 pb-2"
              style="gap: 5%"
              *ngIf="disciplines && disciplines.length > 0 && showDisc"
            >
              <div class="businessUnit info">
                <h5 class="single-line-ellipsis">business unit</h5>
                <p class="single-line-ellipsis">{{ lesson?.businessUnit ?? 'no business unit assigned'  }}</p>
              </div>
              <div class="discipline info">
                <h5 class="single-line-ellipsis">discipline</h5>
                <p class="single-line-ellipsis">{{ convertDisciplineCode(lesson?.discipline ?? 'no discipline assigned')  }}</p>
              </div>
              <div class="description info">
                <h5 class="single-line-ellipsis">description</h5>
                <p class="single-line-ellipsis" [innerHTML]="lesson?.description || 'no description given'"></p>
              </div>
              <div class="potentialCauseSummary info">
                <h5 class="single-line-ellipsis">potential cause</h5>
                <p class="single-line-ellipsis">{{ lesson?.potentialCause ?? 'no potential cause given'}}</p>
              </div>
              <div class="recommendation info">
                <h5 class="single-line-ellipsis">recommendation</h5>
                <p class="single-line-ellipsis">{{ lesson?.recommendation ?? 'no recommendation given'}}</p>
              </div>
              <div class="pointOfContact info">
                <h5 class="single-line-ellipsis">point of contact</h5>
                <p class="single-line-ellipsis">{{ lesson?.pointOfContact?.fullName ?? 'no point of contact assigned'  }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
