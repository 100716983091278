<div class="container-fluid p-4">
  <h2>remove {{ convertToDesc(this.data.role) }}</h2>
  <div class="managers" *ngIf="this.data.managers && this.data.managers.length > 0">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedManager" [(value)]="selectedManager" name="manager" placeholder="Please select one">
        <mat-option
          *ngFor="let manager of this.data.managers"
          [value]="manager"
          >{{ manager?.fullName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="legalApprovers" *ngIf="this.data.legalApprovers && this.data.legalApprovers.length > 0">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedLegalApprover" [(value)]="selectedLegalApprover" name="legalApr" placeholder="Please select one">
        <mat-option
          *ngFor="let legalApr of this.data.legalApprovers"
          [value]="legalApr"
          >{{ legalApr?.fullName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="ecApprovers" *ngIf="this.data.ecApprovers && this.data.ecApprovers.length > 0">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedEcApprover" 
      [(value)]="selectedEcApprover" 
      name="ecApr" 
      placeholder="Please select one" 
      (selectionChange)="onTechnicalApproverChange(selectedEcApprover, this.data.triggerType)">
        <mat-option
          *ngFor="let ecApr of this.data.ecApprovers"
          [value]="ecApr"
          >{{ ecApr?.fullName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="triggerValues">
    <mat-form-field appearance="outline" *ngIf="selectedEcApprover">
      <mat-select [(ngModel)]="selectedTriggerValue" [(value)]="selectedTriggerValue" name="trigger" placeholder="Please select one">
        <mat-option
          *ngFor="let selectedTriggerValue of triggerValues"
          [value]="selectedTriggerValue"
          >{{ selectedTriggerValue?.description }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="technicalApprovers" *ngIf="this.data.technicalApprovers && this.data.technicalApprovers.length > 0">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedTechnicalApprover" 
      [(value)]="selectedTechnicalApprover" 
      name="technicalApprover" 
      placeholder="Please select one"
      (selectionChange)="onTechnicalApproverChange(selectedTechnicalApprover, this.data.triggerType)" >
        <mat-option
          *ngFor="let technicalApprover of this.data.technicalApprovers"
          [value]="technicalApprover"
          >{{ technicalApprover?.fullName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="triggerValues">
    <mat-form-field appearance="outline" *ngIf="selectedTechnicalApprover">
      <mat-select [(ngModel)]="selectedTriggerValue" [(value)]="selectedTriggerValue" name="trigger" placeholder="Please select one">
        <mat-option
          *ngFor="let selectedTriggerValue of triggerValues"
          [value]="selectedTriggerValue"
          >{{ selectedTriggerValue?.description }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex flex-row justify-content-end mt-4" style="gap: 1rem">
    <button
      type="button"
      mat-button
      class="btn"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <button class="btn btn-primary" id="submitButton" (click)="submit()">
      submit
    </button>
  </div>
</div>
