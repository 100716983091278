import { Injectable } from "@angular/core";
import { Lesson } from "src/app/shared/models";
import {
  FieldConditionModel,
  FieldsModel,
} from "src/app/shared/models/configsV2/workflowConfigModel";
import { ProjectLookback } from "src/app/shared/models/project-lookback";

@Injectable({
  providedIn: "root",
})
export class SetConfigService {
  fieldConditionCheck(f: FieldsModel): boolean {
    let actionResult = true;
    if (f.Condition == null) {
      return true;
    }
    f.Condition.forEach((c) => {
      actionResult = this.callDynamicFunction(c);
      if (!actionResult) {
        return actionResult;
      }
    });
  }

  public callDynamicFunction(c: FieldConditionModel): boolean {
    if (c.Param) {
      return this[c.MethodName](c.Param);
    } else {
      return this[c.MethodName]();
    }
  }

  public isNotNull(fValue: any): boolean {
    //determine if field is part of core vs extension
    //this.commonService.isExtensionProperty() call is ExtensionProperty but there is circular dependency
    if (Array.isArray(fValue)) fValue = fValue.length > 0 ? true : false;
    else fValue = !fValue ? false : true;

    return fValue;
  }

  public isParticipantOfLesson(lesson: Lesson): boolean {
    return lesson.isParticipantOfLesson;
  }

  public isParticipantOfProjectLookback(projectLookback: ProjectLookback): boolean {
    return projectLookback.isParticipantOfProjectLookback;
  }



  certainRoles(roleName: string): boolean {
    const role = sessionStorage.getItem("roles");

    if (role.indexOf(roleName) > -1) {
      return true;
    }
    return false;
  }
}
