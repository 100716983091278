import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AppRoles } from "src/app/config/global-enums.config";

@Injectable()
export class LessonRoutingGuard  {
    constructor(private router: Router) {}

    roles: AppRoles[];
    isAuthenticated = false;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let id = route.queryParams.id;
        let approvedRoles = [
            AppRoles.SUPERADMINUI,
            AppRoles.DEVADMIN
        ]

        try{
            this.roles = JSON.parse(sessionStorage.getItem("profile-ell")).roleClaims;
            for (const element of this.roles) {
                if (approvedRoles.includes(element)) {
                    this.isAuthenticated = true;
                    return true;
                }
            }
        }
        catch{
            this.router.navigate(["/viewLesson"], {queryParams: {id: id}})
            return false;
        }
    }
}