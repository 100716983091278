<app-page-header [pageTitle]="pageTitle"> </app-page-header>
<main class="container-fluid">
  <div class="lessonStatusContainer">
    <div class="lessonStatusHeader">
      <h3> Please select one of the options below</h3>
      <span> The assigned technical approver and/or manager will now review your lesson. You will receive a notification via email once the lesson has progressed further.</span>
    </div>
    <div class="row d-flex justify-content-around navigationOptionsDiv">
      <mat-card appearance="outlined" class="optionsCard" (click)="singleLessonService.onclickcreateNewLessons()">
        <img class="icon" src="assets/icons/Icon - Document.png" alt="" />
        <mat-card-content class="createButton"
          ><h4
            type="button"
            id="createLesson"
          >
            create another lesson
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card
        appearance="outlined"
        class="optionsCard"
        (click)="navigateToActions()"
      >
        <img
          class="icon"
          src="assets/icons/Icon - Search.png"
          alt=""
        />
        <mat-card-content class="viewButton"
          ><h4 type="button" id="viewLesson">
            view your lessons
          </h4></mat-card-content
        >
      </mat-card>
      <mat-card appearance="outlined" class="optionsCard" (click)="navigateBackToHomePage()">
        <img class="icon" src="assets/icons/Icon - Home.png" alt="" />
        <mat-card-content class="homeButton"
          ><h4 type="button" id="navigateToHome">
            return to home page
          </h4></mat-card-content
        >
      </mat-card>
    </div>
  </div>
</main>