import { SearchScopeEnum } from "src/app/config/global-enums.config";
import { Lesson } from "./lesson";

export class SearchFacet {
  key: string;
  value: string;
}

export class SearchOptions {
  scope: SearchScopeEnum = SearchScopeEnum.PublishedLessons;
  keyWords: string = null;
  SearchFacets: Array<SearchFacet> = new Array<SearchFacet>();
  userBU: string;
  size: number;  
  filter: string;
  skip: number;
  scoringProfile: string;
  projectCodes: string;
}

export class LessonSearchResponse {
  pageNumber: number = 0;
  pageSize: number = 10;
  pageCount: number = 0;
  recordCount: number = 0;
  facets: Array<Facet> = new Array<Facet>();
  lessons: Array<Lesson> = new Array<Lesson>();
}

export class Facet {
  label: string;
  key: string;
  facetValues: Array<FacetValue> = new Array<FacetValue>();
  totalCount: number;
  expanded: boolean;
}

export class FacetValue {
  value: string;
  count: number;
  description: string;
  selected: boolean;
  code: string;
  facetLabel: string;
  facetIndex: number;
  selectedIndex: number;
  longDescription: string;
}
