import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-message",
  templateUrl: "./dialog-message.component.html",
  styleUrls: ["./dialog-message.component.scss"],
})
export class DialogMessageComponent implements OnInit {
  retVal: boolean = false;
  title: string = "";
  textBody: string = "";

  public showTextField: boolean = false;
  public fieldValue: string = "";
  public hideConfirm: boolean = false;
  public showDropdown: boolean = false;
  public dropdownList: any;
  dropdownValue: any;
  coreReferences: any;
  workflowType: any;

  constructor(
    public dialogRef: MatDialogRef<DialogMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.retVal = false;
    this.title = this.data.title;
    this.textBody = this.data.body;
    this.showTextField = this.data.showTextField;
    this.fieldValue = this.data.fieldValue ?? "";
    this.hideConfirm = this.data.hideConfirm;
    this.dropdownList = this.data.dropdownList;
    this.showDropdown = this.data.showDropdown;
  }

  confirm() {
    this.retVal = true;
    if (this.showTextField)
      this.dialogRef.close({ fieldValue: this.fieldValue });
    else if (this.showDropdown) this.dialogRef.close(this.dropdownValue);
    else this.dialogRef.close(this.retVal);
  }

  cancel() {
    this.dialogRef.close(this.retVal);
  }
}
