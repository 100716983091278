import { User } from "./user";

export class ProjectLookback {
  id: string;
  projectCode: string = "";
  projectName: string = "";
  projectAdmins: User[];
  asset: string[]=[]
  status: string
  userCount: number;
  businessUnit: string;
  projectStartDate: Date;
  projectDeadline: Date;
  workflowType: string;
  participants: User[];
  pendingParticipants: User[];
  participantsToNotify: User[];
  isParticipantOfProjectLookback: boolean;
  isProjectLookbackEditable: boolean = false;
  transitionName: string;
  pendingStatus: string;
  previousStatus: string;
  lessonIds: string[]=[]
  teamComments: string;
  projectId: string;
  lessonExtensionType: string;
  lessons: any[]=[]; //fe variable
  lessonStatuses: any[]=[] // fe
  updateDate: Date;
}

export class ProjectLookbackFieldUpdate {
  projectLookback: ProjectLookback;
  fieldId: string;
}