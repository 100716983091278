import { Injectable } from "@angular/core";
import { ConfigCacheService } from "../http/config-cache.service";
import { WorkflowConfigModel } from "src/app/shared/models/configsV2/workflowConfigModel";
import { ReferenceConfigModel } from "src/app/shared/models/configsV2/referenceConfigModel";
import { FieldConfigModel } from "src/app/shared/models/configsV2/fieldConfigModel";
import { FromStatusFieldConfigModel } from "src/app/shared/models/configsV2/fromStatusFieldConfigModel";
import { CrossReferenceConfigModel } from "src/app/shared/models/configsV2/crossReferenceConfigModel";
import { CrossVisibilityFieldConfigModel } from "src/app/shared/models/configsV2/crossVisibilityFieldConfigModel";
import { bulkEditConfigProperties } from "src/app/shared/models/configsV2/bulkEditConfigModel";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private cacheService: ConfigCacheService) { }

  async setConfigDataInLocalStorageStorage() {
    const data = await firstValueFrom(this.cacheService.getAllFieldConfigJsons())
    await this.setConfigDataInStorage(data, true);
    console.log("running cache compare on any page");

  }

  async setConfigDataInStorage(data: any, compare = false) {
    let fieldConfig: FieldConfigModel;
    let fromStatusFieldConfig: FromStatusFieldConfigModel;
    let reference: ReferenceConfigModel;
    let crossReference: CrossReferenceConfigModel;
    let workflow: WorkflowConfigModel;
    let crossVisibility: CrossVisibilityFieldConfigModel;
    let regexNaughtyWord: string;
    let bulkEditConfig: bulkEditConfigProperties;


    crossReference = data.CrossReferenceConfig.crossReferenceConfig;
    workflow = data.WorkflowConfig.workflowConfig;
    reference = data.ReferenceConfig.referenceConfig;
    fieldConfig = data.FieldConfig;
    fromStatusFieldConfig = data.FromStatusFieldConfig;
    crossVisibility =
      data.CrossVisibilityFieldConfig.crossVisibilityFieldConfig;
    regexNaughtyWord = (
      data.RiskyNaughtyWordsConfig?.riskyNaughtyWordsConfig?.naughtyWords ?? []
    ).join("|");
    bulkEditConfig = data.BulkEditConfig?.bulkEditConfig;

    localStorage.setItem("workflowConfig", JSON.stringify(workflow));
    localStorage.setItem(
      "crossReferenceConfig",
      JSON.stringify(crossReference)
    );
    localStorage.setItem("referenceConfig", JSON.stringify(reference));
    localStorage.setItem("fieldConfig", JSON.stringify(fieldConfig));
    localStorage.setItem(
      "fromStatusFieldConfig",
      JSON.stringify(fromStatusFieldConfig)
    );
    localStorage.setItem(
      "crossVisibilityFieldConfig",
      JSON.stringify(crossVisibility)
    );
    localStorage.setItem("regexNaughtyWord", JSON.stringify(regexNaughtyWord));
    localStorage.setItem("bulkEditConfig", JSON.stringify(bulkEditConfig));

    if (compare) {
      if (
        JSON.stringify(workflow) !==
        JSON.stringify(JSON.parse(localStorage.getItem("workflowConfig")))
      ) {
        localStorage.setItem("workflowConfig", JSON.stringify(workflow));
      }
      if (
        JSON.stringify(crossReference) !==
        JSON.stringify(JSON.parse(localStorage.getItem("crossReferenceConfig")))
      ) {
        localStorage.setItem(
          "crossReferenceConfig",
          JSON.stringify(crossReference)
        );
      }

      if (
        JSON.stringify(reference) !==
        JSON.stringify(JSON.parse(localStorage.getItem("referenceConfig")))
      ) {
        localStorage.setItem("referenceConfig", JSON.stringify(reference));
      }

      if (
        JSON.stringify(fieldConfig) !==
        JSON.stringify(JSON.parse(localStorage.getItem("fieldConfig")))
      ) {
        localStorage.setItem("fieldConfig", JSON.stringify(fieldConfig));
      }

      if (
        JSON.stringify(fromStatusFieldConfig) !==
        JSON.stringify(
          JSON.parse(localStorage.getItem("fromStatusFieldConfig"))
        )
      ) {
        localStorage.setItem(
          "fromStatusFieldConfig",
          JSON.stringify(fromStatusFieldConfig)
        );
      }

      if (
        JSON.stringify(crossReference) !==
        JSON.stringify(
          JSON.parse(localStorage.getItem("crossVisibilityFieldConfig"))
        )
      ) {
        localStorage.setItem(
          "crossVisibilityFieldConfig",
          JSON.stringify(crossVisibility)
        );
      }

      if (
        JSON.stringify(regexNaughtyWord) !==
        JSON.stringify(JSON.parse(localStorage.getItem("regexNaughtyWord")))
      ) {
        localStorage.setItem(
          "regexNaughtyWord",
          JSON.stringify(regexNaughtyWord)
        );
      }

      if (
        JSON.stringify(bulkEditConfig) !==
        JSON.stringify(JSON.parse(localStorage.getItem("bulkEditConfig")))
      ) {
        localStorage.setItem("bulkEditConfig", JSON.stringify(bulkEditConfig));
      }

      sessionStorage.setItem("cachecompare", "true");
    }
    return Promise.resolve();
  }

  // Checks if value exist in session, if not retrieves it.
  async sessionCacheCheck(cacheName: string) {
    let data = localStorage.getItem(cacheName);
    if (!data) {
      await this.setConfigDataInLocalStorageStorage()
        .then((resp) => {
          data = localStorage.getItem(cacheName);
        })
        .finally(() => {
          return data;
        });
    }
    return data;
  }
}
