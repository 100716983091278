import { User } from "./user";
import { CollectionAction } from "../../config/global-enums.config";
//THIS REGION IS CODE GENERATED, PLEASE DO NOT MANUALLY CHANGE THE CONTENTS IN BETWEEN THIS REGION
//Date Generated:1/5/2023 11:02:23 PM
export class Lesson {
  id: string;
  displayId: string;
  lessonWorkflowType: string;
  lessonWorkflowTypeForTransition: string; //fe only
  title: string;
  description: string;
  potentialCause: string;
  recommendation: string;
  status: string;
  businessUnit: string;
  cop: string;
  copSubGroup: string;
  transitionName: string;
  isEnterprise: boolean;
  discipline: string;
  approverTechnical: User;
  lookbackCategory: string[] = [];
  primaryImpact: string[] = [];
  asset: string[] = [];
  level5A: string;
  validationException: string;
  isArchived: boolean;
  archivedReason: string;
  region: string;
  copTags: string[] = [];
  level2C: string;
  level3: string;
  level5_1: string;
  subDiscipline: string;
  revisionCycle: string;
  exportComplianceCategory: string;
  exportControlClassificationNumber: string;
  intellectualPropertyCategory: string;
  yearIdentified: number;
  isThirdParty: boolean;
  primaryImpactComments: string;
  wbsNumber: string;
  mocNumber: string;
  application: string[] = [];
  projectPhase: string[] = [];
  technology: string[] = [];
  subTechnology: string;
  sumOfVotingLikesForAllUsers: number;
  sumOfVotingDislikesForAllUsers: number;
  cpNumber: string;
  competativePerformance: boolean;
  deviation: string;
  nOJV: boolean;
  dateOfSubmission: Date;
  lastUpdatedDate: Date;
  publishedDate: Date;
  targetPublishedDate: Date;
  lastUpdatedUser: User;
  importId: string;
  legacyData: string;
  legacyId: string;
  legacySor: string;
  legacySorFileName: string;
  lessonAttachments: LessonAttachment[];
  sendAsCesComment: boolean;
  documentNumber: string;
  revisionNumber: string;
  sectionNumber: string;
  suggestion: string;
  justification: string;
  cesCommentId: string;
  lessonComments: LessonComment[];
  ipLegalComplianceReviewQuestion1: boolean;
  ipLegalComplianceReviewQuestion2: boolean;
  ipLegalComplianceReviewQuestion3: boolean;
  exportComplianceReviewQuestion1: boolean;
  exportComplianceReviewQuestion2: boolean;
  exportComplianceReviewQuestion3: boolean;
  statusTransitionDate: Date;
  previousStatus: string;
  pendingStatus: string;
  assignees: User[];
  isLessonEditable: boolean;
  isParticipantOfLesson: boolean;
  submitter: User;
  pointOfContact: User;
  managers: User[] = [];
  approverTechnicals: User[] = [];
  approverSMEs: User[];
  approverEC: User;
  approverLegal: User;
  submitterResponse: string;
  submitterResponseDate: Date;
  managerResponse: string;
  managerResponseDate: Date;
  technicalResponse: string;
  technicalResponseDate: Date;
  smeResponse: string;
  smeResponseDate: Date;
  ecResponse: string;
  ecResponseDate: Date;
  legalResponse: string;
  legalResponseDate: Date;
  projectCodes: string[] = [];
  function: string;
  rootCauseCategory: string;
  assetLocation: string;
  team: string;
  department: string;
  relatedProcess: string;
  type: string;
  subType: string;
  block: string;
  area: string;
  facility: string;
}

//CODE GEN END
export class LessonAttachment {
  attachmentId: string;
  uri: string;
  fileTitle: string;
  fileName: string;
  fileSize: number;
  strFileSize: string;
  lessonId: string;
  content: string;
  fileUrl: string;
  isPublic: boolean;
  fileStream: File;
  action: CollectionAction;
  lastUpdatedUser: User;
  lastUpdatedDate: Date;
  ContentData: any;
}

export class LessonComment {
  title: string;
  content: string;
  isResolved: boolean;
  lastUpdatedUser: User;
  lastUpdatedDate: Date;
  lessonReplies: Array<LessonReply> = new Array<LessonReply>();
  isPublic: boolean;
  allowReply: boolean;
}

export class LessonReply {
  content: string;
  lastUpdatedUser: User;
  lastUpdatedDate: Date;
  allowReply: boolean;
}

export class LessonFieldUpdate {
  lesson: Lesson;
  fieldId: string;
}
export class ImportFileResult {
  totalCount: number;
  importedCount: number;
  rejectedCount: number;
  importDescription: string;
  status: string;
}

export class LessonVoting {
  lessonId: string;
  votingAction: string;
  votingComment: string;
}

export class LessonVotingResponse {
  votingLikes: number;
  votingDislikes: number;
  success: boolean;
  message: string;
}
