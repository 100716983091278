import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PackageListItem } from "src/app/shared/models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PackagingLessonService {
  constructor(private http: HttpClient) {}

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}SqlServer`;

  getPackagedLessons(): Observable<PackageListItem[]> {
    return this.http.get<PackageListItem[]>(
      `${this.apiRoot}/getPackagedLessons`
    );
  }

  createPackagedLessons(
    lessonPackageName: string,
    lessonIds: string[]
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiRoot}/createPackagedLessons?packageName=${lessonPackageName}`,
      lessonIds
    );
  }

  deletePackagedLesson(packageName: string, id?: string): Observable<string> {
    if (id)
      return this.http.delete<string>(
        `${this.apiRoot}/deletePackagedLessons/${packageName}?lessonId=${id}`
      );
    else
      return this.http.delete<string>(
        `${this.apiRoot}/deletePackagedLessons/${packageName}`
      );
  }
}
