import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OpenAiMessage } from "src/app/shared/models/chatbot";

export interface AthenaSearchParams {
  skip?: number;
  size?: number;
  dataSource?: string;
  sourceMetadataFilters?: Record<string, string[]>;
  additionalFilter?: string;
}

export interface AthenaSearchItem {
  score: number;
  document: {
    athenaId: string;
    filePath: string;
    fileName: string;
    dataSource: string;
    sourceMetadata: { name: string; value: string[] }[];
  };
}

export interface AthenaSearchResult {
  totalCount: number;
  results: AthenaSearchItem[];
}

@Injectable({
  providedIn: "root",
})
export class AthenaSearchService {
  private athenaUrl = environment.athenaApiUrl;

  private searchSuggestions = new BehaviorSubject<string[]>([]);
  public searchSuggestions$ = this.searchSuggestions.pipe(
    distinctUntilChanged(),
    debounceTime(500)
  );

  constructor(private http: HttpClient) {}

  performSearch(
    query: string,
    args: AthenaSearchParams = {}
  ): Observable<AthenaSearchResult> {
    return this.http.post<AthenaSearchResult>(
      `${this.athenaUrl}/external-search/search`,
      args,
      {
        params: {
          query,
        },
      }
    );
  }

  getSuggestions(query: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.athenaUrl}/external-search/suggestions`,
      {
        params: {
          input: query,
          dataSource: "ELL",
          size: 5,
        },
      }
    );
  }

  sendMessage(messages: OpenAiMessage[]) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Content-Range, Content-Disposition, Content-Description'
    });

    return this.http.post<OpenAiMessage[]>(`${this.athenaUrl}external-search/chatbot`, messages, {headers} );
  }

  updateSearchSuggestions(query: string): void {
    if (query?.length > 0) {
      this.getSuggestions(query).subscribe((result) =>
        this.searchSuggestions.next(result)
      );
    } else {
      this.searchSuggestions.next([]);
    }
  }



}
