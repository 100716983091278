import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LessonInput } from '../../pages/lessons-list/lessons-table-card/lessons-table-card.component';
import { ReferenceConfigModel } from 'src/app/shared/models/configsV2/referenceConfigModel';
import { Facet, SearchOptions } from 'src/app/shared/models/search-options';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PreviousStateRoutingService } from 'src/app/core/services/previous-state-routing.service';
import { ConvertCodeToDescription } from 'src/app/shared/pipes/convert-code-to-description';
import { ToastService } from 'src/app/core/services/toast.service';
import { SharedService } from '../../shared.service';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { CreateEditSingleLessonService } from 'src/app/core/services/lesson-services/create-edit-single-lesson.service';
import { ExtensionType } from 'src/app/config/global-enums.config';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';

@Component({
  selector: 'app-lesson-details',
  templateUrl: './lesson-details.component.html',
  styleUrls: ['./lesson-details.component.scss']
})
export class LessonDetailsComponent {
  @Input() allLessons: LessonInput[];
  @Input() showCards: boolean;
  @Input() lessonsSource: "athena" | "ell" = "ell";
  @Input() referenceCacheData: ReferenceConfigModel;
  @Input() fieldConfigCore: any;
  @Input() selectedTabIndex: any;
  @Input() searchFilters = new SearchOptions();
  @Input() searchFacets: Facet[] = [];
  @Output() addremovefavoritedeleteLesson = new EventEmitter<any>();

  disciplines: any;
  showDescription: number = -1;
  showDisc: boolean = false;


  constructor(
    private router: Router,
    public dialog: MatDialog,
    public previosStateRoutingService: PreviousStateRoutingService,
    public codeToDescription: ConvertCodeToDescription,
    private toastService: ToastService,
    private shared: SharedService,
    public projectLookbackService: CreateEditProjectLookbackService,
    public singleLessonService: CreateEditSingleLessonService,
    private commonService: CommonService

  ) {}


  ngOnInit() {
    this.commonService.loadConfig();
    try{
      this.disciplines = this.commonService.references['Discipline'];
      this.showDisc = true;
    }
    catch{
      this.toastService.showRetrievingMessage(true, 'Please give us a moment as we finish setting up some things from our end.');
    }
    
  }

  goToEditLesson(lesson: LessonInput) {
    let lessonId = lesson.id;
    if (lesson.lessonWorkflowType == ExtensionType.plbLessonWorkflow) {
      this.projectLookbackService.NavigateToLessonPage(lesson)
    }
    else {
      this.previosStateRoutingService.cacheSearchFilters(
        this.searchFilters,
        this.searchFacets
      );
      if (lessonId == "") {
        const data: any = lessonId
          ? {
              LessonId: lessonId,
            }
          : undefined;

        this.router.navigate(["/createNewLesson"], { state: { data } });
      } else {
        this.router.navigate(["/editLesson"], { queryParams: { id: lessonId } });
      }
    }
  }

  convertDisciplineCode(discCode: string){
    let convertedDiscipline = this.disciplines.find((disc) => disc.Code === discCode)

    return convertedDiscipline ? convertedDiscipline.Description : 'no discipline assigned'

  }

  convertLessonWorkflow(lessonWorkflowType: string){
    switch(lessonWorkflowType){
      case 'abuFEWorkflow':
        return 'source: ABU';
      case 'gombuFWorkflow':
        return 'source: GOMBU';
      case 'bbuFEWorkflow':
        return 'source: BBU';
      case 'rbuFEWorkflow':
        return 'source: RBU';
      case 'mauaFEWorkflow':
        return 'source: Maua';
      case 'singaporeFEWorkflow':
        return 'source: Singapore';
      case 'oakpointFEWorkflow':
        return 'source: Oakpoint';
      case 'gonfrevilleFEWorkflow':
        return 'source: Gonfreville';
      case 'sasbuFEWorkflow':
        return 'source: SASBU';
      case 'copWorkflow':
        return 'source: CoP';
      case 'ipimsWorkflow':
        return 'source: iPIMS';
      case 'nobleFEWorkflow':
        return 'source: Noble';
      case 'plbLessonWorkflow':
        return 'source: Project Lookback';
      default:
        return 'source: N/A'
    }
  }

  getStatusTextColor(status: string){
    switch(status){
      case 'draft':
        return 'white'
      case 'llreview':
        return 'white'
      case 'inTechnicalReview':
        return 'black'
      case 'inSmeReview':
        return 'white'
      case 'inEcReview':
        return 'white'
      case 'inIpReview':
        return 'white'
      case 'inlegalReview':
        return 'white'
      case 'reviewCompleted':
        return 'white'
      case 'publish':
        return 'white'
      case 'review':
        return 'white'
      case 'approved':
        return 'white'
      case 'rejected':
        return 'white'
    }
  }

  getStatusBackgroundColor(status: string){
    switch(status){
      case 'draft':
        return '#8C8F93'
      case 'llreview':
        return '#E5601F'
      case 'inTechnicalReview':
        return '#FAAB18'
      case 'inSmeReview':
        return '#711B00'
      case 'inEcReview':
        return '#00B3BD'
      case 'inIpReview':
        return '#00708C'
      case 'inlegalReview':
        return '#003653'
      case 'reviewCompleted':
        return '#B2CC34'
      case 'publish':
        return '#0b2d71'
      case 'review':
        return '#B2CC34'
      case 'approved':
        return '#444B0D'
      case 'rejected':
        return '#96002E'
    }
  }

  displayStatus(status: string){
    switch(status){
      case 'draft':
        return 'draft'
      case 'llreview':
        return 'in ll manager review'
      case 'inTechnicalReview':
        return 'in technical review'
      case 'inSmeReview':
        return 'in sme review'
      case 'inEcReview':
        return 'in ec review'
      case 'inIpReview':
        return 'in ip review'
      case 'inlegalReview':
        return 'in legal review'
      case 'reviewCompleted':
        return 'completed review'
      case 'publish':
        return 'published'
      case 'review':
        return 'in review'
      case 'approved':
        return 'approved'
      case 'rejected':
        return 'rejected'
    }

  }


}
