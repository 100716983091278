import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-notfound",
  templateUrl: "./notfound.component.html",
  styleUrls: ["./notfound.component.scss"],
})
export class NotfoundComponent implements OnInit {
  ngOnInit(): void {
    // This is intentional
  }
}
