import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() contentContactName = "";
  @Input() contentContactUrl = "";
  @Input() technicalContactName = "";
  @Input() technicalContactUrl = "";

  @Input() productUserGuide = "";
  @Input() productUserGuideUrl = "";

  @Input() copyright = new Date().getFullYear().toString();
  @Input() confidentiality = "Company Confidential";
  @Input() opcoName = "ITC";
  @Input() opcoUrl = "https://tps.chevron.com/itc/";
  @Input() lastUpdate = "";

  ngOnInit(): void {
    // This is intentional
  }
}
