import { NgModule } from "@angular/core";
import { CreateNewLessonAttachmentComponent } from "../modules/home/components/create-new-lesson-attachment/create-new-lesson-attachment.component";
import {
  CommentComponent,
  DialogContent,
} from "../modules/home/components/comment/comment.component";
import { AdditionalSMEComponent } from "../modules/home/dialogs/additional-sme/additional-sme.component";
import { LegacyDataComponent } from "../modules/home/dialogs/legacy-data/legacy-data.component";
import { SharedModule } from "./shared.module";
import { CoreModule } from "../core/core.module";
import { CommonModule } from "@angular/common";
import { DynamicComponentComponent } from "../modules/home/components/dynamic-component/dynamic-component.component";
import { ProjectLookbackDynamicComponentComponent } from "../modules/home/components/project-lookback-dynamic-component/project-lookback-dynamic-component.component";
import { TextInputHighlightModule } from "angular-text-input-highlight";
import { LessonDetailsComponent } from "../modules/home/components/lesson-details/lesson-details.component";
import { ConvertCodeToDescription } from "./pipes/convert-code-to-description";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { WorkflowStatusProgressBarComponent } from "./components/workflow-status-progress-bar/workflow-status-progress-bar.component";

@NgModule({
  declarations: [
    CreateNewLessonAttachmentComponent,
    CommentComponent,
    AdditionalSMEComponent,
    DialogContent,
    LegacyDataComponent,
    DynamicComponentComponent,
    ProjectLookbackDynamicComponentComponent,
    LessonDetailsComponent,
    PageHeaderComponent,
    WorkflowStatusProgressBarComponent
  ],
  imports: [SharedModule, CoreModule, CommonModule],
  exports: [
    CreateNewLessonAttachmentComponent,
    CommentComponent,
    AdditionalSMEComponent,
    LegacyDataComponent,
    DynamicComponentComponent,
    ProjectLookbackDynamicComponentComponent,
    LessonDetailsComponent,
    PageHeaderComponent,
    WorkflowStatusProgressBarComponent
  ],
  providers: [
    ConvertCodeToDescription
  ]
})
export class CreateEditSharedModule {}
