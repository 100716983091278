<mat-form-field
  appearance="outline"
  style="
    width: 20rem;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  "
>
  <mat-label *ngIf="showLabel">{{ label }}</mat-label>

  <ng-container *ngIf="allowMultipleSelection">
    <mat-chip-grid
      #chipList
      [attr.aria-label]="label"
      [hidden]="!showProfileList"
    >
      <mat-chip-row
        *ngFor="let option of selectedOptions"
        [editable]="selectable"
        [removable]="removable"
        (removed)="removeSelectedOption(option)"
      >
        {{ option.displayName }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
    </mat-chip-grid>
    <input
      class="inputcheckbox"
      [placeholder]="label"
      #multipleInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </ng-container>

  <ng-container *ngIf="!allowMultipleSelection">
    <div class="d-flex flex-row align-items-center">
      <input
        class="autocomplete__input"
        [placeholder]="label"
        [attr.aria-label]="label"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <button
        mat-button
        *ngIf="selectedValue && clearable"
        matSuffix
        class="button__base button__transparent"
        aria-label="Clear"
        (click)="clearSelection()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-container>

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
    (closed)="panelClosed()"
    [displayWith]="getOptionText"
  >
    <ng-container *ngIf="isSearching; else elseTemplate">
      <mat-option class="is-loading" disabled>
        <mat-progress-bar
          mode="indeterminate"
          color="accent"
        ></mat-progress-bar>
      </mat-option>
    </ng-container>
    <ng-template #elseTemplate>
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        multiLineOption
        [matTooltip]="option.displayName + '\n' + option.userPrincipalName"
      >
        <h4 mat-line>{{ option.displayName }}</h4>
        <p mat-line>{{ option.userPrincipalName }}</p>
      </mat-option>
    </ng-template>
    <mat-option *ngIf="noResultsFound && !isSearching" disabled
      >no results</mat-option
    >
  </mat-autocomplete>
</mat-form-field>
