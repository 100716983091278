import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmbedTokenAndReportId } from "src/app/shared/models/power-bi-config";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "any",
})
export class PbiserviceService {
  private apiRoot = `${environment.apiUrl}User`;

  constructor(private http: HttpClient) {}

  public getEmbedToken(): Observable<EmbedTokenAndReportId> {
    return this.http.get<EmbedTokenAndReportId>(
      `${this.apiRoot}/getEmbedToken`
    );
  }
}
