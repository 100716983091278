<app-page-header [pageTitle]="pageTitle"></app-page-header>
<div class="w-100" *ngIf="!showCards">
  <div
    class="d-flex justify-content-center align-items-center"
    style="min-height: 600px"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<!-- Main Content -->
<main class="d-flex flex-row" *ngIf="showCards">
  <div class="left-ctn">
    <div class="side-filters">
      <h5 class="py-3">filter by</h5>
      <div
        class="row col-12 mb-0 px-0 py-0"
        *ngFor="let facet of facets; let i = index"
      >
        <mat-expansion-panel [expanded]="facet.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ facet.label }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="row form-check"
            *ngFor="
              let selectedFilter of facet.facetValues
                | slice : 0 : (filterShowMore[i] ? undefined : 7);
              let ix = index
            "
          >
            <ul class="px-0">
              <li>
                <input
                  #checkboxes
                  class="form-check-input flexCheckDefault"
                  type="checkbox"
                  [(ngModel)]="selectedFilter.selected"
                  (change)="verifyFacet(facet, selectedFilter, $event)"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  {{ selectedFilter.description }} ({{
                    selectedFilter.count ? selectedFilter.count : 0
                  }})
                  <mat-icon
                    *ngIf="
                      selectedFilter.longDescription != '' &&
                      selectedFilter.longDescription
                    "
                    [ngbTooltip]="selectedFilter.longDescription"
                    style="opacity: 0.5"
                    >info
                  </mat-icon>
                </label>
              </li>
            </ul>
          </div>
          <span
            class="show-more right-align"
            *ngIf="facet.totalCount > 7"
            (click)="filterShowMore[i] = !filterShowMore[i]"
            >{{ filterShowMore[i] ? "less" : "more >" }}</span
          >
        </mat-expansion-panel>
      </div>
    </div>
  </div>

  <div class="right-ctn">
    <div id="back" class="ml-5">
      <span
        class="backText"
        style="cursor: pointer"
        (click)="projectLookbackService.NavigateToLessonReview()"
      >
        back to project lookback</span
      >
      &#62;
      <span class="ml-1"> lessons </span>
    </div>
    <div class="col-6 mt-5">
      <mat-tab-group
        [selectedIndex]="projectLookbackService.lessonTab"
        (selectedTabChange)="
          projectLookbackService.LessonTabChange($event); performSearch(true)
        "
      >
        <mat-tab *ngFor="let tab of tabs" [label]="tab.label"> </mat-tab>
      </mat-tab-group>
    </div>
    <div class="row px-3 py-2">
      <div class="col-8">
        <mat-chip-listbox #chipList *ngIf="getChips()?.length > 0">
          <mat-chip-option
            class="chipValue"
            *ngFor="let item of getChips(); let i = index"
          >
            <b> {{ convertFacetLabelName(item.facetLabel) }}:</b> {{ item.description }}
            <mat-icon *ngIf="removable" (click)="removeChip(item)"
              >close</mat-icon
            >
          </mat-chip-option>
        </mat-chip-listbox>
        <label *ngIf="getChips()?.length <= 0" class="filterstext">
          no filters applied
        </label>
      </div>
      <div class="col-4 paginator d-flex justify-content-end">
        <ngb-pagination
          [(page)]="page"
          [maxSize]="1"
          [collectionSize]="items"
          [rotate]="true"
          [pageSize]="itemsPerPage"
          (pageChange)="performSearch(true)"
        >
        </ngb-pagination>
      </div>
    </div>

    <div class="row px-3">
      <div class="col-10">
        <p class="resultCount">
          showing {{ filteredLessons.length }} out of {{ items }} results
        </p>
      </div>

      <div class="col-2 d-flex justify-content-end export">
        <div
          *ngIf="currentLessonsSource == 'ell'"
          class="px-0 py-0 exportText"
          (click)="exportLessonsCSV(filteredLessons)"
        >
          <span>export lessons</span>
          <img src="assets/icons/32_Export.svg" />
        </div>
      </div>
    </div>




    <!-- Lesson Table and Card implementation -->
    <div class="row px-3">
      <app-lesson-details
        class="col-12 px-0 py-0"
        [allLessons]="filteredLessons"
        [lessonsSource]="currentLessonsSource"
        [showCards]="showCards"
        [referenceCacheData]="commonService.referenceConfig"
        [fieldConfigCore]="fieldConfigCore"
        [selectedTabIndex]="selectedTabIndex.value"
        [searchFilters]="searchOptions"
        [searchFacets]="facets"
      >
      </app-lesson-details>
    </div>
  </div>
</main>

<!-- End Main Content -->
