import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LessonAttachment } from "../../../shared/models/lesson";
import { MatTableDataSource } from "@angular/material/table";

@Injectable({
  providedIn: "root",
})
export class AttachmentService {
  selectedFiles: LessonAttachment[] = [];
  deletedFiles: LessonAttachment[] = [];
  lessonURI = "";

  constructor(private http: HttpClient) {}

  columnsToDisplay: string[] = [
    "fileIcon",
    "name",
    "action",
    "size",
    "date",
    "attachedBy",
  ];

  dataSource = null;

  bindGrid() {
    if (!this.selectedFiles) {
      this.selectedFiles = new Array<LessonAttachment>();
    }
    this.selectedFiles.forEach((file) => {
      if (file) {
        if (file.fileSize) {
          file.strFileSize = this.getFileSize(file.fileSize);
        }
      }
    });
    this.dataSource = new MatTableDataSource(this.selectedFiles);
  }

  getFileSize(fileSize): string {
    if (fileSize > 1024 * 1024) {
      return (
        (Math.round((fileSize * 100) / (1024 * 1024)) / 100).toString() + "MB"
      );
    } else {
      return (Math.round((fileSize * 100) / 1024) / 100).toString() + "KB";
    }
  }
}
