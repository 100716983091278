import { NgModule, ErrorHandler } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./http.token.interceptor";
import { LessonErrorHandler } from "./error-handler.interceptor";

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    { provide: ErrorHandler, useClass: LessonErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
})
export class ErrorHandlerModule {}
