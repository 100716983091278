import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtensionType } from 'src/app/config/global-enums.config';
import { ProjectLookbackService } from 'src/app/core/http/project-lookback.service';
import { User } from 'src/app/shared/models';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { ToastService } from 'src/app/core/services/toast.service';

import { FieldConfigModel } from 'src/app/shared/models/configsV2/fieldConfigModel';
import { ReferenceConfigModel } from 'src/app/shared/models/configsV2/referenceConfigModel';

@Component({
  selector: 'app-project-lookback-dashboard',
  templateUrl: './project-lookback-dashboard.component.html',
  styleUrls: ['./project-lookback-dashboard.component.scss']
})
export class ProjectLookbackDashboardComponent implements OnInit{

  data: any = {};

  referenceConfig = new ReferenceConfigModel();
  fieldConfig = new FieldConfigModel();
  workflowType: string = "ProjectLookback";
  userProfile: User;
  pageTitle = "Project Lookback Dashboard"

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    public projectLookbackService: CreateEditProjectLookbackService,
  ){}

  async ngOnInit(): Promise<void> {
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    await this.getData();

  }

  private getData = async () => {
    this.projectLookbackService.onInit();
    this.toastService.showRetrievingMessage(true, "Please wait a moment as we get all of your project lookbacks.");
  };


}
