import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Lesson, User, Reference, PackageList } from "src/app/shared/models";
import { SharedModule } from "src/app/shared/shared.module";
import { SharedService } from "../../shared.service";
import { ImportLessonModalComponent } from "./import-lesson-modal/import-lesson-modal.component";
//import { LessonDetailsComponent } from "../../components/lesson-details/lesson-details.component";
import { MatDialog } from "@angular/material/dialog";
import { FieldConfigModel } from "src/app/shared/models/configsV2/fieldConfigModel";
import { LessonSearchService } from "src/app/core/services/lesson-services/lesson-search.service";
import { ExtensionType } from "src/app/config/global-enums.config";
import { LessonService } from "src/app/core/http/lesson.service";
import { appInfo } from "src/environments/environment";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { ApplicationInsightsService } from "src/app/core/http/application-insights.service";
import { PackagingLessonService } from "src/app/core/http/packaging-lesson.service";
import { Facet, FacetValue, SearchFacet, SearchOptions } from "src/app/shared/models/search-options";
import { AttributeCore } from "src/app/shared/models/configsV2/fieldConfigModel";
import {
  ReportMetrics,
  UniqueVisitEventModel,
} from "src/app/shared/models/app-insights";
import { AthenaSearchService } from "../../../../core/http/athena-search.service";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { FormControl } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { CurrentUserService } from "src/app/core/http/current-user.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [SharedModule],
})
export class HomeComponent implements OnInit {
  //// Cache
  referenceCacheData = new Array<Reference>();
  fieldConfig: FieldConfigModel;

  show = false;
  userCAI: string;
  userEmail: string;
  userFirstName: string;
  currentFullUserProfile: any;
  topFiveLessons: Lesson[] = new Array<Lesson>();

  currentLessonsSource: "athena" | "ell" = "ell";
  showCards: boolean; //For cards implementation
  fieldConfigCore = new Array<{ key: string; value: AttributeCore }>();
  savedFacets: Facet[] = []; //To get history facets
  selectedTabIndex = new FormControl(0);

  searchText: string;
  scope: any = "PublishedLessons";
  extensionType = ExtensionType;

  public preRecordedVideoUrl = appInfo.preRecordedVideoUrl;
  counter: number = 0;
  uniqueVisits: number;
  uniqueSearch: number;
  uniqueUsers: number;

  pendingTasks: number = 0;

  //Date variables for displaying to 5 lessons
  date: Date = new Date();
  month: string;

  constructor(
    private router: Router,
    private lessonService: LessonService,
    private shared: SharedService,
    public dialog: MatDialog,
    public lessonSearchService: LessonSearchService,
    public athenaSearchService: AthenaSearchService,
    public singleLessonService: CreateEditSingleLessonService,
    public appInsights: ApplicationInsightsService,
    private packageService: PackagingLessonService,
    public commonService: CommonService,
    private currentUserService: CurrentUserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.shared.onClick(1);
    this.userCAI = sessionStorage.getItem("uniqueKey");
    this.userEmail = sessionStorage.getItem("email");
    this.currentFullUserProfile = JSON.parse(sessionStorage.getItem("profile"));
    this.commonService.loadConfig();
    
    await this.getTopFiveLessons();
    this.fieldConfigCore = [].concat(
      ...this.commonService.loadGroups().map((field) => field.value),
      ...this.commonService.loadGroups().map((field) => field.value)
    );
    this.logUserInfoToAppInsights();
    await this.toGetReportMetric();
    /* Total published lesson count */
    this.lessonService.getPublishedLessonCount().subscribe((response) => {
      if (response) {
        this.counter = response;
      }
    });
    //Get Packaged Lessons
    await this.getPackagedLessons();

    this.currentUserService.searchLessonsForCurrentUser();

    this.currentUserService.currentTaskStatus.subscribe(status => this.pendingTasks = status);
  }

  importLessons() {
    const dialogRef = this.dialog.open(ImportLessonModalComponent, {
      width: "100rem"
    });
    dialogRef.afterClosed().subscribe((_) => { });
  }

  navigateToLessons() {
    this.router.navigate(["/lessons", { lessonId: 1 }]);
  }

  navigateToAddEditLesson(lessonId = "") {
    if (lessonId == "") {
      const data: any = lessonId
        ? {
          LessonId: lessonId,
        }
        : undefined;

      this.router.navigate(["/editLesson"], { state: { data: data } });
    } else {
      this.router.navigateByUrl("/editLesson?id=" + lessonId);
    }
  }

  navigateToLessonWorkflowSelection(){
    this.router.navigate(["/selectLessonWorkflowApproval"]);
  }

  navigateToAddLesson(extension: string) {
    this.router.navigate(["/createNewLesson"], {
      queryParams: { lessonWorkflowType: extension },
    });
  }

  navigateToLessonListView(action = "all") {
    // Use string to navigate. 'PublishedLessons' 'ParticipatedLessons' 'AssignedLessons' 'FavoriteLessons'
    const data: any = {
      scope: action,
      searchText: "",
      currentUserProfile: this.currentFullUserProfile,
    };
    this.router.navigate(["/lessons"], { state: { data: data } });
  }
  navigateToLessonListViewSearch(scope, searchText = "") {
    // Use string to navigate. 'PublishedLessons' 'ParticipatedLessons' 'AssignedLessons' 'FavoriteLessons'
    this.lessonSearchService.performSearch(scope, searchText);
  }

  navigateToProjectLookbackView() {
    this.router.navigate(["/projectLookbackView"])
    this.shared.onClick(8)
  }

  lowerCaseStatus(status: string) {
    return status.toLowerCase();
  }

  logUserInfoToAppInsights() {
    let userProfile: User = JSON.parse(sessionStorage.getItem("profile-ell"));
    let uniqueVisitModel = new UniqueVisitEventModel();
    if (userProfile !== null) {
      uniqueVisitModel.userFullName = userProfile.fullName;
      uniqueVisitModel.userBusinessUnit = userProfile.businessUnit;
      uniqueVisitModel.userUniqueKey = userProfile.uniqueKey;
      this.appInsights.createUniqueVisitCustomEvent(uniqueVisitModel);
    } else {
      this.appInsights.createUniqueVisitCustomEvent(uniqueVisitModel);
    }
  }

  openFeedback(){
    window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=oZ15_cG_NEKpHHKzocueJotl3yYo9GlOvOrny6OjKtFUQ1BBQlUyTEs0QlI2MzZDUUVTTEo3TjE3Si4u','_blank');
  }

  openTraining(){
    window.open('https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/77564/Self-Service-Training','_blank');
  }

  navigateToMetrics(){
    this.router.navigate(["/report"]);
  }

  navigateToActions(){
      this.shared.onClick(4);
      this.router.navigate(["/lessons"]);
  }

  public async toGetReportMetric() {
    this.appInsights
      .getReportMetrics()
      .subscribe((reportMetrics: ReportMetrics) => {
        this.uniqueVisits = reportMetrics.uniqueVisits;
        this.uniqueUsers = reportMetrics.uniqueUsers;
        this.uniqueSearch = reportMetrics.uniqueSearches;
      });
  }

  private async getTopFiveLessons() {
    let lessons = await firstValueFrom(this.lessonService.getTopFiveLessons());
    this.topFiveLessons = lessons;
    this.show = true;
    this.showCards = true;
  }

  private async getPackagedLessons() {
    await this.packageService.getPackagedLessons().subscribe((res) => {
      let packageLists: PackageList = { lists: res };
      localStorage.setItem(
        "package-list",
        res ? JSON.stringify(packageLists) : JSON.stringify(new PackageList())
      );
    });
  }

  private leapyear(year) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }
}
