import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../shared.service";
import { LessonService } from "src/app/core/http/lesson.service";
import { lastValueFrom } from "rxjs";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { ApplicationInsightsService } from "src/app/core/http/application-insights.service";



@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportPageComponent implements OnInit {
  public lessonCounts: Record<string, number>;
  public lessonCountsKeys: string[];
  selectedWorkflow: string = '';
  public workflows: any;
  public statuses: any[];
  public appInsightsReport: any;
  public appInsightsReportKeys: any;
  public byStatus: boolean = false;

  pageTitle = "Metrics";
  constructor(
    private shared: SharedService,
    private lessonService: LessonService,
    public commonService: CommonService,
    public appInsights: ApplicationInsightsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.commonService.loadConfig();
    this.shared.onClick(5);
    this.workflows = await this.commonService.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.Code !== 'oroniteFEWorkflow'));
    this.statuses = await this.commonService.referenceConfig.core["Status"].filter((x) => (x.importOnly == undefined));
    this.lessonCounts = await lastValueFrom(this.lessonService.getLessonsByWorkflow());
    this.lessonCountsKeys = Object.keys(this.lessonCounts);
    this.appInsightsReport = await lastValueFrom(this.appInsights.getReportMetrics());
    this.appInsightsReportKeys = Object.keys(this.appInsightsReport);
  }

  getDescription(enumValue: string): string {
    let myReference;
    if(this.byStatus) {
      myReference = this.statuses.find(x => x.Code == enumValue);
    } else {
      myReference = this.workflows.find(x => x.Code == enumValue);
    }
    return myReference?.Description ?? enumValue;
  }

  public async workflowSelected(event: any) {
    this.lessonCounts = await lastValueFrom(this.lessonService.getLessonsByStatus(event.value.Code));
    this.lessonCountsKeys = Object.keys(this.lessonCounts);
    this.byStatus = true;
  }

  getLongName(appInsightsKey){
    switch(appInsightsKey){
      case "uniqueVisits":
        return "Application Visits";
      case "uniqueSearches":
        return "Unique Searches";
      case "uniqueUsers":
        return "Unique Users";
    }
  }

  public async clearWorkflow() {
    this.byStatus = false;
    this.selectedWorkflow = "";
    this.lessonCounts = await lastValueFrom(this.lessonService.getLessonsByWorkflow());
    this.lessonCountsKeys = Object.keys(this.lessonCounts);
  }
}
