<app-page-header [pageTitle]="pageTitle"></app-page-header>
<div class="approvalPathHeaderContainer">
    <mat-card class="approvalPathHeaderCard"> 
        <mat-card-content>
          <div class="businessUnitContainer">
            <h3> First, please select a workflow from our map or manually select a workflow using the dropdown below</h3>
            <h6> Disclaimer: CoP workflow is only available via the dropdown</h6>

            <mat-form-field appearance="outline">
              <mat-select [(value)]="selectedWorkflow" placeholder="Select Business Unit">
                <mat-option *ngFor="let workflow of availableWorkflows" [value]="workflow.Code">
                  {{ workflow.mapShortname }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="selectedWorkflow">
              <button class="btn btn-primary"
              (click)="buSelectedChange(selectedWorkflow)">
                continue
              </button>
            </div>
          </div>
        </mat-card-content>
    </mat-card>
</div>


<div class="ell-chevron-map" >
  <div>
    <button 
    *ngFor="let workflow of availableWorkflowsOnMap; let i = index"
    class="workflow-location"
    [class]="workflow.Code.toLowerCase()"
    (click)="workflowSelectedChange(workflow)"
    >

    <div class="content">
        <img src="/./assets/icons/Chevron_map_pin_rgb_blue_dark.png" class="icon" />        
        <div class="centered-y">
        <h5>{{ workflow.mapShortname }}</h5>
        </div>
    </div>
    </button>
  </div>

</div>
