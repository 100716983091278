import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { FieldConfigModel } from "src/app/shared/models/configsV2/fieldConfigModel";
import { ReferenceConfigModel } from "src/app/shared/models/configsV2/referenceConfigModel";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectLookbackCommonService } from "src/app/core/services/project-lookback-services/project-lookback-common.service";
import { CreateEditProjectLookbackService } from "src/app/core/services/project-lookback-services/create-edit-project-lookback.service";
import { ExtensionType } from "src/app/config/global-enums.config";

@Component({
  selector: 'app-create-edit-publish-project-lookback',
  templateUrl: './create-edit-publish-project-lookback.component.html',
  styleUrls: ['./create-edit-publish-project-lookback.component.scss']
})
export class CreateEditPublishProjectLookbackComponent implements OnInit{

  referenceConfig = new ReferenceConfigModel();
  fieldConfig = new FieldConfigModel();

  constructor(
    public projectLookbackCommonService: ProjectLookbackCommonService,
    public projectLookbackService: CreateEditProjectLookbackService,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,

  ) {}

  async ngOnInit(): Promise<void> {
    this.fieldConfig = JSON.parse(localStorage.getItem("fieldConfig"));
    this.referenceConfig = JSON.parse(localStorage.getItem("referenceConfig"));
    const paramProjectLookbackId = this.activatedRoute.snapshot.queryParamMap.get("id");
    this.projectLookbackService.onInit();
    this.projectLookbackService.extensionType = ExtensionType.plbWorkflow;

    await this.projectLookbackService.generateNewProjectLookback(paramProjectLookbackId);
    this.projectLookbackService.show = true;
  }

  public confirmSubmitDialog(selectedAction: string) {
    this.projectLookbackService.projectLookback.transitionName = selectedAction;
    this.onTransitionNameSelected(selectedAction);
    this.saveOrSubmitProjectLookback(true);
  }

  onTransitionNameSelected(selectedTransitionName: any) {
    //change the transitionName and change the pendingStatus accordingly

    if (selectedTransitionName) {
      this.projectLookbackService.projectLookback.pendingStatus =
        this.projectLookbackCommonService.getPendingStatus(
          selectedTransitionName,
          this.projectLookbackService.projectLookback.workflowType,
          this.projectLookbackService.projectLookback.status
        );
    }
  }

  saveOrSubmitProjectLookback(isSubmit: boolean, isSave?: boolean){
    this.projectLookbackService.saveOrSubmitProjectLookback(isSubmit);
    this.projectLookbackService.show = false;
  }

}
