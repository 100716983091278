import { Component, Input, OnInit } from '@angular/core';
import { ParsingType } from 'src/app/modules/home/pages/coordinator-dashboard/dashboard-landing/dashboard-landing.component';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'app-workflow-status-progress-bar',
  templateUrl: './workflow-status-progress-bar.component.html',
  styleUrls: ['./workflow-status-progress-bar.component.scss']
})
export class WorkflowStatusProgressBarComponent {
  @Input() statusTitle!: string;
  @Input() statuses!: any;
  @Input() currentStatus!: any;

  totalStatuses: any;
  statusProgression: any;
  statusPercentages: any;

  constructor() {

  }

  ngOnInit(){
    this.totalStatuses = this.statuses.length;
    this.statusProgression = 100 / this.totalStatuses;
    this.getProgressBarPercentage();
  }

  getProgressBarPercentage(){
    this.statusPercentages = this.statuses.map(status => ({ status, percentages: 0 }));
    this.statusPercentages.forEach((val, index) => {
      val.percentages = val.percentages || 0;
      val.percentages += (index + 1) * this.statusProgression;
    })
  }

  getPercentage(status: string){
    let statusObj = this.statusPercentages.find(obj => obj.status === status);
    return statusObj ? statusObj.percentages : 0;
  }

  getCurrentStageLabel(): string {
    let currentStageName = this.displayStatus(this.currentStatus);
    return currentStageName;
  }

  getNextStageLabel(): string | null {
    const currentIndex = this.statuses.indexOf(this.currentStatus);
    if (currentIndex === -1) {
      // Current status not found in the array
      return null;
    }
  
    const nextIndex = currentIndex + 1;
    if (nextIndex < this.statuses.length) {
      // Return the label of the next stage
      let nextStageName = this.displayStatus(this.statuses[nextIndex]);
      return `next stage: ${nextStageName}`;
    } else {
      // No more stages after the current one
      return null;
    }
  }

  
  displayStatus(status: string){
    switch(status){
      case 'draft':
        return 'draft'
      case 'llreview':
        return 'll manager review'
      case 'inTechnicalReview':
        return 'technical review'
      case 'inSmeReview':
        return 'sme review'
      case 'inEcReview':
        return 'ec review'
      case 'inIpReview':
        return 'ip review'
      case 'inlegalReview':
        return 'legal review'
      case 'reviewCompleted':
        return 'completed review'
      case 'publish':
        return 'published'
      case 'review':
        return 'review'
      case 'approved':
        return 'approved'
      case 'rejected':
        return 'rejected'
      case 'active':
        return 'active'
      case 'inAdminReview':
        return 'admin review';
      case 'completed':
        return 'completed';
    }
  }
  

}
