import { Injectable } from "@angular/core";
import { UserFavoriteLesson, Lesson } from "src/app/shared/models";
import { FavoriteLessonService } from "src/app/core/http/favoriteLesson.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SharedService } from "../../../modules/home/shared.service";
import { ToastService } from "../toast.service";

@Injectable({ providedIn: "root" })
export class FavoriteLessonCommonService {
  favoriteLessons: UserFavoriteLesson[];
  favoriteLessonsCountByUser: number;
  isFavourite: boolean = false;
  lesson: Lesson = new Lesson();
  constructor(
    private shared: SharedService,
    private svcUserFavoriteLessons: FavoriteLessonService,
    private snackBar: MatSnackBar,
    private toastService: ToastService
  ) {}

  getFavoriteLessons() {
    return new Promise((resolve) => {
      this.svcUserFavoriteLessons.getFavoriteLessons().subscribe((result) => {
        this.favoriteLessons = result;
        resolve(this.favoriteLessons);
      });
    });
  }

  getFavoriteLessonCountByUser() {
    return new Promise<number>((resolve) => {
      this.svcUserFavoriteLessons
        .getFavoriteLessonsCount()
        .subscribe((result) => {
          this.favoriteLessonsCountByUser = result;
          resolve(this.favoriteLessonsCountByUser);
        });
    });
  }

  isFavoriteLesson(lessonId: string) {
    let retVal = false;

    if (this.favoriteLessons?.some((x) => x.lessonId == lessonId)) {
      retVal = true;
    }

    return retVal;
  }

  public getStarIcon(lessonId: string): string {
    const isFavourite: boolean = this.isFavoriteLesson(lessonId);

    let retVal = "star_border";

    if (isFavourite) {
      retVal = "star";
    }

    return retVal;
  }

  public AddRemoveFavoriteLesson(lessonId: string) {
    return new Promise<void>((resolve) => {
      let isFavourite: boolean = this.isFavoriteLesson(lessonId);
      this.AddRemoveFavoriteLessonInternal(!isFavourite, lessonId).then(() => {
        resolve();
      });
    });
  }

  private AddRemoveFavoriteLessonInternal(
    isFavourite: boolean,
    lessonId: string
  ) {
    return new Promise<void>((resolve) => {
      if (isFavourite) {
        this.toastService.showSuccess(true, "Successfully added this lesson to your favorites.");

        this.svcUserFavoriteLessons
          .createFavoriteLesson(lessonId)
          .subscribe((result) => {
            this.shared.favoriteLessonCount = result.length;
            this.favoriteLessons = result;
            resolve();
          });
      } else {
        this.toastService.showSuccess(true, "Sucessfully deleted this lesson from your favorites.");

        let id = this.favoriteLessons.find((x) => x.lessonId == lessonId).id;

        this.svcUserFavoriteLessons
          .deleteFavoriteLesson(id)
          .subscribe((result) => {
            const index = this.favoriteLessons.findIndex(
              (x) => x.lessonId == lessonId
            );
            this.favoriteLessons.splice(index, 1);
            this.shared.favoriteLessonCount = result;
            resolve();
          });
      }
    });
  }
}
