<!-- Header -->

<app-header *ngIf="loggedIn"></app-header>

<!--Menu-->
<app-menubar *ngIf="loggedIn"></app-menubar>

<!-- Main Content -->
<main class="container" style="background-color: white">
  <div class="w-100" style="min-height: 750px">
    <div>
      <section id="column1" class="layout12">
        <app-success-toast></app-success-toast>
        <app-error-toast></app-error-toast>
        <div class="row justify-content-center">
          <span class="disclaimerText">
            Lessons Learned are Company Confidential. Please do not share externally
            without prior written approval.
          </span>
          <span class="bold">
            &nbsp; Content Contact:
            <a href="{{ contentContactUrl }}">{{ contentContactName }}</a>
          </span>
        </div>
        <router-outlet *ngIf="loggedIn"></router-outlet>
        <app-chatbot-icon
          *ngIf="loggedIn"
        ></app-chatbot-icon>
      </section>
    </div>
    <div
      *ngIf="!loggedIn"
      class="d-flex justify-content-center align-items-center"
      style="min-height: 750px"
    >
      <div>
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</main>
<!-- Footer -->
<app-footer
  [contentContactName]="contentContactName"
  [contentContactUrl]="contentContactUrl"
  [technicalContactName]="technicalContactName"
  [technicalContactUrl]="technicalContactUrl"
  [copyright]="copyright"
  [lastUpdate]="lastUpdate"
  [productUserGuide]="productUserGuide"
  [productUserGuideUrl]="productUserGuideUrl"
>
</app-footer>
