import { Injectable } from "@angular/core";
import { Lesson, User } from "src/app/shared/models";
import { FormGroup, Validators } from "@angular/forms";
import { ProjectLookback, ProjectLookbackFieldUpdate } from "src/app/shared/models/project-lookback";
import { environment } from "src/environments/environment";
import { ProjectLookbackCommonService } from "src/app/core/services/project-lookback-services/project-lookback-common.service";
import { ExtensionType } from "src/app/config/global-enums.config";
import { WorkflowType } from "src/app/shared/models/configsV2/workflowConfigModel";
import { filter, firstValueFrom, forkJoin, lastValueFrom, Subscription } from "rxjs";
import { CollectionAction, Statuses } from "../../../config/global-enums.config";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogMessageComponent } from "../../../modules/home/dialogs/dialog-message/dialog-message.component";
import { Router } from "@angular/router";
import { AttachmentService } from "../lesson-services/attachment.service";
import { ToastService } from "../toast.service";
import { AttributeCore } from "src/app/shared/models/configsV2/fieldConfigModel";
import { PreviousStateRoutingService } from "../previous-state-routing.service";
import { SharedService } from "../../../modules/home/shared.service";
import { ProjectLookbackOperationResponse } from "src/app/shared/models/project-lookback-operation-response";
import { EvalconfigService } from "../lesson-services/eval-config.service";
import { ProjectLookbackService } from "../../http/project-lookback.service";
import { Status } from "@cucumber/cucumber";
import { ProjectLookbackEvalConfigService } from "../project-lookback-services/project-lookback-eval-config.service";
import { UserService } from "../../http/user.service";
import { LessonInput } from "src/app/modules/home/pages/lessons-list/lessons-table-card/lessons-table-card.component";
import { LessonStatus, LessonTabStatus, ProjectLookbackStatus } from "src/app/shared/models/project-lookback-status";
import { LessonService } from "../../http/lesson.service";


@Injectable({
  providedIn: "root",
})
export class CreateEditProjectLookbackService{
  private grpEnv = environment.groupEnv;
  show = false;

  dashboardTab: any = 0;
  lessonTab: any = 0;

  projectLookback: ProjectLookback;
  projectLookbackOperationResponse;
  currentVisibleProjectLookbacks: ProjectLookback[];
  currentVisibleLessons: Lesson[];
  projectLookbackFormGroup: FormGroup;
  choosingDiscipline: string;

  projectLookbackNextSteps: WorkflowType[] = [];
  projectLookbackNextStep: string = ""

  participants: User[] = [];

  extensionType: ExtensionType.plbWorkflow;

  // Is required, Editable, and dasable arrays in order to bind to html
  requiredFields: Array<string> = [];
  editableFields: Array<string> = [];
  visibleFields: Array<string> = [];
  groupCache: Array<string> = [];

  userProfile: User;
  setStartDate = Date.now();
  startDate = new Date(this.setStartDate);

  field = new AttributeCore();


  enumMapDashboard: { [key: number]: string } = {};
  enumMapLesson: { [key: number]: string } = {};

  public subscriptions: Subscription = new Subscription();
  

  public showProjectLookback: boolean = true;

  projectLookbackWorkflowStatuses: any;

  constructor(
    private router: Router,
    private shared: SharedService,
    private projectLookbackApiService: ProjectLookbackService,
    private userService: UserService,
    //private attachmentService: AttachmentService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private previousRoute: PreviousStateRoutingService,
    private snackBar: MatSnackBar,
    public projectLookbackEvalService: ProjectLookbackEvalConfigService,
    public projectLookbackCommonService: ProjectLookbackCommonService,
    public lessonService: LessonService
    
  ) { }

  async onInit() {
    this.projectLookback = new ProjectLookback();
    this.projectLookback.workflowType = ExtensionType.plbWorkflow;
    this.show = false;
    this.shared.onClick(8);
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.projectLookbackEvalService.onInit();
    this.projectLookbackCommonService.loadConfig();
    Object.keys(ProjectLookbackStatus).forEach((key, index) => {
        this.enumMapDashboard[index] = String(key);
    });

    Object.keys(LessonTabStatus).forEach((key, index) => {
        this.enumMapLesson[index] = String(key);
    });
    await this.getProjectLookbacksForCurrentUser(); 

  }


  private loadConfigs() {
    this.projectLookbackCommonService.loadGroups();
    this.projectLookbackCommonService.loadFieldConfigurations();
    this.projectLookbackCommonService.loadFieldsStatus(this.projectLookback);
    this.projectLookbackCommonService.loadVisibleItemGroupValues("draft");
    this.projectLookbackEvalService.loadProjectLookbackNextSteps(this.projectLookback);
  }

  public onDestroy() {
    this.subscriptions.unsubscribe();
  }

  resetForm() {
    this.projectLookbackFormGroup = this.projectLookbackCommonService.getProjectLookbackForm(this.projectLookback.status);
  }

  async updateProjectLookbackCommentsAsync() {
    this.projectLookback.isProjectLookbackEditable = this.projectLookback.isProjectLookbackEditable ?? true;
    await firstValueFrom(this.projectLookbackApiService.createUpdateProjectLookback(this.projectLookback)).then(
      (result) => {
        this.toastService.showSuccess(
          true, "Comments have been updated!"
        );
      },
      (err) => {
        this.toastService.showError(true, "Uh-oh! Looks like there was an issue with adding your comment. Please try again or reach out to the support team for further assistance");
      }
    );

  }

  async generateNewOrEditProjectLookback(id: string) {
    await this.retrieveProjectLookback(id);
  }

  getProjectLookbackFieldValue(field: string, plb?: ProjectLookback) {
    let tempPlb: ProjectLookback = this.projectLookback;
    if (plb){
      tempPlb = plb;
    }

    if (tempPlb[field]) {
      return tempPlb[field];
    } else {
      if (tempPlb[tempPlb.workflowType]) {
        return tempPlb[tempPlb.workflowType][field];
      }
    }
  }

  async retrieveProjectLookback(code: string) {
    const getProjectLookback = this.projectLookbackApiService.getProjectLookback(code).subscribe(async (result) => {
      this.toastService.showRetrievingMessage(true, "Please wait a moment as we get all of your project lookbacks.");
    })
  }

  updateProjectLookback(value: ProjectLookbackFieldUpdate) {
    this.projectLookback[value.fieldId] = value.projectLookback[value.fieldId];
  }

  async saveOrSubmitProjectLookback(isSubmit: boolean) {
    this.userProfile.projectCodes = this.userProfile.projectCodes || [];

    if(isSubmit){
      let result = await this.projectLookbackEvalService.setAndValidateTransition(this.projectLookback);
      if(!result){
        this.toastService.showError(true, "Uh-oh! Looks like there was an issue with submitting the Project Lookback. Please reach out to the support team for further assistance.");
      }
      this.projectLookback.previousStatus = this.projectLookback.status;
      this.projectLookback.status = this.projectLookback.pendingStatus;
      
      
    }

    firstValueFrom(this.projectLookbackApiService.createUpdateProjectLookback(this.projectLookback)).then(
      (result) => {
        this.projectLookbackOperationResponse.projectLookbacks.push([...result.projectLookbacks]);
        this.currentVisibleProjectLookbacks = this.projectLookbackOperationResponse.projectLookbacks;
        if (!this.userProfile.projectCodes.includes(result.projectLookbacks[0].projectCode))
        {
          this.userProfile.projectCodes.push(result.projectLookbacks[0].projectCode);
          sessionStorage.setItem("profile-ell", JSON.stringify(this.userProfile));
        }
        this.userService.updateProjectLookbackUser(this.userProfile).subscribe(res => {
          this.router.navigate([`projectLookbackView/submitProject/${result.projectLookbacks[0].projectCode}`])
        });
      });
  }

  async deleteProjectLookback(ids) {
    firstValueFrom(this.projectLookbackApiService.deleteProjectLookbacks(ids)).then(
      (result) => {
        if(result.success)
        {
          this.currentVisibleProjectLookbacks = this.currentVisibleProjectLookbacks.filter(x=> !ids.includes(x.projectId));
        }
        else{
          this.toastService.showError(true, result.message)
        }
      }
    );
  }

  generateProjectCode(length: number) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for(let i = 0; i < length; i++){
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }
    return result;

  }

  async checkForPlb(comingFromLesson?: boolean): Promise<void> {

    if(this.projectLookback == undefined && !this.projectLookback?.id)
    {
      if(comingFromLesson)
      {
        await this.onInit();
      }
      else 
      {
        this.NavigateToDashboard();
      }
    }
  }

  async getProjectLookbackConfigs(){
    this.projectLookback.workflowType = ExtensionType.plbWorkflow;
    this.loadConfigs();
    this.checkCrossVisibilityConfig();
  }

  async generateNewProjectLookback(id: string){
    this.projectLookback.status = Statuses.draft;
    this.projectLookback.workflowType = ExtensionType.plbWorkflow;
    this.projectLookback.isProjectLookbackEditable = true;
    this.projectLookback.projectStartDate = new Date();
    this.projectLookback.projectAdmins = [].concat(this.userProfile);
    this.projectLookback.participants = [].concat(this.userProfile);

    this.projectLookback.projectCode = this.generateProjectCode(4);

    // Setting default values if needed?
    let coreGroup = this.projectLookbackCommonService.fieldConfig.fieldConfig.projectLookbackCore;
    for (const item in coreGroup) {
      const fields = coreGroup[item]; 
      for (const fieldName in fields) {
        const key = fieldName; 
        const fieldAttribute = fields[fieldName];
        if (fieldAttribute.feMetadata?.defaultValue != undefined) {
          this.projectLookback[key] = fieldAttribute.feMetadata.defaultValue;
        }
      }
    }

    // Need to update projectAdmin with projectCode after creation


    this.loadConfigs();
    this.checkCrossVisibilityConfig();
    this.resetForm();
  }

  public checkCrossVisibilityConfig() {
    let visibleFieldsFromCrossVisibilityConfig = [];
    let hiddenFieldsFromCrossVisibilityConfig = [];
    for (const item in this.projectLookbackCommonService.crossVisibilityFieldConfig) {
      let fieldItem = this.projectLookbackCommonService.crossVisibilityFieldConfig[item];

      //why are we assiging the fieldvalue: the value of exportComplianceReviewQuestion1 in respective to the lesson, they are coming from the default values already set
      let fieldValue;
      fieldValue = this.projectLookback[item];
      if (fieldValue != null && fieldValue != undefined) {
        if (
          Array.isArray(
            fieldItem[fieldValue]?.visibleFields ||
            fieldItem[fieldValue]?.visibleFields != undefined
          )
        ) {
          visibleFieldsFromCrossVisibilityConfig =
            visibleFieldsFromCrossVisibilityConfig?.concat(
              fieldItem[fieldValue].visibleFields
            );
        }

        if (Array.isArray(fieldItem[fieldValue]?.hiddenFields)) {
          hiddenFieldsFromCrossVisibilityConfig =
            hiddenFieldsFromCrossVisibilityConfig.concat(
              fieldItem[fieldValue].hiddenFields
            );
        }
      }
    }
  }

  async getProjectLookbacksForCurrentUser() {
    this.projectLookbackApiService.getAllProjectLookbacks().subscribe((result) => {
      this.projectLookbackOperationResponse = result;
      this.projectLookback.status = Statuses.draft;
      this.projectLookback.workflowType = ExtensionType.plbWorkflow;
      this.userProfile.projectCodes = this.userProfile.projectCodes || [];
      const filteredProjectLookbacks = this.projectLookbackOperationResponse.projectLookbacks.filter(lookback => {
        return this.userProfile.projectCodes.includes(lookback.projectCode);
      })

      this.projectLookbackOperationResponse.projectLookbacks = filteredProjectLookbacks;
      this.currentVisibleProjectLookbacks = this.projectLookbackOperationResponse.projectLookbacks;
       
      this.convertLessonStatus();
      
     
    })
  }

  async convertLessonStatus() {
    const observables = {};
    this.currentVisibleProjectLookbacks.forEach(x => {
      if (x.lessonIds.length > 0)
      {
        observables[x.projectCode] = this.lessonService.getProjectLookbackLessons(x.projectCode);
      }
      
    })

    try {
      if(Object.keys(observables).length > 0)
      {
        const results: Object = await lastValueFrom(forkJoin(observables));
        Object.keys(results).forEach(key => {
        let indx = this.currentVisibleProjectLookbacks.findIndex(x => x.projectCode == key);
        if (indx > -1){
          if(results[key].lessons){
            this.currentVisibleProjectLookbacks[indx].lessons = results[key].lessons;
          }
        }
      });

      }
       this.show = true;

      // All observables have completed
    } catch (error) {
      // Handle errors if any of the observables fail
      console.error('Error:', error);
    }

    this.loadConfigs();
    this.resetForm();
  }

  async onValueChange(
    selectedValue: any,
    field: string,
    referenceConfigGraph: string
  ) {
    switch (field) {
      case "asset":
        this.onAssetChange(selectedValue);
        break;
      case "businessUnit":
        this.onBusinessUnitChange(selectedValue);
        break;
      default:
    }
  }

  async onAssetChange(selectedAsset: any) {
    if(selectedAsset) {
      this.projectLookback.asset = [].concat(selectedAsset.value)
    }
  }

  async onBusinessUnitChange(selectedBusinessUnit: any){
    switch(selectedBusinessUnit.value){
      case "ABU":
        this.projectLookback.lessonExtensionType = ExtensionType.abuFEWorkflow;
        break;
      case "GOMBU":
        this.projectLookback.lessonExtensionType = ExtensionType.gombuFEWorkflow;
        break;
      case "EBU":
        this.projectLookback.lessonExtensionType = ExtensionType.tcoFEWorkflow;
        break;
      case "BBU":
        this.projectLookback.lessonExtensionType = ExtensionType.bbuFEWorkflow;
        break;
      case "SASBU":
        this.projectLookback.lessonExtensionType = ExtensionType.sasbuFEWorkflow;
        break;
      case "Singapore":
        this.projectLookback.lessonExtensionType = ExtensionType.singaporeFEWorkflow;
        break;
      case "Gonfreville":
        this.projectLookback.lessonExtensionType = ExtensionType.gonfrevilleFEWorkflow;
        break;
      case "Oakpoint":
        this.projectLookback.lessonExtensionType = ExtensionType.oakpointFEWorkflow;
        break;
      case "Maua":
        this.projectLookback.lessonExtensionType = ExtensionType.mauaFEWorkflow;
        break;
      default:
    }
  }

  cancelLookback() {
    if (this.router.url.includes("create")) {
      this.router.navigate(["/projectLookbackView"]);
    } else {
      this.router.navigate([this.previousRoute.getPreviousUrl()]);
    }
  }

  // Router methods
  NavigateToDashboard() {
    this.router.navigate(["projectLookbackView"]);
  }

  onClickCreateNewProjectLookback() {
    this.router.navigate(["projectLookbackView/create"])
  }

  NavigateBackToHomePage() {
    if (this.previousRoute.getPreviousUrl()) {
      let prevRoute = this.previousRoute.getPreviousUrl()
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([prevRoute])
      );
    }
    else {
      this.router.navigate(["/home"]);
    }
  }

  NavigateToLessonPage(lesson?: LessonInput) {
    if(lesson)
    {
      this.router.navigate(['/projectLookbackView/addProjectLookbackLesson'], { queryParams: { id: lesson.id } });
    }
    else{
      this.router.navigate(["projectLookbackView/addProjectLookbackLesson"]);
    }
    
  }

  NavigateToLessonReview(){
    this.router.navigate([`projectLookbackView/projectLookbackBucket/${this.projectLookback.id}`])
  }

  NavigateToLessonDetail(discipline: string){
    if(discipline)
    {
      this.choosingDiscipline = discipline;
      this.router.navigate([`projectLookbackView/lessonFilterDetail/${this.projectLookback.id}`])
    }
    else{
      this.choosingDiscipline = null;
      this.router.navigate([`projectLookbackView/lessonFilterDetail/${this.projectLookback.id}`])
    }
  }

  DashBoardTabChange(tab){
    this.dashboardTab = tab.index;
    let currentStatus = this.enumMapDashboard[this.dashboardTab]
    if (currentStatus == "all")
    {
      this.currentVisibleProjectLookbacks = this.projectLookbackOperationResponse.projectLookbacks;
    }
    else {
      this.currentVisibleProjectLookbacks = this.projectLookbackOperationResponse.projectLookbacks.filter(x => x.status == currentStatus);
    }
  }

  LessonTabChange(tab) {
    this.lessonTab = tab.index;
  }

}