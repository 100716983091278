<div class="workflowStatusContainer">
  <div class="statusTitle" *ngIf="statusTitle">
    <h1> {{ statusTitle }} </h1>
  </div>
  <div class="statusContainer">
    <div class="progress-label-bar">current stage: {{ getCurrentStageLabel() }}</div>
    <div class="custom-progress-bar">
      <ng-container>
        <div class="progress-segment" [style.width.%]="getPercentage(currentStatus)">
        </div>
      </ng-container>
    </div>
    <div class="progress-label">{{ getNextStageLabel() }}</div>
  </div>
</div>