import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Facet } from "src/app/shared/models/search-options";

@Injectable({
  providedIn: "root",
})
export class PreviousStateRoutingService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public cacheSearchFilters(filters: any, facets: any) {
    sessionStorage.setItem("searchFilters", JSON.stringify(filters));
    sessionStorage.setItem("searchFacets", JSON.stringify(facets));
  }

  public getCachedFilters(): any {
    return JSON.parse(sessionStorage.getItem("searchFilters"));
  }

  public getCachedFacets(): Facet[] {
    return JSON.parse(sessionStorage.getItem("searchFacets"));
  }

  public clearCacheFilters() {
    sessionStorage.removeItem("searchFilters");
    sessionStorage.removeItem("searchFacets");
  }
}
