import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-verfiy-reportedlesson",
  templateUrl: "./verfiy-reportedlesson.component.html",
  styleUrls: ["./verfiy-reportedlesson.component.scss"],
})
export class VerfiyReportedlessonComponent implements OnInit {
  public managerComments: string;

  constructor(public dialogRef: MatDialogRef<VerfiyReportedlessonComponent>) {}

  ngOnInit(): void {
    // This is intentional
  }

  confirm() {
    this.dialogRef.close({ managerComments: this.managerComments });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
