export const constants = {
  CrossVisibilityFieldsConst: [
    "ipLegalComplianceReviewQuestion1",
    "ipLegalComplianceReviewQuestion2",
    "ipLegalComplianceReviewQuestion3",
    "exportComplianceReviewQuestion1",
    "exportComplianceReviewQuestion2",
    "exportComplianceReviewQuestion3",
    "sendAsCesComment",
  ],
};
