import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Facet, FacetValue, SearchFacet, SearchOptions } from 'src/app/shared/models/search-options';
import { ConvertCodeToDescription } from 'src/app/shared/pipes/convert-code-to-description';
import { SharedModule } from 'src/app/shared/shared.module';
import { LessonInput } from '../../../lessons-list/lessons-table-card/lessons-table-card.component';
import { AttributeCore } from 'src/app/shared/models/configsV2/fieldConfigModel';
import { Lesson, User } from 'src/app/shared/models';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/home/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PackageService } from 'src/app/core/services/lesson-services/package.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ApplicationInsightsService } from 'src/app/core/http/application-insights.service';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { UniqueSearchEventModel } from 'src/app/shared/models/app-insights';
import { ProjectLookbackCommonService } from 'src/app/core/services/project-lookback-services/project-lookback-common.service';
import { AppRoles, ExtensionType, SearchScopeEnum } from 'src/app/config/global-enums.config';
import { AthenaSearchService } from 'src/app/core/http/athena-search.service';
import { CreateEditSingleLessonService } from 'src/app/core/services/lesson-services/create-edit-single-lesson.service';
import { LessonService } from 'src/app/core/http/lesson.service';
import { SqlServerService } from 'src/app/core/http/sql-server.service';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';
import { LessonStatus, LessonTabStatus } from 'src/app/shared/models/project-lookback-status';
import { ExportCsvService } from 'src/app/core/services/lesson-services/export-csv.service';

@Component({
  selector: 'app-project-lookback-lesson-filters',
  templateUrl: './project-lookback-lesson-filters.component.html',
  styleUrls: ['./project-lookback-lesson-filters.component.scss'],
  providers: [SharedModule, ConvertCodeToDescription],
})
export class ProjectLookbackLessonFiltersComponent implements OnInit {
  page = 1;
  pageSort: string = "popular-scoring-profile";
  items = 1;
  filterShowMore = new Array(10).fill(false);

  selectedTabIndex = new FormControl(0);
  public tabs = Object.values(LessonTabStatus).map((status) => ({
      key: status,
      label: status,
  }));
  //Role attribute for R2 release
  role: string = "";
  userBU: string = "";
  facets: Facet[] = []; //UI: Binding
  searchFacets: SearchFacet[] = [];
  allLessons: LessonInput[] = [];
  filteredLessons: LessonInput[] = [];
  searchOptions: SearchOptions = new SearchOptions();
  importLogs: any = [];

  show: boolean = false;
  scope: SearchScopeEnum = SearchScopeEnum.ProjectCodes;
  searchText: string = "";
  sortByoption = [
    { key: "most-recent-scoring-profile", label: "most recent" },
  ];
  itemsPerPage = 10;
  removable = true;
  currentLessonsSource: "athena" | "ell" = "ell";
  workflowType: string = "Lesson";
  showCards: boolean; //For cards implementation
  fieldConfigCore = new Array<{ key: string; value: AttributeCore }>();
  savedFacets: Facet[] = []; //To get history facets
  refresh: boolean = false;

  public lesson: Lesson;

  public currentUser: User;
  public subscriptions: Subscription = new Subscription();
  pageTitle = "Lessons";

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public packageService: PackageService,
    public codeToDescription: ConvertCodeToDescription,
    private toastService: ToastService,
    public commonService: CommonService,
    private appInsights: ApplicationInsightsService,
    public projectLookbackService: CreateEditProjectLookbackService,
    public athenaSearchService: AthenaSearchService,
    private lessonService: LessonService,
    private sqlServerService: SqlServerService,
    private exportCSVService: ExportCsvService
  ) {}

  ngOnInit(): void {
    this.projectLookbackService.checkForPlb();
    this.loadLocalCaches();
    this.currentUser = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.searchOptions.scope = this.scope;
    this.searchOptions.keyWords = this.searchText;
    this.searchOptions.size = this.itemsPerPage;
    this.searchOptions.userBU = this.userBU;
    if (this.projectLookbackService.projectLookback.projectCode !== null) {
      this.searchOptions.projectCodes = this.projectLookbackService.projectLookback.projectCode;
    }
    this.refresh = true;
    this.performSearch(true);
  }

  clearAllFacetFilters() {
    for (let facet of this.facets) {
      for (let sel of facet.facetValues.filter((sel) => sel.selected)) {
        sel.selected = false;
      }
    }
  }

  setSearchFilter() {
    this.searchFacets = [];
    for (let facet of this.facets) {
      if (facet.facetValues.some((sel) => sel.selected)) {
        let searchFacet = new SearchFacet();
        searchFacet.key = facet.key;
        searchFacet.value = facet.facetValues
          .filter((i) => i.selected)
          .map((s) => s.value)
          .join(",");
        this.searchFacets.push(searchFacet);
      }
    }
    if(this.refresh)
    {
      this.setHistoryData();
      this.refresh = false;
    }

  }

  getChips() {
    return this.facets
      .flatMap((facet) => {
        return facet.facetValues;
      })
      .filter((a) => a.selected);
  }

  removeChip(current) {
    this.facets[current.facetIndex].facetValues[
      current.selectedIndex
    ].selected = false;
    this.performSearch(true);
  }

  onItemsPerPageChange() {
    this.page = 1;
  }

  sortByOption() {
    if(!this.pageSort) {
      return;
    }
    this.show = false;
    this.allLessons.sort((a, b) => {
        const descA = new Date(a.dateOfSubmission).getTime();
        const descB = new Date(b.dateOfSubmission).getTime();
        return descB - descA;
      })
    this.show = true;
  }

  verifyFacet(facet: Facet, selectedFilter: FacetValue, event: any) {
    if (facet.key == "ImportId") {
      if (facet.facetValues.filter((i) => i.selected).length > 1) {
        this.toastService.showError(true,
          `Sorry! We only allow one import at a time to filter`
        );
        facet.facetValues.find(
          (x) => x.value == selectedFilter.value
        ).selected = false;
        event.currentTarget.checked = false;
      } else {
        this.performSearch(true);
      }
    } else {
      this.performSearch(true);
    }
  }

  public logApplicationStatistics(
    searchScope: any,
    searchText: any,
    facets: any
  ) {
    let uniqueSearchModel = new UniqueSearchEventModel();
    uniqueSearchModel.scope = searchScope;
    uniqueSearchModel.searchText = searchText;
    uniqueSearchModel.userFullName = this.currentUser.fullName;
    uniqueSearchModel.userUniqueKey = this.currentUser.uniqueKey;
    uniqueSearchModel.userBusinessUnit = this.currentUser.businessUnit;
    uniqueSearchModel.facets = facets.map((x) => ({ key: x.key, ...x }));
    this.appInsights
      .createUniqueSearchCustomEvent(uniqueSearchModel)
      .subscribe();
  }

  
  convertFacetLabelName(facetName){
    switch(facetName){
      case "asset":
        return "asset";
      case "level2C":
        return "Asset Class";
      case "businessUnit":
        return "Business Unit";
      case "cop":
        return "CoP";
      case "businessUnit":
        return "Business Unit";
      case "discipline":
        return "Discipline";
      case "primaryImpact":
        return "Primary Impact";
      case "lessonWorkflowType":
        return "Lesson Workflow";
      case "department":
        return "Department";
      case "team":
        return "Team";
      case "relatedProcess":
        return "Related Process";
      case "lookbackCategory":
        return "Lookback Category";
      case "status":
        return "Lesson Status";
      case "subType":
        return "Sub-Type";
      case "type":
        return "Type";
      case "projectCodes":
        return "Project Lookback Name";
      case "isEnterprise":
        return "Shared Enterprise Wide";
      default:      
        break;
    }
  }

  public loadLocalCaches() {

    // Tol load attributes from field config core and copconfig, gomFeconfig
    this.commonService.loadConfig();
    this.fieldConfigCore = [].concat(
      ...this.commonService.loadGroups().map((field) => field.value),
      ...this.commonService.loadGroups().map((field) => field.value)
    );

    // Add archive tab if user role is ELL manager R2 Release
    this.role = sessionStorage.getItem("roles");
    this.userBU = sessionStorage.getItem("businessunit");

  }

  public setHistoryData() {
    if (this.projectLookbackService.choosingDiscipline)
    {
      let searchFacet = new SearchFacet();
      searchFacet.key = 'discipline';
      searchFacet.value = this.projectLookbackService.choosingDiscipline;
      this.searchFacets.push(searchFacet);
    }
    else{
      this.filteredLessons  = this.allLessons.filter(x => x.discipline == null);
    }
    this.projectLookbackService.choosingDiscipline = "";
  }

  public loadSearchIndexes(facets: Facet[]) {
    if (this.savedFacets?.length <= 0) {
      this.facets = [];
      facets
        .filter((facet) => facet.facetValues.length !== 0)
        .forEach((singleFacet, index) => {
          let facet = new Facet();
          let facetKey = singleFacet.key;
          facet.label = this.fieldConfigCore.find(
            (fieldid) => fieldid.key == facetKey
          )?.value?.feMetadata.formLabel;
          facet.key = singleFacet.key;
          let fieldReferenceEnum = this.fieldConfigCore.find(
            (fieldid) => fieldid.key == facetKey
          )?.value?.feMetadata.referenceConfigEnum;
          let referenceConfigValue =
            this.commonService.references[fieldReferenceEnum];
          facet.totalCount = singleFacet.facetValues.length;
          facet.expanded = this.searchFacets.find((f) => f.key === facet.key)
            ? true
            : false;
          facet.facetValues = singleFacet.facetValues.map((facet, i) => ({
            facetLabel: facetKey,
            code: facet.value,
            description:
              facetKey != "importId"
                ? this.codeToDescription.transform(
                    facet.value,
                    referenceConfigValue
                  )
                : this.importLogs.find((l) => l.id == facet.value)
                    ?.importDescription,
            selected: this.searchFacets.find(
              (f) => f.value.indexOf(facet.value) > -1
            )
              ? true
              : false,
            facetIndex: index,
            selectedIndex: i,
            count: facet.count,
            value: facet.value,
            longDescription:
              facetKey != "importId"
                ? ""
                : this.importLogs.find((l) => l.id == facet.value)
                    ?.longDescription,
          }));
          this.facets.push(facet);
          this.filterShowMore = new Array(this.facets.length).fill(false);
        });

    }
    this.savedFacets = []; //empty the history facets after it is utilized.
  }

  setSearchParameters() {
    this.setSearchFilter();
    this.searchOptions.scope = this.scope;
    this.searchOptions.keyWords = this.searchText;
    this.searchOptions.size = this.itemsPerPage;
    this.searchOptions.skip = this.itemsPerPage * (this.page - 1);
    this.searchOptions.SearchFacets = this.searchFacets;
    this.searchOptions.scoringProfile = this.pageSort;
  }

  performSearch(resetCount: boolean = false) {
    this.setSearchParameters();
    if (
      (this.searchOptions.keyWords != "" &&
        this.searchOptions.keyWords != null) ||
      this.searchOptions.SearchFacets.length > 0
    ) {
      this.logApplicationStatistics(
        this.searchOptions.scope,
        this.searchOptions.keyWords,
        this.searchOptions.SearchFacets
      );
    }
    this.toastService.showRetrievingMessage(true, "Please wait a moment as we get the lessons.");
    this.lessonService
      .searchLessonsFromSql(this.searchOptions)
      .subscribe((response) => {
        if (resetCount) {
          let facets = response.facets;
          if (
            facets.find((f) => f.key == "importId") &&  facets.find((f) => f.key == "importId").facetValues.length > 0
          ) {
            let importIndex = facets.findIndex((x) => x.key == "importId");
            let importLogIds = facets[importIndex].facetValues
              .map((s) => s.value)
              .join(",");
            this.sqlServerService.getImportLogs(importLogIds).subscribe(
              (result) => {
                this.importLogs = result;
                this.loadSearchIndexes(facets);
              },
              (error) => this.loadSearchIndexes(facets)
            );
          } else {
            this.loadSearchIndexes(facets);
          }
        }

        this.allLessons = response.lessons;
        this.currentLessonsSource = "ell";
        this.items = response.recordCount;
        this.showCards = true;
        this.snackBar.dismiss();
        this.filteredLessons = [];
        let currentStatus = this.projectLookbackService.enumMapLesson[this.projectLookbackService.lessonTab];
        if (currentStatus == "all")
        {
          this.pageTitle = "Lessons";
          this.filteredLessons = this.allLessons;
        }
        else {
          switch(currentStatus){
            case "draft":
              this.pageTitle = "Lessons in Draft";
              break;
            case "review":
              this.pageTitle = "Lessons in Review";
              break;
            case "rejected":
              this.pageTitle = "Rejected Lessons";
              break;
            case "approved":
              this.pageTitle = "Approved Lessons";
            case "publish":
              this.pageTitle = "Published Lessons"
              break;
          }
          this.filteredLessons  = this.allLessons.filter(x => x.status == currentStatus);
        }
      });

    // this.filteredLessons = this.filteredLessons.filter(x => {
    //   return x.lessonWorkflowType == ExtensionType.plbLessonWorkflow
    // })
  }

  public exportLessonsCSV(data: LessonInput[]) {
    if (this.currentLessonsSource != "ell") {
      return;
    }
    this.exportCSVService.exportToCsv(
      "COMPANY_CONFIDENTIAL_LessonsLearnedExport-" +
        new Date().toLocaleString() +
        ".csv",
      data as Lesson[],
      this.fieldConfigCore
    );
  }


}
