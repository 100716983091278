import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CreateEditPublishLessonComponent } from "./create-edit-publish-lesson.component";
import { SharedModule } from "../../../../shared/shared.module";
import { CoreModule } from "../../../../core/core.module";
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { TextInputHighlightModule } from "angular-text-input-highlight";
import { ViewLessonComponent } from './view-lesson/view-lesson.component';
import { CreateEditLessonFormComponent } from "./create-edit-lesson-form/create-edit-lesson-form.component";
import { SubmittedLessonComponent } from './submitted-lesson/submitted-lesson.component';

const routes: Routes = [
  { path: '', component: ViewLessonComponent},
  { path: 'submittedLesson/:id', component: SubmittedLessonComponent}
  
]


@NgModule({
  declarations: [CreateEditPublishLessonComponent, ViewLessonComponent, CreateEditLessonFormComponent, SubmittedLessonComponent],
  imports: [
    SharedModule,
    CoreModule,
    //TextInputHighlightModule,
    RouterModule.forChild(routes),
    CreateEditSharedModule,
  ],
})
export class CreateEditPublishLessonModule {}
