import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LessonSearchResponse } from "src/app/shared/models/search-options";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FlaggingLessonService {
  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}FlaggingLessons`;

  constructor(private http: HttpClient) {}

  validateLesson(lessonid: string): Observable<LessonSearchResponse> {
    const endPoint = `${this.apiRoot}/validate/${lessonid}`;
    return this.http.get<LessonSearchResponse>(endPoint);
  }
}
