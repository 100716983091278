import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { ShareProjectLookbackComponent } from '../../../dialogs/share-project-lookback/share-project-lookback.component';
import { ProjectLookbackService } from 'src/app/core/http/project-lookback.service';
import { ProjectLookback, User } from 'src/app/shared/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submitted-project-lookback',
  templateUrl: './submitted-project-lookback.component.html',
  styleUrls: ['./submitted-project-lookback.component.scss']
})
export class SubmittedProjectLookbackComponent implements OnInit{

  pageTitle = ""
  id: string = "";
  projectAdmins: User[];
  participant: User[];
  projectLookback: ProjectLookback;
  constructor(
    public route: ActivatedRoute,
    public projectLookbackService: CreateEditProjectLookbackService,
    public ProjectLookbackService: ProjectLookbackService,
    public dialog: MatDialog,
    private router: Router
  ){}

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.pageTitle = `Project Lookback with Code: ${this.id} - has been transitioned`;
    this.getData();
  }

  async getData() {
    this.ProjectLookbackService.getProjectLookback(this.id).subscribe(result => {
      if(result.projectLookbacks.length > 0 && result.projectLookbacks[0] != null)
      {
        this.projectLookback = result.projectLookbacks[0];
        this.projectAdmins = this.projectLookback.projectAdmins;
        this.participant = this.projectLookback.participants;
        
      }

    })
  }

  navigateBackToHomePage() {
    this.router.navigate(["/home"]);
  }

  navigateToDashboard() {
    this.router.navigate(["/projectLookbackView"]);
  }


  shareCode() {
    const dialogRef = this.dialog.open(ShareProjectLookbackComponent, {
      width: "40%",
      position: {},
      autoFocus: false,
      minHeight: "40vh", // you can adjust the value as per your view,
      data: {
        code: this.id,
        admin: this.projectAdmins,
        participant: this.participant
      },
    });
  }

}
