import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "src/app/core/core.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { ProjectLookbackComponent } from "./project-lookback.component";
//import { CreateEditPublishProjectLookbackComponent } from "./create-edit-publish-project-lookback/create-edit-publish-project-lookback/create-edit-publish-project-lookback.component";
import { ProjectLookbackDashboardComponent } from './project-lookback-dashboard/project-lookback-dashboard.component';
import { CreateEditPublishProjectLookbackComponent } from './create-edit-publish-project-lookback/create-edit-publish-project-lookback.component';
import { SubmittedProjectLookbackComponent } from './submitted-project-lookback/submitted-project-lookback.component';
import { ProjectLookbackCardComponent } from './project-lookback-card/project-lookback-card.component';
import { ProjectLookbackBucketComponent } from "./project-lookback-bucket/project-lookback-bucket.component";
import { CreateEditPublishPlbLessonComponent } from "./project-lookback-bucket/create-edit-publish-plb-lesson/create-edit-publish-plb-lesson.component";
import { ProjectLookbackLessonFiltersComponent } from './project-lookback-bucket/project-lookback-lesson-filters/project-lookback-lesson-filters.component';
import { ProjectLookbackLessonsTableCardComponent } from './project-lookback-bucket/project-lookback-lesson-filters/project-lookback-lessons-table-card/project-lookback-lessons-table-card.component';

const routes: Routes = [
  { path: '', component: ProjectLookbackDashboardComponent }, 
  { path: 'create', component: CreateEditPublishProjectLookbackComponent},
  { path: 'submitProject/:id', component: SubmittedProjectLookbackComponent},
  { path: 'projectLookbackBucket/:id', component: ProjectLookbackBucketComponent},
  { path: 'lessonFilterDetail/:id', component: ProjectLookbackLessonFiltersComponent},
  { path: 'addProjectLookbackLesson', component: CreateEditPublishPlbLessonComponent}
];

@NgModule({
  declarations: [
    CreateEditPublishProjectLookbackComponent,
    ProjectLookbackDashboardComponent,
    CreateEditPublishProjectLookbackComponent,
    SubmittedProjectLookbackComponent,
    ProjectLookbackCardComponent,
    ProjectLookbackBucketComponent,
    CreateEditPublishPlbLessonComponent,
    ProjectLookbackLessonFiltersComponent,
    ProjectLookbackLessonsTableCardComponent,
    
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    OverlayModule,
    CreateEditSharedModule
  ],
  exports: [

  ]
})
export class ProjectLookbackModule {}