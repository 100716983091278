<div class="container">
  <div class="col col-11 dialogHeader">
    <span class="material-symbols-outlined closebtn" (click)="cancel()">
      &times;
    </span>
  </div>
  <div class="instruction">
      <h6>please select a different column property: </h6>
  </div>

  <div class="input">
    <mat-form-field class="columnFieldsDropdown" appearance="outline">
      <mat-select
        class="mat-select-style"
        panelClass="mat-select-panel-class"
        [(ngModel)]="selectedColumn"
        placeholder="select one"
      >
        <mat-option *ngFor="let item of visibleFieldsByStatus" [value]="item">
          <span>{{ bulkEditService.formLabel(item) }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex flex-row justify-content-end mt-4" style="gap: 1rem">
    <button
      type="button"
      mat-button
      class="btn"
      (click)="cancel()"
      id="cancelPrimaryInputDialog"
    >
      cancel
    </button>
    <button
      type="button"
      id="confirmPrimaryInputDialog"
      [mat-dialog-close]="true"
      class="btn btn-primary"
      (click)="confirm()"
      [disabled]="!selectedColumn"
    >
      confirm
    </button>
  </div>


</div>