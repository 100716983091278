import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/shared/models';
import { UserRole } from 'src/app/shared/models/user-role';
import { ParsingType } from '../../pages/coordinator-dashboard/dashboard-landing/dashboard-landing.component';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';
import { iif } from 'rxjs';

@Component({
  selector: 'app-add-new-manager',
  templateUrl: './add-new-manager.component.html',
  styleUrls: ['./add-new-manager.component.scss']
})
export class AddNewManagerComponent {

  currentUser: User;
  currentDiscipline: ParsingType;
  currentTriggerValue: string = '';
  copSubGroupTriggerValue: string = '';
  selectedValue: ParsingType;
  availableDiscipline: ParsingType[] = [];
  availableTriggerValues: ParsingType[] = [];
  hasSubGroup: boolean = false;
  subGroups: any;

  constructor(
    public dialogRef: MatDialogRef<AddNewManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    if(this.data.role == UserRole.TECHAPR)
    {
      this.availableTriggerValues = this.data.values;
    }
    if(this.data.role == UserRole.ECAPR){
      this.availableTriggerValues = this.data.values;
    }
  }

  getUserInfo(user) {
    if(user) {
      let newUser = new User()
      newUser.uniqueKey = user.mail;
      newUser.firstName = user.givenName;
      newUser.lastName = user.surname;
      newUser.email = user.mail;
      newUser.fullName = `${newUser.lastName}, ${newUser.firstName}`;
      this.currentUser = newUser;
    }
  }

  convertRoleName(role){
    switch(role){
      case "Manager":
        return "manager"
      case "TechnicalApprover":
        return "technical approver"
      case "LegalApprover":
        return "legal approver"
      case "EcApprover":
        return "ec approver"
    }
  }

  onValueChange(event: any){
    let validSubGroups = this.commonService.references["CopSubGroup"];
    this.hasSubGroup = this.commonService.crossReferenceConfig.CopCopSubGroup.some(val => val.cop == event.value);
    if(this.hasSubGroup){
      let relatedSubGroups = this.commonService.crossReferenceConfig.CopCopSubGroup.filter(
        (copSubGroup) => copSubGroup.cop == event.value
      );
      this.subGroups = relatedSubGroups.map(subGroup => {
        return validSubGroups.find(vsg => vsg.Code === subGroup.copSubGroup);
      });
    }
  }

  cancel() {
    this.dialogRef.close({update: false, newUser: null, value: null});
  }

  submit() {
    if(this.copSubGroupTriggerValue){
      this.dialogRef.close({update: true, newUser: this.currentUser, value: this.copSubGroupTriggerValue });
    }
    else{
      this.dialogRef.close({update: true, newUser: this.currentUser, value: this.currentTriggerValue });
    }
  }
}
