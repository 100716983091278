<div class="row">
  <div class="col col-11 left-align packageClass">
    <h6>{{ title }}</h6>
  </div>
  <div class="col col-1">
    <span class="closebtn" (click)="cancel()">
      &times;
    </span>
  </div>
</div>
<div class="row py-1">
  <div class="col col-12 left-align paddingmarginTopBottom0 paddingtop10">
    {{ textBody }}
  </div>
</div>
<div class="row py-2">
  <div class="col col-12 d-flex justify-content-between dialogButtons">
    <button type="button" 
    class="btn btn-outline-primary" 
    (click)="cancel()"
    id="cancelButton">
      cancel
    </button>
    <button
      type="button"
      [mat-dialog-close]="true"
      class="btn btn-primary"
      (click)="confirm()"
      id="confirmButton"
    >
      confirm
    </button>
  </div>
</div>
