<div class="row">
  <div class="col col-11 left-align packageClass">
    <h6>legacy data</h6>
  </div>
  <div class="col col-1">
    <span class="closebtn" (click)="cancel()">
      &times;
    </span>
  </div>
</div>
<mat-dialog-content>
  <div class="row legacyDataContent">
    <div class="col col-12 left-align paddingmarginTopBottom0 paddingtop30">
      <table>
        <tr>
          <th></th>
        </tr>
        <tr *ngFor="let item of legacyDataMapper; let i = index">
          <td class="legacyData" style="vertical-align: top">
            {{ item.key }}:
          </td>
          <td class="left-align">
            <div
              class="legacyData"
              style="word-wrap: word-break; text-align: justify"
            >
              <b>{{ item.value }}</b>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<div>
  <div class="col col-12 left-align paddingmarginTopBottom0 paddingtop10">
    <mat-dialog-actions align="end">
      <button class="btn btn-outline-primary" id="cancelDialog" (click)="cancel()">cancel</button>
    </mat-dialog-actions>
  </div>
</div>
