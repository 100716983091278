import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastService } from "src/app/core/services/toast.service";
import { CreateEditBulkLessonService } from "src/app/core/services/lesson-services/create-edit-bulk-lesson.service";

@Component({
  selector: "app-dialog-column-setting",
  templateUrl: "./column-setting.component.html",
  styleUrls: ["./column-setting.component.scss"],
})
export class ColumnSettingComponent implements OnInit {
  draggingIndex: number;
  selectedColumns: string[];
  visibleFieldsByStatus: string[];
  selectedColumn: string;
  index: number;

  constructor(
    public dialogRef: MatDialogRef<ColumnSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public bulkEditService: CreateEditBulkLessonService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.selectedColumns = this.data.selectedColumns;
    this.visibleFieldsByStatus = this.data.editableFieldByStatus;
    this.index = this.data.columnIndex;
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    if (this.selectedColumns.indexOf(this.selectedColumn) < 0) {
      this.selectedColumns.splice(this.index, 1);
      this.selectedColumns.splice(this.index, 0, this.selectedColumn);
    } else {
      this.toastService.showError(true, 
        `Uh-oh! ${this.bulkEditService.formLabel(
          this.selectedColumn
        )} already exists in the table. Please select different column.`
      );
    }
    this.dialogRef.close({ selectedColumns: this.selectedColumns });
  }
}
