import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "../../shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { Cop } from "../../shared/models/cop";


@Injectable({
  providedIn: "root",
})
export class CopService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}Cop`;

    public getCop(code: string): Observable<Cop> {
      return this.http.get<Cop>(`${this.apiRoot}/getCop/${code}`);
    }

    public getAllCops(): Observable<Cop[]> {
      return this.http.get<Cop[]>(`${this.apiRoot}/getAllCops`);
    }



}