import { Injectable } from "@angular/core";
import { Lesson, User } from "src/app/shared/models";
import { FormGroup, Validators } from "@angular/forms";
import { ProjectLookback, ProjectLookbackFieldUpdate } from "src/app/shared/models/project-lookback";
import { environment } from "src/environments/environment";
import { ProjectLookbackCommonService } from "src/app/core/services/project-lookback-services/project-lookback-common.service";
import { ExtensionType } from "src/app/config/global-enums.config";
import { WorkflowType } from "src/app/shared/models/configsV2/workflowConfigModel";
import { filter, firstValueFrom, forkJoin, lastValueFrom, Subscription } from "rxjs";
import { CollectionAction, Statuses } from "../../../config/global-enums.config";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogMessageComponent } from "../../../modules/home/dialogs/dialog-message/dialog-message.component";
import { Router } from "@angular/router";
import { AttachmentService } from "../lesson-services/attachment.service";
import { ToastService } from "../toast.service";
import { AttributeCore } from "src/app/shared/models/configsV2/fieldConfigModel";
import { PreviousStateRoutingService } from "../previous-state-routing.service";
import { SharedService } from "../../../modules/home/shared.service";
import { ProjectLookbackOperationResponse } from "src/app/shared/models/project-lookback-operation-response";
import { EvalconfigService } from "../lesson-services/eval-config.service";
import { ProjectLookbackService } from "../../http/project-lookback.service";
import { Status } from "@cucumber/cucumber";
import { ProjectLookbackEvalConfigService } from "../project-lookback-services/project-lookback-eval-config.service";
import { UserService } from "../../http/user.service";
import { LessonInput } from "src/app/modules/home/pages/lessons-list/lessons-table-card/lessons-table-card.component";
import { LessonStatus, LessonTabStatus, ProjectLookbackStatus } from "src/app/shared/models/project-lookback-status";
import { LessonService } from "../../http/lesson.service";
import { UserRole } from "src/app/shared/models/user-role";


@Injectable({
  providedIn: "root",
})
export class CoordinatorService{


  userProfile: User;
  

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  onInit() {
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
  }

  async getManagersByBu(bu)
  {
    let res = await firstValueFrom(this.userService.getUserByFilters(bu, UserRole.MGR));
    return res;
  }

  async getTechAprsByBu(bu)
  {
    let res = await firstValueFrom(this.userService.getUserByFilters(bu, UserRole.TECHAPR));
    return res;
  }

  async getEcApproversByBu(bu){
    let res = await firstValueFrom(this.userService.getUserByFilters(bu, UserRole.ECAPR));
    return res;
  }

  async getLegalApproversByBu(bu){
    let res = await firstValueFrom(this.userService.getUserByFilters(bu, UserRole.LGAPR));
    return res;
  }

}